import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { EmptyObject } from 'react-hook-form'
import {
  FetchCancelProposalRequest,
  FetchInstallmentRequest,
  MutationAcceptPaymentPlanResponse,
  MutationAddTuitionRequest,
  MutationAddTuitionToCampaignResponse,
} from './types'

export const contractsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for cancel proposal
     */
    cancelProposal: async ({
      enrollmentId,
      schoolId,
      params,
    }: FetchCancelProposalRequest): Promise<EmptyObject> => {
      const { data } = await fetcher.patch(
        `v1/enrollment/${schoolId}/${enrollmentId}/cancel`,
        params
      )

      return data
    },
    /**
     * Function responsible for add tuition
     */
    addTuitionToCampaign: async ({
      body,
      campaignId,
    }: MutationAddTuitionRequest): Promise<MutationAddTuitionToCampaignResponse> => {
      const { data } = await fetcher.post(`v1/campaign/${campaignId}/bulk-add-installments`, body)

      return data
    },
    /**
     * TODO: add description
     * @param enrollmentId
     * @returns
     */
    acceptPaymentPlan: async (enrollmentId: uuid): Promise<MutationAcceptPaymentPlanResponse> => {
      const { data } = await fetcher.post<MutationAcceptPaymentPlanResponse>(
        `v1/campaign/enrollments/${enrollmentId}/accept-payment-plan`,
        {
          accepted_by: 'pi',
        }
      )

      return data
    },
    /**
     * Function responsible for fetching an installment
     *
     * @param id Installment ID
     * @param params Request params
     *
     * @returns Installment data
     */
    fetchInstallment: async (
      id: uuid,
      params?: AxiosRequestConfig
    ): Promise<FetchInstallmentRequest> => {
      const { data } = await fetcher.get<FetchInstallmentRequest>(`/installment/${id}`, params)

      return data
    },
  }
}
