import { Grid, GridItem } from '@gravity/grid'
import { Text } from '@gravity/text'
import { InteractiveCard } from '@gravity/interactive-card'

import { StudentFinancesGuardian } from '@monorepo/students/services/students/types'

import { SectionErrorState } from '../../SectionErrorState'
import { GuardiansLoading } from '../GuardiansLoading'

import { Container } from './styles'

export type GuardiansContentProps = {
  guardians?: StudentFinancesGuardian[]
  hasError: boolean
  isLoading: boolean
  onNavigateToGuardian: (guardianId: string) => void
  onRetry?: () => void
}

export const GuardiansContent = ({
  hasError,
  isLoading,
  onRetry,
  onNavigateToGuardian,
  guardians,
}: GuardiansContentProps) => {
  if (hasError && !!onRetry) {
    return <SectionErrorState onRetry={onRetry} />
  }

  return (
    <Container>
      <Grid className="grid-container">
        {isLoading && <GuardiansLoading />}
        {!isLoading &&
          guardians?.map(guardian => (
            <GridItem lg={4} xl={4} md={8} sm={4} key={guardian.id}>
              <InteractiveCard fullWidth onClick={() => onNavigateToGuardian(guardian.id)}>
                <div className="content">
                  <Text variant="body-2-regular" className="title">
                    Financeiro
                  </Text>
                  <Text variant="subtitle-regular" data-testid="guardian-name" className="name">
                    {guardian.name}
                  </Text>
                </div>
              </InteractiveCard>
            </GridItem>
          ))}
      </Grid>
    </Container>
  )
}
