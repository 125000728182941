import { useQuery } from '@/shared/hooks/useQuery'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  PaymentDisputeFilterQueryParams,
  PaymentDisputeFilterType,
} from '../../pages/PaymentDispute/types'
import { PaymentDisputeSummaryState } from '../../models/PaymentDisputeSummaryState.enum'

export const usePaymentDisputeFilter = (initialStatusValue: PaymentDisputeSummaryState[] = []) => {
  const { query, setMultipleQueryParams } = useQuery()

  const getFiltersFromUrl = (
    defaultStatusValues: PaymentDisputeSummaryState[] = []
  ): PaymentDisputeFilterType => {
    return {
      status:
        (query.get('status')?.split(',') as PaymentDisputeSummaryState[]) ?? defaultStatusValues,
    }
  }

  const [paymentDisputeFilter, setPaymentDisputeFilter] = useState<PaymentDisputeFilterType>(() =>
    getFiltersFromUrl(initialStatusValue)
  )

  useEffect(() => {
    const checkIfHasChanges = (
      urlFilter: PaymentDisputeFilterType,
      stateFilter: PaymentDisputeFilterType
    ) => {
      let hasChanges = false

      Object.keys(stateFilter).every(key => {
        if (
          stateFilter[key as keyof PaymentDisputeFilterType].toString() !==
          urlFilter[key as keyof PaymentDisputeFilterType].toString()
        ) {
          hasChanges = true

          return false
        }

        return true
      })

      return hasChanges
    }

    const urlPaymentDisputeFilter = getFiltersFromUrl()

    const hasChangesOnFilter = checkIfHasChanges(urlPaymentDisputeFilter, paymentDisputeFilter)

    if (hasChangesOnFilter) {
      setPaymentDisputeFilter(urlPaymentDisputeFilter)
    }
  }, [query])

  const updateFilter = useCallback(
    (
      updatedFilter: PaymentDisputeFilterType = {
        status: [],
      }
    ) => {
      const data: Array<{
        name: PaymentDisputeFilterQueryParams
        value: string
      }> = [
        {
          name: 'status',
          value: updatedFilter.status.join(','),
        },
      ]

      setMultipleQueryParams(data, 'replace')
    },
    [setMultipleQueryParams]
  )

  useEffect(() => {
    updateFilter(paymentDisputeFilter)
  }, [])

  return useMemo(
    () => ({
      paymentDisputeFilter,
      updateFilter,
    }),
    [paymentDisputeFilter, updateFilter]
  )
}
