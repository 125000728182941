import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks'
import { onboardingAPI } from '../services/onboardingApi'
import { SubmitBatchParams } from '../services/types/onboardingBatch'

export const usePostSubmitBatchMutation = (
  options?: UseMutationOptions<any, unknown, SubmitBatchParams, unknown>
) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useMutation(async (params: SubmitBatchParams) => {
    const response = await api.postSubmitBatch(params)

    return response.data
  }, options)
}
