import { useState } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'

import { filterContractsForm } from '@monorepo/students/models/filterContractsSchema'
import { TableFilter } from './types'
import { ContractsFilter } from '../useQueryParamFilters/types'

type useFilterContractsType = {
  emptyForm: filterContractsForm
  form: UseFormReturn<filterContractsForm>
  referenceYearsOptions: string[]
  removeFilterParam: (filter: ContractsFilter) => void
  updateFilterParam: (filter: ContractsFilter) => void
}

export const useFilterContracts = ({
  updateFilterParam,
  removeFilterParam,
  referenceYearsOptions,
  form,
}: useFilterContractsType) => {
  const [isFilterDialogOpen, toggleIsFilterDialogOpen] = useState(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { setValue } = form

  const handleOpenFilterDialog = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.CONTRACTS_FILTER,
      })
    }

    toggleIsFilterDialogOpen(true)
  }

  const handleCloseFilterDialog = () => {
    toggleIsFilterDialogOpen(false)
  }

  const handleRemoveFilter = (filter: TableFilter) => {
    const convertedFilter = filter.value

    const filterToRemove = form
      .getValues()
      .reference_years?.find(year => year.value === convertedFilter)

    if (filterToRemove) {
      setValue(
        'reference_years',
        form.getValues().reference_years?.map(year => {
          if (year.value === convertedFilter) {
            return {
              ...year,
              selected: false,
            }
          }
          return year
        })
      )
    }

    removeFilterParam({
      reference_years: [convertedFilter],
    })
  }

  const onSubmitFilters: SubmitHandler<filterContractsForm> = ({ reference_years }) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.TO_SAVE_CONTRACTS_FILTER,
      })
    }

    updateFilterParam({
      reference_years:
        reference_years?.filter(year => year.selected)?.map(year => year.value) ?? [],
    })
    toggleIsFilterDialogOpen(false)
  }

  return {
    isFilterDialogOpen,
    referenceYearsOptions,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleRemoveFilter,
    onSubmitFilters,
    setValue,
  }
}
