import type { AxiosInstance } from 'axios'

import { paramsToQueryString } from '@/shared/utils'

import type { APIResponse, Pagination, OldProduct as Product, Student } from '@/shared/interfaces'
import type { ListStudentsFilters } from '@/shared/interfaces/student'
import type { ListProductsFilters } from '@/shared/interfaces/product'

export const utilsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetching products
     *
     * @param params Product request parameters
     *
     * @returns List of products
     */
    fetchProducts: async (params?: Pagination & ListProductsFilters) => {
      return (await fetcher.get<APIResponse<Product[]>>(`/product/?${paramsToQueryString(params)}`))
        .data
    },

    /**
     * Function responsible for fetching students
     *
     * @param params Student request parameters
     *
     * @returns List of students
     */
    fetchStudents: async (params?: Pagination & ListStudentsFilters) => {
      return (await fetcher.get<APIResponse<Student[]>>(`/student/?${paramsToQueryString(params)}`))
        .data
    },
  }
}
