import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@gravity/button'
import { Heading } from '@gravity/heading'

import { mdiChevronLeft } from '@mdi/js'
import Icon from '@mdi/react'

import { PageWrapper } from '@/shared/components/PageWrapper'
import { Container, Header, Iframe } from './styles'
import { Error } from '@/shared/components/Error'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetFidcReport } from '../../hooks/queries/fidcReport'

export const FidcConfirmationReports = () => {
  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()
  const { data, status } = useGetFidcReport()
  const [iframeStatus, setIframeStatus] = useState<typeof status | undefined>('loading')

  const Child = {
    loading: <CircularLoadingPlaceholder />,
    error: <Error />,
    success: (
      <>
        {iframeStatus === 'loading' ? <CircularLoadingPlaceholder /> : null}
        <Iframe
          id="fidc-report"
          src={data?.url}
          style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
          onLoad={() => setIframeStatus('success')}
        />
      </>
    ),
  }

  return (
    <PageWrapper>
      <Container>
        <Header>
          <Button
            variant="ghost"
            iconStart={<Icon path={mdiChevronLeft} />}
            onClick={() => push(`/${schoolSlug}/relatorios`)}
          >
            Voltar
          </Button>
          <Heading variant="heading-h3-medium" className="title">
            Relatório de validação de contratos 2025
          </Heading>
        </Header>
        {Child[status]}
      </Container>
    </PageWrapper>
  )
}
