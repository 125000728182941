import { useQuery } from '@/shared/hooks/useQuery'

import {
  EditGuardianFormMode,
  EditGuardianFormSection,
} from '@/escolas/router/responsavel/[guardianId]/contratos/constants'
import { clearCollapses } from '@/escolas/components/contract/EditRegistrationDrawer/EditRegistrationDrawer'

export enum StateQueryParamsEnum {
  ABRIR_CADASTRO = 'abrirCadastro',
  ADDRESS_SECTION = 'abrirEndereco',
  ATIVAR_EDICAO = 'ativarEdicao',
  GUARDIAN_SECTION = 'abrirResponsavel',
  STUDENT_SECTION = 'abrirAluno',
}

export const useGuardiansStateQueryParams = () => {
  const { query, setOnQueryParam } = useQuery()

  const states: Map<StateQueryParamsEnum, string> = new Map([
    [StateQueryParamsEnum.ABRIR_CADASTRO, query.get(StateQueryParamsEnum.ABRIR_CADASTRO)],
    [StateQueryParamsEnum.ADDRESS_SECTION, query.get(StateQueryParamsEnum.ADDRESS_SECTION)],
    [StateQueryParamsEnum.ATIVAR_EDICAO, query.get(StateQueryParamsEnum.ATIVAR_EDICAO)],
    [StateQueryParamsEnum.GUARDIAN_SECTION, query.get(StateQueryParamsEnum.GUARDIAN_SECTION)],
    [StateQueryParamsEnum.STUDENT_SECTION, query.get(StateQueryParamsEnum.STUDENT_SECTION)],
  ])

  const getObjectFromStates = () => {
    const statesObject = Object.fromEntries(states)
    return Object.entries(statesObject)
  }

  const setQueryParams = () => {
    const statesObject = getObjectFromStates()
    statesObject.forEach(([key, value]) => {
      setOnQueryParam(value, key as StateQueryParamsEnum, 'replace')
    })
  }

  const handleEditRegistrationDrawerClose = () => {
    const statesObject = getObjectFromStates()
    statesObject.forEach(([key]) => {
      query.delete(key as StateQueryParamsEnum)
    })

    clearCollapses(query)
  }

  const openEditRegistrationDrawer = (
    mode: EditGuardianFormMode = EditGuardianFormMode.VIEW_MODE,
    section: EditGuardianFormSection = EditGuardianFormSection.STUDENT_SECTION
  ): void => {
    states.set(StateQueryParamsEnum.ABRIR_CADASTRO, 'true')

    if (mode === EditGuardianFormMode.EDIT_MODE) {
      states.set(StateQueryParamsEnum.ATIVAR_EDICAO, 'true')
      states.set(
        StateQueryParamsEnum[(section as unknown) as keyof typeof StateQueryParamsEnum],
        'true'
      )
    }

    setQueryParams()
  }

  return {
    statesQueryParams: states,
    openEditRegistrationDrawer,
    handleEditRegistrationDrawerClose,
  }
}
