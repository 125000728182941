import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { colors } from '@gravity/tokens'
import { mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import styled from 'styled-components'
import { BatchProgress } from '../../services/types/onboardingBatch'
import { useJWT } from '@/shared/hooks'
import { useEffect } from 'react'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
  margin: 0 auto;
  text-align: center;
`

const StyledHeading = styled(Heading).attrs({
  variant: 'heading-h3-medium',
})`
  margin: 32px 0 16px;
`

export const DoneContent = ({ progress }: { progress: BatchProgress }) => {
  const { isBackofficeUser } = useJWT()
  const events = useEvents()

  useEffect(() => {
    events?.batchPageDoneViewed()
  }, [])

  if (!progress) {
    return null
  }

  if (progress.FAIL > 0) {
    const text = isBackofficeUser
      ? 'Exporte os contratos em arquivo .csv para identificar os erros. Faça uma nova coleta de dados destes contratos com as escolas e passe pelo fluxo de migração novamente.'
      : 'Para resolver esses erros, contate o seu analista de implantação. Todos os outros contratos foram migrados corretamente e estão disponíveis na Plataforma isaac.'

    return (
      <StyledRoot>
        <Icon
          path={mdiAlertCircleOutline}
          size={3}
          color={colors['colors-background-semantic-colors-error-1']}
        />
        <StyledHeading style={{ color: colors['colors-background-semantic-colors-error-1'] }}>
          {`${progress.FAIL} contratos tiveram erros de migração`}
        </StyledHeading>
        <Text>{text}</Text>
      </StyledRoot>
    )
  }

  const title = isBackofficeUser
    ? 'Todos os contratos foram migrados'
    : 'A Plataforma isaac está pronta para sua escola!'

  const text = isBackofficeUser
    ? 'A escola está pronta para operar com todos os contratos na Plataforma isaac.'
    : 'A partir de agora você vai realizar todas as operações da escola com praticidade e poucos cliques. Vamos lá?'

  return (
    <StyledRoot>
      <Icon
        path={mdiCheck}
        size={3}
        color={colors['colors-background-semantic-colors-success-1']}
      />
      <StyledHeading style={{ color: colors['colors-background-semantic-colors-success-1'] }}>
        {title}
      </StyledHeading>
      <Text>{text}</Text>
    </StyledRoot>
  )
}
