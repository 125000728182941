import styled from 'styled-components'
import { Text } from '@gravity/text'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { Theme } from '@/shared/@types/styled'

export const SplitInputRow = styled.div`
  margin-top: ${({ theme }) => theme.gravity.spacing[4]};

  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.gravity.spacing[6]};

  > div {
    flex: 1;
  }
`

export const DatepickerTrigger = styled(Datepicker.Trigger)`
  width: 100%;
`

export const InputRow = styled.div`
  margin-top: ${({ theme }) => theme.gravity.spacing[4]};
`

export const InputLabelWrapper = styled.div`
  margin: ${({ theme }) => theme.gravity.spacing[8]} 0 ${({ theme }) => theme.gravity.spacing[4]};
`

export const SelectWrapper = styled.div`
  width: 100%;
`

export const SelectFeedbackText = styled(Text)`
  margin-top: ${({ theme }) => theme.gravity.spacing[3]};
  margin-left: ${({ theme }) => theme.gravity.spacing[1]};

  color: ${({ theme }) => theme.gravity.colors['colors-background-semantic-colors-error-1']};
`

export const InputLabel = styled.div`
  margin-bottom: ${({ theme }) => theme.gravity.spacing[2]};
`

export const InputWrapper = styled.div<{ hasError: boolean }>`
  > span {
    margin-bottom: ${({ theme }) => theme.gravity.spacing[2]};
    line-height: 24px;
  }

  ${({ hasError }) =>
    hasError &&
    `
    button {
      --tw-ring-shadow: 0 0 #000 !important;
      border: 1px solid ${({ theme }: { theme: Theme }) =>
        theme.gravity.colors['colors-interaction-dangerous-1']};
    }
    span {
      color: ${({ theme }: { theme: Theme }) =>
        theme.gravity.colors['colors-interaction-dangerous-1']};
    }
  `}
`
