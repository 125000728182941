import { ReenrollmentStatus, ReEnrollmentSubStatus } from '@/shared/interfaces'
import { EnabledEnrollmentCycle } from '../components/StatusEnrollmentSection/hooks/useSelectCycle'

export const SIDEBAR_MENU_LEFT_BAR_WIDTH = '2px'

export const EMPTY_ENABLED_ENROLLMENT_CYCLE: EnabledEnrollmentCycle = {
  is_reenrollable: false,
  reference_year: 0,
  started_enrollment: false,
  student_status: ReenrollmentStatus.ENROLLED,
  student_status_details: [ReEnrollmentSubStatus.PRE_ENROLLMENT_PENDING],
  student_financial_situation: '',
}

// Installment creation is an async process. The business rule defined that
// "waitTime" seconds must be waited before trying to get the installment.
export const WAIT_TIME = 5000
