import styled from 'styled-components'

export const Container = styled.div`
  margin-left: -${props => props.theme.gravity.spacing[4]};

  .grid-container {
    row-gap: ${props => props.theme.gravity.spacing[4]};
    column-gap: ${props => props.theme.gravity.spacing[4]};
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .title {
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
  }

  .name {
    width: 26ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .loading {
    gap: ${props => props.theme.gravity.spacing[2]};
  }

  @media (min-width: 1430px) {
    margin-left: -${props => props.theme.gravity.spacing[10]};
  }
`
