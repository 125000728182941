import type { OptionType } from '../types'
import { ReEnrollmentSubStatus, ReenrollmentStatus } from '@/shared/interfaces'

export const useFilterOptions = (selectedYear: number) => {
  const statusOptions: OptionType[] = [
    { label: 'Não rematriculável', value: ReenrollmentStatus.NOT_REENROLLABLE },
    { label: 'Rematrícula disponível', value: ReenrollmentStatus.REENROLLMENT_AVAILABLE },
    { label: 'Matrícula em andamento', value: ReenrollmentStatus.ENROLLMENT_IN_PROGRESS },
    { label: 'Matrícula em risco', value: ReenrollmentStatus.ENROLLMENT_AT_RISK },
    { label: 'Matriculado', value: ReenrollmentStatus.ENROLLED },
  ]

  const getSubStatusOptions = (
    selectedYear: number
  ): { label: string; value: ReEnrollmentSubStatus }[] => {
    return [
      {
        label: 'Aguardando confirmação de interesse na matrícula',
        value: ReEnrollmentSubStatus.WAITING_ENGAGE,
      },
      {
        label: 'Interesse na matrícula confirmado',
        value: ReEnrollmentSubStatus.GUARDIAN_ENGAGED,
      },
      {
        label: `Matrícula ${selectedYear} bloqueada`,
        value: ReEnrollmentSubStatus.REENROLLMENT_SUSPENDED,
      },
      {
        label: 'Parcelas vencidas em contratos anteriores',
        value: ReEnrollmentSubStatus.PREVIOUS_YEAR_OVERDUE,
      },
      {
        label: 'Aguardando quitação dos contratos anteriores',
        value: ReEnrollmentSubStatus.PREVIOUS_YEAR_PENDING,
      },
      {
        label: 'Aguardando pagamento da pré-matrícula',
        value: ReEnrollmentSubStatus.PRE_ENROLLMENT_PENDING,
      },
      {
        label: 'Aguardando criação de mensalidades',
        value: ReEnrollmentSubStatus.WAITING_TUITION_CREATION,
      },
      {
        label: 'Pagamento da pré-matrícula confirmado',
        value: ReEnrollmentSubStatus.PRE_ENROLLMENT_PAID,
      },
    ]
  }

  const subStatusOptions = getSubStatusOptions(selectedYear)

  return {
    subStatusOptions,
    statusOptions,
  }
}
