import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => props.theme.gravity.spacing[8]};
  height: 349px;

  .content {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.gravity.spacing[2]};
    margin-bottom: ${props => props.theme.gravity.spacing[4]};
    width: 450px;
  }
`
