import { useState, useEffect } from 'react'
import BoxFooter from '../../BoxFooter/BoxFooter'
import type { PaidDateFieldProps } from '../types'
import * as S from '../styles'
import { DatePicker } from '@olaisaac/design-system'
import { useSendManualLiquidationEvent } from '../../../hooks/manualLiquidation/useSendManualLiquidationEvents'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import dayjs, { Dayjs } from 'dayjs'

import { makeIsDayAfterDate } from '@/shared/utils'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import useLiquidationInfo from '../../../hooks/manualLiquidation/useLiquidationInfo'
import { getIdFromUrn } from '../../../helper'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { getValidationForPaidDate } from '../../../utils/getValidationForPaidDate'

const PaidDateField = ({
  onSave,
  onCancel,
  value,
  maxDate,
  contractCreationDate,
  isEdition,
  installment,
}: PaidDateFieldProps) => {
  const receivableId = getIdFromUrn(installment?.urn)
  const [selectedValue, setSelectedValue] = useState(value)
  const [errorMessage, setErrorMessage] = useState('')

  const sendManualLiquidationEvent = useSendManualLiquidationEvent()

  const isValidatePaidDateBefore7Days = useUnleashFlag(
    UnleashFlags.PAYMENTS_PEX_273_ENABLE_VALIDATE_PAID_DATE_MIN_7_DAYS_BEFORE
  )

  const { isLoading } = useLiquidationInfo({
    installment,
    paidDate: selectedValue,
    isSimulateRequest: true,
  })

  const getErrorMessage = (date: Dayjs) => {
    const { isBeforeMinDate, message } = getValidationForPaidDate({
      installment,
      isValidatePaidDateBefore7Days,
      paidDate: date,
    })

    if (isBeforeMinDate) {
      return message
    }

    const isAfterMaxDate = makeIsDayAfterDate(dayjs(maxDate).utc())(date)

    if (isAfterMaxDate) {
      return isEdition
        ? 'Data não pode ser depois da última data de recebimento'
        : 'Data não pode ser futura.'
    }

    return ''
  }

  useEffect(() => {
    const errorMessage = getErrorMessage(selectedValue)
    setErrorMessage(errorMessage)

    if (errorMessage !== '') return

    sendManualLiquidationEvent(
      EventDispatcherEvents.WARNING_VIEWED,
      EventDispatcherEntities.MANUAL_LIQUIDATION_PAST_DATE_WARNING,
      undefined,
      {
        contract_id: installment.contracts[0].id,
        name: 'Alerta data retroativa',
        selected_date: selectedValue.toISOString(),
        receivable_id: receivableId,
      }
    )
  }, [selectedValue])

  return (
    <S.StyledBox>
      <S.StyledContent>
        <DatePicker
          style={{ maxWidth: '255px' }}
          value={selectedValue}
          minDate={contractCreationDate}
          disableFuture
          label=""
          onChange={(date: MaterialUiPickersDate) =>
            date && dayjs(date).isValid() ? setSelectedValue(dayjs(date)) : setSelectedValue(value)
          }
          data-testid="input-paid-date"
          helperText={errorMessage}
          error={!!errorMessage}
        />
      </S.StyledContent>
      <BoxFooter
        isValid={!isLoading && errorMessage === ''}
        onCancel={onCancel}
        onSave={() => onSave(selectedValue)}
      />
    </S.StyledBox>
  )
}

export default PaidDateField
