import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, GridItem } from '@gravity/grid'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import {
  useStudentContracts,
  useStudentEnrollmentCycle,
  useStudentFinances,
  useStudentProfile,
} from '../../hooks/queries/students'
import { useSectionObserver } from '@monorepo/students/hooks/useSectionObserver'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'

import { useSelectCycle } from './components/StatusEnrollmentSection/hooks/useSelectCycle'
import { ProfileSection } from './components/ProfileSection'
import { SidebarMenu, SidebarMenuSection } from './components/SidebarMenu'
import { Header } from './components/Header'
import { StudentNameContainer } from './components/StudentNameContainer'
import { FinancesSection } from './components/FinancesSection'
import { EditStudentDialog } from './components/EditStudentDialog'

import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { StatusEnrollmentSection } from './components/StatusEnrollmentSection'
import { GuardiansSection } from './components/GuardiansSection'
import { PendingPaymentsDialog } from './components/FinancesSection/components/PendingPaymentsDialog'
import { ContractsSection } from './components/ContractsSection'

import { EMPTY_ENABLED_ENROLLMENT_CYCLE } from './constants'
import { Container, Content } from './styles'

export const Student = () => {
  useLayout({ enableSideMenu: true, enableHeader: false, headerTitle: 'Aluno' })
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { school } = useSelectedSchool()
  const { studentId } = useParams<{ studentId: string }>()
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const { isBackofficeUser } = useJWT()
  const [isOpenEditStudentDialog, setIsOpenEditStudentDialog] = useState(false)
  const [dialogPendingPaymentsVisible, setDialogPendingPaymentsVisible] = useState(false)
  const allowEditStudent = isBackofficeUser || !isIntegratedSchool

  const { data, isFetching, isError, refetch } = useStudentProfile(
    { studentId },
    { enabled: !!studentId }
  )

  const studentName = data?.data?.name ?? ''
  const taxId = data?.data.tax_id ?? null
  const birthDate = data?.data.birth_date ?? null

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.PAGE_VIEWED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.VIEW_STUDENT_PAGE,
      })
    }
  }, [isInitialized])

  const {
    data: studentFinancesData,
    isError: studentFinancesIsError,
    isFetching: studentFinancesIsFetching,
    refetch: refetchStudentFinances,
  } = useStudentFinances(
    { studentId, schoolId: school?.id ?? '' },
    { enabled: !!studentId && !!school?.id }
  )

  const {
    data: studentContractsData,
    isError: studentContractsIsError,
    isFetching: studentContractsIsFetching,
    refetch: refetchStudentContracts,
  } = useStudentContracts(
    { studentId, schoolId: school?.id ?? '' },
    { enabled: !!studentId && !!school?.id }
  )

  const {
    data: studentEnrollmnetData,
    refetch: refetchStudentEnrollment,
    isFetching: studentEnrollmentIsFetching,
  } = useStudentEnrollmentCycle(
    { studentId, schoolId: school?.id ?? '' },
    { enabled: !!studentId && !!school?.id }
  )

  const openAmount = studentFinancesData?.data?.financial_summary?.OPEN ?? 0
  const overdueAmount = studentFinancesData?.data?.financial_summary?.OVERDUE ?? 0
  const hasPendingsWithGuardians = studentFinancesData?.data?.has_pending ?? false
  const guardianId = studentFinancesData?.data?.guardians[0].id ?? ''
  const guardians = studentFinancesData?.data?.guardians
  const quantityOfGuardians = guardians?.length ?? 0

  const studentData = studentEnrollmnetData?.data
  const enabledCycles = studentData?.enabled_enrollment_cycles ?? []

  const contracts = studentContractsData?.data?.contracts || []

  const activeContracts = (() => {
    return contracts.filter(contract => contract.status === 'OPEN').length
  })()

  const {
    selectedCycle,
    setSelectedCycleYear,
    shouldShowSelectCycleYearDropdown,
    optionsCycle,
  } = useSelectCycle(enabledCycles)

  const sections: SidebarMenuSection[] = [
    {
      id: 'dados cadastrais',
      label: 'Dados cadastrais',
      subLabel: !taxId || !birthDate ? 'Incompletos' : undefined,
    },
    { id: 'responsaveis', label: 'Responsáveis' },
    {
      id: 'financeiro',
      label: 'Financeiro',
      subLabel: overdueAmount > 0 ? 'Em atraso' : undefined,
    },
    {
      id: 'contratos',
      label: 'Contratos',
      subLabel: `${activeContracts} ativos`,
    },
  ]

  const {
    activeSectionId,
    setActiveSectionId,
    registerSection,
    handleActiveSection,
  } = useSectionObserver()

  const handleOpenEditStudentDialog = () => {
    if (!isFetching && allowEditStudent) {
      if (isInitialized) {
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.STUDENT_PAGE,
          name: EventDispatcherEvents.BUTTON_CLICKED,
          pageName: EventPageName.STUDENT_PAGE,
          identifierName: EventIdentifierName.EDIT_STUDENT_REGISTRATION,
        })
      }
      setIsOpenEditStudentDialog(true)
    }
  }

  const handleSendToggleObfuscateEvent = (status: boolean) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.STUDENT_DATA_MASK,
        customProperties: {
          $mascara: status,
        },
      })
    }
  }

  const handleRetryStudentEnrollment = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.RETRY_STUDENT_STATUS,
      })
    }

    refetchStudentEnrollment()
  }

  useEffect(() => {
    setActiveSectionId('dados cadastrais')
  }, [])

  return (
    <>
      <Header
        studentName={studentName}
        studentStatus={
          selectedCycle?.student_status ?? EMPTY_ENABLED_ENROLLMENT_CYCLE.student_status
        }
        isLoading={isFetching || studentEnrollmentIsFetching}
      />

      <Container>
        <Grid>
          <GridItem xl={2} lg={2} md={2} sm={1}>
            <SidebarMenu
              sections={sections}
              activeSectionId={activeSectionId}
              onSectionActiveCallback={handleActiveSection}
            />
          </GridItem>

          <GridItem xl="4 / 13" lg="4 / 13" md={6} sm={3}>
            <Content>
              <StudentNameContainer
                isLoading={isFetching || studentEnrollmentIsFetching}
                studentName={studentName}
                studentStatus={
                  selectedCycle?.student_status ?? EMPTY_ENABLED_ENROLLMENT_CYCLE.student_status
                }
              />

              <ProfileSection
                id="dados cadastrais"
                ref={el => registerSection('dados cadastrais', el)}
                schoolName={school?.name ?? ''}
                isLoading={isFetching}
                hasError={isError}
                taxId={taxId}
                birthDate={birthDate}
                onRequestEditProfile={() => handleOpenEditStudentDialog()}
                onSendToggleObfuscateEvent={handleSendToggleObfuscateEvent}
                onRetry={refetch}
                disableEdit={!allowEditStudent}
              />

              <StatusEnrollmentSection
                data={selectedCycle ?? EMPTY_ENABLED_ENROLLMENT_CYCLE}
                studentId={studentId}
                mainGuardianId={guardianId}
                hasEnrollmentCycleEnabled={enabledCycles.length > 0}
                enabledCycles={optionsCycle}
                selectValue={selectedCycle?.reference_year ?? 0}
                shouldShowSelectCycleYearDropdown={shouldShowSelectCycleYearDropdown}
                setSelectedCycleYear={setSelectedCycleYear}
                onRetry={handleRetryStudentEnrollment}
              />

              <GuardiansSection
                id="responsaveis"
                ref={el => registerSection('responsaveis', el)}
                guardians={guardians}
                isLoading={studentFinancesIsFetching}
                hasError={studentFinancesIsError}
                onRetry={refetchStudentFinances}
              />

              <FinancesSection
                id="financeiro"
                ref={el => registerSection('financeiro', el)}
                openAmount={openAmount}
                overdueAmount={overdueAmount}
                isLoading={studentFinancesIsFetching}
                hasError={studentFinancesIsError}
                onRetry={refetchStudentFinances}
                hasPendingsWithGuardians={hasPendingsWithGuardians}
                hasTwoOrMoreGuardians={quantityOfGuardians > 1}
                guardianId={guardianId}
                studentId={studentId}
                onOpenPaymentDialog={() => setDialogPendingPaymentsVisible(true)}
              />

              <PendingPaymentsDialog
                isOpen={dialogPendingPaymentsVisible}
                studentId={studentId}
                onClose={() => setDialogPendingPaymentsVisible(false)}
                guardians={guardians ?? []}
              />

              <ContractsSection
                id="contratos"
                ref={el => registerSection('contratos', el)}
                guardianId={guardianId}
                studentId={studentId}
                studentName={studentName}
                data={studentContractsData?.data}
                isLoading={studentContractsIsFetching}
                hasError={studentContractsIsError}
                onRetry={refetchStudentContracts}
              />
            </Content>
          </GridItem>
        </Grid>
      </Container>

      <EditStudentDialog
        birthDateParam={birthDate ?? ''}
        studentId={studentId}
        studentName={studentName}
        taxID={taxId ?? ''}
        onClose={() => setIsOpenEditStudentDialog(false)}
        open={isOpenEditStudentDialog}
      />
    </>
  )
}
