export enum EventIdentifierName {
  CHANGE_SCHOOL = 'trocar_de_escola',
  NAVIGATE_TO_CREDIT_PAGE = 'acessar_pagina_de_credito',
  NAVIGATE_TO_ENROLLMENT_PAGE = 'acessar_pagina_de_matriculas',
  NAVIGATE_TO_GUARDIANS_PAGE = 'acessar_pagina_do_responsavel',
  NAVIGATE_TO_MANAGEMENT_DASHBOARD_PAGE = 'acessar_pagina_de_painel_de_gestao',
  NAVIGATE_TO_REPORT_DASHBOARD_PAGE = 'acessar_pagina_de_repasses',
  NAVIGATE_TO_REPORT_PAGE = 'acessar_pagina_de_relatorios',
  SELECT_SCHOOL = 'selecionar_escola',
}
