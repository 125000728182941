import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { identity } from 'ramda'

import { client } from '@/shared/integrations/sorting-hat'

import type { JWTProps } from './types'
import type { UserAuthData, UserGroupNames } from '@/shared/integrations/sorting-hat/types'

import { RESPONSE_STATUS } from '@/shared/interfaces/api'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

export type Roles =
  | 'acesso_extendido_grupo_escolar'
  | 'extended_school_group_access'
  | 'atribuir_acesso_gestor'
  | 'atribuir_acesso_mantenedor'
  | 'atribuir_acesso_secretaria'
  | 'remover_acesso_mantenedor'
  | 'remover_acesso_secretaria'
  | 'alterar_vencimento'
  | 'contrato_cancelar'
  | 'criar_contrato'
  | 'payout_report_access'
  | 'visualizar_consolidado_financeiro'
  | 'criar_baixa_manual'
  | 'editar_baixa_manual'
  | 'cancelar_contrato'
  | 'editar_contrato'
  | 'atribuir_acesso_operador_basico'
  | 'remover_acesso_operador_basico'
  | 'material_didatico_relatorio_completo'
  | 'material_didatico_relatorio_basico'

type AuthProviderProps = { children: ReactNode }

type AuthContextProps = {
  getBackofficeUserRoles: () => Set<Roles>
  getUserGroupNameBySchool: (schoolId: string) => UserGroupNames | undefined
  getUserGroupNameLabel: (schoolId: string) => string
  getUserRolesBySchool: (schoolId: string) => Set<Roles>
  isBackofficeUser: boolean
  jwtProps: JWTProps | null
}

export const AuthContext = createContext({ jwtProps: {} } as AuthContextProps)

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [jwtProps, setJwtProps] = useState<JWTProps | null>(null)
  const [userAuthData, setUserAuthData] = useState<UserAuthData>()

  const fetchUserAuthData = async () => {
    const validateIfUserHasAccess = (data: UserAuthData) => {
      if (data.realm === 'isaac' && data.client === 'plataforma') {
        const hasResources = (data.resources ?? []).length > 0

        if (!hasResources) return false
      }

      return true
    }

    const parseToOldFormat = (data: UserAuthData): JWTProps => {
      const formattedData: JWTProps = {
        exp: data.expires_at,
        sub: data.user.id,
        azp: data.client,
        realm: data.realm,
        given_name: data.user.first_name,
        family_name: data.user.last_name,
        name: `${data.user.first_name} ${data.user.last_name}`,
        email: data.user.email,
        preferred_username: data.user.username,
        resource_definition: {
          definition: data.multi_tenant_roles ?? {},
        } as JWTProps['resource_definition'],
        resource_roles: data.plain_roles ?? [],
      }

      return formattedData
    }

    const { data, err } = await client.getUserData()

    if (err) {
      client.logout()
      return
    }

    const hasAccess = validateIfUserHasAccess(data)

    if (!hasAccess) {
      client.logout()
      return
    }

    setJwtProps(parseToOldFormat(data))
    setUserAuthData(data)
  }

  useEffect(() => {
    const runSyncIsAuthorized = async () => {
      await fetchUserAuthData()
    }

    if (!jwtProps || !userAuthData) {
      runSyncIsAuthorized()
    }
  }, [])

  axios.defaults.withCredentials = true

  axios.interceptors.response.use(identity, error => {
    const shouldRedirect =
      error.response.status === RESPONSE_STATUS.UNAUTHORIZED ||
      error.response.status === RESPONSE_STATUS.FORBIDDEN
    if (shouldRedirect) {
      return client.logout()
    }

    return error
  })

  const getUserRolesBySchool = useCallback(
    (schoolId: string): Set<Roles> => {
      if (schoolId === '' || !userAuthData) return new Set<Roles>()

      const roles = (userAuthData.multi_tenant_roles?.[schoolId].roles ?? []) as Roles[]

      return new Set(roles)
    },
    [userAuthData]
  )

  const getUserGroupNameBySchool = useCallback(
    (schoolId: string): UserGroupNames | undefined => {
      if (schoolId === '' || !userAuthData) return

      const groupName = userAuthData.multi_tenant_roles?.[schoolId]?.group_name

      return groupName
    },
    [userAuthData]
  )

  const isBackofficeUser = (() => {
    if (!userAuthData) return false

    return userAuthData.client === 'backoffice' && userAuthData.realm === 'backoffice'
  })()

  const getBackofficeUserRoles = useCallback((): Set<Roles> => {
    if (!isBackofficeUser || !userAuthData) return new Set<Roles>()
    const roles = (userAuthData.plain_roles ?? []) as Roles[]
    return new Set(roles)
  }, [userAuthData])

  const getUserGroupNameLabel = useCallback(
    (schoolId: string): string => {
      if (schoolId === '') return ''

      const USER_GROUP_NAME_LABELS = {
        backoffice: 'Administração',
        gestor: 'Administração de Grupo escolar',
        mantenedor: 'Administração de Unidade',
        secretaria: 'Operação',
        'operador-basico': 'Operação básica',
      }

      if (isBackofficeUser) {
        return USER_GROUP_NAME_LABELS.backoffice
      }

      const userGroupName = getUserGroupNameBySchool(schoolId)

      if (!userGroupName) {
        return ''
      }

      return USER_GROUP_NAME_LABELS[userGroupName]
    },
    [isBackofficeUser, getUserGroupNameBySchool]
  )

  const contextValues = useMemo<AuthContextProps>(
    () => ({
      getUserRolesBySchool,
      getBackofficeUserRoles,
      getUserGroupNameLabel,
      getUserGroupNameBySchool,
      isBackofficeUser,
      jwtProps,
    }),
    [
      getUserRolesBySchool,
      getBackofficeUserRoles,
      getUserGroupNameLabel,
      getUserGroupNameBySchool,
      isBackofficeUser,
      jwtProps,
    ]
  )

  if (!jwtProps || !userAuthData) {
    return <CircularLoadingPlaceholder />
  }

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>
}
