import { Button } from '@gravity/button'
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js'
import { useHistory } from 'react-router-dom'

import * as Styled from './styles'
import Icon from '@mdi/react'

export const PageFooter = () => {
  const history = useHistory()
  const handleCancelClick = history.goBack
  return (
    <Styled.Wrapper>
      <Button variant="outline" onClick={handleCancelClick}>
        Cancelar
      </Button>

      <Styled.ButtonsWrapper>
        <Button disabled variant="outline" iconStart={<Icon path={mdiArrowLeft} />}>
          Voltar
        </Button>
        <Button variant="solid" iconEnd={<Icon path={mdiArrowRight} />}>
          Próximo
        </Button>
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  )
}
