import { useQuery } from '@tanstack/react-query'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { reportService } from '@/shared/services/report'
import type { DashboardName } from '@/shared/services/report/types'

export const useDashboardReportQuery = (dashboardName: DashboardName) => {
  const { school } = useSelectedSchool()
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery({
    queryKey: ['dashboard-report', dashboardName, school?.id],
    queryFn: async () => {
      const { data } = await service.fetchEmbedDashboardReport({
        schoolId: school?.id ?? '',
        dashboardName,
      })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: !!school?.id,
  })
}
