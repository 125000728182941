import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import { MenuItem, ListItemText } from '@material-ui/core'
import { Button } from '@olaisaac/design-system'
import { Button as DSButton } from '@gravity/button'

import { useJWT } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { downloadFile } from '@/shared/utils'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import useInstallments from '@/escolas/hooks/useInstallments'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { useContract } from '@/escolas/hooks'
import { ContractStatus } from '@/shared/interfaces'
import ChangeContractOwnershipButton from '@/escolas/pages/ChangeGuardian/components/ChangeContractOwnershipButton'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import CancelContractMenuItem from '../GuardianCard/CancelContractMenuItem'
import {
  getAgglutinatedInstallment,
  getDueTodayInstallment,
  getOpenInstallments,
  getOverdueInstallment,
  getRenegotiatedInstallment,
} from '../utils'
import { FailureFeedbackContent, FailureFeedbackDialog } from '../../modal/FeedbackCarneDialog'
import { PUBLIC_DOMAIN } from '@/utils/api'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useIsaacPayOutsourcedPaymentProvider } from '@/shared/hooks/useIsaacPayOutsourcedPaymentProvider'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

export type ContractCheckoutActionsProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  changeContractOwnership?: () => void
  clickPaidAmountStatement: (value: boolean) => void
  contractId: string
  hasInvoicesWithError?: boolean
  isPreContract?: boolean
  isReenrollable?: boolean
  openCancellationDrawer: () => void
  queryLoading: boolean
  setShowInvoicesNotGeneratedDialog: (status: boolean) => void
  showAnnualPaidAmountsStatement: boolean
}
export const ContractCheckoutActions = ({
  addContract,
  changeContractOwnership,
  clickPaidAmountStatement,
  contractId,
  hasInvoicesWithError,
  isPreContract,
  isReenrollable,
  openCancellationDrawer,
  queryLoading,
  setShowInvoicesNotGeneratedDialog,
  showAnnualPaidAmountsStatement,
}: ContractCheckoutActionsProps) => {
  const { api } = useApi()
  const { isAdmin, getUserRolesBySchool } = useJWT()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { sendHotjarEvent } = useHotjar()
  const { contract } = useContract()
  const { processedInstallments } = useInstallments()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isLoadingCarne, setIsLoadingCarne] = useState(false)
  const [carneErrorMessage, setCarneErrorMessage] = useState<string>('')
  const [showErrorModal, setShowErrorModal] = useState(false)

  useEffect(() => {
    setShowErrorModal(!!carneErrorMessage)
  }, [carneErrorMessage])

  const isNewFormToExportCarneEnabled = useUnleashFlag(
    UnleashFlags.PEX_447_CHANGE_FORM_TO_EXPORT_CARNE_PI
  )

  const isRevokedCancelContract = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)

  const cancelContractNewRules = useUnleashFlag(UnleashFlags.EFI_215_CANCEL_CONTRACT_NEW_RULES)

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const isOpen = contract?.status === ContractStatus.OPEN
  const isPending = contract?.status === ContractStatus.PENDING

  /**
   * Caso o usuário não seja admin mas a escola tem permissão para cancelar contratos.
   */
  const isCancelContractEnabled = !isAdmin && !isRevokedCancelContract

  const open = Boolean(anchorEl)

  const openInstallments = getOpenInstallments(processedInstallments)
  const hasInstallments = !!contract?.installments?.length

  const hasOpenInstallments = openInstallments?.length > 0

  const hasRenegotiatedInstallment = Boolean(getRenegotiatedInstallment(processedInstallments))
  const hasAgglutinatedInstallment = Boolean(getAgglutinatedInstallment(processedInstallments))
  const hasOverdueInstallment = Boolean(getOverdueInstallment(processedInstallments))
  const hasDueTodayInstallment = Boolean(getDueTodayInstallment(processedInstallments))

  const hasDebt = hasRenegotiatedInstallment || hasAgglutinatedInstallment || hasOverdueInstallment

  const canChangeContractOwnership =
    hasOpenInstallments &&
    !hasDueTodayInstallment &&
    !hasDebt &&
    !isPreContract &&
    !isIntegratedSchool

  const canCancelContract = !isPreContract && hasOpenInstallments && isCancelContractEnabled

  const canEnableCancellationOption = isAdmin || isPreContract || canCancelContract

  const isQueryLoaded = isPreContract && !hasInstallments ? true : queryLoading

  const considerUserRole = useUnleashFlag(UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL)
  const hasCancelContractRole = getUserRolesBySchool(school?.id ?? '').has('cancelar_contrato')

  /**
   * Caso o nível de operação básica esteja habilitada, deve sempre exibir o botão
   * para cancelamento de contratos com status pendente. Caso contrário, é mantida
   * a lógica padrão existente
   */
  const shouldDisplayCancellationContractOptionForPendingContracts = (() => {
    if (considerUserRole) return true

    return isAdmin || isPreContract || canCancelContract
  })()

  /**
   * Caso o nível de operação básica esteja habilitada, deve herdar os critérios
   * originais que antes estavam na condicional para renderizar o componente de
   * botão, manter a regra original para desabilitar o botão e validar se o
   * usuário tem a role `cancelar_contrato`
   */
  const shouldDisableCancellationContractOptionForPendingContracts = (() => {
    if (considerUserRole) {
      if (!hasCancelContractRole && !isAdmin) return true

      if (!isPreContract) return true

      if (!canCancelContract) return true

      return !isQueryLoaded
    }

    return !isQueryLoaded
  })()

  /**
   * Caso o nível de operação básica esteja habilitada, deve herdar os critérios
   * originais que antes estavam na condicional para renderizar o componente de
   * botão, manter a regra original para desabilitar o botão e validar se o
   * usuário tem a role `cancelar_contrato`
   */
  const shouldDisableCancellationContractOptionForNotPendingContracts = (() => {
    if (considerUserRole) {
      if (!hasCancelContractRole) return true

      return !isOpen || !(isPreContract || canCancelContract) || !isQueryLoaded
    }

    return !isOpen || !canEnableCancellationOption || !isQueryLoaded
  })()

  const useDownloadCarne = async (contractId: string) => {
    const carneFileDownload = async (contractId: string) => {
      await api.contracts.downloadCarne(contractId).then(async data => {
        if (data.status > 200) {
          const error = await data.data.text()
          setCarneErrorMessage(error)
        } else {
          downloadFile(`carne-${contractId}`, 'pdf', async () => await data.data)
        }
      })
    }

    try {
      setCarneErrorMessage('')
      await carneFileDownload(contractId)
    } finally {
      setIsLoadingCarne(false)
    }
  }

  const printCarne = () => {
    const printContractURL = `${PUBLIC_DOMAIN}/contract/${contractId}/print-slips`

    if (hasInvoicesWithError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else {
      if (isInitialized)
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.BUTTON_CLICKED,
          scope: EventDispatcherEventScopes.CONTRACT_PAGE,
          action: 'click',
          entity: EventDispatcherEntities.PRINT_BANKSLIP,
          customProperties: {
            $name: 'Imprimir carnê',
            $contract_id: contractId,
          },
        })
      if (isNewFormToExportCarneEnabled) {
        setIsLoadingCarne(true)
        useDownloadCarne(contract?.id)
      } else {
        window.open(printContractURL)
      }
    }
  }

  const sendContractDrawerEvent = (button_name: string) => {
    if (isInitialized) {
      eventDispatcherClient
        .sendEvent({
          scope: EventDispatcherEventScopes.CONTRACT_DRAWER,
          name: EventDispatcherEvents.BUTTON_CLICK,
          action: 'click',
          customProperties: {
            $button_name: button_name,
            $contract_type: isPreContract ? 'NOT EFFECTED' : 'EFFECTED',
          },
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const sendGenerateStatementEvent = () => {
    if (isInitialized)
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.CONTRACT_PAGE,
        action: 'click',
        entity: EventDispatcherEntities.PRINT_DEMONSTRATIVE_PAID_VALUES,
        customProperties: {
          $name: 'Gerar demonstrativo de pagamento',
          $contract_id: contract?.id,
        },
      })
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const close = () => {
    setAnchorEl(null)
  }

  const getHandleClick = (callback: () => void) => () => {
    if (hasInvoicesWithError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else {
      callback()
      close()
    }
  }

  return (
    <>
      <FailureFeedbackDialog
        isVisible={showErrorModal}
        buttonLabel="Entendi"
        onClose={() => setShowErrorModal(false)}
        submitHandler={() => setShowErrorModal(false)}
      >
        <FailureFeedbackContent err={carneErrorMessage} />
      </FailureFeedbackDialog>

      {isPending ? (
        shouldDisplayCancellationContractOptionForPendingContracts && (
          <Button
            disabled={shouldDisableCancellationContractOptionForPendingContracts}
            onClick={getHandleClick(openCancellationDrawer)}
            variation="ghost"
          >
            Cancelar contrato
          </Button>
        )
      ) : (
        <>
          <DSButton
            data-testid="contract-actions-button"
            variant="ghost"
            aria-label="mais ações"
            aria-controls="long-menu"
            aria-haspopup="true"
            fullWidth
            onClick={handleClick}
          >
            Outras opções
          </DSButton>
          <Menu
            id="menu-imprimir"
            data-testid="contract-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={close}
          >
            {showAnnualPaidAmountsStatement && (
              <MenuItem
                onClick={() => {
                  sendHotjarEvent(HotjarEvents.GENERATE_STATEMENT_CHECKOUT)
                  clickPaidAmountStatement(true)
                  sendGenerateStatementEvent()
                }}
                data-testid="generate-statement-button"
              >
                Gerar demonstrativo de pagamento
              </MenuItem>
            )}

            <ChangeContractOwnershipButton
              canChangeContractOwnership={canChangeContractOwnership}
              changeContractOwnership={changeContractOwnership}
            />
            {isLoadingCarne ? (
              <CircularLoadingPlaceholder />
            ) : (
              !isOutsourcedProvider && (
                <MenuItem onClick={() => printCarne()} component="a" target="blank">
                  <ListItemText>Imprimir carnê</ListItemText>
                </MenuItem>
              )
            )}

            {!cancelContractNewRules && (
              <CancelContractMenuItem
                disabled={shouldDisableCancellationContractOptionForNotPendingContracts}
                cancelRevoked={!isCancelContractEnabled}
                cancelContractNotAvailableTooltipMessage={
                  !hasCancelContractRole ? 'Você não tem permissão para cancelar o contrato' : ''
                }
                onClick={close}
                openCancellationDrawer={() => {
                  sendContractDrawerEvent('Cancelar contrato')
                  getHandleClick(openCancellationDrawer)()
                }}
              />
            )}

            {isReenrollable && (
              <MenuItem onClick={() => addContract?.(true)}>Rematricular</MenuItem>
            )}
          </Menu>
        </>
      )}
    </>
  )
}
