import { Control, useFieldArray } from 'react-hook-form'

import { filterContractsForm } from '@monorepo/students/models/filterContractsSchema'
import { ChoiceChip } from '@gravity/chip'
import { Wrapper } from './styles'

interface Props {
  control: Control<filterContractsForm>
  filterType: 'reference_years'
}

export const FilterChip = ({ control, filterType }: Props) => {
  const { fields, update } = useFieldArray({
    control,
    name: filterType,
  })

  const handleChoiceSelected = (index: number) => {
    update(index, {
      ...fields[index],
      selected: !fields[index]?.selected || false,
    })
  }

  return (
    <Wrapper>
      {fields.map((field, index) => (
        <ChoiceChip
          key={field.value}
          text={field.label}
          value={field.value}
          selected={field.selected}
          onSelected={() => handleChoiceSelected(index)}
        />
      ))}
    </Wrapper>
  )
}
