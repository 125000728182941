import axios, { AxiosError } from 'axios'
import type { Query, QueryKey, UseQueryOptions } from '@tanstack/react-query'
import { useQuery, useMutation } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'
import { enrollmentService } from '@/modules/enrollment/services/enrollmentService'
import type {
  CloseCampaignRequest,
  CreateCampaignRequest,
  GetCampaignsRequest,
  GetCampaignsResponse,
  GetCampaignStudentRequest,
  GetCampaignStudentResponse,
  HasCampaignEnrollmentRequest,
  HasCampaignEnrollmentResponse,
} from '@/modules/enrollment/services/enrollmentService/types'
import { queryClient } from '@/shared/contexts/ReactQuery'

const getCampaignsKey = 'get-campaigns'

type UseCreateCampaignMutationParams = CreateCampaignRequest & {
  schoolId: uuid
}
export const useCreateCampaignMutation = () => {
  const { apiClient } = useApiClient()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  return useMutation(({ schoolId, ...params }: UseCreateCampaignMutationParams) => {
    return service.createCampaign(schoolId, params)
  })
}

type UseCloseCampaignMutationParams = CloseCampaignRequest & {
  campaignId: uuid
}
export const useCloseCampaignMutation = () => {
  const { apiClient } = useApiClient()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  return useMutation(
    ({ campaignId, ...params }: UseCloseCampaignMutationParams) => {
      return service.closeCampaign(campaignId, params)
    },
    { onSuccess: () => queryClient.invalidateQueries([getCampaignsKey]) }
  )
}

export const useFetchCampaigns = (
  params: GetCampaignsRequest,
  options?: UseQueryOptions<GetCampaignsResponse>
) => {
  const { apiClient } = useApiClient()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  return useQuery<GetCampaignsResponse>(
    [getCampaignsKey, params],
    () => service.fetchCampaigns(params),
    options
  )
}

export const useFetchStudentHasCampaign = (
  params: HasCampaignEnrollmentRequest,
  options?: UseQueryOptions<HasCampaignEnrollmentResponse['data']>
) => {
  const { apiClient } = useApiClient()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  return useQuery<HasCampaignEnrollmentResponse['data']>(
    ['has-campaign-enrollment', params],
    () => service.hasCampaignEnrollment(params),
    options
  )
}

export const fetchCampaignStudentsQueryKey = 'fetch-campaign-students'
export const useFetchCampaignStudents = (
  params: GetCampaignStudentRequest,
  options: UseQueryOptions<GetCampaignStudentResponse, Error | AxiosError> = {}
) => {
  const { apiClient } = useApiClient()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  const isError404 = (error: Error | AxiosError) =>
    axios.isAxiosError(error) && error.response?.status === 404

  const retry = (failureCount: number, error: Error | AxiosError) => {
    if (isError404(error)) return false
    return failureCount < 2
  }
  const refetchOnWindowFocus = (query: Query<GetCampaignStudentResponse, Error | AxiosError>) => {
    return !(query.state?.error && isError404(query.state.error))
  }
  return useQuery(
    [fetchCampaignStudentsQueryKey, params] as QueryKey,
    () => service.fetchCampaignStudents(params),
    {
      ...options,
      retry,
      refetchOnWindowFocus,
    }
  )
}
