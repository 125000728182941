import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

import { AmountContainer } from './styles'

type AmountCardProps = {
  amount: string
  title: string
}

export const AmountCard = ({ title, amount }: AmountCardProps) => {
  return (
    <AmountContainer>
      <Text variant="body-2-regular">{title}</Text>
      <Heading variant="heading-h4-medium">{amount}</Heading>
    </AmountContainer>
  )
}
