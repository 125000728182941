import { Skeleton } from '@gravity/skeleton'
import { ChargeContractWrapper } from '.'

export const Loading = () => {
  return (
    <ChargeContractWrapper>
      <Skeleton height={88} fullWidth />
      <Skeleton height={88} fullWidth />
      <Skeleton height={88} fullWidth />
    </ChargeContractWrapper>
  )
}
