import { useEffect, useRef, useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'

const DISTANCE_TO_TOP = 160

type ClosestSectionType = {
  distance: number
  id: string
}

export const useSectionObserver = () => {
  const [activeSectionId, setActiveSectionId] = useState<string | undefined>(undefined)
  const sectionRefs = useRef<Map<string, HTMLElement | null>>(new Map())
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const viewportHeight = window.innerHeight
      const documentHeight = document.body.scrollHeight

      // If the user is at the bottom of the page, activate the last section
      if (scrollPosition + viewportHeight + 1 >= documentHeight) {
        const lastItemId = Array.from(sectionRefs.current.keys()).pop()
        setActiveSectionId(lastItemId)
        return
      }

      let closestSection = null as ClosestSectionType | null

      // Verify which section is 160px or less from the top
      sectionRefs.current.forEach((el, id) => {
        const elementTop = el?.getBoundingClientRect().top || 0

        if (elementTop <= DISTANCE_TO_TOP && elementTop >= 0) {
          const distance = DISTANCE_TO_TOP - elementTop

          if (!closestSection || distance < closestSection.distance) {
            closestSection = { id, distance }
          }
        }
      })

      if (closestSection) {
        setActiveSectionId(closestSection.id)
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [sectionRefs])

  const handleActiveSection = (id: string) => {
    const section = sectionRefs.current.get(id)

    if (section) {
      if (isInitialized) {
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.STUDENT_PAGE,
          name: EventDispatcherEvents.PAGE_VIEWED,
          pageName: EventPageName.STUDENT_PAGE,
          identifierName: EventIdentifierName.CLICK_NAVIGATION,
          customProperties: {
            $menu: id,
          },
        })
      }

      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const registerSection = (id: string, element: HTMLElement | null) => {
    sectionRefs.current.set(id, element)
  }

  return { activeSectionId, setActiveSectionId, registerSection, handleActiveSection }
}
