import { queryClient } from '@/shared/contexts/ReactQuery'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { enrollmentService } from '@/modules/enrollment/services/enrollmentService'
import { ContractCancellationReason } from '@/shared/interfaces'
import * as Sentry from '@sentry/react'
import { useMutation } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { useToast } from '@gravity/toast'

type Params = {
  enrollmentID: uuid
  schoolID: uuid
}

export const useCancelProposal = () => {
  const { apiClient } = useApiClient()
  const { toast } = useToast()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  const onSuccessDefault = () => {
    toast({
      title: 'Proposta cancelada com sucesso',
      type: 'success',
    })

    queryClient.invalidateQueries(['matriculas-api-contracts'])
    queryClient.invalidateQueries(['get-campaigns'])
    queryClient.invalidateQueries(['fetch-campaign-students'])
  }

  const onErrorDefault = (error: AxiosError) => {
    toast({
      title: 'Erro ao cancelar proposta, tente novamente mais tarde',
      type: 'error',
    })
    Sentry.captureException(error)
  }

  return {
    mutate: useMutation({
      mutationFn: async (params: Params) => {
        await service.cancel(params.enrollmentID, params.schoolID, {
          cancellation_description: 'PROPOSAL_CANCELED',
          cancellation_reason: ContractCancellationReason.OTHER,
        })
      },
      onSuccess: onSuccessDefault,
      onError: onErrorDefault,
    }),
    onSuccessDefault,
    onErrorDefault,
  }
}
