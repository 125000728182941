import { Button } from '@gravity/button'
import { usePostSubmitBatchMutation } from '../../hooks/usePostSubmitBatchMutation'
import { useParams } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query'
import { OnboardingBatch } from '../../services/types/onboardingBatch'
import { OverrideBatchTrigger } from '../components/OverrideBatch/OverrideBatchTrigger'
import { pageNames, useEvents } from '../../hooks/eventContext'

interface DashboardFooterProps {
  refetchGetOnboardingBatch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<OnboardingBatch, unknown>>
}

export const DashboardFooter = ({ refetchGetOnboardingBatch }: DashboardFooterProps) => {
  const { batchID } = useParams<{ batchID: string }>()
  const { toast } = useToast()
  const event = useEvents()

  const {
    mutate: mutateStartMigration,
    isLoading: isStartMigrationLoading,
  } = usePostSubmitBatchMutation()

  const handleSubmit = async () => {
    event?.submitBatchButtonClicked()
    mutateStartMigration(
      { batchID },
      {
        onSuccess: async () => {
          await refetchGetOnboardingBatch()
        },
        onError: error => {
          toast({
            title: 'Erro ao tentar migrar os dados',
            type: 'error',
          })
          console.error(error)
        },
      }
    )
  }

  return (
    <>
      <OverrideBatchTrigger pageName={pageNames.BATCH_PAGE} />
      <Button loading={isStartMigrationLoading} onClick={handleSubmit}>
        Migrar contratos
      </Button>
    </>
  )
}
