import styled from 'styled-components'

import theme from '@/shared/theme'

interface ContainerProps {
  $size: number
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.$size};
  height: ${props => props.$size};

  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader-icon {
    animation: rotate 0.8s infinite linear;
    display: inline-block;
    transform-origin: 50% 50%;
    color: ${theme.gravity.colors['colors-background-accent-1']};
  }
`
