import { useState } from 'react'
import { Button } from '@gravity/button'

import Icon from '@mdi/react'
import { mdiCalendarToday, mdiTrayArrowDown } from '@mdi/js'
import { CircularLoading } from '@/shared/components/CircularLoading'

import { ReportCard } from '../ReportCard'

import { CardFooter, StyledSelect } from './styles'

import { DownloadPaymentStatusPeriod } from '../../services/account-reports/types'
import { useDownloadPaymentReport } from '../../hooks/useDownloadPaymentReport'

type PaymentStatusReportOption = {
  label: string
  value: DownloadPaymentStatusPeriod
}

const PAYMENTS_REPORT_OPTIONS: PaymentStatusReportOption[] = [
  { label: 'Este ano', value: 'CURRENT_YEAR' },
]

export const PaymentReportCard = () => {
  const { downloadPaymentReport, isLoading } = useDownloadPaymentReport()

  const [
    paymentReportSelectedOption,
    setPaymentReportSelectedOption,
  ] = useState<DownloadPaymentStatusPeriod>(PAYMENTS_REPORT_OPTIONS[0].value)

  const handleDownloadReportPayments = async () => {
    await downloadPaymentReport(paymentReportSelectedOption)
  }

  return (
    <ReportCard
      title="Relatório de pagamentos"
      description="Relatórios com principais informações sobre cobranças liquidadas, inadimplentes e/ou abertas"
    >
      <CardFooter>
        <StyledSelect
          size={3}
          variant="surface"
          options={PAYMENTS_REPORT_OPTIONS}
          icon={<Icon path={mdiCalendarToday} />}
          value={paymentReportSelectedOption}
          onValueChange={value =>
            setPaymentReportSelectedOption(value as DownloadPaymentStatusPeriod)
          }
        />
        <Button
          variant="ghost"
          iconEnd={isLoading ? <CircularLoading iconSize={16} /> : <Icon path={mdiTrayArrowDown} />}
          onClick={handleDownloadReportPayments}
          disabled={isLoading}
        >
          Baixar
        </Button>
      </CardFooter>
    </ReportCard>
  )
}
