import { boxShadow } from '@gravity/tokens'
import styled from 'styled-components'

export const CloseBtnWrapper = styled.div`
  button {
    border-radius: 50%;
    background-color: #f4f4f4;
    :hover {
      background-color: #e2e2e2;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(4)}px;
  box-shadow: ${boxShadow[2]};
`
