import { useRef } from 'react'
import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import styled from 'styled-components'
import { Charge, GetChargesResponse } from '@monorepo/onboarding/services/types/onboardingCharge'
import { useParams } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import { useQueryClient } from '@tanstack/react-query'
import { FETCH_CHARGES_QUERY_KEY } from '@monorepo/onboarding/hooks/useFetchChargesQuery'
import { GetOnboardingBatchRequest } from '@monorepo/onboarding/services/types/commonTypes'
import { useUpdateChargeMutation } from '@monorepo/onboarding/hooks/useUpdateChargeMutation'
import { ChargeDiscounts } from './ChargeDiscounts'
import { CurrencyInput } from './CurrencyInput'
import { CustomDateInput } from './CustomDateInput'
import { getChargeErrorProps } from './utils'
import { CustomTextField } from './CustomTextField'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { GET_ONBOARDING_BATCH_QUERY_KEY } from '@monorepo/onboarding/hooks/useFetchOnboardingBatches'
import { Callout } from '@gravity/callout'
import dayjs from 'dayjs'

type EditChargeDialogProps = {
  params: GetOnboardingBatchRequest
  selectedCharge: Charge
  setSelectedCharge: (charge: Charge | null) => void
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  & > * {
    flex: 1 1 0px;
  }
`

const isAccrualDateDifferentThanDueDate = (accrualDateString: string, dueDateString: string) => {
  const accrualDate = dayjs(accrualDateString, 'DD/MM/YYYY', true)
  const dueDate = dayjs(dueDateString, 'DD/MM/YYYY', true)

  if (!accrualDate.isValid() || !dueDate.isValid()) return false

  return !accrualDate.isSame(dueDate, 'month')
}

export const EditChargeDialog = ({
  selectedCharge,
  setSelectedCharge,
  params,
}: EditChargeDialogProps) => {
  const { batchID } = useParams<{ batchID: string }>()
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const events = useEvents()

  const originalChargeRef = useRef<Charge>(selectedCharge)

  const { mutateAsync: executeUpdateChargeMutation, isLoading } = useUpdateChargeMutation()

  function handleOpenChange(open: boolean) {
    if (!open) {
      events?.closeEditChargeModal()
      return setSelectedCharge(null)
    }
  }

  const handleChangeFieldValue = (fieldName: string, value: string) => {
    setSelectedCharge({
      ...selectedCharge,
      [fieldName]: value,
    })
  }

  const handleSubmit = async () => {
    await executeUpdateChargeMutation(
      {
        batchID,
        contractID: selectedCharge.contract_id,
        charges: [selectedCharge],
      },
      {
        onSuccess: (response: Charge[]) => {
          const updatedCharge = response.find(charge => charge.id === selectedCharge.id) as Charge
          const allChargesAreValid = response.every(charge => charge.is_valid)

          setSelectedCharge(updatedCharge?.is_valid ? null : updatedCharge)

          if (allChargesAreValid) {
            queryClient.invalidateQueries([FETCH_CHARGES_QUERY_KEY])
            // TODO: consider only invalidating if there's no other invalid contracts
            queryClient.invalidateQueries([GET_ONBOARDING_BATCH_QUERY_KEY, batchID])

            return
          }

          originalChargeRef.current = updatedCharge

          queryClient.setQueryData(
            [FETCH_CHARGES_QUERY_KEY, params],
            (old: GetChargesResponse | undefined) => {
              if (old === undefined) {
                return undefined
              }

              const updatedData = old.data.map(contract => {
                if (contract.id !== updatedCharge.contract_id) {
                  return contract
                }

                // TODO: remove this and update all charges validations by either sending all of them on the request, or changing backend
                const updatedCharges = contract.charges.map(charge => {
                  if (charge.id !== selectedCharge.id) return charge

                  return updatedCharge
                })

                return {
                  ...contract,
                  charges: updatedCharges,
                }
              })

              return {
                ...old,
                data: updatedData,
              }
            }
          )
        },
        onError: error => {
          setSelectedCharge(null)

          toast({
            type: 'error',
            title: 'Erro ao salvar correção.',
            description: 'Tente corrigir novamente',
          })

          console.log(error)
        },
      }
    )
  }

  const showCallout = isAccrualDateDifferentThanDueDate(
    selectedCharge.accrual_date,
    selectedCharge.due_date
  )

  return (
    <Dialog
      size={3}
      backdrop
      modal
      open={Boolean(selectedCharge)}
      onOpenChange={handleOpenChange}
      title="Editar dados de pagamento"
      actionButton={
        <Button
          variant="solid"
          onClick={() => {
            events?.confirmEditCharge()
            handleSubmit()
          }}
          loading={isLoading}
        >
          Salvar
        </Button>
      }
      cancelButton={
        <Button
          variant="ghost"
          onClick={() => {
            events?.cancelEditCharge()
          }}
        >
          Cancelar
        </Button>
      }
      content={
        <ContentWrapper>
          <CurrencyInput
            label="Valor bruto"
            name="amount"
            onChange={value => handleChangeFieldValue('amount', value)}
            value={selectedCharge.amount}
            {...getChargeErrorProps('amount', selectedCharge, originalChargeRef.current)}
          />

          <Flex>
            <CustomDateInput
              defaultValue={selectedCharge.due_date}
              label="Vencimento"
              name="due_date"
              onChange={value => handleChangeFieldValue('due_date', value)}
              {...getChargeErrorProps('due_date', selectedCharge, originalChargeRef.current)}
            />

            <CustomDateInput
              label="Competência"
              name="accrual_date"
              defaultValue={selectedCharge.accrual_date}
              onChange={value => handleChangeFieldValue('accrual_date', value)}
              {...getChargeErrorProps('accrual_date', selectedCharge, originalChargeRef.current)}
            />
          </Flex>

          {showCallout && (
            <Callout text="Atenção: O mês de vencimento está diferente do mês de competência. Confira se o dado está correto." />
          )}

          <Flex>
            <CustomTextField
              label="É matrícula ou mensalidade?"
              name="type"
              onChange={handleChangeFieldValue}
              value={selectedCharge.type}
              {...getChargeErrorProps('type', selectedCharge, originalChargeRef.current)}
            />
            <CustomTextField
              label="Situação da cobrança"
              name="situation"
              onChange={handleChangeFieldValue}
              value={selectedCharge.situation}
              {...getChargeErrorProps('situation', selectedCharge, originalChargeRef.current)}
            />
          </Flex>

          <ChargeDiscounts
            selectedCharge={selectedCharge}
            originalCharge={originalChargeRef.current}
            setSelectedCharge={setSelectedCharge}
          />
        </ContentWrapper>
      }
    />
  )
}
