import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { StyledInput, StyledInputLabel } from './styles'

import { CREDIT_CARD_PAYMENT_OPTIONS, InputCreditCardOptionProps } from './types'

const InputCreditCardPaymentOptions = ({
  handleValue,
  selectedValue,
}: InputCreditCardOptionProps) => {
  return (
    <StyledInput>
      <StyledInputLabel>
        <Typography variation="bodySmall" withoutMargin color="secondary">
          Link de pagamento ou maquininha?
        </Typography>
      </StyledInputLabel>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="credit_card_payment_option">Selecionar forma de pagar</InputLabel>
        <Select
          labelId="credit_card_payment_option"
          value={selectedValue || ''}
          label="Selecionar forma de pagar"
          onChange={e => {
            handleValue(e.target.value)
          }}
        >
          <MenuItem value={CREDIT_CARD_PAYMENT_OPTIONS.POS}>Maquininha</MenuItem>
          <MenuItem value={CREDIT_CARD_PAYMENT_OPTIONS.PAYMENT_LINK}>Link de pagamento</MenuItem>
        </Select>
      </FormControl>
    </StyledInput>
  )
}

export default InputCreditCardPaymentOptions
