import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Text } from '@gravity/text'
import { Icon } from '@mdi/react'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { CardContent } from '../../../hooks/useStatusDetailCards'
import { iconPath } from '../../../utils/iconPath'
import {
  CardContainer,
  CardTitle,
  ExternalLink,
  ExternalLinkContainer,
  NoTitleContainer,
} from './styles'

export const Card = (data: CardContent) => {
  const { text, type } = data

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { school } = useSelectedSchool()

  if (type === 'textOnly') {
    return (
      <NoTitleContainer>
        <Text variant="caption-regular">{text}</Text>
      </NoTitleContainer>
    )
  }

  const { title, variant, externalLink } = data

  const getNavigationUrl = (linkUrl: string) => {
    return `/${school?.slug}/${linkUrl}`.replace(/\/\//g, '/')
  }

  return (
    <CardContainer>
      <div className="icon-container">
        <Icon path={iconPath({ variant })} size={1} />
      </div>
      <div>
        <CardTitle variant="body-1-medium">
          <strong>{title}</strong>
        </CardTitle>
        <Text variant="body-2-regular">{text}</Text>
        {externalLink && (
          <ExternalLinkContainer>
            <ExternalLink
              href={getNavigationUrl(externalLink.link)}
              target="_blank"
              onClick={() =>
                isInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
                  name: EventDispatcherEvents.LINK_CLICKED,
                  action: 'click',
                  customProperties: {
                    $Button_name: 'CALL_TO_ACTION',
                    $value: title,
                    $Card_name: 'DETALHE_DA_SITUACAO_DE_MATRICULA',
                  },
                })
              }
            >
              {externalLink.label}
            </ExternalLink>
          </ExternalLinkContainer>
        )}
      </div>
    </CardContainer>
  )
}
