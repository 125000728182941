import { Badge, BadgeProps } from '@gravity/badge'
import { ReenrollmentStatus } from '@/shared/interfaces'

export const statusParamsDict: PartialRecord<
  ReenrollmentStatus,
  { color: BadgeProps['color']; text: string; variant?: BadgeProps['variant'] }
> = {
  [ReenrollmentStatus.NOT_REENROLLABLE]: { color: 'error', text: 'Não rematriculável' },
  [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: { color: 'accent', text: 'Rematrícula disponível' },
  [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: { color: 'warning', text: 'Matrícula em andamento' },
  [ReenrollmentStatus.ENROLLMENT_AT_RISK]: {
    color: 'error',
    text: 'Matrícula em risco',
    variant: 'solid',
  },
  [ReenrollmentStatus.ENROLLED]: { color: 'success', text: 'Matriculado' },
  [ReenrollmentStatus.GATE_ERROR]: { color: 'neutral', text: 'Indisponível no momento' },
  [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: { color: 'neutral', text: 'Indisponível no momento' },
}

export type StudentStatusBadgeProps = {
  status: ReenrollmentStatus
}

export const StudentStatusBadge = ({ status }: StudentStatusBadgeProps) => {
  return (
    <Badge
      variant={statusParamsDict[status]?.variant ?? 'soft'}
      color={statusParamsDict[status]?.color ?? 'neutral'}
    >
      {statusParamsDict[status]?.text ?? ''}
    </Badge>
  )
}
