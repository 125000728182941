import axios, { AxiosInstance } from 'axios'

import config from '@/config'

import type { ClientNames } from './types'

const PUBLIC_DOMAIN = `${config.API.URL}/api/v1`
const PRIVATE_DOMAIN = `${config.API.URL}/ws/v1`
const PRIVATE_DOMAIN_V2 = `${config.API.URL}/ws/v2`
const BASE_PRIVATE_DOMAIN = `${config.API.URL}/ws`
const BFF_BASE_URL = config.BFF_API_URL

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export class ApiClient {
  public privateApi: AxiosInstance
  public privateApiV2: AxiosInstance
  public basePrivateApi: AxiosInstance
  public publicApi: AxiosInstance

  private bffApi: AxiosInstance

  constructor() {
    this.privateApi = axios.create({ baseURL: PRIVATE_DOMAIN, headers, withCredentials: true })
    this.privateApiV2 = axios.create({
      baseURL: PRIVATE_DOMAIN_V2,
      headers,
      withCredentials: true,
    })
    this.basePrivateApi = axios.create({
      baseURL: BASE_PRIVATE_DOMAIN,
      headers,
      withCredentials: true,
    })
    this.publicApi = axios.create({ baseURL: PUBLIC_DOMAIN, headers, withCredentials: true })

    this.bffApi = axios.create({
      baseURL: BFF_BASE_URL,
      withCredentials: true,
    })
  }

  public getClients(): Record<ClientNames, AxiosInstance> {
    const clients = {
      privateApi: this.privateApi,
      privateApiV2: this.privateApiV2,
      publicApi: this.publicApi,
      bffApi: this.bffApi,
      basePrivateApi: this.basePrivateApi,
    }

    return clients
  }
}
