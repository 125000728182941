import { SIDE_MENU_WIDTH } from '@/shared/constants'
import styled, { css } from 'styled-components'

type ContentProps = {
  drawerWidth: number
  isCollapsed: boolean
}

export const Container = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;

  transition: max-width 0.3s cubic-bezier(0.42, 0.29, 0.39, 0.83);

  ${props =>
    props.isCollapsed &&
    css`
      max-width: calc(100vw - ${SIDE_MENU_WIDTH} - ${props.drawerWidth}px);
    `}
`

export const Content = styled.div`
  padding: 0 4rem 1rem;
  flex: 1;
  overflow-y: auto;
`
