import styled from 'styled-components'

import { SIDEBAR_MENU_LEFT_BAR_WIDTH } from '@monorepo/students/pages/Student/constants'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  gap: ${props => props.theme.gravity.spacing[4]};
`

export const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};

  position: sticky;
  z-index: 97;
  top: calc(${props => props.theme.gravity.spacing[6]} + 60px);
  height: fit-content;
  min-width: fit-content;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${SIDEBAR_MENU_LEFT_BAR_WIDTH};
    background-color: ${props => props.theme.gravity.colors['colors-border-neutral-2']};
  }
`
