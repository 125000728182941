import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import * as Styled from './styles'
import { useHistory } from 'react-router-dom'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/shared/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'

interface CancelNegotiationDialogProps {
  agreementId?: string
  closeDialog: () => void
  visible: boolean
}

export const CancelNegotiationDialog = ({
  visible,
  closeDialog,
  agreementId,
}: CancelNegotiationDialogProps) => {
  const history = useHistory()

  const { pageToReturn } = useGuardianNavigation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendEvent = (type: 'close' | 'confirm') => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.INSTALLMENT_NEGOTIATIONS,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.NEGOTIATION,
        identifierName: EventIdentifierName.MODAL_CANCEL_NEGOTIATION,
        customProperties: {
          $type: type,
          $agreement_id: agreementId,
          $button_name: type === 'close' ? 'Fechar' : 'Ir para o responsável',
        },
      })
    }
  }

  const handleDialogOpenChange = (open: boolean) => {
    if (!open && visible) {
      sendEvent('close')
      closeDialog()
    }
  }

  const handleGoToResponsiblePage = () => {
    sendEvent('confirm')
    closeDialog()
    if (pageToReturn) return history.push(pageToReturn)
    return history.goBack()
  }

  return (
    <Dialog
      title="Você ainda tem faturas a pagar"
      backdrop
      open={visible}
      size={1}
      onOpenChange={handleDialogOpenChange}
      cancelButton={
        <Styled.ButtonContainer>
          <Button variant="solid" onClick={handleGoToResponsiblePage}>
            Ir para o responsável
          </Button>
        </Styled.ButtonContainer>
      }
      actionButton={<></>}
      content={
        <Text variant="body-2-regular">
          Ao voltar para a página do responsável financeiro, você irá cancelar os próximos
          pagamentos.
        </Text>
      }
    />
  )
}
