import { Theme } from '@/shared/@types/styled'
import { IconButton } from '@gravity/icon-button'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const DangerIconButton = styled(IconButton)`
  --tw-ring-shadow: 0 0 #000 !important;

  color: ${({ theme }) => theme.gravity.colors['colors-interaction-dangerous-1']};

  z-index: 2;

  :not([disabled]) {
    border: 1px solid ${({ theme }) => theme.gravity.colors['colors-interaction-dangerous-4']};
  }

  :hover {
    background-color: ${({ theme }) => theme.gravity.colors['colors-interaction-dangerous-4']}20;
  }
`

export const SplitInputRow = styled.div`
  margin-top: ${({ theme }) => theme.gravity.spacing[4]};

  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.gravity.spacing[6]};

  > div {
    flex: 1;
  }
`

export const InputRow = styled.div`
  margin-top: ${({ theme }) => theme.gravity.spacing[4]};
`

type InputWrapperProps = {
  hasError: boolean
}

export const InputWrapper = styled.div<InputWrapperProps>`
  ${({ hasError }) =>
    hasError &&
    `
    margin-bottom: ${({ theme }: { theme: Theme }) => theme.gravity.spacing[4]};
  `}
`

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
