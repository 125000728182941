import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  .grid-container {
    padding-top: ${({ theme }) => theme.gravity.spacing[10]};
  }
`
