import { Skeleton } from '@gravity/skeleton'
import { Text } from '@gravity/text'

import { AmountContainerPlaceholder, FinanceValuesContainerPlaceholder } from './styles'

export const FinancesLoading = () => {
  return (
    <FinanceValuesContainerPlaceholder>
      <AmountContainerPlaceholder data-testid="finances-loading">
        <Text variant="body-2-regular">Vencido</Text>
        <Skeleton variant="neutral" width={107} height={25} />
      </AmountContainerPlaceholder>
      <AmountContainerPlaceholder data-testid="finances-loading">
        <Text variant="body-2-regular">A vencer</Text>
        <Skeleton variant="neutral" width={107} height={25} />
      </AmountContainerPlaceholder>
    </FinanceValuesContainerPlaceholder>
  )
}
