import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Heading } from '@gravity/heading'
import { Button } from '@gravity/button'
import { mdiChevronLeft } from '@mdi/js'
import Icon from '@mdi/react'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetFamilyRegularizationReport } from '../../hooks/queries/familyRegularizationReport'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

import * as Styled from './styles'
import { PageStatusWrapper } from '@/shared/components/PageStatusWrapper'

export const FamilyRegularizationReport = () => {
  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()
  const reportQuery = useGetFamilyRegularizationReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  const goToReportsList = () => push(`/${schoolSlug}/relatorios`)

  return (
    <PageStatusWrapper status={reportQuery.status}>
      <Styled.Header>
        <Button
          variant="ghost"
          iconStart={<Icon path={mdiChevronLeft} />}
          onClick={goToReportsList}
        >
          Voltar
        </Button>
        <Heading variant="heading-h3-medium" className="title">
          Relatório de regularização das famílias
        </Heading>
      </Styled.Header>

      {iframeStatus === 'loading' ? <CircularLoadingPlaceholder /> : null}

      <Styled.Iframe
        id="fidc-report"
        src={reportQuery.data?.url}
        style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
        onLoad={() => setIframeStatus('success')}
      />
    </PageStatusWrapper>
  )
}
