import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@gravity/button'
import { Heading } from '@gravity/heading'
import { Skeleton } from '@gravity/skeleton'
import { mdiArrowLeft } from '@mdi/js'
import { Icon } from '@mdi/react'

import { DebtStatus } from '../GuardianDetailsCard/components'

import { GuardianDetailsHeaderV2Props } from './types'
import { Wrapper, Container } from './styles'

const LoadingPlaceholder = () => (
  <>
    <Skeleton width={350} height={30} />
    <Skeleton radius={6} width={84} />
  </>
)

export const GuardianDetailsCompactedHeader = ({
  children,
  guardian,
  isLoading,
  originalHeaderRef,
}: GuardianDetailsHeaderV2Props) => {
  const history = useHistory()

  const compactedHeader = useRef<HTMLDivElement>(null)

  const compactedHeaderHeight = compactedHeader.current?.clientHeight || 0
  const originalHeaderHeight = originalHeaderRef.current?.clientHeight || 0
  const originalHeaderStart = originalHeaderRef.current?.offsetTop ?? 0

  const scrollThreshold =
    (originalHeaderHeight ? originalHeaderHeight - 80 : 0) + originalHeaderStart

  const lastKnownScrollPosition = useRef(0)
  const ticking = useRef(false)

  const handleHeaderVisibility = (scrollPosition: number) => {
    if (!compactedHeader.current) return

    if (scrollThreshold > 0 && scrollPosition > scrollThreshold) {
      compactedHeader.current.style.transform = `translateY(${compactedHeaderHeight}px)`
    } else {
      compactedHeader.current.style.transform = 'translateY(0)'
    }
  }

  const handleScrollCapture = () => {
    lastKnownScrollPosition.current = window.scrollY

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        handleHeaderVisibility(lastKnownScrollPosition.current)

        ticking.current = false
      })

      ticking.current = true
    }
  }

  const handleClick = () => {
    if (!isLoading) {
      history.goBack()
    }
  }

  useEffect(() => {
    if (!originalHeaderHeight) return

    document.addEventListener('scroll', handleScrollCapture)
    handleHeaderVisibility(window.scrollY)
  }, [originalHeaderHeight])

  useEffect(() => {
    return () => {
      document.removeEventListener('scroll', handleScrollCapture)
    }
  }, [])

  return (
    <Wrapper ref={compactedHeader}>
      <Container>
        <Button
          variant="ghost"
          iconStart={<Icon path={mdiArrowLeft} />}
          onClick={handleClick}
          disabled={isLoading}
          data-testid="backButton"
        >
          Voltar
        </Button>

        {isLoading ? (
          <LoadingPlaceholder />
        ) : (
          <>
            <Heading variant="heading-h4-medium">{guardian?.name}</Heading>
            <DebtStatus status={guardian?.debt_status} />
          </>
        )}
      </Container>
      {children}
    </Wrapper>
  )
}
