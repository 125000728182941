import { Skeleton } from '@gravity/skeleton'
import { Text } from '@gravity/text'
import { Container, StudentStatus } from '../StudentNameContainer/styles'

export const StudentNameContainerPlaceholder = () => (
  <Container>
    <StudentStatus>
      <Text variant="body-2-regular">Aluno</Text>
      <Skeleton width={84} radius={6} />
    </StudentStatus>
    <Skeleton width={200} radius={6} />
  </Container>
)
