import {
  Contract,
  InstallmentType,
  ListProductsFilters,
  ListStudentsFilters,
  Pagination,
} from '@/shared/interfaces'

export const fillMatriculasApiContracts = async (api, contracts: Contract[]) => {
  if (contracts.length === 0) return contracts

  const productIds = []
  const studentIds = []

  contracts.forEach(({ product_id, student_id }) => {
    productIds.push(product_id)
    studentIds.push(student_id)
  })

  const uniqueProductIds = Array.from(new Set(productIds))
  const uniqueStudentIds = Array.from(new Set(studentIds))

  const productQueryParams: Pagination & ListProductsFilters = {
    ids: uniqueProductIds.join(','),
    per_page: 300,
  }
  const studentQueryParams: Pagination & ListStudentsFilters = {
    student_ids: uniqueStudentIds.join(','),
    per_page: 300,
  }

  const [productResp, studentResp] = await Promise.all([
    api.products.getList(productQueryParams),
    api.students.getList(studentQueryParams),
  ])

  return contracts.map(contract => ({
    ...contract,
    has_enrollment_installment: contract.pre_contract_installments?.some(
      i => i.type === InstallmentType.ENROLLMENT
    ),
    product: productResp.data.find(({ id }) => id === contract.product_id),
    student: studentResp.data.find(({ id }) => id === contract.student_id),
  }))
}
