import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    margin-bottom: ${props => props.theme.gravity.spacing[14]};
  }

  .disclaimer {
    text-align: center;
    margin: 0 0 ${props => props.theme.gravity.spacing[6]} 0;
  }
`
