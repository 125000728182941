import { Charge, Discount } from '@monorepo/onboarding/services/types/onboardingCharge'
import styled from 'styled-components'
import { Text } from '@gravity/text'
import { Checkbox } from '@gravity/checkbox'
import { CurrencyInput } from './CurrencyInput'
import { CustomDateInput } from './CustomDateInput'
import { DiscountFieldName, getChargeDiscountErrorProps } from './utils'
import { useState } from 'react'
import { TextField } from '@gravity/text-field'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'

type ChargeDiscountProps = {
  charge: Charge
  discount: Discount
  index: number
  onChange: (index: number, fieldName: DiscountFieldName, value: string) => void
  originalCharge: Charge
}

const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const DiscountFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`

const DiscountDueDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  & > *:first-child {
    flex: 1 1 0px;
  }
`

const PERMANENT_DISCOUNT_DUE_DATE = 'PERMANENTE'

export const ChargeDiscount = ({
  charge,
  discount,
  index,
  onChange,
  originalCharge,
}: ChargeDiscountProps) => {
  const [checked, setChecked] = useState(
    discount.expiration_date.toUpperCase() === PERMANENT_DISCOUNT_DUE_DATE
  )
  const events = useEvents()

  return (
    <DiscountWrapper key={discount.id}>
      <Text variant="body-1-medium">{`Desconto ${index + 1}`}</Text>

      <DiscountFieldsWrapper>
        <CurrencyInput
          label="Valor do desconto"
          name={`discount.${index}.amount`}
          onChange={value => onChange(index, 'amount', value)}
          value={discount.amount}
          {...getChargeDiscountErrorProps('amount', index, charge, originalCharge)}
        />

        <DiscountDueDateWrapper>
          {checked ? (
            <TextField
              size={3}
              disabled
              label="Vencimento do desconto"
              value="Desconto permanente"
              name={`discount.${index}.expiration_date`}
            />
          ) : (
            <CustomDateInput
              label="Vencimento do desconto"
              name={`discount.${index}.expiration_date`}
              defaultValue={discount.expiration_date}
              onChange={value => {
                onChange(index, 'expiration_date', value)
              }}
              {...getChargeDiscountErrorProps('expiration_date', index, charge, originalCharge)}
            />
          )}

          <div style={{ paddingTop: '40px' }}>
            <Checkbox
              checked={checked}
              onCheckedChange={value => {
                events?.editDiscountType()
                setChecked(Boolean(value))

                onChange(index, 'expiration_date', value ? PERMANENT_DISCOUNT_DUE_DATE : '')
              }}
              text="É desconto permanente (não vence)"
              size={1}
            />
          </div>
        </DiscountDueDateWrapper>
      </DiscountFieldsWrapper>
    </DiscountWrapper>
  )
}
