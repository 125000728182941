import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import { Heading } from '@gravity/heading'
import { useGetOnboardingBatch } from '../../hooks/useFetchOnboardingBatches'
import { useParams } from 'react-router-dom'
import { OnboardingBatch } from '../../services/types/onboardingBatch'
import { LoadingPage } from '../LoadingPage'
import styled from 'styled-components'
import { GenericError } from '../components/GenericError'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

interface BatchProgressProps {
  style?: React.CSSProperties
}

export const BatchProgress = (props: BatchProgressProps) => {
  const { batchID } = useParams<{ batchID: string }>()

  const { data, isLoading, isError } = useGetOnboardingBatch(
    { batchID },
    {
      onError(error) {
        console.error(error)
      },
      refetchInterval: (data: OnboardingBatch | undefined) => {
        return data?.status !== 'DONE' ? 5000 : false
      },
    }
  )

  if (isError) return <GenericError />

  if (isLoading || !data?.progress) {
    return <LoadingPage />
  }

  const contractsProcessed = data.progress.FAIL + data.progress.SUCCESS + data.progress.SKIP

  const totalContracts: number = Object.values(data.progress).reduce(
    (acc: number, value: number) => acc + value,
    0
  )

  const normalizedProgress = (contractsProcessed * 100) / totalContracts

  return (
    <Container {...props}>
      <Heading variant="heading-h3-regular">
        {`${contractsProcessed} contratos migrados de ${totalContracts}`}
      </Heading>
      <Box>
        <LinearProgress
          style={{ width: '353px', height: '6px' }}
          variant="determinate"
          value={normalizedProgress}
        />
      </Box>
    </Container>
  )
}
