import type { AxiosInstance } from 'axios'

import { APIResponse, Student } from '@/shared/interfaces'

export const studentsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for updating student data
     *
     * @param id Student ID
     * @param student Student data to be updated
     *
     * @returns Student data updated
     */
    updateStudentV2: async (id: uuid, student: Partial<Student>) =>
      (await fetcher.patch<APIResponse<Student>>(`/v2/student/${id}`, student))?.data.data,
  }
}
