import { StatusBadgeColor } from '@/shared/components/StatusBadge'
import { ContractStatus } from '@/shared/interfaces'

export const contractStatusDict: PartialRecord<
  ContractStatus,
  { color: StatusBadgeColor; text: string }
> = {
  OPEN: { color: 'success', text: 'Ativo' },
  CANCELED: { color: 'error', text: 'Inativo' },
}
