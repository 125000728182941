import { useEffect, useState } from 'react'
import { ReenrollmentStatus, ReEnrollmentSubStatus } from '@/shared/interfaces'

export type EnabledEnrollmentCycle = {
  is_reenrollable: boolean
  reference_year: number
  started_enrollment: boolean
  student_financial_situation: string
  student_status: ReenrollmentStatus
  student_status_details: ReEnrollmentSubStatus[]
}

export const useSelectCycle = (enabledCycles: EnabledEnrollmentCycle[]) => {
  const [selectedCycleYear, setSelectedCycleYear] = useState(0)

  const selectedCycle = enabledCycles.find(cycle => cycle.reference_year === selectedCycleYear)

  const shouldShowSelectCycleYearDropdown = selectedCycle && enabledCycles.length > 1

  useEffect(() => {
    if (selectedCycleYear === 0 && enabledCycles.length) {
      setSelectedCycleYear(enabledCycles[0].reference_year)
    }
  }, [enabledCycles])

  const optionsCycle = (() => {
    return enabledCycles?.map(cycle => {
      return {
        label: `Ano letivo ${cycle.reference_year}`,
        value: cycle.reference_year.toString(),
      }
    })
  })()

  return {
    setSelectedCycleYear,
    shouldShowSelectCycleYearDropdown,
    selectedCycle,
    optionsCycle,
  }
}
