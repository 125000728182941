import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.gravity.spacing[4]};

  .guardian-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card {
    padding: ${({ theme }) => theme.gravity.spacing[2]};
  }

  .name {
    width: 26ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${({ theme }) => theme.gravity.spacing[6]};

  > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${({ theme }) => theme.gravity.spacing[2]};
  }
`
