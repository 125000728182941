import type { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { FormOfPayment } from '@/modules/guardians/models/agreement'
import { useAgreement } from 'src/escolas/contexts/agreementContext'

import AgreementConclusionStep from '../../steps/AgreementConclusionStep'
import Conclusion from '../../steps/Conclusion'
import { NegotiationData } from '../../EntryNegotiation/hooks/useEntryNegotiation'

interface ExtendedStepComponentProps extends StepComponentProps {
  goToPayAgreementStep: () => void
}

const SelectConclusion = ({
  id,
  goBack,
  goToPayAgreementStep,
  isNegotiationDownPayment,
}: ExtendedStepComponentProps) => {
  const { selectedFormOfPayment, checkoutData } = useAgreement()

  if (
    selectedFormOfPayment === FormOfPayment.POS ||
    selectedFormOfPayment === FormOfPayment.POS_V2
  ) {
    const conclusionInfo = {
      receivableId: checkoutData?.receivables?.[0]?.id,
      amount: checkoutData?.amount,
      paymentMethod: checkoutData?.payment_methods[0],
      id: checkoutData?.id,
      installmentsNumberSelected: checkoutData?.installments_count,
    } as NegotiationData
    return <Conclusion id={id} goBack={goBack} onFinish={goBack} conclusionInfo={conclusionInfo} />
  }

  return (
    <AgreementConclusionStep
      id={id}
      goToPayAgreementStep={goToPayAgreementStep}
      isNegotiationDownPayment={isNegotiationDownPayment}
    />
  )
}

export default SelectConclusion
