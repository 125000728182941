import { formatCentsToReal } from '@/shared/utils'
import { Card } from '@gravity/card'
import { Grid, GridItem } from '@gravity/grid'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { mdiAccountSchoolOutline, mdiFolderOutline, mdiTagOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import monetizationIcon from '../../assets/monetization-on-outline.svg'
import { useBatchSummaryQuery } from '../../hooks/useBatchSummaryQuery'
import { GenericError } from '../components/GenericError'
import { LoadingPage } from '../LoadingPage'

const StyledImg = styled.img`
  max-width: 24px;
  max-height: 24px;
`

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const DashboardContent = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const events = useEvents()

  const { isLoading, data, isError } = useBatchSummaryQuery(
    { batchID },
    {
      onError(error) {
        console.error(error)
      },
    }
  )
  useEffect(() => {
    events?.batchPageDashboardViewed()
  }, [])

  if (isError) return <GenericError />

  if (isLoading || data === undefined) {
    return <LoadingPage />
  }

  return (
    <Grid>
      <GridItem lg={6}>
        <Card variant="ghost">
          <StyledCardContent>
            <StyledImg src={monetizationIcon} />
            <Heading style={{ color: '#2F7C57' }} variant="heading-h1-medium">
              {formatCentsToReal(data.total_amount)}
            </Heading>
            <Text variant="body-1-regular">Valor total de receita a ser migrado</Text>
          </StyledCardContent>
        </Card>
      </GridItem>
      <GridItem lg={6}>
        <Card variant="ghost">
          <StyledCardContent>
            <Icon path={mdiTagOutline} size={1} />
            <Heading style={{ color: '#CE2C31' }} variant="heading-h1-medium">
              {formatCentsToReal(data.total_discount_amount)}
            </Heading>
            <Text variant="body-1-regular">Valor total de desconto a ser migrado</Text>
          </StyledCardContent>
        </Card>
      </GridItem>
      <GridItem lg={6}>
        <Card variant="ghost">
          <StyledCardContent>
            <Icon path={mdiAccountSchoolOutline} size={1} />
            <Heading variant="heading-h1-medium">{data.student_count}</Heading>
            <Text variant="body-1-regular">Alunos a serem migrados</Text>
          </StyledCardContent>
        </Card>
      </GridItem>
      <GridItem lg={6}>
        <Card variant="ghost">
          <StyledCardContent>
            <Icon path={mdiFolderOutline} size={1} />
            <Heading variant="heading-h1-medium">{data.contract_count}</Heading>
            <Text variant="body-1-regular">Contratos a serem migrados</Text>
          </StyledCardContent>
        </Card>
      </GridItem>
    </Grid>
  )
}
