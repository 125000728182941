import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useQuery } from '@/shared/hooks/useQuery'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { useGuardianHistoryQuery } from './useGuardianHistoryQuery'

export const useGuardianHistory = () => {
  const now = dayjs()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { guardianId } = useParams<{ guardianId: string }>()
  const { school } = useSelectedSchool()

  const { query, setOnQueryParam } = useQuery()
  const period = query.get('period')
  const lastPeriod = dayjs(period, 'YYYY-MM').subtract(1, 'month').startOf('month')
  const nextPeriod = dayjs(period, 'YYYY-MM').add(1, 'month').startOf('month')

  const [selectedPeriod, setSelectedPeriod] = useState<Date>(null)

  const isCurrentMonthSelected = period === now.format('YYYY-MM')

  const { guardianHistory, isLoading, isSuccess } = useGuardianHistoryQuery({
    guardianId,
    schoolId: school.id,
    period: period || now.format('YYYY-MM'),
  })

  const isEmpty = !isLoading && isSuccess && guardianHistory?.length < 1

  const isSameDate = (currentDate: string, lastCardDate?: string) => {
    if (!lastCardDate) return true
    return dayjs(currentDate).format('YYYY-MM-DD') !== dayjs(lastCardDate).format('YYYY-MM-DD')
  }

  const handleApplyFilter = (value: Date) => {
    setSelectedPeriod(value)
    setOnQueryParam(dayjs(value).format('YYYY-MM'), 'period', 'replace')
  }

  const handleEventDatepicker = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.GUARDIANS,
        action: 'click',
        customProperties: {
          $page_name: 'Historico RF',
          $button_name: 'Nav datepicker historico',
        },
      })
    }
  }

  const handleEventDateNavigation = (direction: 'left' | 'right') => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.GUARDIANS,
        action: 'click',
        customProperties: {
          $page_name: 'Historico RF',
          $button_name: direction === 'right' ? 'Nav proximo historico' : 'Nav anterior historico',
        },
      })
    }
  }

  const handleEventCollapsible = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.GUARDIANS,
        action: 'click',
        customProperties: {
          $page_name: 'Historico RF',
          $button_name: 'Detalhes do histórico',
        },
      })
    }
  }

  useEffect(() => {
    if (period) {
      setSelectedPeriod(dayjs(period, 'YYYY-MM').toDate())
    } else {
      const startOfMonth = now.startOf('month')
      setSelectedPeriod(startOfMonth.toDate())
      setOnQueryParam(startOfMonth.format('YYYY-MM'), 'period', 'replace')
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.PAGE_VIEWED,
        scope: EventDispatcherEventScopes.GUARDIANS,
        action: 'page_view',
        customProperties: {
          $page_name: 'Historico RF',
        },
      })
    }
  }, [])

  return {
    now,
    lastPeriod,
    nextPeriod,
    selectedPeriod,
    handleApplyFilter,
    isSameDate,
    guardianHistory,
    isLoading,
    isEmpty,
    isCurrentMonthSelected,
    handleEventDatepicker,
    handleEventDateNavigation,
    handleEventCollapsible,
  }
}
