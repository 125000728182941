import { Skeleton } from '@gravity/skeleton'
import { GridItem } from '@gravity/grid'
import { InteractiveCard } from '@gravity/interactive-card'

export const GuardiansLoading = () => {
  return (
    <>
      {[0, 1].map(guardian => (
        <GridItem lg={4} xl={4} md={8} sm={4} key={guardian} data-testid="loading">
          <InteractiveCard fullWidth>
            <div className="content loading">
              <Skeleton variant="neutral" width={100} height={20} />
              <Skeleton variant="neutral" width={200} height={25} />
            </div>
          </InteractiveCard>
        </GridItem>
      ))}
    </>
  )
}
