import { Text } from '@gravity/text'
import { Container } from './styles'

export const StatusEnrollmentError = () => {
  return (
    <Container>
      <Text variant="body-1-regular">
        Não há dados de matrícula para serem exibidos no ano letivo atual
      </Text>
    </Container>
  )
}
