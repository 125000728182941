import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks'
import { onboardingAPI } from '../services/onboardingApi'

export type BatchCSVParams = {
  batchID: uuid
}

export const useBatchCSVQuery = (params: BatchCSVParams, options?: UseQueryOptions<Blob>) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useQuery<Blob>(['batchCSVQuery', params], () => api.getBatchCSV(params), options)
}
