import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { isEmpty, propEq } from 'ramda'
import { Dayjs } from 'dayjs'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

import { Receivable } from '@/shared/interfaces'
import { useContract, usePagedDrawer } from '@/escolas/hooks'
import { UnleashFlags, useJWT, useUnleashFlag, useSnackbar } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import PagedDrawer from '@/escolas/components/PagedDrawer'
import { FailureFeedbackDialog } from '@/shared/components/ConfirmationDialog'
import { useChangeDueDayInfo } from '@/escolas/hooks/useChangeDueDayInfo'

import SelectDueDay from './components/SelectNewDueDay/SelectNewDueDay'
import ConfirmationNewsDueDays from './components/ConfirmationNewsDueDays/ConfirmationNewsDueDays'
import SelectInitialInstallment from './components/SelectInitialInstallment/SelectInitialInstallment'
import SelectReason from './components/SelectReason/SelectReason'
import SelectFirstDueDay from './components/SelectFirstDueDay/SelectFirstDueDay'
import { EditAvailabilityWarning } from './components/EditAvailabilityWarning/EditAvailabilityWarning'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`

export const SelectOverDueTitle = styled(StyledTypography)`
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 4px;
`
export const SelectOverDueSubtitle = styled(StyledTypography)`
  margin-bottom: 26px;
  color: #6d6d6d;
`
export const OverDueHeader = styled(Box)`
  margin-bottom: 4px;
  margin-top: 24px;
`

export type ContractEditForm = {
  change_due_month: string
  change_reason_additional_information: string
  due_day: number
  installment_id: uuid
  start_month?: Dayjs
  use_working_dates?: string
}

export type InstallmentChange = {
  current_amount?: number
  due_month: datestring
  id: uuid
  index: number
  newDueDate: Dayjs
  newValue?: number
  orderReference: string
  originalDueDate: Dayjs
  originalValue: number
  receivable: Receivable
}

export type ContractEditDueDayDrawerProps = {
  availableInstallments: InstallmentChange[]
  callbackEdits?: Dispatch<SetStateAction<boolean>>
  flexibleMaxDueDate?: string
  isContractInFlexiblePeriod?: boolean
  isOpen: boolean
  onClose: () => void
}

const UpdateZipCodeBtn = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3d4ed7;
  cursor: pointer;
`

const ContractEditDueDayDrawer = ({
  availableInstallments,
  callbackEdits,
  flexibleMaxDueDate,
  isContractInFlexiblePeriod,
  isOpen,
  onClose,
}: ContractEditDueDayDrawerProps) => {
  const history = useHistory()
  const params = new URLSearchParams()
  const { api } = useApi()
  const { contract } = useContract()
  const form = useForm<ContractEditForm>({
    mode: 'all',
    defaultValues: {
      installment_id: '',
      change_reason_additional_information: '',
      use_working_dates: 'true',
    },
  })
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = form
  watch([
    'change_due_month',
    'installment_id',
    'due_day',
    'start_month',
    'change_reason_additional_information',
    'use_working_dates',
  ])
  const {
    change_due_month,
    installment_id,
    due_day,
    start_month,
    change_reason_additional_information,
    use_working_dates,
  } = getValues()

  const { currentPage, setCurrentPage } = usePagedDrawer()
  const [isNextPageDisabled, setIsNextPageDisabled] = useState(true)
  const [showFailureDialog, setShowFailureDialog] = useState(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { setContract } = useContract()
  const { isAdmin } = useJWT()
  const isNewContractEditRulesEnabled =
    useUnleashFlag(UnleashFlags.ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS) && !isAdmin

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const isChangeDueMonthTrue = (change_due_month_value: any) =>
    isAdmin ? change_due_month_value === 'true' : true

  const submitHandler = async () => {
    return api.contracts
      .changeDueDayCreate(contract?.id, {
        change_reason_additional_information,
        change_due_month: isChangeDueMonthTrue(change_due_month),
        due_day: +due_day,
        use_working_dates: use_working_dates === 'true',
        installment_id,
        start_month: start_month.hour(0).minute(0).second(0).millisecond(0).utc().hour(0).format(),
      })
      .then(contractData => {
        setContract(contractData)
        setSnackbarVariation('success')
        setSnackbarMessage('Vencimentos alterados com sucesso')
        setSnackbarIsOpen(true)
        onClose()
        if (callbackEdits) {
          callbackEdits(true)
        }
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (isInitialized) {
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.DUE_DATE_DRAWER,
              name: EventDispatcherEvents.MODAL_VIEWED,
              action: 'component_view',
              customProperties: {
                $button_name: 'Confirmar',
                $modal_name: 'Mensagem de erro edição vencimentos',
              },
            })
          }
          if (status === 422 && errors[0].message === 'Zip Code error') {
            setSnackbarIsOpen(true, {
              variation: 'error',
              title: 'Atualize o CEP do responsável',
              description:
                'Não foi possível alterar o desconto, pois identificamos que o CEP no cadastro está desatualizado',
              link: (
                <UpdateZipCodeBtn
                  onClick={() => {
                    params.append('abrirCadastro', 'true')
                    params.append('abrirEndereco', 'true')
                    history.push({ search: params.toString() })
                  }}
                >
                  Atualizar CEP
                </UpdateZipCodeBtn>
              ),
            })
          } else {
            setShowFailureDialog(true)
          }
          onClose()
        }
      )
  }

  const { installmentsToChangeDueDay, isFetching } = useChangeDueDayInfo({
    contractId: contract?.id,
    change_due_month: isChangeDueMonthTrue(change_due_month),
    due_day: due_day,
    installment_id: installment_id,
    start_month: start_month?.hour(0).minute(0).second(0).millisecond(0).utc().hour(0).format(),
    use_working_dates: use_working_dates === 'true',
  })

  const setProgressButton = () => {
    const firstStep = isAdmin ? 0 : -1
    const isReasonStep = currentPage === firstStep
    const isSelectFirstInstallment = currentPage === firstStep + 1
    const isSelectDueDay = currentPage === firstStep + 2

    switch (true) {
      case isReasonStep:
        setIsNextPageDisabled(!change_due_month)
        break
      case isSelectFirstInstallment:
        setIsNextPageDisabled(
          !installment_id ||
            change_reason_additional_information.length < 5 ||
            change_reason_additional_information.length > 100
        )
        break
      case isSelectDueDay:
        setIsNextPageDisabled(!start_month || !due_day || !isEmpty(errors))
        break
    }
  }

  useEffect(setProgressButton, [
    currentPage,
    start_month,
    change_due_month,
    installment_id,
    change_reason_additional_information,
    due_day,
    use_working_dates,
  ])

  useEffect(() => {
    const selectedInstallment = availableInstallments?.find(propEq('id', installment_id))
    if (!selectedInstallment) return
    const installmentDueDate = selectedInstallment.originalDueDate
    setValue('start_month', installmentDueDate, { shouldValidate: true })
  }, [installment_id])

  const pages: React.ReactFragment[] = [
    <SelectInitialInstallment
      form={form}
      key={0}
      availableInstallments={availableInstallments}
      contract={contract}
      isContractInFlexiblePeriod={isContractInFlexiblePeriod}
    />,
    <div key={1}>
      <EditAvailabilityWarning
        isContractInFlexiblePeriod={isContractInFlexiblePeriod}
        isNewContractEditRulesEnabled={isNewContractEditRulesEnabled}
      />
      <OverDueHeader>
        <SelectOverDueTitle>Escolha a nova data de vencimento das mensalidades:</SelectOverDueTitle>
        <SelectOverDueSubtitle>
          Selecione a regra de dia de vencimento das parcelas
        </SelectOverDueSubtitle>
      </OverDueHeader>
      <SelectDueDay form={form} radioName="use_working_dates" inputName="due_day" />
      <SelectFirstDueDay
        form={form}
        inFlexPeriod={isContractInFlexiblePeriod}
        flexibleMaxDueDate={flexibleMaxDueDate}
        allInstallments={contract?.installments}
        selectedInstallmentID={installment_id}
      />
    </div>,
    <ConfirmationNewsDueDays
      key={2}
      installmentChanges={installmentsToChangeDueDay}
      changeReasonAdditionalInformation={change_reason_additional_information}
      isWorkingDay={use_working_dates === 'true'}
    />,
  ]

  const pagesConfig = {
    pages: {
      SELECT_REASON_STEP: {
        index: -1,
        action: () => setCurrentPage(currentPage + 1),
      },
      SELECT_INITIAL_INSTALLMENT_STEP: {
        index: 0,
        action: () => setCurrentPage(currentPage + 1),
      },
      SELECT_NEW_DUE_DAY_STEP: {
        index: 1,
        action: () => setCurrentPage(currentPage + 1),
      },
      CONFIRMATION_STEP: {
        index: 2,
        action: handleSubmit(submitHandler),
      },
    },
  }

  const getPagesConfig = () => {
    const {
      SELECT_REASON_STEP,
      SELECT_INITIAL_INSTALLMENT_STEP,
      SELECT_NEW_DUE_DAY_STEP,
      CONFIRMATION_STEP,
    } = pagesConfig.pages

    const currentSelectReasonIndex = SELECT_REASON_STEP.index
    const currentSelectInitialInstallmentIndex = SELECT_INITIAL_INSTALLMENT_STEP.index
    const currentSelectNewDueDayIndex = SELECT_NEW_DUE_DAY_STEP.index
    const currentConfirmationIndex = CONFIRMATION_STEP.index

    if (isAdmin) {
      pages.unshift(
        <SelectReason form={form} key={0} isContractInFlexiblePeriod={isContractInFlexiblePeriod} />
      )
      return {
        [currentSelectReasonIndex + 1]: SELECT_REASON_STEP.action,
        [currentSelectInitialInstallmentIndex + 1]: SELECT_INITIAL_INSTALLMENT_STEP.action,
        [currentSelectNewDueDayIndex + 1]: SELECT_NEW_DUE_DAY_STEP.action,
        [currentConfirmationIndex + 1]: CONFIRMATION_STEP.action,
      }
    }

    return {
      [currentSelectInitialInstallmentIndex]: SELECT_INITIAL_INSTALLMENT_STEP.action,
      [currentSelectNewDueDayIndex]: SELECT_NEW_DUE_DAY_STEP.action,
      [currentConfirmationIndex]: CONFIRMATION_STEP.action,
    }
  }

  const handleClickMap = getPagesConfig()

  return (
    <>
      <PagedDrawer
        isLoading={isSubmitting || isFetching}
        isNextPageDisabled={isNextPageDisabled}
        pages={pages}
        handleClickMap={handleClickMap}
        isOpen={isOpen}
        onClose={() => {
          onClose()
          reset()
        }}
        title="Editar vencimento"
      />
      <FailureFeedbackDialog
        buttonLabel="Voltar"
        isVisible={showFailureDialog}
        onClose={() => setShowFailureDialog(false)}
        submitHandler={() => setShowFailureDialog(false)}
        centered
      />
    </>
  )
}

export default ContractEditDueDayDrawer
