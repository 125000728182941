import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { APIResponse, Installment } from '@/shared/interfaces'

export const installmentsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetching an installment
     *
     * @param id Installment ID
     * @param params Request params
     *
     * @returns Installment data
     */
    fetchInstallment: async (id: uuid, params?: AxiosRequestConfig) =>
      (await fetcher.get<APIResponse<Installment>>(`/installment/${id}`, params))?.data.data,
  }
}
