import styled from 'styled-components'

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  margin-top: ${props => props.theme.gravity.spacing[8]};
  padding-bottom: ${props => props.theme.gravity.spacing[10]};
  position: relative;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${props => props.theme.gravity.spacing[5]} 0 0;
`

export const HeaderTextWrapper = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[2]};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
`
