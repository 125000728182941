import { FC } from 'react'
import { Typography } from '@olaisaac/design-system'
import dayjs from 'dayjs'

import StatusBadge, { StatusBadgeColor } from '@/shared/components/StatusBadge'
import PrintReceiptButton from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/PrintReceiptButton'
import type { PaymentRowProps } from '@/modules/guardians/Negotiation/types'
import { CheckoutStatus, PaymentMethod } from 'src/shared/interfaces'
import { formatCentsToReal } from 'src/shared/utils'

import * as S from './styles'

const PaymentRow: FC<PaymentRowProps> = ({
  idForReceipt,
  amount,
  paymentMethod,
  status,
  onSuccess,
  installmentsNumber,
}) => {
  const paymentStatus = (function (): { badgeColor: StatusBadgeColor; label: string } {
    switch (status) {
      case CheckoutStatus.ABORTED:
        return {
          badgeColor: 'error',
          label: 'Abortado',
        }
      case CheckoutStatus.FINISHED:
        return {
          badgeColor: 'success',
          label: 'Paga',
        }
      case CheckoutStatus.ON_HOLD:
        return {
          badgeColor: 'grey',
          label: 'Aguardando',
        }
      case CheckoutStatus.PROCESSING:
        return {
          badgeColor: 'grey',
          label: 'Processando',
        }
    }
  })()

  const paymentMethodLabelMap: Record<PaymentMethod, string> = {
    [PaymentMethod.BANK_SLIP_PAYMENT_METHOD]: 'Boleto bancário    ',
    [PaymentMethod.CREDIT_CARD_PAYMENT_METHOD]: 'Cartão de crédito',
    [PaymentMethod.DEBIT_CARD_PAYMENT_METHOD]: 'Cartão de débito',
    [PaymentMethod.BANK_CHECK]: '',
    [PaymentMethod.BANK_DEPOSIT]: '',
    [PaymentMethod.BANK_TRANSFER]: '',
    [PaymentMethod.CASH]: '',
    [PaymentMethod.DIRECT_DEBIT]: '',
    [PaymentMethod.OTHER]: '',
    [PaymentMethod.PIX_PAYMENT_METHOD]: 'Pix',
    [PaymentMethod.SUBSCRIPTION]: '',
    [PaymentMethod.SCHOOL_PORTFOLIO_REPURCHASE]: '',
  }
  const numberInstallments = installmentsNumber ?? 1
  const amountPaid = amount / numberInstallments
  return (
    <S.Wrapper>
      <S.Column>
        <Typography withoutMargin variation="caption" color="secondary">
          Parcela 1 de 1
        </Typography>
        <Typography withoutMargin variation="bodyLarge">
          {formatCentsToReal(amount)}
        </Typography>
      </S.Column>

      <S.Column>
        <Typography withoutMargin variation="caption" color="secondary">
          {paymentMethodLabelMap[paymentMethod]}
        </Typography>
        <Typography withoutMargin variation="bodyLarge">
          {numberInstallments}x de {formatCentsToReal(amountPaid)}
        </Typography>
      </S.Column>

      <S.Column>
        <Typography withoutMargin variation="caption" color="secondary">
          Data de pagamento
        </Typography>
        <Typography withoutMargin variation="bodyLarge">
          Hoje, {dayjs().format('D MMMM')}
        </Typography>
      </S.Column>

      <S.Column>
        <Typography withoutMargin variation="caption" color="secondary">
          Situação
        </Typography>
        <Typography withoutMargin variation="bodyLarge">
          <StatusBadge color={paymentStatus.badgeColor} /> {paymentStatus.label}
        </Typography>
      </S.Column>

      <S.Column>
        <Typography withoutMargin variation="caption" color="secondary">
          Ações
        </Typography>
        <PrintReceiptButton
          buttonVariation="ghost"
          receivableId={idForReceipt}
          label="Gerar comprovante"
          onSuccess={onSuccess}
        />
      </S.Column>
    </S.Wrapper>
  )
}

export default PaymentRow
