import type { Dispatch, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'
import type { UseFormReturn } from 'react-hook-form'

import type { School } from '@/shared/models/School'
import type { AddTuitionToCampaignState, FormAddTuitionProps } from '../types'

import { useAddTuitionToCampaign } from './useAddTuitionToCampaign'

type Props = {
  campaignId: uuid
  school?: School
  setAddTuitionDialogState: Dispatch<SetStateAction<AddTuitionToCampaignState>>
}

export const useAddTuitionToOneCampaignStudent = ({
  campaignId,
  setAddTuitionDialogState,
  school,
}: Props) => {
  const form = useForm<FormAddTuitionProps>({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      custom_monthly_amount: 0,
      duration_months: '',
      installment_due_date_rule: null,
      due_day: undefined,
      due_date: null,
      due_date_month: null,
      discounts: [],
    },
  })

  const { addTuition, isLoading: isLoadingAddTuitionMutation } = useAddTuitionToCampaign({
    campaignId,
    school,
  })

  const handleSubmitAddTuitionToProposal = async (
    enrollmentId: uuid,
    formData: UseFormReturn<FormAddTuitionProps>
  ) => {
    addTuition({
      formData: formData.getValues(),
      enrollmentId: enrollmentId,
      excludedEnrollmentIds: [],
    })
    form.reset()
    setAddTuitionDialogState({ enrollmentId: '', studentName: '', openDialog: false })
  }
  const handleCloseDialogForm = (form: UseFormReturn<FormAddTuitionProps>) => {
    setAddTuitionDialogState({ enrollmentId: '', studentName: '', openDialog: false })
    form.reset()
  }
  return {
    isLoadingAddTuitionMutation,
    handleSubmitAddTuitionToProposal,
    form,
    handleCloseDialogForm,
  }
}
