import { ReenrollmentStatus } from '@/shared/interfaces'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'

import { StudentNameContainerPlaceholder } from '../StudentNameContainerPlaceholder'
import { StudentStatusBadge } from '../StudentStatusBadge'
import { StudentStatus, Container } from './styles'

export type StudentNameContainerProps = {
  isLoading: boolean
  studentName: string
  studentStatus: ReenrollmentStatus
}

export const StudentNameContainer = ({
  studentName,
  studentStatus,
  isLoading,
}: StudentNameContainerProps) => {
  return (
    <>
      {/* TODO: add state of error */}
      {isLoading ? (
        <StudentNameContainerPlaceholder />
      ) : (
        <Container>
          <StudentStatus>
            <Text variant="body-2-regular">Aluno</Text>
            <StudentStatusBadge status={studentStatus} />
          </StudentStatus>
          <Heading variant="heading-h3-medium">{studentName}</Heading>
        </Container>
      )}
    </>
  )
}
