import React, { useEffect, useState } from 'react'
import {
  Button,
  DialogContent,
  DialogActions,
  Typography,
  SystemButton,
} from '@olaisaac/design-system'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import { CircularProgress } from '@material-ui/core/'
import { CloseRounded } from '@material-ui/icons'

import { CheckoutStatus, CheckoutProvider } from '@/shared/interfaces'
import paymentFail from 'src/escolas/assets/paymentFail.svg'
import paymentSuccess from 'src/escolas/assets/paymentSuccess.svg'
import pos from 'src/escolas/assets/pos.svg'
import posFail from 'src/escolas/assets/POSFail.svg'

import { ConfirmationDialogProps } from '@/shared/components/ConfirmationDialog'
import * as Styled from './styles'
import { NotificationCheckoutDialog } from './NotificationCheckoutDialog'

export const StatusFeedbackCheckout = {
  on_hold: {
    content: 'Clique na tela da maquininha e siga as instruções indicadas',
    subtitle: '',
    image: pos,
    labelButton: 'Cancelar pagamento',
    showClose: true,
  },
  processing: {
    content: 'Transação selecionada e em andamento',
    subtitle: '',
    image: '',
    labelButton: 'Cancelar pagamento',
    showClose: true,
  },
  finished: {
    content: 'Pagamento realizado com sucesso',
    subtitle: '',
    image: paymentSuccess,
    labelButton: 'Finalizar',
    showClose: true,
  },
  aborted: {
    content: 'Pagamento cancelado',
    subtitle:
      'Agora, aperte o botão vermelho na maquininha para cancelar a transação por lá também.',
    image: paymentFail,
    labelButton: 'Fechar',
    showClose: true,
  },
  FAILURE_TO_CONNECT: {
    content: 'Falha ao conectar com a maquininha',
    subtitle: '',
    image: posFail,
    labelButton: 'Tentar novamente',
    showClose: true,
  },
} as const

export const TextAndImageContentCheckout = ({
  imageSrc,
  message,
  optionMessages,
  status,
  subtitle,
}: {
  imageSrc: string
  message: string
  optionMessages?: Array<React.ReactNode>
  status: CheckoutStatus
  subtitle?: string
}) => (
  <>
    <Box display="flex" justifyContent="center">
      {status === 'processing' ? (
        <Styled.BoxLoading>
          <CircularProgress style={{ width: '120px', height: 'auto' }} />
        </Styled.BoxLoading>
      ) : (
        <Styled.Image withButton={StatusFeedbackCheckout[status].showClose} src={imageSrc} />
      )}
    </Box>
    <Styled.Box display="flex" justifyContent="center" textAlign="center">
      <Typography style={{ padding: '16px 0' }} variation="headlineDesktopSmall">
        {message}
      </Typography>
    </Styled.Box>
    {subtitle && (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingX={7}
        marginBottom={2}
      >
        <Styled.SubtitleText>{subtitle}</Styled.SubtitleText>
      </Box>
    )}
    <Box display="flex" flexDirection="column" justifyContent="start" paddingX={7} marginBottom={2}>
      {optionMessages
        ? optionMessages.map((message, index) => (
            <Typography
              variation="subtitleDesktopMedium"
              color="secondary"
              key={`option-message-${index}`}
              withoutMargin
            >
              {message}
            </Typography>
          ))
        : null}
    </Box>
  </>
)

export const FeedbackCheckout = ({
  listMessage,
  status,
  provider,
}: {
  listMessage: Array<React.ReactNode>
  provider?: CheckoutProvider
  status: CheckoutStatus
}) => {
  const valueStatus = StatusFeedbackCheckout[status]
  const subtitle =
    provider === CheckoutProvider.STONE_PAGARME
      ? StatusFeedbackCheckout[status].subtitle
      : undefined
  return (
    <TextAndImageContentCheckout
      message={valueStatus.content}
      imageSrc={valueStatus.image}
      optionMessages={listMessage}
      status={status}
      subtitle={subtitle}
    />
  )
}

type CheckoutFeedbackDialogProps = ConfirmationDialogProps & {
  listMessage: Array<React.ReactNode>
  onCancelCheckout?: () => void
  posProvider?: CheckoutProvider
  status: CheckoutStatus
}

type ConfirmationDialogCheckoutProps = ConfirmationDialogProps & {
  onCancelCheckout?: () => void
  posProvider?: CheckoutProvider
  showCloseButton?: boolean
  status: CheckoutStatus
}

const ConfirmationDialogCheckout = ({
  buttonLabel = 'Ok, entendi!',
  centered,
  children,
  isVisible,
  onClose,
  submitHandler,
  isConfirmDisabled,
  showCloseButton,
  shortIds,
  posProvider,
  status,
  onCancelCheckout,
}: ConfirmationDialogCheckoutProps) => {
  const isPagarme = posProvider === CheckoutProvider.STONE_PAGARME
  const isCancelNotPagarme = buttonLabel !== 'Cancelar pagamento' && !isPagarme

  const [isCanceling, setIsCanceling] = useState(false)

  useEffect(() => {
    if (status === CheckoutStatus.ABORTED) {
      setIsCanceling(false)
    }
  }, [status])

  return (
    <Dialog open={isVisible} maxWidth="sm" fullWidth>
      {onClose && showCloseButton && (
        <Styled.BoxCloseButton display="flex" justifyContent="flex-end">
          {!isPagarme && (
            <SystemButton onClick={onClose} aria-label="Fechar">
              <CloseRounded />
            </SystemButton>
          )}
        </Styled.BoxCloseButton>
      )}
      <DialogContent>
        {children}
        <NotificationCheckoutDialog shortIds={shortIds} />
      </DialogContent>
      {isPagarme && status !== CheckoutStatus.ABORTED && (
        <DialogActions className={`${centered ? 'centered' : ''}`}>
          <Button
            variation="ghost"
            onClick={() => {
              setIsCanceling(true)
              onCancelCheckout && onCancelCheckout()
            }}
            loading={isCanceling}
          >
            Cancelar transação
          </Button>
        </DialogActions>
      )}
      {isCancelNotPagarme || status === CheckoutStatus.ABORTED ? (
        <DialogActions className={`${centered ? 'centered' : ''}`}>
          <Button variation="ghost" onClick={submitHandler} disabled={isConfirmDisabled?.()}>
            {buttonLabel}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

export const CheckoutFeedbackDialog = ({
  status,
  listMessage,
  posProvider,
  ...rest
}: CheckoutFeedbackDialogProps) => {
  const statusCheckout = StatusFeedbackCheckout[status]

  return (
    <ConfirmationDialogCheckout
      buttonLabel={statusCheckout.labelButton}
      showCloseButton={statusCheckout.showClose}
      posProvider={posProvider}
      status={status}
      {...rest}
    >
      {rest.children ?? (
        <FeedbackCheckout listMessage={listMessage} status={status} provider={posProvider} />
      )}
    </ConfirmationDialogCheckout>
  )
}

export default CheckoutFeedbackDialog
