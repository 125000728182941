import { useJWT } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { Button } from '@gravity/button'
import { useToast } from '@gravity/toast'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useBatchCSVQuery } from '../../hooks/useBatchCSVQuery'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { useNotifyBatchMutation } from '@monorepo/onboarding/hooks/useNotifyBatchMutation'
import { BatchProgress } from '@monorepo/onboarding/services/types/onboardingBatch'

const HomeButton = () => {
  const { schoolSlug } = useSelectedSchool()
  const history = useHistory()
  const event = useEvents()

  return (
    <Button
      onClick={() => {
        event?.redirectToHomeButtonCLicked()
        return history.push(`/${schoolSlug}`)
      }}
    >
      Conhecer Plataforma Isaac
    </Button>
  )
}

export const BatchDoneFooter = ({ progress }: { progress: BatchProgress }) => {
  const { batchID } = useParams<{ batchID: string }>()
  const { isBackofficeUser } = useJWT()
  const { toast } = useToast()
  const event = useEvents()

  const query = useBatchCSVQuery({ batchID }, { enabled: false, retry: false })
  const notifyMutation = useNotifyBatchMutation()

  const handleDownloadCSV = async () => {
    event?.exportContractsButtonClicked()
    const response = query.isSuccess ? query : await query.refetch()

    if (response.error || response.data === undefined) {
      return
    }

    const blobUrl = URL.createObjectURL(response.data)
    const filename = `${batchID}-${new Date().toISOString()}.csv`

    const link = document.createElement('a')
    link.setAttribute('href', blobUrl)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    if (link.parentNode) link.parentNode.removeChild(link)

    window.URL.revokeObjectURL(blobUrl)

    toast({
      title: 'Contratos exportados com sucesso',
      type: 'success',
    })
  }

  useEffect(() => {
    if (query.isError) {
      toast({
        title: 'Erro ao exportar contratos',
        type: 'error',
      })
    }
  }, [query.isError])

  useEffect(() => {
    if (notifyMutation.isError) {
      toast({
        title: 'Erro ao enviar sinalização',
        type: 'error',
      })
    }

    if (notifyMutation.isSuccess) {
      toast({
        title: 'Sinalização enviada com sucesso',
        type: 'success',
      })
    }
  }, [notifyMutation.isError, notifyMutation.isSuccess])

  if (!progress) {
    return null
  }

  if (!isBackofficeUser) {
    return (
      <>
        {progress.FAIL > 0 && (
          <Button
            variant="ghost"
            onClick={() => notifyMutation.mutate({ batchID })}
            loading={notifyMutation.isLoading}
          >
            Sinalizar analista
          </Button>
        )}
        <HomeButton />
      </>
    )
  }

  return (
    <>
      <Button variant="ghost" onClick={handleDownloadCSV} loading={query.isFetching}>
        Exportar contratos
      </Button>
      <HomeButton />
    </>
  )
}
