import { Charge } from '@monorepo/onboarding/services/types/onboardingCharge'
import { ChargeDiscount } from './ChargeDiscount'
import { DiscountFieldName } from './utils'
import { Separator } from '@gravity/separator'
import { Fragment } from 'react/jsx-runtime'
import styled from 'styled-components'

type ChargeDiscountsProps = {
  originalCharge: Charge
  selectedCharge: Charge
  setSelectedCharge: (charge: Charge) => void
}

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSeparator = styled(Separator)`
  margin-top: 60px;
  margin-bottom: 32px;
`

export const ChargeDiscounts = ({
  originalCharge,
  selectedCharge,
  setSelectedCharge,
}: ChargeDiscountsProps) => {
  const handleChangeDiscountValue = (
    discountIndex: number,
    fieldName: DiscountFieldName,
    value: string
  ) => {
    const updatedCharge: Charge = {
      ...selectedCharge,
    }

    updatedCharge.discounts[discountIndex][fieldName] = value

    setSelectedCharge(updatedCharge)
  }

  return (
    <Flex>
      {selectedCharge?.discounts?.map((discount, index) => (
        <Fragment key={discount.id}>
          {index > 0 && <StyledSeparator color="neutral-2" />}

          <ChargeDiscount
            key={discount.id}
            index={index}
            discount={discount}
            onChange={handleChangeDiscountValue}
            charge={selectedCharge}
            originalCharge={originalCharge}
          />
        </Fragment>
      ))}
    </Flex>
  )
}
