import { Table } from '@gravity/table'

import { usePagination } from '@/shared/hooks/usePagination'

import { useParams } from 'react-router-dom'
import { LoadingTable } from '@monorepo/onboarding/pages/components/LoadingTable'
import { NoInvalidData } from '../NoInvalidData'
import { GenericError } from '../GenericError'
import { isFieldInvalid } from '@monorepo/onboarding/utils/isFieldInvalid'
import { PaginationFooter } from '../PaginationFooter'
import { TableErrorBadge } from '../TableErrorBadge'
import { useGuardian } from '@monorepo/onboarding/hooks/useGuardian'
import { Guardian } from '@monorepo/onboarding/services/types/onboardingGuardian'
import { formatCPF } from '@/shared/utils'
import styled from 'styled-components'
import { EditGuardianDialog } from './EditGuardianDialog'
import { useEffect, useState } from 'react'
import { TableAction } from '../TableAction'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'

const DEFAULT_ITEMS_PER_PAGE = 10

const ADDRESS_FIELDS = ['address_number', 'address_zip', 'address_city', 'address_state_code']

const StyledAddress = styled(Table.TextCell)`
  width: 195px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const GuardianTable = () => {
  const { pagination, updatePaginationValue } = usePagination(1, DEFAULT_ITEMS_PER_PAGE)
  const { batchID } = useParams<{ batchID: string }>()

  const [selectedGuardian, setSelectedGuardian] = useState<Guardian | null>(null)

  const events = useEvents()

  useEffect(() => {
    events?.guardianTabViewed()
  }, [])

  const params = {
    batchID,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  }

  const { data, isLoading, isError } = useGuardian(params)

  const formatAddress = (guardian: Guardian) => {
    return `${
      guardian.address_street +
      (guardian.address_street ? ', ' : '') +
      guardian.address_number +
      (guardian.address_number ? ' - ' : '') +
      guardian.address_zip +
      ' ' +
      guardian.address_city +
      ' ' +
      guardian.address_state_code
    }`
  }
  const tableData = {
    columns: [
      {
        id: 'name',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Guardian) => <Table.TextCell>{item.name}</Table.TextCell>,
      },
      {
        id: 'tax_id',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Guardian) => <Table.TextCell>{formatCPF(item.tax_id)}</Table.TextCell>,
      },
      {
        id: 'email',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Guardian) => <Table.TextCell>{item.email}</Table.TextCell>,
      },
      {
        id: 'phone_number',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Guardian) => <Table.TextCell>{item.phone_number}</Table.TextCell>,
      },
      {
        id: 'address',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Guardian) => <StyledAddress>{formatAddress(item)}</StyledAddress>,
      },
    ],
  }

  if (isError) return <GenericError />

  if (isLoading || data === undefined)
    return <LoadingTable rowsLength={pagination.itemsPerPage} columnsLength={3} />

  if (data.data.length === 0) return <NoInvalidData />

  return (
    <>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell name="name" minWidth={80}>
            Nome
          </Table.HeaderCell>
          <Table.HeaderCell name="taxID" minWidth={80}>
            CPF
          </Table.HeaderCell>
          <Table.HeaderCell name="email">E-mail</Table.HeaderCell>
          <Table.HeaderCell name="phone_number">Telefone ou Celular</Table.HeaderCell>
          <Table.HeaderCell name="address">Endereço</Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {data.data.map(guardian => (
            <Table.Row key={guardian.id}>
              {tableData.columns.map(column => {
                if (column.id === 'address') {
                  return ADDRESS_FIELDS.some(addressField =>
                    isFieldInvalid('guardian', addressField, guardian.validations)
                  )
                    ? column.renderCellError
                    : column.renderCell(guardian)
                }
                return isFieldInvalid('guardian', column.id, guardian.validations)
                  ? column.renderCellError
                  : column.renderCell(guardian)
              })}

              <TableAction
                onClick={() => {
                  setSelectedGuardian(guardian)
                }}
              />
            </Table.Row>
          ))}
        </Table.Body>
        {data?.pagination?.total && (
          <PaginationFooter
            pagination={pagination}
            total={data?.pagination.total}
            updatePaginationValue={updatePaginationValue}
          />
        )}
      </Table.Root>

      {selectedGuardian && (
        <EditGuardianDialog
          params={params}
          setSelectedGuardian={setSelectedGuardian}
          selectedGuardian={selectedGuardian}
        />
      )}
    </>
  )
}
