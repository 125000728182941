import { BadgeProps } from '@gravity/badge'
import { DebtStatus } from '@monorepo/students/types/DebtStatus'

export const DEBT_STATUS_VARIANT: {
  [k in DebtStatus]: { color: BadgeProps['color']; text: string }
} = {
  WARNING: { color: 'warning', text: 'Atenção' } as const,
  UP_TO_DATE: { color: 'success', text: 'Em dia' } as const,
  OVERDUE: { color: 'error', text: 'Com pendências' } as const,
}

export function getDebtStatus(status: DebtStatus): { color: BadgeProps['color']; text: string } {
  return DEBT_STATUS_VARIANT[status]
}
