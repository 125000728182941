import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { GuardianDetails } from './GuardianDetails/GuardianDetails'
import { NegotiationFlow } from '@/modules/guardians/Negotiation'
import ManualLiquidationAdd from '@/modules/guardians/ManualLiquidation/Add/ManualLiquidationAdd'
import ManualLiquidationEdit from '@/modules/guardians/ManualLiquidation/Edit/ManualLiquidationEdit'
import { ContractPaidAmountsReport } from '@/modules/guardians/Contracts/PaidAmounts'
import { ListGuardians } from './ListGuardians'
import Awaiting from './Awaiting'

import type { RouteDefinition } from '@/shared/core/router'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'

export const routes: RouteDefinition[] = [
  {
    key: 'GUARDIANS',
    path: '/responsaveis',
    component: ListGuardians,
    exact: true,
  },
  {
    key: 'GUARDIAN_AWAITING',
    path: '/responsaveis/:guardianId/awaiting',
    component: Awaiting,
    exact: true,
  },
  {
    key: 'GUARDIAN_CONTRACT',
    path: '/responsaveis/:guardianId/contratos',
    component: GuardianDetails,
  },
  {
    key: 'GUARDIAN_FATURAS',
    path: '/responsaveis/:guardianId/faturas',
    component: GuardianDetails,
  },
  {
    key: 'GUARDIAN_SUMMARY',
    path: '/responsaveis/:guardianId/resumo',
    component: GuardianDetails,
  },
  {
    key: 'GUARDIAN_NEGOTIATION',
    path: '/:reference_year/responsavel/:guardianId/negociacao',
    component: NegotiationFlow,
  },
  {
    key: 'GUARDIANS_REDIRECT',
    path: '/responsaveis/:guardianId',
    redirect: '/responsaveis/:guardianId/faturas',
    exact: true,
  },
]

export const GuardianRoutes = () => {
  const { path } = useRouteMatch()
  const { defaultGuardianEntryPage } = useGuardianNavigation()

  const guardiansPath = `${path}/responsaveis`
  const guardianPath = `${path}/:reference_year/responsavel/:guardianId`
  const redirect = `${guardiansPath}/:guardianId/${defaultGuardianEntryPage}`

  return (
    <Switch>
      <Route path={`${guardiansPath}`} exact>
        <ListGuardians />
      </Route>
      <Route path={`${guardiansPath}/:guardianId/awaiting`} exact>
        <Awaiting />
      </Route>
      <Redirect exact from={`${guardiansPath}/:guardianId`} to={redirect} />
      <Route path={`${guardiansPath}/:guardianId/contratos`}>
        <GuardianDetails />
      </Route>
      <Route path={`${guardiansPath}/:guardianId/faturas`}>
        <GuardianDetails />
      </Route>
      <Route path={`${guardiansPath}/:guardianId/resumo`}>
        <GuardianDetails />
      </Route>
      <Route path={`${guardiansPath}/:guardianId/historico`}>
        <GuardianDetails />
      </Route>
      <Route path={`${guardianPath}/negociacao`}>
        <NegotiationFlow />
      </Route>
      <Route path={`${guardianPath}/:urn/manual-liquidation/add`}>
        <ManualLiquidationAdd />
      </Route>
      <Route path={`${guardianPath}/:urn/manual-liquidation/edit`}>
        <ManualLiquidationEdit />
      </Route>
      <Route path={`${path}/contrato/:contractId/demonstrativo-pagos`}>
        <ContractPaidAmountsReport />
      </Route>
    </Switch>
  )
}
