import { forwardRef, memo, useCallback, useMemo } from 'react'
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { validate as isValidUUID } from 'uuid'

import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import { TextField } from '@gravity/text-field'
import { Select } from '@gravity/select'

import {
  useProductClassifications,
  useMutateOnCreateProduct,
  useMutateOnUpdateProduct,
} from '@monorepo/products/hooks/queries/products'
import { useFormValues } from './hooks/useFormValues'

import { School, Product } from '@/shared/interfaces'
import { Category, EducationalStage } from '@/shared/interfaces/productClassification'

import { Container } from './styles'
import { formatCurrency, parseCurrencyToCents } from './utils/formatCurrency'

export type SelectedProduct = Pick<
  Product,
  | 'product_category_id'
  | 'product_program_id'
  | 'educational_substage_id'
  | 'id'
  | 'name'
  | 'enrollment_amount'
  | 'enrollment_installments_quantity'
  | 'monthly_amount'
  | 'installments_quantity'
  | 'envelope_template_referral_id'
  | 'educational_substage'
>

export type Props = {
  onApiError?: () => void
  onFormError?: SubmitErrorHandler<Product>
  onSuccess?: () => void
  product: SelectedProduct | null
  schoolId: School['id']
  showEnvelopField?: boolean
}
export type FormValues = Omit<SelectedProduct, 'id'> & {
  educational_stage_id?: EducationalStage['id']
}

const ProductForm = forwardRef<HTMLFormElement, Props>(
  ({ product, schoolId, onSuccess, onApiError, onFormError, showEnvelopField = false }, ref) => {
    const { mutateAsync: mutateAsyncOnCreate } = useMutateOnCreateProduct()
    const { mutateAsync: mutateAsyncOnUpdate } = useMutateOnUpdateProduct()

    const { defaultFormValues } = useFormValues(product)

    const { control, formState, handleSubmit, setError, watch } = useForm<FormValues>({
      mode: 'onChange',
      defaultValues: defaultFormValues,
    })

    const selectedEducationalStageId = watch('educational_stage_id')

    const { data: productClassifications, isLoading: loading } = useProductClassifications()

    const filteredEducationalSubstages = useMemo(
      () =>
        selectedEducationalStageId
          ? productClassifications?.data.educational_substages?.filter(
              substage => substage.educational_stage_id === selectedEducationalStageId
            ) ?? []
          : [],

      [selectedEducationalStageId, productClassifications]
    )

    const selectedCategoryId = watch('product_category_id')
    const hasSelectedEducationalCategory = !!productClassifications?.data.product_categories?.find(
      category => category.id === selectedCategoryId && category.is_educational
    )

    const isProductEdit = Boolean(product?.id)
    const hasDocsign = Boolean(product?.envelope_template_referral_id)

    const validateEnvelopeID = useCallback(
      (value: FormValues['envelope_template_referral_id']) => {
        if (!formState.dirtyFields?.envelope_template_referral_id) {
          return true
        }

        const trimmedValue = value?.trim() ?? ''
        const isIdValid = isValidUUID(trimmedValue)
        const isEmpty = trimmedValue === ''
        const isRemovingEnvelopId = product?.envelope_template_referral_id && isEmpty
        const isEnvelopIdInvalid = !isIdValid && !isEmpty
        const hasError = isEnvelopIdInvalid || isRemovingEnvelopId

        if (hasError) {
          setError('envelope_template_referral_id', {
            type: 'custom',
            message: 'Insira um ID de envelope válido',
          })
          return false
        }
      },
      [formState, product, setError]
    )

    const onValid = useCallback<SubmitHandler<FormValues>>(
      data => {
        if (product) {
          const updateProductParams = {
            id: product.id,
            data: {
              ...product,
              ...data,
              id: (undefined as unknown) as string,
            },
          }
          mutateAsyncOnUpdate(updateProductParams, { onSuccess, onError: onApiError })
        } else {
          const createProductParams = {
            ...data,
            school_id: schoolId,
            product_category: (undefined as unknown) as Category,
          }
          mutateAsyncOnCreate(createProductParams, { onSuccess, onError: onApiError })
        }
      },
      [schoolId, onSuccess, onApiError]
    )

    return (
      <form onSubmit={handleSubmit(onValid, onFormError)} ref={ref} data-testid="product-form">
        <Container>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text variant="subtitle-medium">Nome do produto</Text>
            <Text variant="body-2-regular">O nome que aparece no boleto de cobrança</Text>
          </div>

          <Controller
            rules={{ required: true }}
            name="name"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                {...rest}
                ref={ref}
                placeholder="Nome do produto"
                id="product-name-input"
                data-testid="product-name-input"
                size={3}
                error={Boolean(formState.errors.name)}
                errorMessage={
                  formState.errors.name?.type === 'required'
                    ? 'Insira um nome para o produto'
                    : formState.errors.name?.message
                }
              />
            )}
          />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text variant="subtitle-medium">Categoria</Text>
            <Text variant="body-2-regular">O tipo de produto que você quer criar</Text>
          </div>

          <Controller
            rules={{ required: true }}
            control={control}
            name="product_category_id"
            render={({ field: { value, onChange, ...rest } }) => (
              <>
                <Select
                  {...rest}
                  placeholder="Selecione uma categoria"
                  data-testid="category_test_id"
                  size={3}
                  value={value ? String(value) : ''}
                  onValueChange={valueChange => onChange(parseInt(valueChange))}
                  fullWidth
                  disabled={loading}
                  options={
                    productClassifications?.data?.product_categories?.map(category => ({
                      label: category.name,
                      value: category.id.toString(),
                    })) ?? []
                  }
                  hasError={Boolean(formState.errors.product_category_id)}
                />
              </>
            )}
          />
        </Container>

        <Separator color="neutral-2" />

        <Container>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text variant="subtitle-medium">Segmento</Text>
            <Text variant="body-2-regular">
              Ciclo de ensino do MEC. {!hasSelectedEducationalCategory && 'Opcional'}
            </Text>
          </div>

          <Controller
            rules={{ required: hasSelectedEducationalCategory }}
            control={control}
            name="educational_stage_id"
            render={({ field: { value, onChange } }) => (
              <>
                <Select
                  name="educational_stage_select"
                  placeholder="Selecione o segmento"
                  value={value ? String(value) : ''}
                  fullWidth
                  size={3}
                  disabled={loading}
                  onValueChange={valueChange => onChange(parseInt(valueChange))}
                  options={
                    productClassifications?.data?.educational_stages?.map(stage => ({
                      label: stage.name,
                      value: String(stage.id),
                    })) ?? []
                  }
                  hasError={Boolean(formState.errors.educational_stage_id)}
                />
              </>
            )}
          />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text variant="subtitle-medium">Série</Text>
            {!hasSelectedEducationalCategory && <Text variant="body-2-regular">Opcional</Text>}
          </div>

          <Controller
            rules={{ required: hasSelectedEducationalCategory }}
            control={control}
            name="educational_substage_id"
            render={({ field: { value, onChange } }) => (
              <>
                <Select
                  name="educational_substage_select"
                  placeholder="Selecione a série"
                  value={value ? String(value) : ''}
                  size={3}
                  onValueChange={valueChange => onChange(parseInt(valueChange))}
                  fullWidth
                  disabled={loading || !filteredEducationalSubstages?.length}
                  options={
                    filteredEducationalSubstages?.map(substage => ({
                      label: substage.name,
                      value: String(substage.id),
                    })) ?? []
                  }
                  hasError={Boolean(formState.errors.educational_substage_id)}
                />
              </>
            )}
          />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text variant="subtitle-medium">Turno</Text>
            <Text variant="body-2-regular">Horário em que as aulas acontecem. Opcional</Text>
          </div>

          <Controller
            control={control}
            name="product_program_id"
            render={({ field: { value, onChange } }) => (
              <>
                <Select
                  name="product_program_select"
                  placeholder="Selecione o Turno"
                  value={value ? String(value) : ''}
                  size={3}
                  onValueChange={valueChange => onChange(parseInt(valueChange))}
                  fullWidth
                  disabled={loading}
                  options={
                    productClassifications?.data?.product_programs?.map(program => ({
                      label: program.name,
                      value: String(program.id),
                    })) ?? []
                  }
                />
              </>
            )}
          />
        </Container>

        <Separator color="neutral-2" />

        <Container>
          <Controller
            control={control}
            name="enrollment_amount"
            rules={{
              validate: v => v >= 0,
            }}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                ref={ref}
                id="enrollment_amount-input"
                name="enrollment_amount"
                data-testid="enrollment_amount-input"
                size={3}
                label="Valor pré-matrícula"
                placeholder="0,00"
                inputPrefix="R$"
                onChange={e => {
                  onChange(parseCurrencyToCents(e.target.value))
                }}
                value={formatCurrency(value)}
                error={Boolean(formState.errors.enrollment_amount)}
                errorMessage={formState.errors.enrollment_amount ? 'Insira um valor válido' : ''}
              />
            )}
          />

          <Controller
            rules={{ required: false, validate: v => v >= 0 }}
            control={control}
            name="enrollment_installments_quantity"
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value ?? 0}
                type="number"
                id="enrollment_installments_quantity-input"
                data-testid="enrollment_installments_quantity-input"
                name="enrollment_installments_quantity"
                label="Quantidade pré-matrícula"
                size={3}
                disabled={isProductEdit}
                onChange={e => onChange(parseInt(e.target.value))}
                error={Boolean(formState.errors.enrollment_installments_quantity)}
              />
            )}
          />

          <Controller
            rules={{
              required: true,
              validate: v => v >= 100, // in cents (R$1,00)
            }}
            control={control}
            name="monthly_amount"
            shouldUnregister
            render={({ field: { onChange, value, ...rest } }) => (
              <TextField
                {...rest}
                id="monthly_amount-input"
                name="monthly_amount"
                data-testid="monthly_amount-input"
                size={3}
                label="Valor mensalidade"
                placeholder="0,00"
                inputPrefix="R$"
                onChange={e => {
                  onChange(parseCurrencyToCents(e.target.value))
                }}
                value={formatCurrency(value)}
                error={Boolean(formState.errors.monthly_amount)}
                errorMessage={formState.errors.monthly_amount ? 'Insira um valor válido' : ''}
              />
            )}
          />

          <Controller
            rules={{ required: true, validate: v => v >= 1 }}
            control={control}
            name="installments_quantity"
            render={({ field: { onChange, value, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  value={value ?? 1}
                  type="number"
                  id="installments_quantity-input"
                  data-testid="installments_quantity-input"
                  name="installments_quantity"
                  label="Quantidade mensalidade"
                  size={3}
                  disabled={isProductEdit}
                  onChange={e => {
                    onChange(parseInt(e.target.value))
                  }}
                  error={Boolean(formState.errors.installments_quantity)}
                  errorMessage={
                    formState.errors.installments_quantity ? 'Insira um valor válido' : ''
                  }
                />
              )
            }}
          />
        </Container>

        {showEnvelopField && (
          <>
            <Separator color="neutral-2" />

            <Container>
              <Controller
                rules={{
                  required: hasDocsign,
                  validate: value => validateEnvelopeID(value),
                }}
                name="envelope_template_referral_id"
                control={control}
                render={({ field: { onChange, value, ...rest } }) => (
                  <TextField
                    {...rest}
                    value={value ?? ''}
                    size={3}
                    name="envelope_template_referral_id"
                    label="ID do envelope DocuSign"
                    placeholder="Digite o ID"
                    id="product-envelope-template-id-input"
                    data-testid="product-envelope-template-id-input"
                    onChange={currentValue => {
                      onChange(currentValue.target.value)
                    }}
                    error={Boolean(formState.errors.envelope_template_referral_id)}
                    errorMessage={
                      formState.errors.envelope_template_referral_id
                        ? 'Insira um ID de envelope válido'
                        : undefined
                    }
                  />
                )}
              />
            </Container>
          </>
        )}
      </form>
    )
  }
)

export default memo(ProductForm)
