import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'

import { ReenrollmentStatus } from '@/shared/interfaces'

import { Container, SubContainer } from './styles'
import { StudentStatusBadge } from '../StudentStatusBadge'

export type HeaderProps = {
  isLoading: boolean
  studentName: string
  studentStatus: ReenrollmentStatus
}

export const Header = ({ studentName, studentStatus, isLoading }: HeaderProps) => {
  const { goBack } = useHistory()
  const [scrolled, setScrolled] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      setScrolled(scrollPosition > 60)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Container scrolled={scrolled} data-testid="student-page-header-container">
      <div className="content">
        <Button variant="ghost" size={1} onClick={() => goBack()}>
          Voltar
        </Button>
        <SubContainer scrolled={scrolled}>
          {isLoading ? (
            <>
              <Skeleton width={200} radius={6} />
              <Text variant="body-2-regular">Aluno</Text>
              <Skeleton width={84} radius={6} />
            </>
          ) : (
            <>
              <Text variant="body-2-medium">{studentName}</Text>
              <Text variant="body-2-regular">Aluno</Text>
              <StudentStatusBadge status={studentStatus} />
            </>
          )}
        </SubContainer>
      </div>
    </Container>
  )
}
