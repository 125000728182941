import { ReactNode } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core'
import copy from 'copy-to-clipboard'
import styled from 'styled-components'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { Link } from '@gravity/link'
import { mdiContentCopy } from '@mdi/js'
import Icon from '@mdi/react'

import { Installment } from '@/shared/interfaces'

const CircularProgressWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`

const LoadingContentWrapper = styled.div`
  margin-bottom: 24px;
`

type Case = {
  content: ReactNode
  isActive: boolean
  secondaryAction?: ReactNode
  showActions: boolean
  title: string
}

type Props = {
  installment?: Installment
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
  onClose: () => void
}

/**
 * @deprecated The component must be migrated to the new design system.
 */

export const AcceptPaymentPlanDialog = ({
  installment,
  isError,
  isLoading,
  isSuccess,
  onClose,
}: Props) => {
  if (!installment || !installment?.receivables) return <></>

  // The installment has just been created, so its safe to get the first receivable
  const invoice = installment?.receivables[0]?.invoice

  const cases: Case[] = [
    {
      isActive: isError,
      title: 'Erro ao gerar parcela de pré-matrícula',
      content: (
        <Text variant="body-2-regular">
          A parcela de pré-matrícula não pôde ser gerada no momento. Você pode acessar a segunda via
          através da página de parcelas do responsável financeiro.
        </Text>
      ),
      showActions: true,
    },
    {
      isActive: isLoading,
      title: 'Gerar parcela de pré-matrícula',
      content: (
        <LoadingContentWrapper>
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
          <Text variant="body-2-regular">
            Estamos gerando a parcela de pré-matrícula. Por favor, aguarde um instante.
          </Text>
        </LoadingContentWrapper>
      ),
      showActions: false,
    },
    {
      isActive: isSuccess,
      title: 'Pré-matrícula gerada com sucesso',
      content: (
        <>
          <Text variant="body-2-regular">
            A parcela de pré-matrícula foi gerada. O responsável será notificado por e-mail, e o
            link da parcela é:
          </Text>
          {invoice ? (
            <p style={{ marginTop: '24px' }}>
              <Link underline="auto" href={invoice.checkout_url} target="_blank" rel="noreferrer">
                {invoice.checkout_url}
              </Link>
            </p>
          ) : null}
        </>
      ),
      showActions: true,
      secondaryAction: (
        <Button
          variant="ghost"
          onClick={() => copy(invoice?.checkout_url ?? '')}
          iconStart={<Icon path={mdiContentCopy} />}
        >
          Copiar link
        </Button>
      ),
    },
  ]

  const currentCase = cases.find(i => i.isActive)

  if (!currentCase) {
    return null
  }

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle style={{ paddingTop: '24px' }}>{currentCase.title}</DialogTitle>
      <DialogContent>{currentCase.content}</DialogContent>
      {currentCase.showActions && (
        <DialogActions>
          {currentCase.secondaryAction && currentCase.secondaryAction}
          <Button variant="solid" onClick={onClose}>
            Ok, entendi
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
