import { TextField } from '@gravity/text-field'

type CustomTextFieldProps = {
  error?: boolean
  errorMessage?: string
  label: string
  name: string
  onChange: (name: string, value: string) => void
  value: string
}

export const CustomTextField = ({
  error,
  errorMessage,
  name,
  label,
  onChange,
  value,
}: CustomTextFieldProps) => {
  return (
    <TextField
      fullWidth
      size={3}
      label={label}
      name={name}
      value={value}
      onChange={e => onChange(e.target.name, e.target.value)}
      errorMessage={errorMessage}
      error={error}
    />
  )
}
