export enum EventDispatcherEventScopes {
  ACCESS_MANAGEMENT = 'GESTAO_DE_ACESSOS',
  ADD_NEW_CONTRACT_PAGE = 'CRIACAO_DE_CONTRATOS',
  AGGLUTINATE = 'Aglutinar',
  CANCEL_DRAWER = 'Gaveta de cancelamento',
  CHECKOUT = 'Checkout',
  CONTRACT_DRAWER = 'Gaveta de contrato',
  CONTRACT_EDIT = 'CONTRACT_EDIT',
  CONTRACT_PAGE = 'Contrato',
  CONTRACTS_PAGE = 'PAGINA_DE_CONTRATOS',
  CREDIT = 'CREDITO',
  DEMONSTRATIVE_IR_REPORT = 'Relatório do demonstrativo de valores pagos 2023',
  DUE_DATE_DRAWER = 'Gaveta de edição de vencimento',
  ENROLLMENT = 'MATRICULAS',
  ENROLLMENT_CYCLE = 'PAGINA_DE_MATRICULAS',
  ENROLLMENT_EDIT = 'ENROLLMENT_EDIT',
  ENROLLMENT_REPORT = 'ENROLLMENT_REPORT',
  ENROLLMENT_STUDENT = 'PAGINA_DE_ALUNOS',
  FAMILY_REGULARIZATION_REPORT = 'Relatório de regularização das famílias',
  FIDC_REPORT = 'FIDC_REPORT',
  GUARDIANS = 'GUARDIANS',
  HEADER_MENU = 'HEADER_MENU',
  HOME = 'HOME',
  INSTALLMENT_DRAWER = 'Gaveta da parcela',
  INSTALLMENT_NEGOTIATIONS = 'NEGOCIACAO_DE_PARCELAS',
  INSTALLMENTS_LIST = 'Listagem de parcelas',
  INVOICE = 'Fatura',
  IRREGULARITY_ENROLLMENTS_PAGE = 'Correção de dados irregulares',
  ISAAC_PAY_INCOME_PANEL = 'Painel de recebimentos isaac pay',
  ISAAC_PAY_REPORT = 'ISAAC_PAY_REPORT',
  MANAGEMENT_DASHBOARD = 'PAINEL_DE_GESTAO',
  MANUAL_LIQUIDATION = 'BAIXA_MANUAL',
  MANUAL_LIQUIDATION_PAGE = 'Baixa Manual',
  NEVER_PAID = 'NEVER_PAID',
  NOTIFICATIONS = 'QUADRO_DE_AVISOS',
  ONBOARDING = 'ONBOARDING',
  PAYMENT_DISPUTE = 'PAYMENT_DISPUTE',
  PAYOUT_ANTICIPATION = 'Antecipação de repasse',
  PAYOUT_REPORT = 'PAYOUT_REPORT',
  PRE_CONTRACT_EDIT = 'Editar pré contrato',
  PRODUCTS = 'PRODUCTS',
  RENEGOTIATION = 'Renegociação',
  REPORT_DASHBOARD = 'REPORT_DASHBOARD',
  REPORTS = 'REPORTS',
  SCHOOL_GROUP = 'SCHOOL_GROUP',
  SCHOOL_SELECTION = 'SCHOOL_SELECTION',
  SCORE_ISAAC = 'SCORE_ISAAC',
  SEARCH_HOME = 'BUSCA_HOME',
  SIDE_MENU = 'SIDE_MENU',
  STUDENT_PAGE = 'STUDENT_PAGE',
  TEACHING_MATERIAL_INCOME_PANEL = 'Painel de vendas de material didático',
}
