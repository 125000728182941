import { CardVariant } from '../hooks/useStatusDetailCards'
import { mdiClockTimeFourOutline, mdiAlertCircle, mdiCheckCircle } from '@mdi/js'

export const iconPath = ({ variant }: { variant: CardVariant }) => {
  return {
    [CardVariant.WARNING]: mdiClockTimeFourOutline,
    [CardVariant.ALERT]: mdiAlertCircle,
    [CardVariant.SUCCESS]: mdiCheckCircle,
  }[variant]
}
