import styled from 'styled-components'

export const Wrapper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 4rem;
  right: 0;
  padding: 24px 80px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`

export const ButtonsWrapper = styled.div`
  gap: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
`
