import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useCheckout, useNavigation } from 'src/escolas/hooks'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'

const useFlowValidation = () => {
  const { guardianId } = useParams<{ guardianId: uuid; referenceYear: string }>()
  const { getNavigationUrl } = useNavigation()
  const history = useHistory()
  const { finalCheckoutList } = useCheckout()
  const { pageToReturn } = useGuardianNavigation()

  const { checkoutCart } = useCheckoutCart()

  const isValid = checkoutCart?.length > 0 || finalCheckoutList.length > 0

  useEffect(() => {
    if (!isValid) {
      const path = `responsaveis/${guardianId}/faturas`
      const url = getNavigationUrl({
        path,
      })

      history.replace(pageToReturn ?? url)
    }
  }, [finalCheckoutList])
}

export default useFlowValidation
