import { EmptyObject } from 'react-hook-form'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  FetchCancelProposalRequest,
  FetchInstallmentResponse,
  MutationAddTuitionRequest,
  MutationAddTuitionToCampaignResponse,
} from '@monorepo/students/services/contracts/types'
import { contractsService } from '@monorepo/students/services/contracts'

import { WAIT_TIME } from '@monorepo/students/pages/Student/constants'

export const useMutationCancelProposal = (
  options?: UseMutationOptions<EmptyObject, unknown, FetchCancelProposalRequest, unknown>
) => {
  const { apiClient } = useApiClient()

  const service = contractsService(apiClient.getClients().basePrivateApi)

  return useMutation(service.cancelProposal, options)
}

export const useAddTuitionToCampaignQuery = (
  options?: UseMutationOptions<
    MutationAddTuitionToCampaignResponse,
    unknown,
    MutationAddTuitionRequest,
    unknown
  >
) => {
  const { apiClient } = useApiClient()

  const service = contractsService(apiClient.getClients().basePrivateApi)

  return useMutation(service.addTuitionToCampaign, options)
}

export const useAcceptPaymentPlan = () => {
  const { apiClient } = useApiClient()

  const serviceEnrollment = contractsService(apiClient.getClients().basePrivateApi)
  const serviceInstallments = contractsService(apiClient.getClients().privateApi)

  return useMutation({
    mutationFn: async ({ enrollmentId }: FetchInstallmentResponse) => {
      const res = await serviceEnrollment.acceptPaymentPlan(enrollmentId)

      // Installment creation is an async process. The business rule defined that
      // "waitTime" seconds must be waited before trying to get the installment.
      await new Promise(resolve => setTimeout(resolve, WAIT_TIME))

      const installment = await serviceInstallments.fetchInstallment(res.data.installment_id, {
        params: {
          include_receivables: true,
          include_invoice: true,
        },
      })

      return installment.data
    },
  })
}
