import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'

import type { NotificationErrorProps } from './types'
import { Container } from './styles'

export const NotificationError = ({ onRetry }: NotificationErrorProps) => {
  return (
    <Container>
      <div className="content">
        <Heading variant="heading-h4-medium">Opa, não foi possível exibir avisos agora</Heading>
        <Text>Alguma coisa aconteceu por aqui, mas podemos tentar de novo! Vamos lá?</Text>
      </div>
      <Button onClick={onRetry} style={{ width: 'fit-content' }}>
        Tentar ler de novo
      </Button>
    </Container>
  )
}
