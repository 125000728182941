import {
  ContainerCard,
  InternalContainerCard,
  TitleText,
  DescriptionText,
  StyledButton,
  ContainerButton,
  ResponsiveContainer,
} from './styles'
import { Box, Grid } from '@material-ui/core'
import { Card } from '@gravity/card'
import { Tooltip } from '@gravity/tooltip'
import { HeaderHomeProps } from './types'

export const HeaderHome = ({
  description,
  isEligible,
  title,
  onClickButton,
  orderLock,
  isInMaintenance,
}: HeaderHomeProps) => {
  const hasOrderLock = orderLock?.isLocked

  const getSimulationButton = () => {
    const tooltipText = isInMaintenance
      ? `Estamos em manutenção, solicitação de crédito\n indisponível no momento. Voltamos em breve.`
      : hasOrderLock
      ? 'Existe um pedido de antecipação em andamento. Aguarde sua conclusão antes de simular uma oferta de crédito.'
      : ''

    return (
      <Tooltip
        position="bottom"
        text={tooltipText}
        style={hasOrderLock ? { maxWidth: '13.875rem' } : {}}
        hidden={!tooltipText}
      >
        <ContainerButton>
          <StyledButton disabled={isInMaintenance || hasOrderLock} onClick={onClickButton}>
            Simule agora
          </StyledButton>
        </ContainerButton>
      </Tooltip>
    )
  }

  return (
    <ContainerCard isEligible={isEligible}>
      <Box mx={8}>
        <ResponsiveContainer>
          <Grid container justifyContent={isEligible ? 'flex-start' : 'flex-end'}>
            <Grid item xs={6}>
              <InternalContainerCard isEligible={isEligible}>
                <Card style={{ borderRadius: '2rem', border: 'none' }}>
                  <Box my={1} mx={5}>
                    <TitleText>{title}</TitleText>
                    <DescriptionText>{description}</DescriptionText>
                    {isEligible && getSimulationButton()}
                  </Box>
                </Card>
              </InternalContainerCard>
            </Grid>
          </Grid>
        </ResponsiveContainer>
      </Box>
    </ContainerCard>
  )
}
