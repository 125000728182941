import { AxiosInstance } from 'axios'

import type {
  FetchStudentEnrollmentCycleRequest,
  FetchStudentEnrollmentCycleResponse,
  FetchStudentFinancesRequest,
  FetchStudentFinancesResponse,
  FetchStudentProfileRequest,
  FetchStudentProfileResponse,
  FetchStudentContractsRequest,
  FetchStudentContractsResponse,
  UpdateStudentRequest,
  UpdateStudentResponse,
} from './types'

export const studentsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetch a student profile data
     */
    fetchStudentProfile: async ({
      studentId,
    }: FetchStudentProfileRequest): Promise<FetchStudentProfileResponse> => {
      const { data } = await fetcher.get<FetchStudentProfileResponse>(
        `/students/${studentId}/profile`
      )

      return data
    },
    /**
     * Function responsible for update a student data
     * @param id Student ID
     * @param student Student data to be updated
     */
    updateStudent: async ({
      id,
      student,
    }: UpdateStudentRequest): Promise<UpdateStudentResponse> => {
      const { data } = await fetcher.patch<UpdateStudentResponse>(`/student/${id}`, student)

      return data
    },
    /**
     * Function responsible for fetch a student finances data
     */
    fetchStudentFinances: async ({
      studentId,
      schoolId,
    }: FetchStudentFinancesRequest): Promise<FetchStudentFinancesResponse> => {
      const { data } = await fetcher.get<FetchStudentFinancesResponse>(
        `/students/${studentId}/finances`,
        { params: { school_id: schoolId } }
      )

      return data
    },
    /**
     *
     * Function responsible for get all enabled enrollment cycles for a student
     *
     * @param request schoolId and studentId
     *
     * @returns StudentEnrollmentCycle
     */
    fetchStudentEnrollmentCycle: async (
      request: FetchStudentEnrollmentCycleRequest
    ): Promise<FetchStudentEnrollmentCycleResponse> => {
      return (
        await fetcher.get<FetchStudentEnrollmentCycleResponse>(
          `student/enrollment/${request.studentId}/enabled-cycles`,
          {
            params: {
              school_id: request.schoolId,
            },
          }
        )
      ).data
    },
    /**
     * Function responsible for fetch a student's contracts data
     */
    fetchStudentContracts: async ({
      studentId,
      schoolId,
    }: FetchStudentContractsRequest): Promise<FetchStudentContractsResponse> => {
      const { data } = await fetcher.get<FetchStudentContractsResponse>(
        `/students/${studentId}/contracts`,
        {
          params: { school_id: schoolId },
        }
      )

      return data
    },
  }
}
