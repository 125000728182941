import { useState } from 'react'
import { Table } from '@gravity/table'

import { StatusBadge } from '@/modules/enrollment/components/StatusBadge'
import { EnrollmentCycleResponse } from '@/modules/enrollment/services/enrollmentService/types'
import { EnrollmentTableProducts } from '@/modules/enrollment/pages/SchoolEnrollmentCycle/components/EnrollmentTableProducts'
import useAddContractEnabled from '@/modules/enrollment/hooks/useAddContractEnabled'
import { PaginationType } from '@/shared/hooks/usePagination/types'
import { TableFilters } from '@/modules/enrollment/pages/SchoolEnrollmentCycle/types'

import { useTableData } from './hooks/useTableData'
import { useTableActions } from './hooks/useTableActions'
import { EnrollmentTableActions } from '../EnrollmentTableActions'
import * as Styled from './styles'

interface TableProps {
  enrollmentsData?: EnrollmentCycleResponse
  enrollmentYear: number
  filtersArray: TableFilters[]
  handleOpenFilter: () => void
  handleRemoveFilter: (filter: TableFilters) => void
  isLoading: boolean
  pagination: PaginationType
  updatePaginationValue: (key: keyof PaginationType, value: number, replaceRoute?: boolean) => void
}

export const EnrollmentTable = ({
  enrollmentsData,
  isLoading,
  updatePaginationValue,
  pagination,
  handleOpenFilter,
  handleRemoveFilter,
  enrollmentYear,
  filtersArray,
}: TableProps) => {
  const addContractEnabled = useAddContractEnabled()

  const { handleRowClick } = useTableActions()

  const [refreshedEnrollments, setRefreshedEnrollments] = useState(enrollmentsData?.data ?? [])

  const { rows, columnHeaders } = useTableData(enrollmentsData?.data)

  return (
    <Styled.TableWrapper>
      <Table.Root>
        <Table.Filtering
          filters={filtersArray}
          onClickFilterButton={handleOpenFilter}
          onRemoveFilter={handleRemoveFilter}
        />
        <Table.Head>
          {columnHeaders.map(({ label, key, minWidth }) => (
            <Styled.TableHeaderCell key={key} name={key} style={{ minWidth }}>
              {label}
            </Styled.TableHeaderCell>
          ))}
        </Table.Head>
        <Table.Body>
          {isLoading ? (
            <>
              <Table.Row>
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>
              <Table.Row>
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>
              <Table.Row>
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>
            </>
          ) : !enrollmentsData?.data?.length && !isLoading ? (
            <Styled.EmptyRow>Nenhum resultado encontrado</Styled.EmptyRow>
          ) : (
            rows.map((row, _index) => (
              <Table.Row
                key={row.key}
                onClick={() => {
                  handleRowClick(row.studentID, row.columns.student_status)
                }}
              >
                <Table.TextCell>{row.columns.student_name}</Table.TextCell>
                <Table.TextCell>{row.columns.guardian_name}</Table.TextCell>
                <Table.CustomCell>
                  <EnrollmentTableProducts
                    mainProductName={row.columns.product_name}
                    otherProductsNames={row.otherProducts}
                  />
                </Table.CustomCell>

                <Table.CustomCell>
                  <StatusBadge status={row.columns.student_status} />
                </Table.CustomCell>

                <EnrollmentTableActions
                  rowValue={row}
                  enrollmentYear={enrollmentYear}
                  setRefreshedEnrollments={setRefreshedEnrollments}
                  refreshedEnrollments={refreshedEnrollments}
                  addContractEnabled={addContractEnabled}
                />
              </Table.Row>
            ))
          )}
        </Table.Body>
        {enrollmentsData?.pagination?.total && (
          <Table.Pagination
            page={pagination.page}
            itemsPerPage={pagination.itemsPerPage}
            total={enrollmentsData?.pagination.total}
            onChangePage={newPage => {
              updatePaginationValue('page', newPage, true)
              window.scrollTo(0, 0)
            }}
            onChangeItemsPerPage={newItensPerPage => {
              updatePaginationValue('itemsPerPage', newItensPerPage, true)
            }}
          />
        )}
      </Table.Root>
    </Styled.TableWrapper>
  )
}
