import styled from 'styled-components'

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.gravity.spacing[4]};
  width: 100%;

  span {
    overflow: hidden;
    white-space: nowrap;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing[11]};
  width: 100%;
`

export const CheckNoTaxID = styled.div`
  margin-top: ${({ theme }) => theme.gravity.spacing[10]};
`
