import { Fragment } from 'react'

import { Checkout, CheckoutProvider, CheckoutStatus, Pos } from 'src/shared/interfaces'
import CheckoutFeedbackDialog from 'src/escolas/components/modal/FeedbackCheckout'
import { formatCentsToReal } from 'src/shared/utils'
import { useApi } from '@/utils/hooks/useApi'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/shared/components/ConfirmationDialog'

interface StatusCheckoutProps {
  checkoutInformationTitle: string
  checkoutObject: Checkout | null
  closeCheckoutFeedback: () => void
  closeErrorDialog: () => void
  guardianName: string
  isCheckoutFeedbackDisabled: boolean
  isProcessingCheckoutPayment: boolean
  onCancelCheckout: () => void
  onFinishCheckout: () => void
  pos?: Pos
  shortIds?: string[]
  showErrorDialog: boolean
}

type ListMessageProps = {
  code: string
  guardianName: string
  posName: string
  showErrorCode?: boolean
  totalAmount: string
}

const StatusCheckout = ({
  guardianName,
  checkoutInformationTitle,
  checkoutObject,
  closeCheckoutFeedback,
  closeErrorDialog,
  isCheckoutFeedbackDisabled,
  isProcessingCheckoutPayment,
  pos,
  onCancelCheckout,
  onFinishCheckout,
  showErrorDialog,
  shortIds,
}: StatusCheckoutProps) => {
  const { correlationId } = useApi()

  const posName = pos?.pdv_name
  const posProvider = pos?.provider
  const isPagarme = posProvider === CheckoutProvider.STONE_PAGARME

  const createListMessage = ({
    guardianName,
    code,
    totalAmount,
    posName,
    showErrorCode = false,
  }: ListMessageProps) => [
    <Fragment key="total-amount">
      {showErrorCode && !!correlationId ? (
        <>
          <b>Código do erro: </b>#{correlationId.split('-')[0]}
        </>
      ) : null}
    </Fragment>,
    <Fragment key="code">
      <b>Nome do responsável: </b>
      {guardianName}
    </Fragment>,
    <Fragment key="code">
      <b>Código da transação: </b>
      {code}
    </Fragment>,
    <Fragment key="total-amount">
      {totalAmount ? (
        <>
          <b>Valor a pagar: </b>
          {totalAmount}
        </>
      ) : null}
    </Fragment>,
    <Fragment key="pos">
      <b>Maquininha: </b>
      {posName}
    </Fragment>,
    <Fragment key="note">
      {isPagarme ? (
        <>
          <b>Observação: </b>
          Para visualizar a transação, acesse a área de pagamento da maquininha. Se não encontrar a
          transação, pressione o botão X para sair e entre novamente na área de pagamento.
        </>
      ) : null}
    </Fragment>,
  ]

  return (
    <>
      {!showErrorDialog && checkoutObject !== null ? (
        <CheckoutFeedbackDialog
          isVisible={isProcessingCheckoutPayment && !isCheckoutFeedbackDisabled}
          onClose={closeCheckoutFeedback}
          onCancelCheckout={onCancelCheckout}
          submitHandler={onFinishCheckout}
          listMessage={createListMessage({
            guardianName: guardianName,
            code: checkoutInformationTitle,
            totalAmount: formatCentsToReal(checkoutObject?.amount ?? 0),
            posName: posName ?? '',
          })}
          status={checkoutObject?.status ?? CheckoutStatus.FAILURE_TO_CONNECT}
          shortIds={shortIds}
          posProvider={posProvider}
        />
      ) : null}

      {showErrorDialog && (
        <FailureFeedbackDialog
          isVisible
          buttonLabel="Tentar novamente"
          onClose={() => {
            closeCheckoutFeedback()
            closeErrorDialog()
          }}
          submitHandler={() => {
            closeCheckoutFeedback()
            closeErrorDialog()
          }}
        >
          <FailureFeedbackContent />
        </FailureFeedbackDialog>
      )}
    </>
  )
}

export default StatusCheckout
