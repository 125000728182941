import { ChangeEvent, DragEvent } from 'react'
import styled from 'styled-components'
import { mdiCheck, mdiFileSyncOutline, mdiTrashCan } from '@mdi/js'
import { Icon } from '@mdi/react'
import { useToast } from '@gravity/toast'
import { Text } from '@gravity/text'
import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'

type FileUploadProps = {
  file: File | null
  setFile: (file: File | null) => void
}

const FileUploadWrapper = styled.div`
  width: 684px;
`

const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px dashed;
  border-color: #d0d0d0;
  border-radius: 4px;
  width: 100%;
  padding: 32px 0px;
`

const SelectedFile = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid #d0d0d0;
`

const SelectedFileNameWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const FileUpload = ({ file, setFile }: FileUploadProps) => {
  const { toast } = useToast()

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const files = Array.from(event.dataTransfer.files)

    if (files.length === 0) return

    if (event.dataTransfer.files.length > 1) {
      return toast({
        title: 'Selecione apenas um arquivo',
        type: 'alert',
      })
    }

    const inputFile = files[0]

    if (!inputFile.name.endsWith('.xlsx')) {
      return toast({
        title: 'O arquivo selecionado deve estar no formato .xlsx',
        type: 'alert',
      })
    }

    setFile(inputFile)
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleInputFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files === null || files.length === 0) {
      return
    }

    setFile(files[0])
  }

  return (
    <FileUploadWrapper>
      <DropZone onDrop={handleDrop} onDragOver={handleDragOver}>
        <Icon path={mdiFileSyncOutline} color="#A0A0A0" size={2} style={{ paddingBottom: '8px' }} />

        <Text variant="body-1-regular">
          <Text>Arraste aqui ou </Text>
          <label>
            <input
              type="file"
              style={{ display: 'none' }}
              accept=".xlsx"
              onChange={handleInputFileChange}
            />
            <Text style={{ color: '#444fd4', cursor: 'pointer' }}>busque seus arquivos</Text>
          </label>
        </Text>

        <Text as="div" variant="caption-regular">
          Formatos de documentos permitidos: XLSX
        </Text>
      </DropZone>

      {file && (
        <SelectedFile>
          <SelectedFileNameWrapper>
            <Icon path={mdiCheck} size="16px" />
            <Text variant="body-1-regular">{file?.name}</Text>
          </SelectedFileNameWrapper>
          <Tooltip text="Excluir planilha">
            <IconButton size={1} variant="ghost" onClick={() => setFile(null)}>
              <Icon path={mdiTrashCan} />
            </IconButton>
          </Tooltip>
        </SelectedFile>
      )}
    </FileUploadWrapper>
  )
}
