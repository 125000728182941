import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import SortOutlinedIcon from '@material-ui/icons/SortOutlined'
import MuiPopover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { Button, Grid, Pagination, Table, TableProps, Typography } from '@olaisaac/design-system'
import { assoc, includes } from 'ramda'

import { renderDebtStatus } from '@/shared/components/DebtStatus'
import { PageTableControlBar } from 'src/escolas/components/layout/PageTableControlBar'
import { PageWithTableControlBar } from 'src/escolas/components/layout/PageWithTableControlBar'
import { TableColumns } from 'src/escolas/components/Table/TableColumnCell'
import { useContractsFilter, useNavigation } from 'src/escolas/hooks'
import { useContractsByGuardians } from 'src/escolas/hooks/queries/contracts'
import { useContractsByStudents } from '@/shared/hooks/queries/useContractsByStudents'
import useRouterReferenceYear from 'src/escolas/hooks/useRouterReferenceYear'
import { StatusSearch } from 'src/escolas/hooks/useSearchByStudentOrGuardian'
import { ContractFilters } from '@/escolas/router/[schoolSlug]/contratos'
import {
  HotjarEvents,
  hotjarPageFactory,
  HotjarPageNames,
  useHotjar,
} from 'src/shared/hooks/useHotjar'
import { Contract, ContractResponse, ContractStatus, GroupGuardian } from 'src/shared/interfaces'
import { formatCPF, monthsToContractValidity } from 'src/shared/utils'
import { groupNameStudents } from 'src/shared/utils/groupInfo'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import styled from 'styled-components'
import { FilterReportButton } from '../../report/FilterReportDrawer'
import SearchByStudentOrGuardian from '../../SearchByStudentOrGuardian'
import ContractListActionButton from '../ContractListActionButton'
import { StudentContractsFilterDrawer } from './components/StudentContractsFilterDrawer'
import { GuardianContractsFilterDrawer } from './components/GuardianContractsFilterDrawer'
import { SelectedFilters } from '../SelectedFilters'
import { usePagination } from 'src/shared/hooks/usePagination'
import { useIsFirstRender } from 'src/shared/hooks/useIsFirstRender'
import { mixpanel, MixpanelEventsEnum } from 'src/shared/integrations'
import { Title } from './components/Title'
import useAddContractEnabled from '@/modules/enrollment/hooks/useAddContractEnabled'
import { CURRENT_YEAR } from '../constants'
import { useLayout } from '@/shared/hooks/useLayout'
import ContractYearPopover from '../ContractYearPopover'
import { usePageEvents } from './usePageEvents'

const LoadingBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`
export enum SEARCH_BY {
  GUARDIAN = 'GUARDIAN',
  STUDENT = 'STUDENT',
}

type ContractsYearProps = {
  filters: ContractFilters
  handleRowClick: (obj: any, isGuardian?: boolean) => void
  keyprop?: string
  name: string
  search: (statusSearch: StatusSearch) => void
  setName: (name: string) => void
}

const ContractsYearCheckout = ({
  keyprop,
  handleRowClick,
  filters,
  name,
  setName,
  search,
}: ContractsYearProps) => {
  const referenceYear = useRouterReferenceYear()
  const events = usePageEvents()

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: <Title referenceYear={referenceYear ?? ''} boxAlignItems="center" />,
  })

  const sortButtonRef = useRef<HTMLButtonElement | null>(null)

  const { pagination, updatePaginationValue } = usePagination()
  const { school, getNavigationUrl } = useNavigation()

  const { setHotjarPage, sendHotjarEvent } = useHotjar()
  const history = useHistory()
  const schoolId = school?.id
  const {
    studentsFilter,
    studentsFilterCount,
    guardiansFilter,
    guardiansFilterCount,
  } = useContractsFilter()
  const isFirstRender = useIsFirstRender()
  const isAddContractButtonEnabled = useAddContractEnabled()

  const [orderBy, setOrderBy] = useState<{
    column: string
    order: 'ASC' | 'DESC'
    paramName: string
  } | null>(null)
  const [isContractsFilterDrawerOpen, setIsContractsFilterDrawerOpen] = useState({
    guardiansFilter: false,
    studentsFilter: false,
  })

  const [showOrderByPopover, setShowOrderByPopover] = useState(false)

  const {
    data: contractsByStudentsData,
    isFetching: isFetchingContractsByStudents,
  } = useContractsByStudents(
    {
      schoolId: schoolId ?? '',
      referenceYear,
      filter: {
        ...(filters.name?.value ? { name: filters.name.value } : {}),
        ...studentsFilter,
      },
      pagination: {
        page: pagination.page,
        per_page: pagination.itemsPerPage,
        ...(orderBy && {
          sort_by: orderBy.paramName,
          sort_order: orderBy.order.toUpperCase() as 'ASC' | 'DESC',
        }),
      },
    },
    {
      enabled: !filters.name?.isGuardian,
    }
  )

  const getGuardiansContractsFilter = ((): ContractStatus | undefined => {
    return guardiansFilter.contractStatus.length === 1
      ? guardiansFilter.contractStatus[0]
      : undefined
  })()

  const {
    data: contractsByGuardiansData,
    isFetching: isFetchingContractsByGuardians,
  } = useContractsByGuardians(
    {
      schoolId: schoolId ?? '',
      referenceYear: referenceYear ?? '',
      filter: {
        ...(filters.name?.isCpf
          ? { taxId: filters.name.value || '' }
          : { name: filters.name.value || '' }),
        ...(getGuardiansContractsFilter && { contractStatus: getGuardiansContractsFilter }),
      },
      pagination: {
        page: pagination.page,
        per_page: pagination.itemsPerPage,
      },
    },
    {
      enabled: filters.name?.isGuardian,
    }
  )

  useEffect(() => {
    if (name) {
      updatePaginationValue('page', 1)
    }
  }, [name])

  useEffect(() => {
    if (isFirstRender) return

    updatePaginationValue('page', 1)
  }, [studentsFilter, guardiansFilter])

  useEffect(() => {
    setHotjarPage(hotjarPageFactory(HotjarPageNames.CONTRACTS_CHECKOUT, referenceYear))
  }, [referenceYear])

  const goToCampaign = () => {
    history.push(getNavigationUrl({ path: `/contratos/${referenceYear}/rematricula-em-lote` }))

    sendHotjarEvent(HotjarEvents.CAMPAIGN_NEW_CAMPAIGN_CLICKED)
  }

  const getSelectedFiltersCount = (filterType: 'guardiansFilter' | 'studentsFilter') => {
    return filterType === 'studentsFilter' ? studentsFilterCount : guardiansFilterCount
  }

  const formatData = (data: ContractResponse[]) => {
    return data.map(contract => {
      const {
        id,
        guardian_id,
        start_month,
        end_month,
        student,
        product,
        status,
        debt_status,
        guardian,
      } = contract
      return {
        debt_status: debt_status,
        guardian: guardian?.name ? prettifyFullName(guardian.name) : '',
        id,
        guardian_id,
        key: id,
        product: product?.name || '',
        productId: product?.id || '',
        studentId: student?.id || '',
        student: student?.name ? prettifyFullName(student.name) : '',
        validity: monthsToContractValidity({ start_month, end_month }),
        status,
      }
    })
  }

  const formatSearchByGuardian = (data: GroupGuardian[]) =>
    data?.map(({ name, students_names, products_names, tax_id, debt_status, guardian_id }) => {
      return {
        id: guardian_id,
        name: prettifyFullName(name),
        tax_id: formatCPF(tax_id),
        students: groupNameStudents(
          Object.keys(students_names).map(
            value => students_names[value as keyof typeof students_names]
          ),
          30
        ),
        key: guardian_id,
        products: Object.keys(products_names)?.length,
        status: debt_status,
      }
    }) ?? []

  const handleOpenContractsFilter = (filtersType: 'guardiansFilter' | 'studentsFilter') => {
    setIsContractsFilterDrawerOpen({
      guardiansFilter: false,
      studentsFilter: false,
      [filtersType]: true,
    })
  }

  const handleCloseContractsFilter = () => {
    setIsContractsFilterDrawerOpen({
      guardiansFilter: false,
      studentsFilter: false,
    })
  }

  useEffect(() => {
    setOrderBy(null)
    handleCloseContractsFilter()
  }, [filters?.name?.isGuardian])

  const contractsTableColumns: TableColumns = [
    { headerName: 'Aluno', field: 'student', enableSorting: true },
    { headerName: 'Responsável', field: 'guardian', enableSorting: true },
    { headerName: 'Produto', field: 'product' },
    {
      field: 'debt_status',
      headerName: 'Situação',
      renderCell: (value: any) => renderDebtStatus(value),
    },
  ]

  const contractsTableColumnsByGuardian: TableColumns = [
    { headerName: 'Responsável', field: 'name' },
    { headerName: 'CPF', field: 'tax_id' },
    { headerName: 'Aluno', field: 'students' },
    {
      headerName: 'Produto',
      field: 'products',
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: (value: any) => renderDebtStatus(value),
    },
  ]

  const guardiansDataSource =
    contractsByGuardiansData && formatSearchByGuardian(contractsByGuardiansData?.data)
  const studentsDataSource = contractsByStudentsData && formatData(contractsByStudentsData?.data)

  const dataSource = filters.name?.isGuardian ? guardiansDataSource : studentsDataSource
  const total = filters.name?.isGuardian
    ? contractsByGuardiansData?.pagination?.total ?? 0
    : contractsByStudentsData?.pagination?.total ?? 0

  const source =
    keyprop && dataSource
      ? dataSource.map(r => assoc('key', r[keyprop as keyof typeof r], r))
      : dataSource || []

  const defaultProps: TableProps<Contract> = {
    columns: contractsTableColumns,
    isLoading: isFetchingContractsByStudents,
    rows: source as any,
    sorted: true,
    orderBy: orderBy?.column,
    sortingHandler: value => {
      if (!value) {
        setOrderBy(null)
        return
      }

      const SORTING_PARAM_NAMES = {
        student: 'student.name',
        guardian: 'guardian.name',
      }

      mixpanel.trackEvent({
        name: MixpanelEventsEnum.CHANGE_TABLE_ORDER,
        properties: { $reference_year: Number(referenceYear) },
      })
      setOrderBy({
        column: value,
        paramName: SORTING_PARAM_NAMES[value as keyof typeof SORTING_PARAM_NAMES],
        order: 'ASC',
      })
    },
  }

  const defaultPropsGuardian: TableProps<Contract> = {
    columns: contractsTableColumnsByGuardian,
    isLoading: isFetchingContractsByGuardians,
    rows: source as any,
    sorted: true,
  }

  const propsWithOnClickStudent = assoc(
    'onRowClick',
    (params: any) => {
      const { guardian_id, product, productId, student, studentId } = params.row
      const obj = {
        guardianId: guardian_id,
        product,
        productId: productId,
        student,
        studentId: studentId,
      }
      handleRowClick(obj)
    },
    defaultProps
  )

  const propsWithOnClickGuardian = assoc(
    'onRowClick',
    (params: any) => {
      const { id } = params.row
      const obj = {
        guardianId: id,
      }
      handleRowClick(obj, true)
    },
    defaultPropsGuardian
  )

  const handleOrderByTable = (column: string, paramName: string, order: 'ASC' | 'DESC') => {
    setOrderBy({ column, paramName, order })

    setShowOrderByPopover(false)
    updatePaginationValue('page', 1)
  }

  const showCreateContractBtn =
    isAddContractButtonEnabled && includes(Number(referenceYear), [CURRENT_YEAR, CURRENT_YEAR + 1])

  return (
    <Box paddingTop="2.5rem">
      <PageWithTableControlBar>
        <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <ContractYearPopover />
          <Grid container alignItems="center">
            <Grid item sm={8}>
              <SearchByStudentOrGuardian
                handleChange={name => setName(name)}
                search={(value: StatusSearch) => {
                  !isFirstRender &&
                    mixpanel.trackEvent({
                      name: MixpanelEventsEnum.SEARCH_CONTRACTS_BY_STUDENT_NAME,
                      properties: { $reference_year: Number(referenceYear) },
                    })
                  search(value)
                }}
                searchValue={name}
                onClick={() => events.searchContract(referenceYear)}
              />
            </Grid>

            <Grid item sm={4} style={{ textAlign: 'right' }}>
              <Box display="flex" gridGap="1rem" justifyContent="flex-end">
                {!filters?.name?.isGuardian && (
                  <>
                    <Button
                      ref={sortButtonRef}
                      variation="ghost"
                      onClick={() => setShowOrderByPopover(!showOrderByPopover)}
                      startIcon={<SortOutlinedIcon />}
                    >
                      Ordenar por
                    </Button>

                    <MuiPopover
                      open={showOrderByPopover}
                      anchorEl={sortButtonRef.current}
                      onClose={() => setShowOrderByPopover(false)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <List style={{ minWidth: '12.5rem' }}>
                        <ListItem
                          button
                          onClick={() => handleOrderByTable('', 'created_at', 'DESC')}
                        >
                          <Typography variation="subtitleDesktopMedium">Mais novo</Typography>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => handleOrderByTable('', 'created_at', 'ASC')}
                        >
                          <Typography variation="subtitleDesktopMedium">Mais antigo</Typography>
                        </ListItem>
                      </List>
                    </MuiPopover>
                  </>
                )}

                <FilterReportButton
                  selectedFilters={getSelectedFiltersCount(
                    filters?.name?.isGuardian ? 'guardiansFilter' : 'studentsFilter'
                  )}
                  showFilterDrawer={
                    isContractsFilterDrawerOpen.guardiansFilter ||
                    isContractsFilterDrawerOpen.studentsFilter
                  }
                  onClick={() =>
                    handleOpenContractsFilter(
                      filters?.name?.isGuardian ? 'guardiansFilter' : 'studentsFilter'
                    )
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Box m="0.5rem 1rem" flex="1">
          <SelectedFilters filterType={filters?.name?.isGuardian ? 'guardians' : 'students'} />
        </Box>

        {filters?.name?.isGuardian && dataSource && !isFetchingContractsByGuardians && (
          <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Table<Contract> {...propsWithOnClickGuardian} />
          </Grid>
        )}

        {!filters?.name?.isGuardian && dataSource && !isFetchingContractsByStudents && (
          <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Table<Contract> {...propsWithOnClickStudent} />
          </Grid>
        )}

        {isFetchingContractsByStudents ||
          (isFetchingContractsByGuardians && (
            <LoadingBox>
              <CircularProgress />
            </LoadingBox>
          ))}

        <PageTableControlBar>
          <Pagination
            currentPage={pagination.page}
            itensPerPage={pagination.itemsPerPage}
            itensPerPageOptions={[10, 15, 25, 50]}
            totalItens={total || 0}
            onPageChange={newPage => {
              updatePaginationValue('page', newPage)
            }}
            onItensPerChangeChange={newItensPerPage => {
              updatePaginationValue('itemsPerPage', newItensPerPage)
            }}
          />
          {showCreateContractBtn && (
            <ContractListActionButton
              handleAddContract={() => {
                mixpanel.trackEvent({ name: MixpanelEventsEnum.CREATE_CONTRACT })
                history.push(getNavigationUrl({ path: `/contratos/${referenceYear}/novo` }))
              }}
              handleGoToCampaign={goToCampaign}
            />
          )}
        </PageTableControlBar>
      </PageWithTableControlBar>

      <StudentContractsFilterDrawer
        isOpen={isContractsFilterDrawerOpen.studentsFilter}
        onClose={handleCloseContractsFilter}
      />

      <GuardianContractsFilterDrawer
        isOpen={isContractsFilterDrawerOpen.guardiansFilter}
        onClose={handleCloseContractsFilter}
      />
    </Box>
  )
}

export default ContractsYearCheckout
