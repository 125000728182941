import { useApiClient } from '@/shared/hooks/useApiClient'
import { enrollmentService } from '@/modules/enrollment/services/enrollmentService'
import { installmentsService } from '@/shared/services/installments'
import { useMutation } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

type Params = {
  enrollmentID: uuid
}

export const useAcceptPaymentPlan = (waitTime: number) => {
  const { apiClient } = useApiClient()

  const serviceEnrollment = enrollmentService(apiClient.getClients().basePrivateApi)
  const serviceInstallments = installmentsService(apiClient.getClients().privateApi)

  return useMutation({
    mutationFn: async (params: Params) => {
      const res = await serviceEnrollment.acceptPaymentPlan(params.enrollmentID)

      // Installment creation is an async process. The business rule defined that
      // "waitTime" seconds must be waited before trying to get the installment.
      await new Promise(resolve => setTimeout(resolve, waitTime))

      const installment = await serviceInstallments.fetchInstallment(res.data.installment_id, {
        params: {
          include_receivables: true,
          include_invoice: true,
        },
      })

      return installment
    },
    onError: error => {
      Sentry.captureException(error)
    },
  })
}
