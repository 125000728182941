import { useApiClient } from '@/shared/hooks/useApiClient'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { EmptyObject } from 'react-hook-form'
import {
  FetchCancelProposalRequest,
  MutationAddTuitionRequest,
  MutationAddTuitionToCampaignResponse,
} from '@monorepo/students/services/contracts/types'
import { contractsService } from '@monorepo/students/services/contracts'

export const useMutationCancelProposal = (
  options?: UseMutationOptions<EmptyObject, unknown, FetchCancelProposalRequest, unknown>
) => {
  const { apiClient } = useApiClient()

  const service = contractsService(apiClient.getClients().basePrivateApi)

  return useMutation(service.cancelProposal, options)
}

export const useAddTuitionToCampaignQuery = (
  options?: UseMutationOptions<
    MutationAddTuitionToCampaignResponse,
    unknown,
    MutationAddTuitionRequest,
    unknown
  >
) => {
  const { apiClient } = useApiClient()

  const service = contractsService(apiClient.getClients().basePrivateApi)

  return useMutation(service.addTuitionToCampaign, options)
}
