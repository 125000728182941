import { TextField } from '@gravity/text-field'
import NumberFormat from 'react-number-format'

type CustomDateInputProps = {
  defaultValue: string
  disabled?: boolean
  error?: boolean
  errorMessage?: string
  label: string
  name: string
  onChange: (value: string) => void
}

export const CustomDateInput = ({
  defaultValue,
  disabled,
  error,
  errorMessage,
  label,
  name,
  onChange,
}: CustomDateInputProps) => {
  return (
    <NumberFormat
      valueIsNumericString
      style={{ flexGrow: 1, overflow: 'visible' }}
      type="text"
      format="##/##/####"
      placeholder="DD/MM/AAAA"
      label={label}
      name={name}
      disabled={disabled}
      defaultValue={defaultValue}
      onValueChange={({ formattedValue }) => {
        onChange(formattedValue)
      }}
      customInput={TextField}
      size={3}
      variant="outlined"
      error={error}
      errorMessage={errorMessage}
    />
  )
}
