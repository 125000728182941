import { useHistory } from 'react-router-dom'
import { Dialog } from '@gravity/dialog'
import { InteractiveCard } from '@gravity/interactive-card'
import { Heading } from '@gravity/heading'
import { ArrowRight } from '@gravity/icons'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import { StudentFinancesGuardian } from '@monorepo/students/services/students/types'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { formatCentsToReal } from '@/shared/utils'

import { Container, Content } from './styles'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useEffect } from 'react'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'

type PendingPaymentsDialogProps = {
  guardians: StudentFinancesGuardian[]
  isOpen: boolean
  onClose: () => void
  studentId: string
}

export const PendingPaymentsDialog = ({
  guardians,
  isOpen,
  studentId,
  onClose,
}: PendingPaymentsDialogProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { school } = useSelectedSchool()

  const { push } = useHistory()

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.MODAL_VIEWED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.MODAL_STUDENT_GUARDIANS,
      })
    }
  }, [isInitialized])

  const handleDialogOpenChange = (open: boolean) => {
    if (!open && isOpen) onClose()
  }

  const handleRedirectToGuardianPage = (guardianId: string) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.CARD_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.CARD_GUARDIAN_STUDENT,
      })
    }

    push(`/${school?.slug}/responsaveis/${guardianId}/faturas?student_ids=${studentId}`)
  }

  return (
    <Dialog
      onOpenChange={handleDialogOpenChange}
      open={isOpen}
      title="Clique no responsável financeiro e regularize suas pendências vencidas"
      backdrop
      size={3}
      content={
        <Container>
          {!!guardians.length &&
            guardians.map(guardian => (
              <InteractiveCard
                data-testid="guardian-card"
                fullWidth
                key={guardian.id}
                onClick={() => handleRedirectToGuardianPage(guardian.id)}
              >
                <div className="card">
                  <header className="guardian-name" data-testid="guardian-name">
                    <Heading className="name" variant="heading-h4-medium">
                      {guardian.name}
                    </Heading>
                    <ArrowRight />
                  </header>
                  <Separator color="neutral-2" style={{ marginTop: 16, marginBottom: 16 }} />
                  <Content>
                    <div>
                      <Text variant="subtitle-medium">Vencido neste aluno</Text>
                      <Text variant="body-2-regular" data-testid="overdue-total-student-amount">
                        {formatCentsToReal(
                          guardian.financial_summary?.OVERDUE?.total_student_amount
                        )}
                      </Text>
                    </div>
                    <div>
                      <Text variant="subtitle-medium">Vencido em todos seus alunos</Text>
                      <Text variant="body-2-regular" data-testid="overdue-total-amount">
                        {formatCentsToReal(guardian.financial_summary?.OVERDUE?.total_amount)}
                      </Text>
                    </div>
                  </Content>
                </div>
              </InteractiveCard>
            ))}
        </Container>
      }
    />
  )
}
