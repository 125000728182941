import { useState } from 'react'

import * as Styled from '../styles'

import { useGetTeachingMaterialIncomesReport } from '@monorepo/account-reports/hooks/queries/teachingMaterialReport'
import { PageStatusWrapper } from '@/shared/components/PageStatusWrapper'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

export const TeachingMaterialSalesReport = () => {
  const reportQuery = useGetTeachingMaterialIncomesReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  return (
    <PageStatusWrapper status={reportQuery.status}>
      {iframeStatus === 'loading' ? <CircularLoadingPlaceholder /> : null}

      <Styled.Iframe
        id="isaac-pay-income-report"
        src={reportQuery.data?.url}
        style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
        onLoad={() => setIframeStatus('success')}
      />
    </PageStatusWrapper>
  )
}
