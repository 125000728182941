import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { useToast } from '@gravity/toast'

import { useMutateOnDeleteProduct } from '@monorepo/products/hooks/queries/products'

import type { Product } from '@/shared/interfaces'

type DeleteProductDialogProps = {
  isVisible: boolean
  selectedProduct: Product | null
  setIsVisible: (value: boolean) => void
}

export const DeleteProductDialog = ({
  selectedProduct,
  isVisible,
  setIsVisible,
}: DeleteProductDialogProps) => {
  const { toast } = useToast()
  const { mutateAsync } = useMutateOnDeleteProduct()

  const submitHandler = async () => {
    if (!selectedProduct) return

    await mutateAsync(selectedProduct.id, {
      onSuccess: () => {
        toast({
          title: 'Produto excluído com sucesso!',
          type: 'success',
        })
      },
      onError: () => {
        toast({
          title: 'Não foi possível excluir este produto.',
          description: 'Ele já está atrelado a um contrato.',
          type: 'error',
        })
      },
    })

    setIsVisible(false)
  }

  return (
    <Dialog
      open={isVisible}
      onOpenChange={setIsVisible}
      size={1}
      title="Excluir produto"
      description={
        <Text variant="body-2-regular">
          Tem certeza que você quer excluir o produto{' '}
          <Text variant="body-2-medium">{selectedProduct?.name}</Text>? Essa ação não poderá ser
          desfeita.
        </Text>
      }
      backdrop
      actionButton={
        <Button size={1} onClick={submitHandler}>
          Confirmar
        </Button>
      }
      cancelButton={
        <Button size={1} variant="ghost">
          Cancelar
        </Button>
      }
    />
  )
}
