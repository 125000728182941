import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import {
  InstallmentStatuses,
  InstallmentType,
  ReEnrollmentSubStatus,
  ReenrollmentStatus,
} from '@/shared/interfaces'
import { getIsaacPayInstallmentMessage } from '@/shared/utils/getIsaacPayInstallmentMessage'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { useIsaacPayOutsourcedPaymentProvider } from '@/shared/hooks/useIsaacPayOutsourcedPaymentProvider'

export enum CardVariant {
  ALERT = 'ALERT',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export type CardContent =
  | {
      externalLink?: ExternalLink
      text: string
      title: string
      type: 'full'
      variant: CardVariant
    }
  | {
      text: string
      type: 'textOnly'
    }

export type ExternalLink = {
  label: string
  link: string
}

export const statusDetailCards = (
  status: ReenrollmentStatus,
  details: ReEnrollmentSubStatus[],
  enrollmentYear: number,
  mainGuardianId: string,
  studentId: string
): CardContent[] => {
  const previousYear = enrollmentYear - 1

  const { school } = useSelectedSchool()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const withSearchParams = (params: Record<string, string>) => {
    const serachParams = new URLSearchParams(params)
    return `?${serachParams.toString()}`
  }

  const hasCampaignSubStatus =
    details?.includes(ReEnrollmentSubStatus.WAITING_ENGAGE) ||
    details?.includes(ReEnrollmentSubStatus.GUARDIAN_ENGAGED)

  const canShowCampaignCard =
    hasCampaignSubStatus &&
    [
      ReenrollmentStatus.REENROLLMENT_AVAILABLE,
      ReenrollmentStatus.ENROLLMENT_AT_RISK,
      ReenrollmentStatus.ENROLLMENT_IN_PROGRESS,
      ReenrollmentStatus.NOT_REENROLLABLE,
    ].includes(status)

  const conditions: [boolean, CardContent][] = [
    [
      canShowCampaignCard && details?.includes(ReEnrollmentSubStatus.GUARDIAN_ENGAGED),
      {
        title: `Aluno em campanha de matrícula`,
        text: `Interesse na matrícula confirmado.`,
        variant: CardVariant.SUCCESS,
        type: 'full',
      },
    ],
    [
      canShowCampaignCard && details?.includes(ReEnrollmentSubStatus.WAITING_ENGAGE),
      {
        title: `Aluno em campanha de matrícula`,
        text: `Aguardando confirmação de interesse na matrícula.`,
        variant: CardVariant.ALERT,
        type: 'full',
      },
    ],
    [
      details?.includes(ReEnrollmentSubStatus.PRE_ENROLLMENT_PAID),
      {
        title: `Pagamento da pré-matrícula confirmado`,
        text: `A primeira parcela de pré matrícula foi paga.`,
        variant: CardVariant.SUCCESS,
        type: 'full',
      },
    ],
    [
      details?.includes(ReEnrollmentSubStatus.PRE_ENROLLMENT_PENDING),
      {
        type: 'full',
        title: `Aguardando pagamento da pré-matrícula`,
        text: `É necessário pagar a primeira parcela da pré-matrícula.`,
        variant: CardVariant.WARNING,
        externalLink: {
          label: 'Ver parcelas de pré-matrícula',
          link: `/responsaveis/${mainGuardianId}/faturas${withSearchParams({
            student_ids: studentId,
            installment_statuses: InstallmentStatuses.OPEN,
            installment_types: InstallmentType.ENROLLMENT,
          })}`,
        },
      },
    ],
    [
      details?.includes(ReEnrollmentSubStatus.PREVIOUS_YEAR_OVERDUE),
      {
        type: 'full',
        title: `Parcelas vencidas em contratos anteriores`,
        text:
          status === ReenrollmentStatus.ENROLLMENT_AT_RISK
            ? `É necessário regularizar o pagamento das parcelas vencidas, do contrário a rematrícula pode ser cancelada.`
            : `É necessário regularizar o pagamento das parcelas vencidas.`,
        variant: CardVariant.ALERT,
        externalLink: {
          label: 'Ver parcelas vencidas',
          link: `/responsaveis/${mainGuardianId}/faturas${withSearchParams({
            student_ids: studentId,
            installment_statuses: InstallmentStatuses.OVERDUE,
            reference_years: previousYear.toString(),
          })}`,
        },
      },
    ],
    [
      details?.includes(ReEnrollmentSubStatus.PREVIOUS_YEAR_PENDING) &&
        ![ReenrollmentStatus.REENROLLMENT_AVAILABLE, ReenrollmentStatus.NOT_REENROLLABLE].includes(
          status
        ),
      {
        type: 'full',
        title: `Aguardando quitação dos contratos anteriores`,
        text: `É necessário quitar todas as parcelas em aberto.`,
        variant: CardVariant.WARNING,
        externalLink: {
          label: 'Ver parcelas em aberto',
          link: `/responsaveis/${mainGuardianId}/faturas${withSearchParams({
            student_ids: studentId,
            installment_statuses: `${InstallmentStatuses.OPEN},${InstallmentStatuses.OVERDUE}`,
            reference_years: previousYear.toString(),
          })}`,
        },
      },
    ],

    [
      (status === ReenrollmentStatus.ENROLLMENT_IN_PROGRESS ||
        status === ReenrollmentStatus.ENROLLMENT_AT_RISK) &&
        details?.includes(ReEnrollmentSubStatus.WAITING_TUITION_CREATION),
      {
        type: 'full',
        title: `Aguardando criação de mensalidades`,
        text: `O contrato não tem parcelas de mensalidades cadastradas, crie as parcelas de mensalidade.`,
        variant: CardVariant.WARNING,
      },
    ],
    [
      !canShowCampaignCard && status === ReenrollmentStatus.REENROLLMENT_AVAILABLE,
      {
        type: 'full',
        title: `Rematrícula disponível`,
        text: `Está tudo certo para iniciar a rematrícula do aluno para o ano de ${enrollmentYear}.`,
        variant: CardVariant.SUCCESS,
      },
    ],
    [
      status === ReenrollmentStatus.ENROLLMENT_AT_RISK,
      {
        type: 'textOnly',
        text: `Aguardando a resolução de pendências para confirmar a matrícula.`,
      },
    ],
    [
      status === ReenrollmentStatus.ENROLLMENT_IN_PROGRESS &&
        details?.includes(ReEnrollmentSubStatus.STUDENT_RECORD_PENDING),
      {
        type: 'textOnly',
        text: `Desculpe o inconveniente, não foi possível carregar os detalhes. Tente atualizar a situação do aluno ou aguarde.`,
      },
    ],
    [
      status === ReenrollmentStatus.ENROLLMENT_IN_PROGRESS &&
        !details?.includes(ReEnrollmentSubStatus.STUDENT_RECORD_PENDING),
      {
        type: 'textOnly',
        text: `Após isso a matrícula será confirmada.`,
      },
    ],
    [
      status === ReenrollmentStatus.NOT_REENROLLABLE &&
        details?.includes(ReEnrollmentSubStatus.REENROLLMENT_SUSPENDED),
      {
        type: 'full',
        title: `Matrícula ${enrollmentYear} bloqueada`,
        text: `É necessário regularizar o pagamento das parcelas vencidas para que as parcelas do novo contrato sejam criadas.`,
        variant: CardVariant.ALERT,
      },
    ],
    [
      status === ReenrollmentStatus.NOT_REENROLLABLE &&
        !details?.includes(ReEnrollmentSubStatus.REENROLLMENT_SUSPENDED),
      {
        type: 'full',
        title: `Matrícula ${enrollmentYear} bloqueada`,
        text: `Aguardando a resolução de pendências para liberar a rematrícula.`,
        variant: CardVariant.ALERT,
      },
    ],
    [
      !canShowCampaignCard && status === ReenrollmentStatus.REENROLLMENT_AVAILABLE,
      {
        type: 'textOnly',
        text: `Aguardando o início da rematricula.`,
      },
    ],
    [
      status === ReenrollmentStatus.ENROLLED,
      {
        type: 'textOnly',
        text: isIsaacPaySchool(school)
          ? getIsaacPayInstallmentMessage(isOutsourcedProvider)
          : 'Tudo certo com a matrícula do aluno. Suas mensalidades estão garantidas e serão repassadas pelo isaac!',
      },
    ],
    [
      status === ReenrollmentStatus.UNAVAILABLE_AT_MOMENT,
      {
        type: 'textOnly',
        text: `Desculpe o inconveniente. Estamos trabalhando para resolver o problema e exibir a situação de matrícula do aluno.`,
      },
    ],
  ]

  return conditions.filter(i => i[0]).map(i => i[1])
}
