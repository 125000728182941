import { useHistory } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'

type RedirectToInvoiceProps = {
  guardianId: string
  productId: string
  referenceYear: string
  studentId: string
}

type RedirectToContractProps = {
  guardianId: string
  productId: string
  referenceYear: string
  studentId: string
}

export const useContractRedirect = () => {
  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleRedirectToInvoice = ({
    guardianId,
    studentId,
    productId,
    referenceYear,
  }: RedirectToInvoiceProps) => {
    window.scrollTo(0, 0)

    push(
      `/${schoolSlug}/responsaveis/${guardianId}/faturas?&reference_years=${referenceYear}&student_ids=${studentId}&product_ids=${productId}`
    )
  }

  const handleRedirectToEditContract = (contractId: string) => {
    window.scrollTo(0, 0)

    push(`/${schoolSlug}/contratos/${contractId}/editar`)
  }

  const handleRedirectToContract = ({
    guardianId,
    referenceYear,
    productId,
    studentId,
  }: RedirectToContractProps) => {
    window.scrollTo(0, 0)

    push(
      `/${schoolSlug}/responsaveis/${guardianId}/contratos?reference_years=${referenceYear}&student_ids=${studentId}&product_ids=${productId}`
    )
  }

  const handleAddNewContract = (guardianId: string) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.ADD_NEW_CONTRACT,
      })
    }

    window.scrollTo(0, 0)

    push(`/${schoolSlug}/contratos/${new Date().getFullYear()}/novo?guardian_id=${guardianId}`)
  }

  return {
    handleRedirectToInvoice,
    handleRedirectToEditContract,
    handleRedirectToContract,
    handleAddNewContract,
  }
}
