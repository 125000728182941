import { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import type { UseFormReturn } from 'react-hook-form'

import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { Text } from '@gravity/text'
import { RadioGroup, RadioGroupItem } from '@gravity/radio'

import dayjs from 'dayjs'

import { formatCentsToReal, formatDate, formatRealToCents } from '@/shared/utils'

import { FormAddTuitionProps } from '@monorepo/students/pages/Student/components/ContractsSection/types'
import { installmentDueDateRule } from '@monorepo/students/pages/Student/components/ContractsSection/constants'
import { useMaxTuitionInstallmentValidation } from '../../hooks/useMaxTuitionInstallmentValidation'

import * as Styled from './styles'

interface Props {
  enableDueDayField: boolean
  form: UseFormReturn<FormAddTuitionProps>
  hasMultipleInstallments: boolean
  installmentTotalAmount: number
  referenceYear: number
  validateDueDate: (v: number) => boolean | string
}

export const PaymentPlanFields = ({
  form,
  validateDueDate,
  installmentTotalAmount,
  hasMultipleInstallments,
  enableDueDayField,
  referenceYear,
}: Props) => {
  const firstDayOfReferenceYear = dayjs(`${referenceYear}-01-01`).toDate()
  const lastDayOfReferenceYear = dayjs(`${referenceYear}-12-31`).toDate()
  const today = dayjs().startOf('day').toDate()

  const { due_date_month: dueDateMonth } = form.getValues()

  const {
    maxTuitionInstallments,
    tuitionDurationMonthsValidationText,
    isSelectedTuitionDurationMonthsValid,
  } = useMaxTuitionInstallmentValidation({ dueDateMonth, referenceYear })

  const installmentList = useMemo(() => {
    return Array.from(Array(Math.abs(maxTuitionInstallments))).map((_, i) => ({
      label: String(i + 1),
      value: String(i + 1),
    }))
  }, [maxTuitionInstallments])

  return (
    <>
      <Styled.SplitInputRow>
        <Controller
          rules={{
            required: true,
            validate: v => isSelectedTuitionDurationMonthsValid(v),
          }}
          name="duration_months"
          control={form.control}
          render={({ field, fieldState }) => (
            <Styled.SelectWrapper>
              <Styled.InputWrapper hasError={!!fieldState.error?.type}>
                <Styled.InputLabel>
                  <Text variant="subtitle-medium">Número de parcelas</Text>
                </Styled.InputLabel>
                <Select
                  size={3}
                  fullWidth
                  aria-label="Número de parcelas"
                  placeholder="Selecione a quantidade de parcelas"
                  options={installmentList}
                  value={field.value}
                  // eslint-disable-next-line react/jsx-handler-names
                  onValueChange={field.onChange}
                />
                {!!fieldState.error?.type && (
                  <Styled.SelectFeedbackText variant="body-2-regular">
                    {tuitionDurationMonthsValidationText}
                  </Styled.SelectFeedbackText>
                )}
              </Styled.InputWrapper>
            </Styled.SelectWrapper>
          )}
        />

        <Controller
          rules={{
            required: true,
            validate: v => v >= 100,
          }}
          name="custom_monthly_amount"
          control={form.control}
          render={({ field, fieldState }) => (
            <NumberFormat
              placeholder="Digite o valor das parcelas"
              customInput={TextField}
              format={formatCentsToReal}
              size={3}
              label="Valor da parcela"
              aria-label="Valor da parcela"
              hiddenLabel
              variant="outlined"
              value={field.value}
              onChange={event => {
                field.onChange(Number(formatRealToCents(event.target.value)))
              }}
              error={!!fieldState.error?.type}
              errorMessage="Insira um valor válido"
            />
          )}
        />
      </Styled.SplitInputRow>

      <Styled.InputRow>
        <NumberFormat
          placeholder="R$ 0,00"
          customInput={TextField}
          format={formatCentsToReal}
          disabled
          hiddenLabel
          variant="outlined"
          value={installmentTotalAmount}
          size={3}
          label="Valor total"
          aria-label="Valor total"
        />
      </Styled.InputRow>
      {hasMultipleInstallments && (
        <Styled.InputRow>
          <Controller
            rules={{
              required: true,
            }}
            name="due_date_month"
            control={form.control}
            render={({ field }) => (
              <Datepicker.Root>
                <div>
                  <Text variant="subtitle-medium" id="due-date-month-label">
                    Mês de início das mensalidades
                  </Text>
                </div>
                <Styled.DatepickerTrigger
                  size={3}
                  aria-labelledby="due-date-month-label"
                  placeholder="Mês"
                >
                  {field.value ? formatDate(field.value, 'MM/YYYY') : null}
                </Styled.DatepickerTrigger>
                <Datepicker.Calendar
                  value={field.value}
                  minDate={today}
                  maxDate={lastDayOfReferenceYear}
                  data-testid="datepicker-calendar-month"
                  minDetail="month"
                  maxDetail="year"
                  onChange={date => field.onChange(date)}
                  defaultActiveStartDate={firstDayOfReferenceYear}
                />
              </Datepicker.Root>
            )}
          />
        </Styled.InputRow>
      )}
      {hasMultipleInstallments && (
        <Styled.InputRow>
          <Controller
            rules={{ required: true }}
            control={form.control}
            name="installment_due_date_rule"
            render={({ field }) => (
              <>
                <Styled.InputLabelWrapper>
                  <Text variant="subtitle-medium">
                    Selecione a regra de dia de vencimento das mensalidades
                  </Text>
                </Styled.InputLabelWrapper>
                <RadioGroup
                  defaultValue={field.value as installmentDueDateRule}
                  onValueChange={value => field.onChange(value)}
                >
                  <RadioGroupItem
                    value={installmentDueDateRule.WORKING_DAY}
                    label="Vencimento por dia útil"
                  />
                  <RadioGroupItem
                    value={installmentDueDateRule.FIXED_DAY}
                    label="Vencimento por dia fixo"
                  />
                </RadioGroup>
              </>
            )}
          />
        </Styled.InputRow>
      )}

      <Styled.InputRow>
        {hasMultipleInstallments && (
          <Controller
            rules={{
              validate: v => validateDueDate(v ?? 0),
            }}
            name="due_day"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                disabled={!enableDueDayField}
                label="Dia"
                aria-label="Dia"
                placeholder="Dia de vencimento"
                size={3}
                type="number"
                name={field.name}
                value={field.value ?? ''}
                ref={field.ref}
                // eslint-disable-next-line react/jsx-handler-names
                onChange={field.onChange}
                error={!!fieldState.error?.message}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        )}
        {!hasMultipleInstallments && (
          <Controller
            rules={{
              required: true,
            }}
            name="due_date"
            control={form.control}
            render={({ field }) => (
              <Datepicker.Root>
                <div>
                  <Text variant="subtitle-medium" id="due-date-label">
                    Data de vencimento
                  </Text>
                </div>
                <Styled.DatepickerTrigger
                  size={3}
                  aria-labelledby="due-date-label"
                  placeholder="Selecione a data"
                >
                  {field.value ? formatDate(field.value, 'DD/MM/YYYY') : null}
                </Styled.DatepickerTrigger>
                <Datepicker.Calendar
                  value={field.value}
                  minDate={today}
                  maxDate={lastDayOfReferenceYear}
                  data-testid="datepicker-calendar"
                  minDetail="decade"
                  onChange={date => field.onChange(date)}
                  defaultActiveStartDate={firstDayOfReferenceYear}
                />
              </Datepicker.Root>
            )}
          />
        )}
      </Styled.InputRow>
    </>
  )
}
