import React from 'react'
import { ReEnrollmentSubStatus, ReenrollmentStatus } from '@/shared/interfaces'
import { CardVariant, statusDetailCards } from '../../hooks/useStatusDetailCards'
import { CardsContainer, Divider } from './styles'
import { Card } from './Card'
import { EnrolledText } from './EnrolledText'

type StatusDetailsCardsProps = {
  details: ReEnrollmentSubStatus[]
  enrollmentYear: number
  mainGuardianId: string
  status: ReenrollmentStatus
  studentId: string
}

export const StatusDetailCards = ({
  enrollmentYear,
  status,
  details,
  mainGuardianId,
  studentId,
}: StatusDetailsCardsProps) => {
  const cards = statusDetailCards(status, details, enrollmentYear, mainGuardianId, studentId)

  if (status === ReenrollmentStatus.ENROLLED) {
    return <EnrolledText variant={CardVariant.SUCCESS} text={cards[0].text} />
  }

  if (
    status === ReenrollmentStatus.ENROLLMENT_IN_PROGRESS &&
    details.includes(ReEnrollmentSubStatus.STUDENT_RECORD_PENDING)
  ) {
    return <EnrolledText variant={CardVariant.WARNING} text={cards[0].text} />
  }

  if (status === ReenrollmentStatus.UNAVAILABLE_AT_MOMENT) {
    return (
      <EnrolledText
        variant={CardVariant.WARNING}
        text="Desculpe o inconveniente. Estamos trabalhando para resolver o problema e exibir a situação de matrícula do aluno. Por favor, tente novamente mais tarde."
      />
    )
  }

  return (
    <CardsContainer>
      {cards.map((value, index) => {
        return (
          <React.Fragment key={index}>
            <Card {...value} />
            {index < cards.length - 1 && <Divider />}
          </React.Fragment>
        )
      })}
    </CardsContainer>
  )
}
