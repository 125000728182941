import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[3]};
  padding-top: ${props => props.theme.gravity.spacing[8]};
  padding-bottom: 0;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  border-radius: 16px;
`

export const BadgeContainer = styled.div`
  padding-left: 8px;

  display: flex;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[1]};

  .tooltip-button {
    color: ${({ theme }) => theme.gravity.colors['colors-border-neutral-4']};
    cursor: help;
  }
`

export const BannerHeader = styled.div`
  display: flex;
  align-items: center;

  padding: 0 ${props => props.theme.gravity.spacing[8]};
`

export const RefreshButtonContainer = styled.div`
  margin-left: auto;
`
