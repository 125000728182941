import { useContext } from 'react'

import { BreadcrumbsContext } from '@/shared/contexts/BreadcrumbsContext'

/**
 * Custom hook to consume Breadcrumbs context
 *
 * @returns Context values
 */
export const useBreadcrumbs = () => useContext(BreadcrumbsContext)
