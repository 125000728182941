import type { UseFormReturn } from 'react-hook-form'
import { DialogPrimitives } from '@gravity/dialog'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'

import { InstallmentForm } from './InstallmentForm'

import { FormAddTuitionProps } from '../../types'
import * as Styled from './styles'

type Props = {
  enrollmentID: uuid
  form: UseFormReturn<FormAddTuitionProps>
  isLoading: boolean
  onClose: (form: UseFormReturn<FormAddTuitionProps>) => void
  onConfirm: (enrollmentID: uuid, form: UseFormReturn<FormAddTuitionProps>) => void
  open: boolean
  referenceYear: number
  studentName: string | null
}

export const AddTuitionDialog = ({
  open,
  onConfirm,
  onClose,
  enrollmentID,
  studentName,
  isLoading,
  form,
  referenceYear,
}: Props) => {
  const handleDialogOpenChange = (visible: boolean) => {
    if (!visible && open) onClose(form)
  }

  return (
    <DialogPrimitives.Root open={open} onOpenChange={handleDialogOpenChange}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        size={3}
        title="Adicionar mensalidades"
        actionButton={
          <Button
            disabled={isLoading}
            color="accent"
            variant="solid"
            onClick={form.handleSubmit(() => {
              return onConfirm(enrollmentID, form)
            })}
            loading={isLoading}
          >
            Adicionar
          </Button>
        }
        cancelButton={
          <Button disabled={isLoading} variant="ghost" onClick={() => onClose(form)}>
            Cancelar
          </Button>
        }
      >
        <Styled.ContentWrapper>
          <Text variant="body-1-regular">{studentName}</Text>
          <InstallmentForm form={form} referenceYear={referenceYear} />
        </Styled.ContentWrapper>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}
