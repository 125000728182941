import { useApiClient } from '@/shared/hooks'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { onboardingAPI } from '../services/onboardingApi'
import { UpdateChargeParams } from '../services/types/onboardingCharge'

export const useUpdateChargeMutation = (
  options?: UseMutationOptions<any, unknown, UpdateChargeParams, unknown>
) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useMutation(async (params: UpdateChargeParams) => {
    const response = await api.updateCharges(params)

    return response.data
  }, options)
}
