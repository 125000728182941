import styled from 'styled-components'
import NoCreditHome from '@/modules/credit/assets/no-credit-home.jpeg'
import CreditHome from '@/modules/credit/assets/credit-home.jpeg'
import { fontSize, fontWeight, spacing } from '@gravity/tokens'
import { Typography } from '@olaisaac/design-system'
import { Button } from '@gravity/button'

export const ContainerCard = styled.div<{ isEligible: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;

  background-image: url(${props => (props.isEligible ? CreditHome : NoCreditHome)});
  height: 485px;
  width: 100%;
  background-size: 100%;
  background-position: ${props => (props.isEligible ? 'top -100px right' : 'top -140px right')};

  @media screen and (min-width: 1441px) {
    background-position: ${props => (props.isEligible ? 'top -150px right' : 'top -250px right')};
  }
`

export const InternalContainerCard = styled.div<{ isEligible: boolean }>`
  @media screen and (min-width: 1440px) {
    max-width: 596px;
  }
`

export const TitleText = styled(Typography)`
  font-size: ${fontSize['3xl']};
  font-weight: ${fontWeight.medium};
  margin-bottom: ${spacing[4]};
  line-height: 3rem;
  white-space: pre-line;
`

export const DescriptionText = styled(Typography)`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.normal};
  white-space: pre-line;
`

export const ContainerButton = styled.span`
  display: inline-block;
`

export const StyledButton = styled(Button)`
  margin-top: ${spacing[8]};
  width: 100%;
`

export const ResponsiveContainer = styled.div`
  @media screen and (min-width: 1440px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1152px;
  }
`
