import { forwardRef } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { StudentFinancesGuardian } from '@monorepo/students/services/students/types'

import { useRedirectTo } from '@/shared/hooks/useRedirectTo'

import { SectionContainer } from '../SectionContainer'
import { GuardiansContent } from './GuardiansContent'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'

type FinancesSectionProps = {
  guardians?: StudentFinancesGuardian[]
  hasError: boolean
  id: string
  isLoading: boolean
  onRetry?: () => void
}

export const GuardiansSection = forwardRef<HTMLElement, FinancesSectionProps>(
  ({ id, guardians, isLoading, hasError, onRetry }, ref) => {
    const { redirectTo } = useRedirectTo()
    const { isInitialized, eventDispatcherClient } = useEventDispatcher()

    const handleNavigateToGuardianPage = (guardianId: string) => {
      if (isLoading || hasError) return

      if (isInitialized) {
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.STUDENT_PAGE,
          name: EventDispatcherEvents.CARD_CLICKED,
          pageName: EventPageName.STUDENT_PAGE,
          identifierName: EventIdentifierName.CARD_GUARDIAN,
        })
      }

      redirectTo(`/responsaveis/${guardianId}`)
    }

    return (
      <SectionContainer id={id} title="Responsáveis" ref={ref}>
        <GuardiansContent
          isLoading={isLoading}
          hasError={hasError}
          onRetry={onRetry}
          guardians={guardians}
          onNavigateToGuardian={handleNavigateToGuardianPage}
        />
      </SectionContainer>
    )
  }
)
