import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

export const usePageEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const searchContract = (referenceYear: string | undefined) => {
    if (!isInitialized) {
      return null
    }

    eventDispatcherClient.sendEvent({
      action: 'click',
      name: 'contracts-searched',
      scope: EventDispatcherEventScopes.CONTRACTS_PAGE,
      customProperties: {
        $reference_year: referenceYear,
      },
    })
  }

  const filterContract = (referenceYear: string) => {
    if (!isInitialized) {
      return null
    }

    eventDispatcherClient.sendEvent({
      action: 'click',
      name: 'contracts-filtered',
      scope: EventDispatcherEventScopes.CONTRACTS_PAGE,
      customProperties: {
        $reference_year: referenceYear,
      },
    })
  }

  return {
    searchContract,
    filterContract,
  }
}
