export const INITIAL_SUPPORTED_CYCLE_YEAR = 2024
export const LEGACY_CYCLE_YEARS = [2021, 2022, 2023]

/**
 * @deprecated Should be replace by useSchoolEnrollmentCycleContext
 */
export const ENROLLMENT_YEAR = 2024
export const ENROLLMENT_PAGE_BASE_PATH = 'matriculas'

export const ENROLLMENT_CYCLE_QUERY_KEY = 'enrollment-cycle'
export const FIRST_AVAILABLE_YEAR = 2021
