import { Dispatch, SetStateAction } from 'react'
import { Button } from '@gravity/button'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import * as Styled from './ContractDetailsDrawerFooter.styles'

import { Contract, ContractStatus, PreContractStatuses } from 'src/shared/interfaces'

import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'
import { useNavigation } from 'src/escolas/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { ContractCheckoutActions } from '../ContractActionsCheckout/ContractActionsCheckout'

import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useJWT } from '@/shared/hooks/useJWT'

export type ContractDetailsDrawerFooterProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  cancelContractQueryLoading?: boolean
  changeContractOwnership?: () => void
  contract: Contract
  hasInvoicesWithError?: boolean
  isCheckoutSchool?: boolean
  isReenrollable?: boolean
  openCancellationDrawer: () => void
  setIsConfirmationDialogVisible: (value: boolean) => void
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
  showAnnualPaidAmountsStatement: boolean
}

const ContractDetailsDrawerFooter = ({
  contract,
  isCheckoutSchool,
  isReenrollable,
  setIsConfirmationDialogVisible,
  addContract,
  changeContractOwnership,
  openCancellationDrawer,
  cancelContractQueryLoading = false,
  showAnnualPaidAmountsStatement,
  hasInvoicesWithError,
  setShowInvoicesNotGeneratedDialog,
}: ContractDetailsDrawerFooterProps) => {
  const { sendHotjarEvent } = useHotjar()
  const { schoolSlug, navigateTo } = useNavigation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { getUserRolesBySchool, isBackofficeUser } = useJWT()

  const considerUserRole = useUnleashFlag(UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL)

  const { school } = useSelectedSchool()

  const sendGenerateStatementEvent = () => {
    if (isInitialized)
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.CONTRACT_PAGE,
        action: 'click',
        entity: EventDispatcherEntities.PRINT_DEMONSTRATIVE_PAID_VALUES,
        customProperties: {
          $name: 'Gerar demonstrativo de pagamento',
          $contract_id: contract?.id,
        },
      })
  }

  const isPreContract = contract?.pre_contract_status !== PreContractStatuses.EFFECTED_PCS
  const isCanceledContract = contract?.status === ContractStatus.CANCELED
  const isCanceledPreContract = isPreContract && isCanceledContract

  const goToContractEdit = () => {
    if (isInitialized)
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.CONTRACT_DRAWER,
        action: 'click',
        customProperties: {
          $button_name: 'Editar contrato',
          $contract_type: isPreContract ? 'PRE_CONTRACT' : 'EFFECTED_CONTRACT',
        },
      })

    const path = `/contratos/${contract?.id}/editar`

    navigateTo({ slug: schoolSlug, path })
  }

  const hasEditContractPermission =
    isBackofficeUser || getUserRolesBySchool(school?.id ?? '').has('editar_contrato')

  const showEditContractButton = !considerUserRole || hasEditContractPermission

  const renderGenerateStatementButton = () => {
    return (
      <Styled.ButtonContainer>
        <Button
          fullWidth
          onClick={() => {
            sendHotjarEvent(HotjarEvents.GENERATE_STATEMENT)
            setIsConfirmationDialogVisible(true)
            sendGenerateStatementEvent()
          }}
          data-testid="generate-statement-button"
        >
          Gerar demonstrativo de pagamento
        </Button>
      </Styled.ButtonContainer>
    )
  }

  if (isCanceledPreContract) return renderGenerateStatementButton()

  return (
    <>
      {!isCheckoutSchool && showAnnualPaidAmountsStatement && renderGenerateStatementButton()}
      {isCheckoutSchool && (
        <Styled.ButtonContainer>
          {showEditContractButton && (
            <Button onClick={goToContractEdit} fullWidth>
              Editar Contrato
            </Button>
          )}

          <ContractCheckoutActions
            contractId={contract?.id}
            hasInvoicesWithError={hasInvoicesWithError}
            setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
            addContract={addContract}
            isReenrollable={isReenrollable}
            changeContractOwnership={changeContractOwnership}
            showAnnualPaidAmountsStatement={showAnnualPaidAmountsStatement}
            clickPaidAmountStatement={value => setIsConfirmationDialogVisible(value)}
            openCancellationDrawer={openCancellationDrawer}
            queryLoading={cancelContractQueryLoading}
            isPreContract={isPreContract}
          />
        </Styled.ButtonContainer>
      )}
    </>
  )
}

export default ContractDetailsDrawerFooter
