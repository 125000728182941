import { useMemo } from 'react'

import type { EnrollmentCycleItem } from '@/modules/enrollment/services/enrollmentService/types'

import type { ColumnProps, TableRowProps } from '../types'
import { formatGuardians } from '../utils'
import { columnNames } from '../types'

export const useTableData = (enrollmentCycleItem?: EnrollmentCycleItem[]) => {
  const rows = useMemo((): TableRowProps[] => {
    if (!enrollmentCycleItem) return []
    return enrollmentCycleItem?.map(enrollment => {
      return {
        columns: {
          [columnNames.STUDENT_NAME]: enrollment.student_name,
          [columnNames.GUARDIAN_NAME]: formatGuardians([
            enrollment.main_guardian,
            ...(enrollment.other_guardians || []),
          ]),
          [columnNames.PRODUCT_NAME]: enrollment.main_product?.name,
          [columnNames.STUDENT_STATUS]: enrollment.student_status,
        },
        hasStartedEnrollment: enrollment.started_enrollment,
        isReenrollable: enrollment.is_reenrollable,
        studentID: enrollment.student_id,
        otherProducts: enrollment.other_products?.map(product => product.name),
        key: enrollment.student_id,
      }
    })
  }, [enrollmentCycleItem])

  const columnHeaders: Array<ColumnProps> = [
    { key: columnNames.STUDENT_NAME, label: 'Aluno', sortable: false, minWidth: '190px' },
    { key: columnNames.GUARDIAN_NAME, label: 'Responsável', sortable: false, minWidth: '190px' },
    {
      key: columnNames.PRODUCT_NAME,
      label: 'Produto',
      sortable: false,
      minWidth: '136px',
    },
    {
      key: columnNames.STUDENT_STATUS,
      label: 'Situação de matricula',
      sortable: false,
      minWidth: '230px',
    },
  ]

  return {
    rows,
    columnHeaders,
  }
}
