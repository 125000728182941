import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { mdiListBox, mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'

import { useJWT } from '@/shared/hooks/useJWT'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { MoreOptionsPopover } from '@/shared/components/MoreOptionsPopover'
import { PopoverOptions } from '@/shared/components/MoreOptionsPopover/types'
import { MatriculasApiContractStatus } from '@/shared/interfaces'
import { StudentContract } from '@monorepo/students/services/students/types'

type ContractActionsCellProps = {
  contract: StudentContract
  onAcceptPaymentPlan: (contractId: string) => void
  onOpenAddTuitionDialog: (props: {
    campaignId: string
    enrollmentId: string
    referenceYear: string
  }) => void
  onOpenProposalDialog: (enrollmentId: string) => void
  onRedirectToEditContract: (contractId: string) => void
  onRedirectToInvoice: (props: {
    guardianId: string
    productId: string
    referenceYear: string
    studentId: string
  }) => void
  studentId: string
}

export const ContractActions = ({
  contract,
  onRedirectToEditContract,
  onRedirectToInvoice,
  onOpenProposalDialog,
  onOpenAddTuitionDialog,
  onAcceptPaymentPlan,
  studentId,
}: ContractActionsCellProps) => {
  const { getUserRolesBySchool, isBackofficeUser } = useJWT()
  const { school } = useSelectedSchool()

  const considerUserRole = useUnleashFlag(UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL)

  const hasEditContractPermission =
    isBackofficeUser || getUserRolesBySchool(school?.id ?? '').has('editar_contrato')

  const showEditContractOption = !considerUserRole || hasEditContractPermission

  const moreOptions: PopoverOptions[] = (() => {
    const options = []

    if (contract.can_add_tuition) {
      options.push({
        label: 'Adicionar mensalidades',
        onClick: () =>
          onOpenAddTuitionDialog({
            campaignId: contract.campaign_id ?? '',
            enrollmentId: contract.id,
            referenceYear: contract.reference_year,
          }),
      })
    }

    if (contract.status === MatriculasApiContractStatus.PENDING_GUARDIAN_APPROVAL) {
      options.push({
        label: 'Cancelar proposta',
        onClick: () => onOpenProposalDialog(contract.id),
      })

      if (contract.can_accept_payment_plan) {
        options.push({
          label: 'Gerar boleto de pré-matrícula',
          onClick: () => onAcceptPaymentPlan(contract.id),
        })
      }
    }

    return options
  })()

  return (
    <>
      <Table.ActionCell
        data-testid="action-cell"
        actions={
          <>
            <Tooltip text="Ver faturas" position="left">
              <IconButton
                data-testid="invoices"
                variant="ghost"
                onClick={event => {
                  event.stopPropagation()
                  onRedirectToInvoice({
                    guardianId: contract.guardian?.id ?? '',
                    productId: contract.product?.id ?? '',
                    referenceYear: contract.reference_year,
                    studentId,
                  })
                }}
              >
                <Icon path={mdiListBox} size={16} />
              </IconButton>
            </Tooltip>

            {showEditContractOption && (
              <Tooltip text="Editar contrato" position="left">
                <IconButton
                  data-testid="edit-contract"
                  variant="ghost"
                  onClick={event => {
                    event.stopPropagation()
                    onRedirectToEditContract(contract.id)
                  }}
                >
                  <Icon path={mdiPencil} size={16} />
                </IconButton>
              </Tooltip>
            )}
            {!!moreOptions.length && <MoreOptionsPopover options={moreOptions} />}
          </>
        }
      />
    </>
  )
}
