import { Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

import type { ContractResponse, ContractStatus } from '@/shared/interfaces'
import StatusBadge, { StatusBadgeColor } from '@/shared/components/StatusBadge'

export const contractStatusParamsDict: PartialRecord<
  ContractResponse['status'],
  { color: StatusBadgeColor; text: string }
> = {
  CANCELED: { color: 'error', text: 'Inativo' },
  OPEN: { color: 'success', text: 'Ativo' },
  PENDING_GUARDIAN_APPROVAL: { color: 'grey', text: 'Pendente' },
}

export const renderContractStatus = (status: ContractStatus) => {
  return (
    <>
      <StatusBadge color={contractStatusParamsDict[status]?.color ?? 'primary'} />
      <Box ml={1}>
        <Typography variation="bodySmall" color="secondary">
          {contractStatusParamsDict[status]?.text}
        </Typography>
      </Box>
    </>
  )
}
