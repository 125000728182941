import { useMemo } from 'react'

import Icon from '@mdi/react'
import {
  mdiSchool,
  mdiCurrencyUsd,
  mdiAccountMultiple,
  mdiWallet,
  mdiArchive,
  mdiHandCoin,
} from '@mdi/js'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

type Props = {
  currentDate: string
  currentYear: number
  hasPaymentDisputes: boolean
  isIntegratedSchool: boolean
  isIsaacPay: boolean
  isMaintainer: boolean
  isSecretary: boolean
  url: string
}

export function useQuickAccessSectionLinks({
  url,
  currentYear,
  currentDate,
  isSecretary,
  isIsaacPay,
  isMaintainer,
  isIntegratedSchool,
  hasPaymentDisputes,
}: Props) {
  const isIsaacScoreEnabled = useUnleashFlag(UnleashFlags.MAT_616_ENABLE_ISAAC_SCORE)
  const value = useMemo(() => {
    const payoutReportUrl = `${url}/repasses/mensalidades`

    const links: Array<{
      href: string
      icon: JSX.Element
      title: string
    }> = []

    const addIsaacScoreLink = () => {
      if (isIsaacScoreEnabled) {
        links.push({
          icon: <Icon path={mdiArchive} size={1} />,
          href: `${url}/matriculas/score`,
          title: 'isaac Score',
        })
      }
    }

    const guardiansLink = hasPaymentDisputes
      ? {
          icon: <Icon path={mdiHandCoin} size={1} />,
          href: `${url}/cobrancas-indevidas`,
          title: 'Cobranças Indevidas',
        }
      : {
          icon: <Icon path={mdiAccountMultiple} size={1} />,
          href: `${url}/responsaveis`,
          title: 'Responsáveis',
        }

    if (isMaintainer) {
      if (!isIsaacPay) {
        links.push({
          icon: <Icon path={mdiCurrencyUsd} size={1} />,
          href: payoutReportUrl,
          title: 'Repasse do mês',
        })
      }

      links.push({
        icon: <Icon path={mdiSchool} size={1} />,
        href: `${url}/matriculas`,
        title: 'Matrículas',
      })
      links.push(guardiansLink)

      addIsaacScoreLink()
    } else {
      links.push({
        icon: <Icon path={mdiSchool} size={1} />,
        href: `${url}/matriculas`,
        title: 'Matrículas',
      })

      links.push({
        icon: <Icon path={mdiWallet} size={1} />,
        href: `${url}/responsaveis?debt_status=OVERDUE`,
        title: 'Negociar parcelas',
      })
      links.push(guardiansLink)

      addIsaacScoreLink()
    }

    return links
  }, [
    url,
    currentYear,
    currentDate,
    hasPaymentDisputes,
    isSecretary,
    isIsaacPay,
    isMaintainer,
    isIntegratedSchool,
  ])

  return value
}
