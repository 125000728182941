import { OldProduct as Product } from '@/shared/interfaces'
import { GetProductClassificationsData } from '../interfaces/productClassification'
import { school1, school2 } from './schools'

export const product1: Product = {
  created_at: '2021-01-27T17:29:18.701Z',
  enrollment_amount: 0,
  enrollment_installments_quantity: 0,
  id: 'b6fa7b39-a7fe-4194-96f1-f310d3af1c15',
  installments_quantity: 12,
  monthly_amount: 30000,
  name: 'Judô',
  school_id: school1.id,
  educational_substage_id: '',
  product_program_id: '',
  product_category_id: '',
}

export const product1WithSchoolData: Product = {
  ...product1,
  school: school1,
}

export const productWithEnrollmentWithoutTemplate: Product = {
  created_at: '2021-01-27T17:29:18.701Z',
  enrollment_amount: 5000,
  enrollment_installments_quantity: 2,
  envelope_template_referral_id: '',
  id: '9a7e447a-ff48-4071-9d45-8f52544b1837',
  installments_quantity: 18,
  monthly_amount: 50000,
  name: 'Quadribol Avançado',
  school_id: school2.id,
  educational_substage_id: '',
  product_program_id: '',
  product_category_id: '',
}

export const productWithEnrollmentAndTemplate: Product = {
  created_at: '2021-01-27T17:29:18.701Z',
  enrollment_amount: 5000,
  enrollment_installments_quantity: 2,
  envelope_template_referral_id: '00000000-0000-0000-0000-000000000001',
  id: 'c537ac4f-2f6b-47e7-8be0-266b67eade0b',
  installments_quantity: 18,
  monthly_amount: 50000,
  name: 'Produto com marícula e template',
  school_id: school2.id,
  educational_substage_id: '',
  product_program_id: '',
  product_category_id: '',
}

export const productsList = [
  product1,
  productWithEnrollmentWithoutTemplate,
  productWithEnrollmentAndTemplate,
  {
    created_at: '2021-01-27T17:29:18.701Z',
    enrollment_amount: 0,
    enrollment_installments_quantity: 0,
    id: 'b6fa7b39-a7fe-4194-96f1-f310d3bf1c15',
    installments_quantity: 12,
    monthly_amount: 30000,
    name: 'Produto 01',
    school_id: school1.id,
    educational_substage_id: '',
    product_program_id: '',
    product_category_id: '',
  },
  {
    created_at: '2021-01-27T17:29:18.701Z',
    enrollment_amount: 0,
    enrollment_installments_quantity: 0,
    id: 'b6fa7b39-a7fe-4194-96f1-f310d3af1c13',
    installments_quantity: 12,
    monthly_amount: 30000,
    name: 'Produto 02',
    school_id: school1.id,
    educational_substage_id: '',
    product_program_id: '',
    product_category_id: '',
  },
  {
    created_at: '2021-01-27T17:29:18.701Z',
    enrollment_amount: 0,
    enrollment_installments_quantity: 0,
    id: 'b6fa7b39-a7fe-4194-96f1-f310d3af1c12',
    installments_quantity: 12,
    monthly_amount: 30000,
    name: 'Produto 03',
    school_id: school1.id,
    educational_substage_id: '',
    product_program_id: '',
    product_category_id: '',
  },
  {
    created_at: '2021-01-27T17:29:18.701Z',
    enrollment_amount: 0,
    enrollment_installments_quantity: 0,
    id: 'b6fa7b39-a7fe-4194-96f1-f310d3af1cd2',
    installments_quantity: 12,
    monthly_amount: 30000,
    name: 'Produto 04',
    school_id: school1.id,
    educational_substage_id: '',
    product_program_id: '',
    product_category_id: '',
  },
  {
    created_at: '2021-01-27T17:29:18.701Z',
    enrollment_amount: 0,
    enrollment_installments_quantity: 0,
    id: 'b6fa7b39-a7fe-4194-96f1-f310d3af1ed2',
    installments_quantity: 12,
    monthly_amount: 30000,
    name: 'Produto 05',
    school_id: school1.id,
    educational_substage_id: '',
    product_program_id: '',
    product_category_id: '',
  },
]

export const productClassifications: GetProductClassificationsData = {
  educational_stages: [
    {
      id: 1,
      name: 'Ensino Infantil',
      slug: '',
    },
  ],
  educational_substages: [
    {
      id: 1,
      educational_stage_id: 1,
      name: 'Ensino Infantil',
      slug: '',
    },
  ],
  product_programs: [
    {
      id: 1,
      name: 'Manhã',
      slug: '',
    },
  ],
  product_categories: [
    {
      id: 1,
      is_educational: true,
      name: 'Curricular',
      slug: '',
    },
    {
      id: 2,
      is_educational: false,
      name: 'Idioma',
      slug: '',
    },
  ],
}
