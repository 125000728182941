import * as Sentry from '@sentry/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useToast } from '@gravity/toast'

import { enrollmentService } from '@/modules/enrollment/services/enrollmentService'
import type {
  GetSignatureDocumentRequest,
  GetSignatureDocumentResponse,
  UploadSignatureDocumentRequest,
} from '@/modules/enrollment/services/enrollmentService/types'
import { useApiClient } from '@/shared/hooks'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { queryClient } from '@/shared/contexts/ReactQuery'

type UseUploadSignatureDocumentMutationParams = UploadSignatureDocumentRequest & {
  schoolId: uuid
}
const signatureDocumentQueryKey = 'SIGNATURE_DOCUMENT'

export const useUploadSignatureMutation = () => {
  const { apiClient } = useApiClient()
  const service = enrollmentService(apiClient.privateApi)
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({ schoolId, ...params }: UseUploadSignatureDocumentMutationParams) => {
      return service.uploadSignatureDocument(schoolId, params)
    },
    onSuccess: () => {
      toast({
        type: 'success',
        title: 'O documento foi salvo com sucesso!',
      })
      queryClient.invalidateQueries([signatureDocumentQueryKey])
    },
    onError: error => {
      toast({
        type: 'error',
        title: 'Ocorreu um erro ao salvar o documento.',
        description: 'Tente novamente mais tarde.',
      })
      Sentry.captureException(error)
    },
  })
}

export const useSignatureDocumentQuery = (
  params: GetSignatureDocumentRequest,
  options?: UseQueryOptions<GetSignatureDocumentResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()
  const service = enrollmentService(apiClient.privateApi)

  return useQuery<GetSignatureDocumentResponse, XMLHttpRequest>(
    [signatureDocumentQueryKey, params],
    async () => {
      const response = await service.getSignatureDocument(params)
      return response.data
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}
