import { REALMS } from '@/shared/constants'
import { useJWT } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Notification } from '@olaisaac/design-system'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import useLiquidationInfo from '../../hooks/manualLiquidation/useLiquidationInfo'
import { getStatus } from '@/escolas/components/contract/utils'
import {
  getIdFromUrn,
  getInformationForPaymentDetailByInstallment,
  getReceivableStatusByInstallment,
  sendEvent,
  sendManualLiquidationEvents,
} from '../../helper'
import { useQuery } from '@/shared/hooks/useQuery'
import { MixpanelEventsEnum } from '@/shared/integrations/mixpanel/types'
import {
  LiquidationInfoResponse,
  LiquidationPaymentMethod,
  LiquidationPaymentMethodAdminLabel,
  LiquidationSource,
  Receivable,
  ReceivableStatuses,
  PaymentMethod,
  Product,
} from '@/shared/interfaces'
import {
  DuplicateAgreementRequest,
  SimulationPaymentMethod,
} from '@/modules/guardians/models/agreement'
import dayjs from 'dayjs'
import { formatCentsToReal, getEndOfToday } from '@/shared/utils'
import usePaymentDetail from '../../hooks/manualLiquidation/usePaymentDetail'
import * as S from '../../styles'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/shared/components/ConfirmationDialog'
import { Box, Grid } from '@material-ui/core'
import Header from '../../components/Header'
import { InputBox } from '../../components/InputBox'
import {
  ManualLiquidationFields,
  ManualLiquidationDiscountPageForm,
  InfoLiquidationValues,
} from '@/modules/guardians/ManualLiquidation/types'
import { Controller } from 'react-hook-form'
import { AccountField } from '../../components/Fields/AccountField'
import { PaymentMethodField } from '../../components/Fields/PaymentMethodField'
import { PaidDateField } from '../../components/Fields/PaidDateField'
import Conclusion from '../../components/Conclusion'
import Footer from '../../components/Footer'
import PaymentDetail from '../../components/PaymentDetail'
import { DiscountsField } from '../../components/Fields/DiscountsField'
import { AddRounded } from '@material-ui/icons'
import useManageFields from '../../hooks/manualLiquidation/useManageFields'
import useManualLiquidationForm from '../../hooks/manualLiquidation/useManualLiquidationForm'
import { useDuplicateAgreement } from '@/escolas/hooks/useDuplicateAgreement'
import LoadingLiquidation from '../../components/LoadingLiquidation'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { InstallmentV2 } from '@/modules/guardians/services/types'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { getDuplicateAgreementPaymentPlan } from '@/modules/guardians/Negotiation/helpers/agreement'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useSendManualLiquidationEvent } from '../../hooks/manualLiquidation/useSendManualLiquidationEvents'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'
import { NotAllowedDialog } from '@/shared/components/NotAllowedDialog'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

interface Props {
  installment: InstallmentV2
  isFetchGuardiansInstallmentsLoading: boolean
  product: Product
}

const ManualLiquidation = ({
  product,
  installment,
  isFetchGuardiansInstallmentsLoading,
}: Props) => {
  const history = useHistory()
  const { setOnQueryParam } = useQuery()
  const { guardianId } = useParams<{ guardianId: uuid }>()
  const { isBackofficeUser, getUserRolesBySchool } = useJWT()
  const { api } = useApi()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const sendManualLiquidationEvent = useSendManualLiquidationEvent()
  const duplicateAgreement = useDuplicateAgreement()
  const { pageToReturn } = useGuardianNavigation()

  const [openDiscount, setOpenDiscount] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [newNegotiationId, setNewNegotiationId] = useState('')

  const [loading, setLoading] = useState(false)
  const [receivableLiquidated, setReceivableLiquidated] = useState<Receivable>()

  const receivableId = getIdFromUrn(installment?.urn)

  const realm = isBackofficeUser ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM

  const isHybridModelEnabled = useUnleashFlag(UnleashFlags.ISP_38_ENABLE_HYBRID_MODEL_CHARGING)

  const isDownPayment = installment?.type === 'DOWN_PAYMENT'
  const { toggleField, fieldsStatus, isFieldDisabled } = useManageFields(realm)
  const { handleSubmit, control, watch, getValues, setValue } = useManualLiquidationForm(
    isBackofficeUser
  )

  watch(['source', 'payment_method', 'paid_date'])
  const { source, payment_method, paid_date } = getValues()

  const {
    isLoading,
    discountInfo,
    form: discountForm,
    clearDiscounts,
    liquidationInfo,
  } = useLiquidationInfo({
    installment,
    paidDate: paid_date,
    installmentIsLoading: isFetchGuardiansInstallmentsLoading,
  })

  discountForm.watch(['additional_discount_amount'])

  const {
    additional_discount_amount,
    apply_lost_due_payment_discount,
    apply_lost_early_payment_discount,
    apply_previous_fines_and_interest,
  } = discountForm.getValues()

  const fineAndInterest = apply_previous_fines_and_interest ? discountInfo.fineAndInterest : 0

  const additionalDiscount = discountInfo.isAdditionalDiscountEnabled
    ? additional_discount_amount
    : 0

  const lostDueDiscount = apply_lost_due_payment_discount ? discountInfo.lostDuePaymentDiscount : 0

  const lostEarlyDiscount = apply_lost_early_payment_discount
    ? discountInfo.lostEarlyPaymentDiscount
    : 0

  const totalDiscount = Number(additionalDiscount) + lostDueDiscount + lostEarlyDiscount

  const fineAndDiscountEqualZero = totalDiscount === 0 && fineAndInterest === 0

  const receivableStatus = getStatus(getReceivableStatusByInstallment(installment))

  const formValidate = () => {
    if (!source) return false
    if (source !== LiquidationSource.ISAAC_ACCOUNT && !payment_method) return false
    if (!paid_date) return false
    if (!discountInfo.validateTotalDiscount) return false
    if (Object.values(fieldsStatus).some(fieldStatus => fieldStatus.isOpen)) return false
    return true
  }

  const isFormValid = formValidate()

  const isSourceIsaacAccount = source === LiquidationSource.ISAAC_ACCOUNT

  const { school } = useSelectedSchool()

  const userRoles = getUserRolesBySchool(school?.id ?? '')

  const isEnabledBasicOperationAccessLevel = useUnleashFlag(
    UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL
  )

  const isManualLiquidationCreateBlockedForNoAccessRole =
    isEnabledBasicOperationAccessLevel && !userRoles.has('criar_baixa_manual') && !isBackofficeUser

  const handleGoBack = () => {
    history.push(pageToReturn || '')
    setOnQueryParam('true', 'refetch', 'replace')
  }

  const handleGoBackWithoutSaving = () => {
    handleGoBack()
    sendEvent(
      receivableId,
      receivableStatus,
      MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAGE_FLOW_BACK
    )
  }

  const handleManualLiquidation = async (id: string) => {
    const resource = api.receivables.manualLiquidation
    const isPaidDateBeforeDueDate = paid_date.isAfter(installment.due_date || '')

    return await resource(
      id,
      {
        additional_discount_amount:
          discountInfo.isAdditionalDiscountEnabled && additional_discount_amount
            ? +additional_discount_amount
            : 0,
        apply_lost_due_payment_discount: apply_lost_due_payment_discount,
        apply_lost_early_payment_discount: apply_lost_early_payment_discount,
        paid_date: dayjs(paid_date)
          ?.hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .utc()
          .hour(0)
          .toISOString(),
        remove_fines_and_interest: isPaidDateBeforeDueDate
          ? false
          : !apply_previous_fines_and_interest,
        remove_fines_and_interest_change: false,
        source: source,
        payment_method:
          source === LiquidationSource.SCHOOL_ACCOUNT
            ? LiquidationPaymentMethod[payment_method as keyof typeof LiquidationPaymentMethod]
            : undefined,
      },
      school?.id || ''
    )
      .then(updatedReceivables => {
        const paidReceivable = updatedReceivables.find(
          receivable => receivable.status === ReceivableStatuses.PAID
        )
        setReceivableLiquidated(paidReceivable)
      })
      .catch(() => setShowErrorModal(true))
      .finally(() => setTimeout(() => setLoading(false), 1000))
  }

  const submitHandler = async () => {
    setLoading(true)

    if (isDownPayment) {
      const paymentPlan = getDuplicateAgreementPaymentPlan(installment.origin_negotiation)

      const body: DuplicateAgreementRequest = {
        simulation_id: installment?.origin_negotiation?.simulation_id || '',
        guardian_id: guardianId,
        payment_plan: paymentPlan,
      }

      duplicateAgreement.mutate(body, {
        onSuccess: async data => {
          setNewNegotiationId(data.id)
          const invoices = data.invoices.map(invoice => {
            if (paymentPlan.payment_method === SimulationPaymentMethod.CREDIT_CARD) {
              return { ...invoice, instalment_credit_card: paymentPlan.number_of_installments }
            }
            return invoice
          })
          const firstInvoice = invoices.find(invoice => invoice.installment?.startsWith('1/'))
          isInitialized &&
            eventDispatcherClient.sendEvent({
              name: MixpanelEventsEnum.CONFIRMATION_INCOMING_REGISTRATION_NEGOTIATION_2_ENTRY,
              action: 'click',
              scope: EventDispatcherEventScopes.INSTALLMENT_NEGOTIATIONS,
              customProperties: {
                $entryValueSelected: installment?.origin_negotiation?.down_payment_amount,
                $negotiationTotalValue: installment?.origin_negotiation?.negotiation_amount,
                $negotiationId: installment?.origin_negotiation?.id,
                $newReceivablesIds: data.invoices.map(invoice => invoice.receivable_id),
                $entryReceivablePaidId: firstInvoice?.receivable_id,
              },
            })
          await handleManualLiquidation(firstInvoice?.receivable_id || '')
        },
        onError: () => {
          setTimeout(() => {
            setShowErrorModal(true)
          }, 500)
          setLoading(false)
        },
      })
    } else {
      await handleManualLiquidation(receivableId || '')
    }
  }

  const getSourceSubtitle = () => {
    if (fieldsStatus.SELECT_ACCOUNT.isOpen) return 'Selecione em que conta a parcela foi recebida'
    switch (source) {
      case LiquidationSource.ISAAC_ACCOUNT:
        return 'Conta isaac'
      case LiquidationSource.SCHOOL_ACCOUNT:
        return 'Conta escola'
      default:
        return 'Selecione em que conta a parcela foi recebida'
    }
  }

  const getPaymentFormSubtitle = () => {
    if ((!payment_method && !isSourceIsaacAccount) || fieldsStatus.PAYMENT_FORM.isOpen)
      return 'Selecione o meio de pagamento'
    if (isSourceIsaacAccount) return 'Recebimento conta Isaac'
    return LiquidationPaymentMethodAdminLabel[
      payment_method as keyof typeof LiquidationPaymentMethodAdminLabel
    ]
  }

  const getPaidDateSubtitle = () => {
    if (!paid_date || fieldsStatus.PAID_DATE.isOpen) return 'Selecione a data'
    return dayjs(paid_date).format('DD/MM/YYYY')
  }

  const getDiscountSubtitle = () => {
    if (fieldsStatus.DISCOUNTS.isOpen)
      return 'Selecione as multas e descontos que deseja adicionar ou remover'

    let message = ''
    if (fineAndInterest) {
      message = `Multas e juros ${formatCentsToReal(fineAndInterest)}`
    }
    if (totalDiscount) {
      if (!message) {
        message = `Desconto de ${formatCentsToReal(totalDiscount)}`
      } else {
        message = message + ` e desconto de ${formatCentsToReal(totalDiscount)}`
      }
    }

    return message
  }

  const maxDate = getEndOfToday()

  const sendDiscountEvents = (values: ManualLiquidationDiscountPageForm) => {
    if (fineAndInterest > 0 && !values?.apply_previous_fines_and_interest) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PENALTY_INTERESTS_REMOVED
      )
    }
    if (lostEarlyDiscount > 0 && values?.apply_lost_early_payment_discount) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_ANTICIPATION_DISCOUNT_ADDED
      )
    }
    if (lostDueDiscount > 0 && values?.apply_lost_due_payment_discount) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PUNCTUALITY_DISCOUNT_ADDED
      )
    }
    if (
      discountInfo?.isAdditionalDiscountEnabled &&
      Number(values?.additional_discount_amount) > 0 &&
      discountInfo?.validateTotalDiscount
    ) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_ADDITIONAL_DISCOUNT_ADDED
      )
    }
  }

  const onSubmit = () => {
    const values = discountForm.getValues()
    sendDiscountEvents(values)
    sendManualLiquidationEvents(values, receivableId || '', receivableStatus)
    submitHandler()
    sendManualLiquidationEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEntities.MANUAL_LIQUIDATION_FINISH,
      undefined,
      {
        contract_id: installment?.contracts?.[0].id,
        name: 'Registrar recebimento - conclusão',
        receivable_id: receivableId,
      }
    )
  }

  const isDiscountDisabled = (() => {
    return isFieldDisabled(fieldsStatus.DISCOUNTS.name)
  })()

  const { finalTotalDiscount, finalAmount } = getInformationForPaymentDetailByInstallment({
    installment,
    liquidationInfo: liquidationInfo as LiquidationInfoResponse,
    discountInfo,
    totalDiscount,
    shouldRemoveDiscountButton: !isDownPayment,
    paidDate: paid_date,
  })

  const { openDialog, closeDialog, handleDialogInformation, dialogInformation } = usePaymentDetail(
    installment,
    !isDownPayment
  )

  const handlePaymentDetailHeader = () => {
    handleDialogInformation(liquidationInfo, discountForm, discountInfo, paid_date)
  }

  const showLoadingPage = loading && newNegotiationId.length

  const isTeachingMaterial = product?.product_category?.name === 'Material'
  const referenceYear = installment?.contracts[0]?.reference_year

  const manualLiquidationBlocked =
    isTeachingMaterial && referenceYear === '2025' && isHybridModelEnabled

  return (
    <S.Wrapper>
      <FailureFeedbackDialog
        isVisible={showErrorModal}
        buttonLabel="Fechar"
        onClose={() => setShowErrorModal(false)}
        submitHandler={() => setShowErrorModal(false)}
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>
      {!showLoadingPage && (
        <Grid item xs={4} sm={6} style={{ minWidth: '752px' }}>
          <Header
            title="Registrar recebimento"
            finalAmount={finalAmount}
            totalDiscount={finalTotalDiscount}
            isLoading={isLoading}
            onClickDetailButton={handlePaymentDetailHeader}
            installment={installment}
          />

          {manualLiquidationBlocked ? (
            <NotAllowedDialog
              title="Não é possível registrar recebimento para este contrato"
              description="O registro de recebimento para contratos de material didático não pode ser feito manualmente. Em caso de dúvida, procure o suporte."
              onConfirm={handleGoBack}
            />
          ) : (
            <>
              {!receivableLiquidated && (
                <S.Form onSubmit={handleSubmit(onSubmit)} role="tree">
                  {isBackofficeUser && (
                    <>
                      <InputBox
                        title="Conta de recebimento da parcela"
                        subTitle={getSourceSubtitle()}
                        name={fieldsStatus.SELECT_ACCOUNT.name}
                        buttonLabel={source ? 'Editar' : 'Selecionar'}
                        isOpen={fieldsStatus.SELECT_ACCOUNT.isOpen}
                        isDisabled={isFieldDisabled(fieldsStatus.SELECT_ACCOUNT.name)}
                        onClick={() => {
                          toggleField(ManualLiquidationFields.SELECT_ACCOUNT)
                          if (source) {
                            sendEvent(
                              receivableId,
                              receivableStatus,
                              MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_EDITED
                            )
                          }
                        }}
                      >
                        <Controller
                          control={control}
                          name="source"
                          render={({ field: { onChange, value } }) => (
                            <AccountField
                              value={value}
                              onCancel={() => {
                                toggleField(ManualLiquidationFields.SELECT_ACCOUNT)
                              }}
                              onSave={newValue => {
                                onChange(newValue)
                                if (newValue === LiquidationSource.ISAAC_ACCOUNT) {
                                  setValue('payment_method', '')
                                  toggleField(
                                    ManualLiquidationFields.SELECT_ACCOUNT,
                                    ManualLiquidationFields.PAID_DATE
                                  )
                                  return
                                }
                                toggleField(
                                  ManualLiquidationFields.SELECT_ACCOUNT,
                                  ManualLiquidationFields.PAYMENT_FORM
                                )
                              }}
                            />
                          )}
                        />
                      </InputBox>

                      <Box mt={2} />
                    </>
                  )}
                  <InputBox
                    title="Forma de pagamento usada"
                    subTitle={getPaymentFormSubtitle()}
                    name={fieldsStatus.PAYMENT_FORM.name}
                    buttonLabel={payment_method || isSourceIsaacAccount ? 'Editar' : 'Selecionar'}
                    isOpen={fieldsStatus.PAYMENT_FORM.isOpen}
                    isDisabled={
                      isFieldDisabled(fieldsStatus.PAYMENT_FORM.name) ||
                      !source ||
                      isSourceIsaacAccount
                    }
                    onClick={() => {
                      toggleField(ManualLiquidationFields.PAYMENT_FORM)
                      if (payment_method || isSourceIsaacAccount) {
                        sendEvent(
                          receivableId,
                          receivableStatus,
                          MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_EDITED
                        )
                      }
                    }}
                  >
                    <Controller
                      control={control}
                      name="payment_method"
                      render={({ field: { onChange, value } }) => (
                        <PaymentMethodField
                          value={value}
                          source={source}
                          realm={realm}
                          onCancel={() => {
                            toggleField(ManualLiquidationFields.PAYMENT_FORM)
                          }}
                          onSave={newValue => {
                            onChange(newValue)
                            toggleField(
                              ManualLiquidationFields.PAYMENT_FORM,
                              ManualLiquidationFields.PAID_DATE
                            )
                          }}
                        />
                      )}
                    />
                  </InputBox>

                  <Box mt={2} />

                  <InputBox
                    title="Data de recebimento"
                    subTitle={getPaidDateSubtitle()}
                    name={fieldsStatus.PAID_DATE.name}
                    buttonLabel={paid_date ? 'Editar' : 'Selecionar'}
                    isOpen={fieldsStatus.PAID_DATE.isOpen}
                    isDisabled={isFieldDisabled(fieldsStatus.PAID_DATE.name)}
                    onClick={() => {
                      toggleField(ManualLiquidationFields.PAID_DATE)
                      if (paid_date) {
                        sendEvent(
                          receivableId,
                          receivableStatus,
                          MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_EDITED
                        )
                      }
                    }}
                  >
                    <Controller
                      control={control}
                      name="paid_date"
                      render={({ field: { onChange, value } }) => (
                        <PaidDateField
                          contractCreationDate={dayjs(installment.contracts?.[0]?.created_at)}
                          value={value ?? maxDate}
                          maxDate={maxDate}
                          onCancel={() => {
                            toggleField(ManualLiquidationFields.PAID_DATE)
                          }}
                          onSave={newDate => {
                            onChange(newDate)
                            toggleField(ManualLiquidationFields.PAID_DATE)
                          }}
                          loading={isLoading}
                          onClickDetailButton={handleDialogInformation}
                          installment={installment}
                        />
                      )}
                    />
                  </InputBox>

                  <Box mt={2} />

                  {!discountInfo.isRenegotiated ? (
                    !isDownPayment &&
                    !openDiscount && (
                      <S.StyledButton
                        variation="ghost"
                        onClick={() => {
                          setOpenDiscount(true)
                          toggleField(ManualLiquidationFields.DISCOUNTS)
                        }}
                        disabled={isDiscountDisabled}
                      >
                        <AddRounded />
                        <S.TextButton
                          variation="buttonLarge"
                          color={isDiscountDisabled ? 'secondary' : 'accent'}
                        >
                          Adicionar desconto
                        </S.TextButton>
                      </S.StyledButton>
                    )
                  ) : (
                    <Notification
                      variation="information"
                      description="Não é possível adicionar descontos em parcelas de negociação durante o registro de
                recebimento."
                    />
                  )}

                  {openDiscount && (
                    <InputBox
                      title="Multas e Descontos"
                      subTitle={getDiscountSubtitle()}
                      name={fieldsStatus.DISCOUNTS.name}
                      buttonLabel="Editar"
                      isOpen={fieldsStatus.DISCOUNTS.isOpen}
                      isDisabled={
                        isFieldDisabled(fieldsStatus.DISCOUNTS.name) ||
                        isLoading ||
                        !discountInfo.validateTotalDiscount
                      }
                      onClick={() => {
                        toggleField(ManualLiquidationFields.DISCOUNTS)
                        setOpenDiscount(!fineAndDiscountEqualZero)
                      }}
                    >
                      <DiscountsField
                        discountsInfo={discountInfo}
                        form={discountForm}
                        isLoading={isLoading}
                        onCancel={() => {
                          toggleField(ManualLiquidationFields.DISCOUNTS)
                          clearDiscounts()
                          setOpenDiscount(false)
                        }}
                        onSave={() => {
                          toggleField(ManualLiquidationFields.DISCOUNTS)
                          setOpenDiscount(!fineAndDiscountEqualZero)
                        }}
                      />
                    </InputBox>
                  )}

                  <Footer
                    isLoading={isLoading || loading}
                    enableSubmit={isFormValid}
                    onClickGoBack={handleGoBackWithoutSaving}
                    onClick={handleSubmit(onSubmit)}
                  />
                </S.Form>
              )}

              {openDialog && (
                <PaymentDetail
                  open={openDialog}
                  paidDate={dialogInformation?.paidDate ?? dayjs()}
                  onClose={closeDialog}
                  info={dialogInformation as InfoLiquidationValues}
                />
              )}
              {receivableLiquidated && (
                <>
                  <Conclusion
                    liquidationInfo={{
                      amount: receivableLiquidated.total_amount_paid || 0,
                      orderReference: 'Parcela 1 de 1',
                      paidDate: receivableLiquidated.paid_date || '',
                      paymentMethod: receivableLiquidated.payment_method as PaymentMethod,
                      receivableId: receivableLiquidated.id,
                    }}
                    onSuccess={() => {
                      sendEvent(
                        receivableId,
                        receivableStatus,
                        MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAYMENT_RECEIPT_DOWNLOAD
                      )
                    }}
                  />
                  <Footer
                    isLoading={isLoading || loading}
                    enableSubmit={isFormValid && !isManualLiquidationCreateBlockedForNoAccessRole}
                    onClickGoBack={handleGoBackWithoutSaving}
                    onClick={handleGoBack}
                    type="button"
                    label="Finalizar Recebimento"
                    hideBackButton
                  />
                </>
              )}
            </>
          )}
        </Grid>
      )}
      {showLoadingPage && (
        <>
          <LoadingLiquidation negotiationId={newNegotiationId} />
        </>
      )}
    </S.Wrapper>
  )
}

export default ManualLiquidation
