import { Ordering } from '@/shared/hooks/useOrdering/types'
import { CONTRACTS_STATUS } from '@monorepo/students/pages/Student/components/ContractsSection/constants'
import { Columns } from '@monorepo/students/pages/Student/components/ContractsSection/types'
import { getDebtStatus } from '@monorepo/students/pages/Student/components/ContractsSection/utils/getDebtStatus'
import { StudentContract } from '@monorepo/students/services/students/types'

export const useSortContracts = (contracts: StudentContract[], ordering: Ordering<Columns>) => {
  const sortedContracts = contracts.sort((a, b) => {
    let a1: string, b1: string
    if (ordering.sortBy === 'product.name') {
      a1 = a.product?.name ?? ''
      b1 = b.product?.name ?? ''
    } else if (ordering.sortBy === 'guardian.name') {
      a1 = a.guardian?.name ?? ''
      b1 = b.guardian?.name ?? ''
    } else if (ordering.sortBy === 'debt_status') {
      a1 = a.debt_status ? getDebtStatus(a.debt_status)?.text ?? '' : ''
      b1 = b.debt_status ? getDebtStatus(b.debt_status)?.text ?? '' : ''
    } else if (ordering.sortBy === 'status') {
      a1 = CONTRACTS_STATUS[a.status] ?? ''
      b1 = CONTRACTS_STATUS[b.status] ?? ''
    } else {
      a1 = ordering.sortBy ? a[ordering.sortBy] ?? '' : ''
      b1 = ordering.sortBy ? b[ordering.sortBy] ?? '' : ''
    }

    if (ordering?.sortOrder?.toLowerCase() === 'asc') {
      return a1?.localeCompare(b1) ?? 0
    }
    return b1?.localeCompare(a1) ?? 0
  })

  return {
    sortedContracts,
  }
}
