import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.gravity.spacing[6]};

  padding-bottom: ${props => props.theme.gravity.spacing[8]};
`

export const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10%;
  margin-bottom: 140px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[14]};

  margin-top: 60px;
`
