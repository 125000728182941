import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { ENROLLMENT_DISCLAIMERS } from '../constants/disclaimer'
import { INITIAL_SUPPORTED_CYCLE_YEAR } from '../constants/enrollment'
import { DisclaimersRequest } from '../services/types'
import { useFetchDisclaimers } from './queries/useFetchDisclaimers'

export const useEnrollmentDisclaimer = ({ schoolId, referenceYear }: DisclaimersRequest) => {
  const isDisclaimerEnabled = useUnleashFlag(UnleashFlags.ENABLE_DISCLAIMER)

  const { data } = useFetchDisclaimers(
    {
      schoolId,
      referenceYear,
    },
    {
      enabled: isDisclaimerEnabled && !!schoolId && referenceYear >= INITIAL_SUPPORTED_CYCLE_YEAR,
    }
  )

  const disclaimers: string[] = data?.data.disclaimers ?? []
  const disclaimerMessage = disclaimers.map(key => ENROLLMENT_DISCLAIMERS[key]).find(Boolean)

  return {
    disclaimerMessage,
  }
}
