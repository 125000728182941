import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { TitleProps } from './types'

export const Title = (props: TitleProps) => {
  const { boxAlignItems = 'end' } = props

  const label = 'Contratos'

  return (
    <Box display="flex" justifyContent="space-between" alignItems={boxAlignItems}>
      <Box display="flex" justifyContent="left" alignItems="center">
        <Typography variation="headlineDesktopXsmall">{label}</Typography>
      </Box>
    </Box>
  )
}
