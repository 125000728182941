import { Student } from '../pages/Student'

import { useUnleashFlag, UnleashFlags } from '@/shared/hooks/useUnleashFlag'

import type { RouteDefinition } from '@/shared/core/router'

export const routes: RouteDefinition[] = [
  {
    key: 'STUDENT',
    path: '/alunos/:studentId',
    component: Student,
    exact: true,
    canAccess: () => useUnleashFlag(UnleashFlags.PE_423_ENABLE_NEW_STUDENT_PAGE),
  },
]
