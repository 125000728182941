import { useEffect } from 'react'
import { UseFormReturn, useFieldArray } from 'react-hook-form'

import { roundCents } from '@/shared/utils'

import type {
  Discount,
  FormAddTuitionProps,
} from '@monorepo/students/pages/Student/components/ContractsSection/types'

export const useManageDiscounts = (form: UseFormReturn<FormAddTuitionProps, any>) => {
  const MAX_DISCOUNT_ITEMS = 3
  const totalAmountPaid = form.watch('custom_monthly_amount')

  const defaultDiscountValue: Discount = {
    amount: null,
    description: '',
    days_before_due_date: null,
    due_date_rule: null,
  }

  const { fields: discountFields, append: appendDiscount, remove: removeDiscount } = useFieldArray({
    control: form.control,
    name: 'discounts',
  })

  const isAddDiscountBtnDisabled = discountFields.length >= MAX_DISCOUNT_ITEMS

  const addNewDiscountInput = () => {
    if (isAddDiscountBtnDisabled) return
    appendDiscount(defaultDiscountValue)
  }

  const handleRemoveDiscountClick = (index: number) => {
    return () => removeDiscount(index)
  }

  const onPercentageValueChange = (inputValue: string, discountIndex: number) => {
    const percent = inputValue.replace('%', '')
    const newDiscountAmount = roundCents((totalAmountPaid * +percent) / 100)
    if (isNaN(newDiscountAmount)) return
    form.setValue(`discounts.${discountIndex}.amount`, newDiscountAmount, { shouldValidate: true })
  }

  const validateDiscountAmount = (value: number | null) => {
    if (value === 0 || (value !== null && value >= totalAmountPaid))
      return 'Desconto não pode ser zero ou superior a 100%'
    return true
  }

  useEffect(() => {
    return () => {
      form.resetField('discounts')
    }
  }, [])

  return {
    handleRemoveDiscountClick,
    addNewDiscountInput,
    isAddDiscountBtnDisabled,
    discountFields,
    validateDiscountAmount,
    onPercentageValueChange,
  }
}
