import { Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

import type { DebtStatus } from '@/shared/interfaces'
import StatusBadge, { StatusBadgeColor } from '@/shared/components/StatusBadge'

export const debtParamsDict: PartialRecord<
  DebtStatus,
  { color: StatusBadgeColor; text: string }
> = {
  WARNING: { color: 'warning', text: 'Atenção' },
  UP_TO_DATE: { color: 'success', text: 'Em dia' },
  PENDING: { color: 'grey', text: 'Aguardando' },
  OVERDUE: { color: 'error', text: 'Com pendências' },
}

export const renderDebtStatus = (status: DebtStatus) => {
  const text = debtParamsDict[status]?.text ?? '-'
  return (
    <>
      <StatusBadge color={debtParamsDict[status]?.color ?? 'primary'} />
      <Box ml={1}>
        <Typography variation="bodySmall" color="secondary">
          {text}
        </Typography>
      </Box>
    </>
  )
}
