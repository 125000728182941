import { useEffect, useState } from 'react'
import { LayoutTemplate } from '../../LayoutTemplate'
import { Button } from '@gravity/button'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useFetchOnboardingBatches } from '../../hooks/useFetchOnboardingBatches'
import { useHistory } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import styled from 'styled-components'
import { useUploadOnboardingFileMutation } from '../../hooks/useUploadOnboardingFileMutation'
import { FileUpload } from '../components/FileUpload'
import { LoadingPage } from '../LoadingPage'
import { GenericError } from '../components/GenericError'
import { EventProvider, useEvents } from '../../hooks/eventContext'

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PageComponent = () => {
  const { school, schoolSlug } = useSelectedSchool()
  const schoolId = school?.id ?? ''
  const [file, setFile] = useState<File | null>(null)

  const history = useHistory()
  const events = useEvents()

  const { toast } = useToast()

  const {
    mutateAsync: executeUploadOnboardingFileMutation,
    isLoading: isLoadingUpload,
  } = useUploadOnboardingFileMutation({
    onError: () => {
      toast({
        title: 'Erro ao tentar importar planilha',
        type: 'error',
      })
    },
  })

  const { isLoading, isError } = useFetchOnboardingBatches(
    { schoolId: school?.id ?? '' },
    {
      onSuccess(data) {
        if (data.length === 0) return

        const onboardingBatch = data[0]
        if (onboardingBatch.status === 'PROCESSING')
          return history.push(`/${schoolSlug}/migrar-dados/${onboardingBatch.id}/migrar`)

        history.push(`/${schoolSlug}/migrar-dados/${onboardingBatch.id}/validar`)
      },
      onError() {
        toast({
          title: 'Erro ao tentar recuperar lote em andamento',
          type: 'error',
        })
      },
      retry: false,
    }
  )

  const handleFileSubmit = async () => {
    try {
      if (!file || !schoolId) return

      events?.startUploadButtonClicked()

      const onboardingBatch = await executeUploadOnboardingFileMutation({
        file,
        schoolId,
      })

      return history.push(`/${schoolSlug}/migrar-dados/${onboardingBatch.id}/validar`)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    events?.uploadPageViewed()
  }, [])

  if (isLoading) {
    return <LoadingPage />
  }

  if (isError) {
    return <GenericError />
  }

  return (
    <LayoutTemplate
      title="Importar planilha de contratos"
      description="Ao importar a sua planilha, a Plataforma isaac vai identificar dados cadastrais de alunos e responsáveis, informações de plano de pagamento de produtos e serviços."
      footer={
        <>
          <Button disabled={!file} onClick={handleFileSubmit} loading={isLoadingUpload}>
            Começar
          </Button>
        </>
      }
    >
      <ContentWrapper>
        <FileUpload file={file} setFile={setFile} />
      </ContentWrapper>
    </LayoutTemplate>
  )
}

export const UploadPage = () => {
  return (
    <EventProvider>
      <PageComponent />
    </EventProvider>
  )
}
