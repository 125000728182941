import { useFetchChargesQuery } from '@monorepo/onboarding/hooks/useFetchChargesQuery'
import { usePagination } from '@/shared/hooks/usePagination'
import { Accordion } from '@gravity/accordion'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import Icon from '@mdi/react'
import { mdiAccountSchoolOutline, mdiAccountOutline } from '@mdi/js'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ChargeTable } from './ChargeTable'
import { Product } from '@monorepo/onboarding/services/types/onboardingProduct'
import { GenericError } from '../GenericError'
import { NoInvalidData } from '../NoInvalidData'
import { Loading } from './Loading'
import { PaginationFooter } from '../PaginationFooter'
import { Table } from '@gravity/table'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { useState, useEffect } from 'react'
import { EditChargeDialog } from './EditChargeDialog'
import { Charge } from '@monorepo/onboarding/services/types/onboardingCharge'

const DEFAULT_ITEMS_PER_PAGE = 10

export const ChargeContractWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const AccordionTriggerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

const AccordionTriggerDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`

const AccordionTriggerDetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const composeFullProductName = (product: Product) => {
  const { reference_year, course, grade } = product

  return [reference_year, course, grade].join(' - ')
}

export const ContractCharges = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const { pagination, updatePaginationValue } = usePagination(1, DEFAULT_ITEMS_PER_PAGE)
  const [selectedCharge, setSelectedCharge] = useState<Charge | null>(null)
  const events = useEvents()

  useEffect(() => {
    events?.contractChargesTabViewed()
  }, [])

  const params = {
    batchID,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  }

  const { data, isError, isLoading } = useFetchChargesQuery(params)

  const contracts = data?.data

  if (isError) return <GenericError />

  if (isLoading || !contracts) {
    return <Loading />
  }

  if (contracts.length === 0) return <NoInvalidData />

  return (
    <Accordion.Root type="multiple">
      <ChargeContractWrapper>
        {contracts?.map(contract => (
          <div key={contract.id}>
            <Accordion.Item value={contract.id}>
              <Accordion.Trigger>
                <AccordionTriggerWrapper>
                  <Heading variant="heading-h4-medium" style={{ textTransform: 'capitalize' }}>
                    {composeFullProductName(contract.product)}
                  </Heading>

                  <AccordionTriggerDetails>
                    <AccordionTriggerDetailsItem>
                      <Icon path={mdiAccountOutline} size="16px" />
                      <Text variant="body-2-regular">
                        Responsável financeiro: {contract?.guardian?.name}
                      </Text>
                    </AccordionTriggerDetailsItem>
                    <AccordionTriggerDetailsItem>
                      <Icon path={mdiAccountSchoolOutline} size="16px" />
                      <Text variant="body-2-regular">Aluno: {contract?.student?.name}</Text>
                    </AccordionTriggerDetailsItem>
                  </AccordionTriggerDetails>
                </AccordionTriggerWrapper>
              </Accordion.Trigger>
              <Accordion.Content>
                <ChargeTable
                  charges={contract.charges}
                  setSelectedCharge={(charge: Charge | null) => setSelectedCharge(charge)}
                />
              </Accordion.Content>
            </Accordion.Item>
          </div>
        ))}

        <Table.Root>
          <PaginationFooter
            pagination={pagination}
            total={data?.pagination?.total ?? 0}
            updatePaginationValue={updatePaginationValue}
          />
        </Table.Root>
      </ChargeContractWrapper>

      {selectedCharge && (
        <EditChargeDialog
          selectedCharge={selectedCharge}
          setSelectedCharge={(charge: Charge | null) => setSelectedCharge(charge)}
          params={params}
        />
      )}
    </Accordion.Root>
  )
}
