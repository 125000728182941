import styled from 'styled-components'

export const Container = styled.main`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-1']};
  overflow: hidden;

  .grid-container {
    align-content: center;
  }

  .grid-item {
    z-index: 10;
  }

  .content-item {
    display: block;

    @media (max-width: 600px) {
      display: none;

      &[data-disclaimer-dismissed='true'] {
        display: block;
      }
    }
  }

  .mobile-disclaimer-item {
    display: none;

    @media (max-width: 600px) {
      display: block;
    }
  }

  > img {
    z-index: 5;
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width: 600px) {
      bottom: -150px;
    }
  }
`
