import { useReducer, useState } from 'react'
import { assoc } from 'ramda'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Button } from '@gravity/button'

import { COLUMN_HEADERS, ProductsTable, SortableColumns } from './components/ProductsTable'
import { CreateOrEditProductDrawer } from './components/CreateOrEditProductDrawer'
import { DeleteProductDialog } from './components/DeleteProductDialog'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/shared/components/ConfirmationDialog'

import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { useLayout } from '@/shared/hooks/useLayout'
import { usePagination } from '@/shared/hooks/usePagination'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { useDrawerWidth } from '@/shared/hooks/useDrawerWidth'
import { useProducts } from '../../hooks/queries/products'
import { useJWT } from '@/shared/hooks/useJWT'

import { calculateTotalAmount } from '@monorepo/products/utils/calculateTotalAmount'

import type { Product } from '@/shared/interfaces/product'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventIdentifierName } from '@monorepo/products/models/EventIdentifierName.enum'
import { EventPageName } from '@monorepo/products/models/EventPageName.enum'

import { Container, Content } from './styles'

export const Products = () => {
  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Produtos' })

  const { isBackofficeUser } = useJWT()

  const { school } = useSelectedSchool()
  const { drawerWidth } = useDrawerWidth()
  const { pagination, updatePaginationValue } = usePagination(1, 25)
  const { ordering, updateOrdering } = useOrdering<SortableColumns>({
    sortBy: 'name',
    sortOrder: 'ASC',
  })
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { sendHotjarEvent } = useHotjar()

  // TODO: Handle request error
  const { data, isFetching } = useProducts(
    {
      schoolId: school?.id ?? '',
      pagination: {
        page: pagination.page,
        per_page: pagination.itemsPerPage,
        sort_by: ordering.sortBy,
        sort_order: ordering.sortOrder,
      },
    },
    { enabled: !!school?.id, keepPreviousData: true }
  )

  const productsWithTotalAmount =
    data?.data.map(product => assoc('total_amount', calculateTotalAmount(product), product)) ?? []

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)

  const [isDrawerOpen, toggleIsDrawerOpen] = useReducer(state => !state, false)
  const [isDeleteDialogVisible, toggleIsDeleteDialogVisible] = useReducer(state => !state, false)
  const [isFailureFeedbackDialogVisible, toggleIsFailureFeedbackDialogVisible] = useReducer(
    state => !state,
    false
  )

  const handleClickAddProductButton = () => {
    sendHotjarEvent(HotjarEvents.ADD_PRODUCT)
    setSelectedProduct(null)
    toggleIsDrawerOpen()
  }

  return (
    <Container isCollapsed={isDrawerOpen} drawerWidth={drawerWidth}>
      {isBackofficeUser && (
        <div className="new-product-button">
          <Button size={2} onClick={handleClickAddProductButton}>
            Novo produto
          </Button>
        </div>
      )}
      <Content>
        <ProductsTable
          products={productsWithTotalAmount}
          isLoading={isFetching}
          ordering={ordering}
          pagination={{
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            total: data?.pagination?.total ?? 0,
          }}
          onChangePage={page => updatePaginationValue('page', page)}
          onChangeItemsPerPage={itemsPerPage => updatePaginationValue('itemsPerPage', itemsPerPage)}
          onChangeOrdering={(sortBy, sortOrder) => {
            updatePaginationValue('page', 1)
            updateOrdering({ sortBy, sortOrder })

            isInitialized &&
              eventDispatcherClient.sendEvent({
                name: EventDispatcherEvents.TABLE_ORDERING,
                scope: EventDispatcherEventScopes.PRODUCTS,
                pageName: EventPageName.PRODUCTS,
                identifierName: EventIdentifierName.SORT_TABLE_COLUMN,
                customProperties: {
                  $sort_by: COLUMN_HEADERS.find(column => column.name === sortBy)?.label,
                  $sort_order: sortOrder,
                },
              })
          }}
          onRequestEdit={product => {
            if (isDrawerOpen) return

            setSelectedProduct(product)
            toggleIsDrawerOpen()

            isInitialized &&
              eventDispatcherClient.sendEvent({
                name: EventDispatcherEvents.BUTTON_CLICK,
                scope: EventDispatcherEventScopes.PRODUCTS,
                pageName: EventPageName.PRODUCTS,
                identifierName: EventIdentifierName.OPEN_PRODUCT_EDITING,
                customProperties: {
                  $button_label: 'Editar',
                  $button_action: 'Abrir gaveta de edição de produtos',
                },
              })
          }}
          onRequestDelete={product => {
            setSelectedProduct(product)
            toggleIsDeleteDialogVisible()

            isInitialized &&
              eventDispatcherClient.sendEvent({
                name: EventDispatcherEvents.BUTTON_CLICK,
                scope: EventDispatcherEventScopes.PRODUCTS,
                pageName: EventPageName.PRODUCTS,
                identifierName: EventIdentifierName.OPEN_DIALOG_TO_DELETE_PRODUCT,
                customProperties: {
                  $button_label: 'Excluir',
                  $button_action: 'Abrir dialog de confirmação de exclusão de produtos',
                },
              })
          }}
        />
      </Content>

      <CreateOrEditProductDrawer
        open={isDrawerOpen}
        onClose={toggleIsDrawerOpen}
        schoolId={school?.id ?? ''}
        product={selectedProduct}
      />

      <DeleteProductDialog
        selectedProduct={selectedProduct}
        isVisible={isDeleteDialogVisible}
        setIsVisible={toggleIsDeleteDialogVisible}
      />

      <FailureFeedbackDialog
        isVisible={isFailureFeedbackDialogVisible}
        onClose={toggleIsFailureFeedbackDialogVisible}
        submitHandler={toggleIsFailureFeedbackDialogVisible}
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>
    </Container>
  )
}
