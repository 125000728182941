import styled from 'styled-components'
import { Button } from '@gravity/button'

export const AddDiscountBtn = styled(Button)`
  margin-top: ${({ theme }) => theme.gravity.spacing[8]};
  max-width: 50%;
`

export const Separator = styled.div`
  margin: ${({ theme }) => theme.gravity.spacing[8]} 0;
  background: ${({ theme }) => theme.gravity.colors['colors-border-neutral-2']};
  width: 100%;
  height: 1px;
`

export const DiscountList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing[8]};
`
