import { InstallmentDrawerProps } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import AddDiscountContent from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/AddDiscountContent'
import { DrawerVariationsProps } from '@/modules/guardians/InstallmentsDrawerContainer/variations'
import { InstallmentDrawerHeader } from '@/modules/guardians/InstallmentsDrawerContainer/components/InstallmentDrawerHeader/InstallmentDrawerHeader'
import { parseInstallmentToReceivable } from '@/modules/guardians/utils/parseInstallmentToReceivable'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'
import { useQuery } from '@/shared/hooks/useQuery'

export const addDiscount = ({
  installment: installmentInfo,
  onCloseDrawer,
}: InstallmentDrawerProps): DrawerVariationsProps => {
  const { setOnQueryParam } = useQuery()
  const receivable = parseInstallmentToReceivable(installmentInfo)
  const { setSelectedDrawer } = useInstallmentDrawerContext()

  const handleToDefaultDrawer = (): void => {
    setSelectedDrawer(null)
  }

  const handleSuccessCallBack = (): void => {
    setSelectedDrawer(null)
    setOnQueryParam('true', 'refetch', 'replace')
    onCloseDrawer()
  }

  const header = (
    <InstallmentDrawerHeader
      installment={installmentInfo}
      title="Mensalidade"
      subTitle="Editar descontos"
      onClose={onCloseDrawer}
      onBack={handleToDefaultDrawer}
    />
  )

  const fullContent = (
    <AddDiscountContent
      onClose={onCloseDrawer}
      onFinish={handleToDefaultDrawer}
      receivables={[receivable]}
      selectedReceivableId={receivable.id}
      setSelectedReceivableId={() => null}
      successCallback={handleSuccessCallBack}
    />
  )

  return {
    header,
    fullContent,
  }
}
