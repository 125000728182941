import { useEffect, useState } from 'react'

import { useQuery } from '@/shared/hooks/useQuery'

import { ContractsFilter, ContractsFiltersType } from './types'

const parseArrayToFilter = <T>(filter: string | undefined) => {
  return (!filter || filter === '' ? [] : filter.split(',')) as T[]
}

enum ContractsQueryParamsNameEnum {
  years = 'reference_years',
}

/**
 * @description
 */
export const useQueryParamFilters = (initialValues?: string[]) => {
  const { query, setMultipleQueryParams } = useQuery()

  const [filtersParams, setFiltersParams] = useState<ContractsFiltersType>({
    reference_years: parseArrayToFilter<string>(
      query.get(ContractsQueryParamsNameEnum.years) ?? initialValues?.toString()
    ),
  })

  const clearParams = () => {
    setFiltersParams({
      reference_years: [],
    })
  }

  const updateFiltersParams = (filter: ContractsFilter) => {
    setFiltersParams(prevState => ({
      ...prevState,
      ...filter,
    }))
  }

  const removeFiltersParams = (filter: ContractsFilter) => {
    setFiltersParams(prevState => {
      const newState = { ...prevState }

      filter.reference_years?.forEach(year => {
        newState.reference_years = newState.reference_years.filter(
          referenceYear => referenceYear !== year
        )
      })

      return newState
    })
  }

  const filtersToQueryParams = (filters: ContractsFiltersType) =>
    Object.keys(filters).map(key => ({
      name: key,
      value: filters[key as keyof ContractsFiltersType].join(','),
    }))

  useEffect(() => {
    setMultipleQueryParams([...filtersToQueryParams(filtersParams)], 'replace')
  }, [filtersParams])

  return {
    filtersParams,
    updateFiltersParams,
    clearParams,
    removeFiltersParams,
  }
}
