import { useStudentsEnrollmentsFilter } from '@/modules/enrollment/hooks/useStudentsEnrollmentsFilter'
import { useMemo, useState } from 'react'
import {
  filterForm,
  filterSchema,
} from '@/modules/enrollment/pages/SchoolEnrollmentCycle/schemas/filterSchemas'
import { useFilterOptions } from './useFilterOptions'
import { useForm } from 'react-hook-form'
import type { SubmitHandler } from 'react-hook-form'
import { TableFilters } from '../types'
import { ReenrollmentStatus, ReEnrollmentSubStatus } from '@/shared/interfaces'
import { zodResolver } from '@hookform/resolvers/zod'
import { FilterChip } from '@/modules/enrollment/pages/SchoolEnrollmentCycle/components/FilterChip'
import { StudentsEnrollmentsFilterType } from '@/modules/enrollment/hooks/useStudentsEnrollmentsFilter/types'

export const useEnrollmentFilters = (selectedCycle: number) => {
  const { statusOptions, subStatusOptions } = useFilterOptions(selectedCycle)
  const [isFilterDialogOpen, toggleIsFilterDialogOpen] = useState(false)
  const { studentsEnrollmentsFilter, updateFilter, clearFilter } = useStudentsEnrollmentsFilter()

  const emptyForm: filterForm = {
    enrollment_status: statusOptions.map(status => {
      return {
        ...status,
        selected: false,
      }
    }),
    enrollment_sub_status: subStatusOptions.map(subStatusOption => {
      return {
        ...subStatusOption,
        selected: false,
      }
    }),
    enrollmentStatus: [],
    enrollmentSubStatus: [],
  }
  const form = useForm<filterForm>({
    mode: 'all',
    defaultValues: emptyForm,
    resolver: zodResolver(filterSchema),
  })

  const { control, setValue } = form

  const handleOpenFilterDialog = () => {
    toggleIsFilterDialogOpen(true)
  }

  const handleCloseFilterDialog = () => {
    toggleIsFilterDialogOpen(false)
  }

  const handleClearFilter = () => {
    setValue(
      'enrollment_status',
      statusOptions.map(status => {
        return {
          ...status,
          selected: false,
        }
      })
    )
    setValue(
      'enrollment_sub_status',
      subStatusOptions.map(subStatusOption => {
        return {
          ...subStatusOption,
          selected: false,
        }
      })
    )
    clearFilter()
    toggleIsFilterDialogOpen(false)
  }

  const handleRemoveFilter = (filter: TableFilters) => {
    const convertedFilter = filter.value
    if (statusOptions.map(e => e.value).includes(convertedFilter)) {
      setValue(
        'enrollment_status',
        form.getValues().enrollment_status?.map(status => {
          if (status.value !== convertedFilter) return status
          return {
            ...status,
            selected: false,
          }
        })
      )
    }

    if (subStatusOptions.map(e => e.value).includes(convertedFilter as ReEnrollmentSubStatus)) {
      setValue(
        'enrollment_sub_status',
        form.getValues().enrollment_sub_status?.map(status => {
          if (status.value !== convertedFilter) return status
          return {
            ...status,
            selected: false,
          }
        })
      )
    }
    const filtersToUpdate = {
      enrollmentStatus: form.getValues().enrollment_status
        ? form.getValues().enrollment_status?.reduce<string[]>((acc, status) => {
            if (status.selected) acc.push(status.value)
            return acc
          }, [])
        : [],
      enrollmentSubStatus: form.getValues().enrollment_sub_status
        ? form.getValues().enrollment_sub_status?.reduce<string[]>((acc, status) => {
            if (status.selected) acc.push(status.value)
            return acc
          }, [])
        : [],
    }
    updateFilter(filtersToUpdate as StudentsEnrollmentsFilterType)
  }

  const filtersArray = useMemo<TableFilters[]>(() => {
    const enrollmentStatusFilters =
      form
        .getValues()
        .enrollment_status?.filter(status => status.selected)
        .map(status => ({
          text: status.label || '',
          value: status.value || '',
        })) || []

    const enrollmentSubStatusFilters =
      form
        .getValues()
        .enrollment_sub_status?.filter(subStatus => subStatus.selected)
        .map(subStatus => ({
          text: subStatus.label || '',
          value: subStatus.value || '',
        })) || []

    return [...enrollmentStatusFilters, ...enrollmentSubStatusFilters]
  }, [form.getValues().enrollment_status, form.getValues().enrollment_sub_status])

  const onSubmitFilters: SubmitHandler<filterForm> = ({
    enrollmentStatus,
    enrollmentSubStatus,
  }) => {
    updateFilter({
      enrollmentStatus: enrollmentStatus as Omit<
        ReenrollmentStatus,
        ReenrollmentStatus.GATE_ERROR
      >[],
      enrollmentSubStatus: enrollmentSubStatus as ReEnrollmentSubStatus[],
    })
    toggleIsFilterDialogOpen(false)
  }

  const filtersGroup = [
    {
      id: 0,
      title: 'Situação de matrícula',
      component: <FilterChip control={control} filterType="enrollment_status" />,
    },
    {
      id: 1,
      title: 'Detalhamento',
      component: <FilterChip control={control} filterType="enrollment_sub_status" />,
    },
  ]

  return {
    isFilterDialogOpen,
    statusOptions,
    subStatusOptions,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleClearFilter,
    handleRemoveFilter,
    onSubmitFilters,
    filtersArray,
    filtersGroup,
    studentsEnrollmentsFilter,
    form,
    setValue,
  }
}
