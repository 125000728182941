import { SchoolHome } from '../pages/SchoolHome'

import type { RouteDefinition } from '@/shared/core/router'

export const routes: RouteDefinition[] = [
  {
    key: 'SCHOOL_HOME',
    path: '/',
    component: SchoolHome,
    exact: true,
  },
]
