import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding: ${spacing[6]};
  border-top: 1px solid #dadada;
  gap: ${spacing[4]};
`
