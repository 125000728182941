import { IrregularityTable } from './IrregularityTable'
import { IrregularityTableTitle, TextWithDescription } from './IrregularityTableTitle'
import monetizationOnOutline from '@/shared/assets/monetization-on-outline.svg'

import {
  InstallmentIrregularityType,
  InstallmentType,
  IrregularityParams,
  installmentIrregularityParamsDict,
  installmentTypeParamsDict,
} from '../types'
import {
  InstallmentRequest,
  InstallmentIrregularityTableItem,
} from '@/modules/enrollment/services/types'

import {
  IrregularityTableBaseboard,
  ReferenceLink,
  ReferenceMessage,
} from './IrregularityTableBaseboard'

import { Text } from '@gravity/text'
import { IrregularityHeaderType } from './IrregularityTable/types'
import { useSortOrder } from '../hooks/useSortOrder'
import { mdiCheckCircleOutline, mdiTimerSand } from '@mdi/js'
import Icon from '@mdi/react'
import styled from 'styled-components'
import { colors } from '@gravity/tokens'

interface InstallmentIrregularityTableProps {
  externalId: string
  installmentRequests: InstallmentRequest[]
  referenceLink: string
}

interface InstallmentIrregularity {
  irregularity_type: string
  resolved_at: string
  type: string
}

const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

const neededActionColumn = {
  key: 'needed_action_column',
  label: 'Ação necessária',
  sortable: false,
}
let shouldShowConcludedMessage = false

const COLUMN_HEADERS: Array<IrregularityHeaderType> = [
  { key: 'pending_column', label: 'Pendência', sortable: true },
  { key: 'installments_column', label: 'Parcelas', sortable: false },
  neededActionColumn,
  { key: 'resolved_column', label: 'Status da ação', sortable: false },
]

export const getResolvedColumn = (isResolved: boolean): JSX.Element => {
  return isResolved ? (
    <CenteredDiv>
      <Icon path={mdiCheckCircleOutline} size={1} color={colors['colors-text-main-3']} />
      <Text variant="body-1-regular">Concluída</Text>
    </CenteredDiv>
  ) : (
    <CenteredDiv>
      <Icon path={mdiTimerSand} size={1} color={colors['colors-text-main-3']} />
      <Text variant="body-1-regular">Pendente</Text>
    </CenteredDiv>
  )
}

const Row = ({
  installment_request_type,
  irregularities,
  irregularity_type,
}: InstallmentIrregularityTableItem) => {
  const irregularityTranslation =
    installmentIrregularityParamsDict[irregularity_type as InstallmentIrregularityType]
  const installmentTypeTranslation =
    installmentTypeParamsDict[installment_request_type as InstallmentType]
  const rowKey = `${installment_request_type}-${irregularity_type}`

  const occurrences = irregularities.filter(
    irregularity => irregularity.resolved_at === null || irregularity.resolved_at === ''
  ).length

  let message = `${occurrences} parcela irregular`
  if (occurrences > 1) {
    message = `${occurrences} parcelas irregulares`
  }

  const textWithDescriptionKey = `${rowKey}-${occurrences}`

  return {
    key: rowKey,
    resolved: occurrences === 0,
    columns: [
      <Text key={textWithDescriptionKey} variant="body-1-regular">
        {irregularityTranslation?.name}
      </Text>,
      <TextWithDescription
        key={textWithDescriptionKey}
        description={message}
        text={installmentTypeTranslation || ''}
      />,
      <Text key={textWithDescriptionKey} variant="body-1-regular">
        {irregularityTranslation?.action}
      </Text>,
      getResolvedColumn(occurrences === 0),
    ],
  }
}

function makeRows(
  installmentRequests: InstallmentRequest[],
  sortRows: (
    rows: InstallmentIrregularityTableItem[],
    dict: Record<string, IrregularityParams>
  ) => void
) {
  const installmentRequestWithIrregularity: InstallmentIrregularity[] = []

  installmentRequests.forEach(installmentRequest => {
    const irregularities = installmentRequest.installment_irregularities || []
    irregularities.forEach(irregularity => {
      installmentRequestWithIrregularity.push({
        type: installmentRequest.type,
        irregularity_type: irregularity.irregularity_type,
        resolved_at: irregularity.resolved_at,
      })
    })
  })

  let installmentIrregularityTableItemsMap: { [key: string]: InstallmentIrregularityTableItem } = {}
  installmentIrregularityTableItemsMap = installmentRequestWithIrregularity.reduce(
    (acc: { [key: string]: InstallmentIrregularityTableItem }, item) => {
      const key = `${item.type}-${item.irregularity_type}`
      if (!acc[key]) {
        acc[key] = {
          installment_request_type: item.type,
          irregularity_type: item.irregularity_type,
          irregularities: [],
        }
      }
      acc[key].irregularities.push({
        irregularity_type: item.irregularity_type,
        resolved_at: item.resolved_at,
      })
      return acc
    },
    {}
  )

  const tableItems = Object.values(installmentIrregularityTableItemsMap)

  sortRows(tableItems, installmentIrregularityParamsDict)

  const rows = Object.values(tableItems).map(item => Row(item))

  const allIrregularitiesAreResolved = rows.reduce((isResolved, row) => {
    isResolved &&= row.resolved
    return isResolved
  }, true)

  shouldShowConcludedMessage = rows.length > 0 && allIrregularitiesAreResolved
  if (shouldShowConcludedMessage) {
    neededActionColumn.label = 'Ação feita'
  }

  return rows
}

const getActionMessage = (referenceLink: string, externalId: string): JSX.Element => {
  return referenceLink ? (
    <ReferenceLink url={referenceLink} />
  ) : (
    <ReferenceMessage
      identifier="ID do Aluno"
      copyable={externalId}
      copyableTooltip="ID do aluno copiado"
    />
  )
}

export const InstallmentIrregularityTable = ({
  externalId,
  installmentRequests,
  referenceLink,
}: InstallmentIrregularityTableProps) => {
  const { order, updateOrder, sortRows } = useSortOrder()

  return (
    <div>
      <IrregularityTableTitle
        title="Plano de pagamento"
        icon={<img src={monetizationOnOutline} />}
      />
      <IrregularityTable
        rows={makeRows(installmentRequests, sortRows)}
        columnHeaders={COLUMN_HEADERS}
        ordering={order}
        onChangeOrdering={updateOrder}
      />
      <IrregularityTableBaseboard
        callToActionMsg={
          shouldShowConcludedMessage
            ? 'Dados corrigidos no seu sistema'
            : 'Corrija os dados no seu sistema'
        }
        actionElement={
          shouldShowConcludedMessage ? <></> : getActionMessage(referenceLink, externalId)
        }
      />
    </div>
  )
}
