import { useHistory, useLocation } from 'react-router-dom'
import { Wrapper, TopPageWrapper } from './styles'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { replaceTabInPathname } from './utils/replaceTabInPathname'
import { useQuery } from '@/shared/hooks/useQuery'
import { GuardianDetailsTabs } from './constants'

import { GuardianDetailsContractsTab } from '../GuardianDetailsContractsTab/GuardianDetailsContractsTab'
import { GuardianDetailsFinancialSummaryTab } from '../GuardianDetailsFinancialSummaryTab/GuardianDetailsFinancialSummaryTab'

import { GuardianDetailsCard } from './components/GuardianDetailsCard'
import { getParamGuardianId } from './utils/getParamGuardianId'
import { useGuardianQuery } from './hooks/useGuardianQuery'
import {
  StateQueryParamsEnum,
  useGuardiansStateQueryParams,
} from '../hooks/useGuardiansStateQueryParams'
import EditRegistrationDrawer from './features/EditRegistrationDrawer/EditRegistrationDrawer'

import { PageWithTableControlBar } from '@/escolas/components/layout/PageWithTableControlBar'

import { FeedbacksCashier } from './features/FeedbacksCashier/FeedbacksCashier'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { UserGuidingGuidesIds } from '@/shared/hooks/useUserGuidingTrigger'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { GuardiansQueryParamsNameEnum } from '../constants'
import { ContractProvider } from '@/shared/contexts/contractContext'
import { queryClient } from '@/shared/contexts/ReactQuery'
import { PendingEnrollmentProcessingAlert } from './components/PendingEnrollmentProcessingAlert'
import { GuardianDetailsHeader } from './components/GuardianDetailsHeader'
import { GuardianDetailsCompactedHeader } from './components/GuardianDetailsCompactedHeader'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { Text } from '@gravity/text'
import { Tabs } from '@gravity/tabs'
import { mdiWalletOutline, mdiListBoxOutline, mdiFolderOutline, mdiCalendarWeek } from '@mdi/js'
import { Icon } from '@mdi/react'
import { GuardianDetailsInvoicesTab } from '@/modules/guardians/GuardianDetailsInvoicesTab'
import { GuardianDetailsHistoryTab } from '@/modules/guardians/GuardianDetailsHistoryTab'
import { GenericError } from '@/shared/components/GenericError'

type NewTabsProps = {
  activeTab: GuardianDetailsTabs
  onChangeFinancialSummaryTab: (tab: GuardianDetailsTabs) => void
  onChangeTab: (tab: GuardianDetailsTabs) => void
  setShowGeneratingInvoicesLoading: Dispatch<SetStateAction<boolean>>
  setShowPendingEnrollmentProcessingAlert: Dispatch<SetStateAction<boolean>>
  showGeneratingInvoicesLoading: boolean
  showPendingEnrollmentProcessingAlert: boolean
}

const TabsTriggers = () => (
  <Tabs.List background="gray">
    <Tabs.Trigger value={GuardianDetailsTabs.RESUMO} iconStart={<Icon path={mdiWalletOutline} />}>
      <Text variant="button-2" className="text-inherit">
        Resumo financeiro
      </Text>
    </Tabs.Trigger>

    <Tabs.Trigger value={GuardianDetailsTabs.FATURAS} iconStart={<Icon path={mdiListBoxOutline} />}>
      <Text variant="button-2" className="text-inherit">
        Faturas
      </Text>
    </Tabs.Trigger>

    <Tabs.Trigger
      value={GuardianDetailsTabs.CONTRATOS}
      iconStart={<Icon path={mdiFolderOutline} />}
    >
      <Text variant="button-2" className="text-inherit">
        Contratos
      </Text>
    </Tabs.Trigger>

    <Tabs.Trigger value={GuardianDetailsTabs.HISTORICO} iconStart={<Icon path={mdiCalendarWeek} />}>
      <Text variant="button-2" className="text-inherit">
        Histórico
      </Text>
    </Tabs.Trigger>
  </Tabs.List>
)

const NewTabs = ({
  activeTab,
  showPendingEnrollmentProcessingAlert,
  setShowPendingEnrollmentProcessingAlert,
  setShowGeneratingInvoicesLoading,
  onChangeTab,
  onChangeFinancialSummaryTab,
}: NewTabsProps) => (
  <Tabs.Root
    value={activeTab}
    onValueChange={value => onChangeTab(value as GuardianDetailsTabs)}
    style={{ marginTop: '32px' }}
  >
    <TabsTriggers />

    <Tabs.Content
      value={GuardianDetailsTabs.RESUMO}
      key={GuardianDetailsTabs.RESUMO + '-tab-content'}
      tabIndex={-1}
    >
      <GuardianDetailsFinancialSummaryTab changeActiveTab={onChangeFinancialSummaryTab} />
    </Tabs.Content>

    <Tabs.Content
      value={GuardianDetailsTabs.FATURAS}
      key={GuardianDetailsTabs.FATURAS + '-tab-content'}
      tabIndex={-1}
    >
      <FeedbacksCashier
        setShowGeneratingInvoicesLoading={value => setShowGeneratingInvoicesLoading(value)}
      />
      {showPendingEnrollmentProcessingAlert && <PendingEnrollmentProcessingAlert />}

      <PageWithTableControlBar>
        <GuardianDetailsInvoicesTab
          setShowPendingEnrollmentProcessingAlert={setShowPendingEnrollmentProcessingAlert}
        />
      </PageWithTableControlBar>
    </Tabs.Content>

    <Tabs.Content
      value={GuardianDetailsTabs.CONTRATOS}
      key={GuardianDetailsTabs.CONTRATOS + '-tab-content'}
      tabIndex={-1}
    >
      <GuardianDetailsContractsTab />
    </Tabs.Content>

    <Tabs.Content
      value={GuardianDetailsTabs.HISTORICO}
      key={GuardianDetailsTabs.HISTORICO + '-tab-content'}
      tabIndex={-1}
    >
      <GuardianDetailsHistoryTab />
    </Tabs.Content>
  </Tabs.Root>
)

export const GuardianDetails = () => {
  const history = useHistory()

  const { query, setOnQueryParam } = useQuery()
  const { pathname, search } = useLocation()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const headerRef = useRef<HTMLDivElement>(null)

  const guardianId = getParamGuardianId()

  const {
    guardian,
    isFetchGuardianLoading,
    isFetchGuardianFetched,
    isFetchGuardianError,
    refetchGuardian,
  } = useGuardianQuery({ guardianId, schoolId: school?.id ?? '' })

  const {
    statesQueryParams,
    handleEditRegistrationDrawerClose,
    openEditRegistrationDrawer,
  } = useGuardiansStateQueryParams()

  const [showGeneratingInvoicesLoading, setShowGeneratingInvoicesLoading] = useState<boolean>(false)
  const [
    showPendingEnrollmentProcessingAlert,
    setShowPendingEnrollmentProcessingAlert,
  ] = useState<boolean>(false)
  const abrirCadastro = Boolean(statesQueryParams.get(StateQueryParamsEnum.ABRIR_CADASTRO))

  useSetPageTitle(guardian ? `${guardian.data.name} - isaac` : `Responsáveis - isaac`)

  const refreshData = () => {
    refetchGuardian()
    handleEditRegistrationDrawerClose()
  }

  const [activeTab, setActiveTab] = useState<GuardianDetailsTabs>(() => {
    const tab = Object.values(GuardianDetailsTabs).find(substring => pathname.includes(substring))
    return tab ?? GuardianDetailsTabs.RESUMO
  })

  const defaultTabsSearchParams = {
    [GuardianDetailsTabs.RESUMO]: '',
    [GuardianDetailsTabs.CONTRATOS]: '',
    [GuardianDetailsTabs.HISTORICO]: '',
    [GuardianDetailsTabs.FATURAS]: '',
  }

  const [tabsSearchParams, setTabsSearchParams] = useState(defaultTabsSearchParams)

  useEffect(() => {
    const foundTab = Object.values(GuardianDetailsTabs).find(tab => pathname.includes(tab))

    if (foundTab === GuardianDetailsTabs.RESUMO) {
      queryClient.invalidateQueries(['guardian-finance-summary'])
    }

    const currentTab = { [foundTab ?? '']: search }
    setTabsSearchParams(tabs => ({ ...tabs, ...currentTab }))

    setActiveTab(foundTab ?? GuardianDetailsTabs.RESUMO)
  }, [pathname, search])

  const sendEvent = (scope: EventDispatcherEventScopes, name: EventDispatcherEvents) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        action: 'click',
      })
  }

  const getEventName = (value: GuardianDetailsTabs) => {
    if (value === GuardianDetailsTabs.FATURAS)
      return EventDispatcherEvents.GUARDIAN_INSTALLMENTS_LIST
    if (value === GuardianDetailsTabs.CONTRATOS) return EventDispatcherEvents.GUARDIAN_CONTRACT_LIST
  }

  const handleActiveTab = (value: GuardianDetailsTabs) => {
    setActiveTab(value)

    const eventName = getEventName(value)

    if (eventName) {
      sendEvent(EventDispatcherEventScopes.GUARDIANS, eventName)
    }

    const newPath = replaceTabInPathname(pathname, value)
    const newTabSearch = tabsSearchParams[value] as string

    history.replace({
      pathname: newPath,
      search: newTabSearch,
    })

    window.scrollTo(0, 0)
  }

  const guideId = isIntegratedSchool
    ? UserGuidingGuidesIds.GUARDIAN_DETAILS_PAGE_INTEGRATED_SCHOOL
    : UserGuidingGuidesIds.GUARDIAN_DETAILS_PAGE

  useEffect(() => {
    setOnQueryParam(guideId, GuardiansQueryParamsNameEnum.guide, 'replace')
  }, [isIntegratedSchool, activeTab])

  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (shouldRefetch) {
      queryClient.invalidateQueries({
        predicate: query => {
          const queryKeyName = String(query.queryKey[0])
          return Boolean(queryKeyName.match(/^guardian-details-.*/g))
        },
      })
    }
  }, [shouldRefetch])

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $name: 'Resumo financeiro',
        },
      })
    }
  }, [])

  if (isFetchGuardianError || (isFetchGuardianFetched && !guardian?.data)) {
    return (
      <GenericError
        onRetry={() => {
          window.location.reload()
        }}
      />
    )
  }

  return (
    <Wrapper>
      <ContractProvider>
        {guardian?.data && (
          <GuardianDetailsCompactedHeader
            guardian={guardian.data}
            isLoading={isFetchGuardianLoading}
            originalHeaderRef={headerRef}
          >
            <Tabs.Root
              value={activeTab}
              onValueChange={value => handleActiveTab(value as GuardianDetailsTabs)}
            >
              <TabsTriggers />
            </Tabs.Root>
          </GuardianDetailsCompactedHeader>
        )}

        <TopPageWrapper ref={headerRef}>
          <GuardianDetailsHeader
            schoolSlug={school?.slug ?? ''}
            guardianId={guardianId}
            isLoading={isFetchGuardianLoading}
            openEditRegistrationDrawer={openEditRegistrationDrawer}
          />
          <GuardianDetailsCard
            guardian={guardian?.data}
            isLoading={isFetchGuardianLoading}
            openEditRegistrationDrawer={openEditRegistrationDrawer}
          />
        </TopPageWrapper>

        <NewTabs
          activeTab={activeTab}
          onChangeTab={handleActiveTab}
          onChangeFinancialSummaryTab={setActiveTab}
          showGeneratingInvoicesLoading={showGeneratingInvoicesLoading}
          setShowGeneratingInvoicesLoading={setShowGeneratingInvoicesLoading}
          showPendingEnrollmentProcessingAlert={showPendingEnrollmentProcessingAlert}
          setShowPendingEnrollmentProcessingAlert={setShowPendingEnrollmentProcessingAlert}
        />

        {abrirCadastro && isFetchGuardianFetched && guardian?.data && (
          <EditRegistrationDrawer
            isOpen={Boolean(abrirCadastro)}
            onClose={handleEditRegistrationDrawerClose}
            guardian={guardian.data}
            refreshData={refreshData}
          />
        )}
      </ContractProvider>
    </Wrapper>
  )
}
