import { ContractStatus, PreContractStatuses } from '@/shared/interfaces'
import { Contract } from '@/modules/guardians/GuardianDetails/types'

export function canCancelContract(
  contract: Contract | undefined,
  hasOpenInstallments: boolean,
  isCancelContractEnabledForNonBackofficeUsers: boolean,
  isAdmin: boolean
) {
  if (!contract) return false

  const isPreContract = contract.pre_contract_status !== PreContractStatuses.EFFECTED_PCS
  const isActiveContract =
    contract.status === ContractStatus.OPEN || contract.status === ContractStatus.PENDING

  const canOpenCancelContract =
    !isPreContract && hasOpenInstallments && isCancelContractEnabledForNonBackofficeUsers

  const canEnableCancellationOption = isAdmin || isPreContract || canOpenCancelContract

  const canCancelPreContract = isAdmin && isPreContract

  return (
    (isAdmin || isCancelContractEnabledForNonBackofficeUsers) &&
    isActiveContract &&
    canEnableCancellationOption &&
    (canCancelPreContract || hasOpenInstallments)
  )
}
