import { ReactNode } from 'react'
import { Provider as TooltipProvider } from '@gravity/tooltip'
import { ToastProvider } from '@gravity/toast'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { CssBaseline, StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayJsUtils from '@date-io/dayjs'
import ptBR from 'dayjs/locale/pt-br'

import '@gravity/accordion/css'
import '@gravity/badge/css'
import '@gravity/button/css'
import '@gravity/checkbox/css'
import '@gravity/dialog/css'
import '@gravity/icon-button/css'
import '@gravity/select/css'
import '@gravity/skeleton/css'
import '@gravity/table/css'
import '@gravity/card/css'
import '@gravity/heading/css'
import '@gravity/tabs/css'
import '@gravity/text/css'
import '@gravity/text-field/css'
import '@gravity/datepicker/css'
import '@gravity/tooltip/css'
import '@gravity/separator/css'
import '@gravity/link/css'
import '@gravity/text-area/css'
import '@gravity/callout/css'
import '@gravity/toast/css'
import '@gravity/radio/css'
import '@gravity/grid/css'
import '@gravity/interactive-card/css'
import '@gravity/combobox/css'

import { SnackbarProvider } from '@/shared/contexts/snackbarContext'
import { ProviderComposer } from '@/shared/core/providers'

import { GlobalStyles } from '@/modules/app/styles/global'
import { theme, materialTheme } from '@/shared/theme'

import { DSHighlighterStyles } from '@/modules/app/components/DSHighlighter/DSHighlighterStyles'

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ProviderComposer
      providers={[
        ({ children }) => <MuiThemeProvider theme={materialTheme}>{children}</MuiThemeProvider>,
        ({ children }) => <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>,
        ({ children }) => <StylesProvider injectFirst>{children}</StylesProvider>,
        ({ children }) => (
          <MuiPickersUtilsProvider locale={ptBR} utils={DayJsUtils}>
            {children}
          </MuiPickersUtilsProvider>
        ),
        ({ children }) => <SnackbarProvider>{children}</SnackbarProvider>,
        ToastProvider,
        TooltipProvider,
      ]}
    >
      {children}
      <GlobalStyles />
      <DSHighlighterStyles />
      <CssBaseline />
    </ProviderComposer>
  )
}
