import { AxiosInstance } from 'axios'

import type {
  FetchSchoolRequest,
  FetchSchoolResponse,
  FetchSchoolsRequest,
  FetchSchoolsResponse,
} from './types'

export const schoolsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetch all schools that the user has access to
     *
     * @param data.nameFilter Filter by name
     * @param data.pagination Pagination properties
     *
     * @returns List of schools
     */
    fetchSchools: async ({
      nameFilter,
      pagination = {},
    }: FetchSchoolsRequest): Promise<FetchSchoolsResponse> => {
      const { data } = await fetcher.get<FetchSchoolsResponse>('school', {
        params: {
          school_name: nameFilter,
          ...pagination,
        },
      })

      return data
    },

    /**
     * Function responsible for fetch a school based on the provided ID
     *
     * @param schoolId School ID
     *
     * @returns School
     */
    fetchSchool: async ({ schoolId }: FetchSchoolRequest): Promise<FetchSchoolResponse> => {
      const { data } = await fetcher.get<FetchSchoolResponse>(`school/${schoolId}`)

      return data
    },
  }
}
