import StatusBadge, { StatusBadgeColor } from '@/shared/components/StatusBadge'
import { InstallmentStatuses, InstallmentType } from '@/shared/interfaces'
import { formatCentsToReal, formatDate } from '@/shared/utils'
import {
  CircularProgress,
  Tooltip,
  Checkbox,
  Table,
  TableBody,
  TableRow,
  Grid,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { TooltipButton, Typography } from '@olaisaac/design-system'
import { useEffect, useRef } from 'react'
import { calculateTotalDiscount, canSelectRow, splitOrderReference } from '../../utils'
import { DiscountChips } from '../DiscountChips/index'
import { Installment, NoEditReason } from '@/modules/contract/services/types'
import * as Styled from './styles'
import { InstallmentsTableProps, Row, TableColumn } from './types'
import { UndoCancelButton } from './UndoCancelButton'
import { useEvents } from '../../hooks/eventContext'

const mapDataToRows = (
  rawData: Installment[],
  selectedIds: uuid[],
  enableCancelFF: boolean
): Row[] => {
  return rawData.map(item => ({
    ...item,
    month_reference: formatDate(item.due_date, 'MMM. YYYY'),
    order_reference: splitOrderReference(item.order_reference),
    net_amount: item.amount - calculateTotalDiscount(item.discounts),
    disabled: !canSelectRow(item, { enableCancelFF }),
    selected: selectedIds.includes(item.backoffice_installment_id),
    competence: formatDate(item.competence_date[0], 'MMM. YYYY'),
  }))
}

const paramsDict: PartialRecord<
  InstallmentStatuses,
  { color: StatusBadgeColor; detail: string | null; text: string }
> = {
  CANCELED: { color: 'grey', text: 'Cancelada', detail: null },
  OPEN: { color: 'primary', text: 'A vencer', detail: null },
  PENDING: {
    color: 'darkGrey',
    text: 'Aguardando',
    detail:
      'A parcela será gerada após o pagamento da pré-matrícula e/ou quitação dos contratos de ',
  },
  AGGLUTINATED: { color: 'grey', text: 'Aglutinada', detail: null },
  DUE_TODAY: { color: 'primary', text: 'A vencer', detail: null },
  OVERDUE: { color: 'error', text: 'Vencida', detail: null },
  PAID: { color: 'success', text: 'Paga', detail: null },
  PAID_AND_CANCELED: { color: 'grey', text: 'Paga e cancelada', detail: null },
  RENEGOTIATED: { color: 'warning', text: 'Negociada', detail: null },
}

const installmentTypeText: PartialRecord<InstallmentType, string> = {
  [InstallmentType.ENROLLMENT]: 'Pré-matrícula',
  [InstallmentType.TUITION]: 'Mensalidade',
}

const headerNegotiatedNotPresentText =
  'Parcelas negociadas não são editáveis e por isso não se encontram listadas abaixo.'

const rowDisabledHelperText =
  'Não é possível editar parcelas de pré-matricula em situação a vencer ou vencida a partir desta página de edições.'

const noEditReasonText: Record<NoEditReason, string> = {
  [NoEditReason.CANCELED]: 'Não é possível editar parcelas canceladas.',
  [NoEditReason.HAS_PAID_PAYOUT]: 'Não é possível editar parcela do mês atual.',
  [NoEditReason.NEGOTIATION]: 'Não é possível editar parcelas renegociadas.',
  [NoEditReason.OVERDUE]: 'Não é possível editar parcelas vencidas.',
  [NoEditReason.PAID]: 'Não é possível editar parcelas pagas.',
  [NoEditReason.OTHER]: 'Não é possível editar esta parcela.',
  [NoEditReason.ENROLLMENT]: rowDisabledHelperText,
}

export const renderStatus = (
  status: InstallmentStatuses,
  referenceYear: any,
  overdueForDays: number,
  paidDate: string
) => {
  const statusMessages: Record<string, string> = {
    [InstallmentStatuses.OVERDUE]: `Há ${overdueForDays} dias`,
    [InstallmentStatuses.PAID]: `Recebido em ${formatDate(paidDate, 'DD/MM/YYYY')}`,
    [InstallmentStatuses.PAID_AND_CANCELED]: `Recebido em ${formatDate(paidDate, 'DD/MM/YYYY')}`,
  }

  const statusDetail = paramsDict[status]?.detail

  return (
    <Styled.StatusContainer>
      <Styled.Status>
        <StatusBadge color={paramsDict[status]?.color as StatusBadgeColor} />
        <Styled.StatusText variation="bodySmall">{paramsDict[status]?.text}</Styled.StatusText>
        {statusDetail != null && (
          <Tooltip title={statusDetail + (referenceYear - 1)}>
            <TooltipButton>
              <InfoOutlinedIcon color="action" fontSize="small" />
            </TooltipButton>
          </Tooltip>
        )}
      </Styled.Status>
      <Styled.StatusMessage>
        <Typography variation="caption" color="secondary">
          {statusMessages[status]}
        </Typography>
      </Styled.StatusMessage>
    </Styled.StatusContainer>
  )
}

export const InstallmentsTable = ({
  data,
  contract,
  isLoading,
  onDeleteDiscount,
  selectedInstallmentIds,
  setSelectedInstallmentIds,
  installmentIDsToCancel,
  setInstallmentIDsToCancel,
  enableCancelFF = true,
}: InstallmentsTableProps) => {
  const events = useEvents()
  const tableRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    if (!tableRef.current) return

    const isTableTopBellowScreenTop = tableRef.current?.getBoundingClientRect().top > 0

    if (selectedInstallmentIds.length > 0 && isTableTopBellowScreenTop) {
      tableRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedInstallmentIds])

  const columns: TableColumn[] = [
    {
      headerName: 'Competência',
      field: 'competence',
      content: row => row.competence,
    },
    {
      headerName: 'Parcela',
      field: 'order_reference',
      content: row => (
        <Styled.OrderReferenceWrapper>
          <Typography>{installmentTypeText[row.receivable_type] || 'Parcela'}</Typography>
          <Typography color="secondary">{row.order_reference}</Typography>
        </Styled.OrderReferenceWrapper>
      ),
    },
    {
      headerName: 'Vencimento',
      field: 'due_date',
      content: row => formatDate(row.due_date, 'DD/MM/YYYY'),
    },
    { headerName: 'Valor', field: 'amount', content: row => formatCentsToReal(row.amount) },
    {
      headerName: 'Desconto',
      field: 'discounts',
      width: '160px',
      content: row => <DiscountChips installment={row} onDelete={onDeleteDiscount} />,
    },
    {
      headerName: 'Valor com o desconto',
      field: 'net_amount',
      content: row => formatCentsToReal(row.net_amount),
    },
    {
      headerName: 'Situação',
      field: 'status',
      content: row =>
        installmentIDsToCancel.includes(row.backoffice_installment_id) ? (
          <UndoCancelButton
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation()
              setInstallmentIDsToCancel(
                installmentIDsToCancel.filter(i => i !== row.backoffice_installment_id)
              )
            }}
          />
        ) : (
          renderStatus(row.status, contract?.reference_year, row.overdue_for_days, row.paid_date)
        ),
    },
  ]
  const rows = mapDataToRows(data, selectedInstallmentIds, enableCancelFF)

  const handleSelectRow = (row: Row) => {
    if (row.disabled) {
      return
    }

    if (row.selected) {
      setSelectedInstallmentIds(
        selectedInstallmentIds.filter(i => i !== row.backoffice_installment_id)
      )
    } else {
      setSelectedInstallmentIds([...selectedInstallmentIds, row.backoffice_installment_id])
    }

    events.clickRow(row)
  }

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedInstallmentIds(rows.filter(i => !i.disabled).map(i => i.backoffice_installment_id))
      return
    }
    setSelectedInstallmentIds([])
  }

  const defineNoEditReasonText = (row: Row): string => {
    if (row.disabled)
      return (
        noEditReasonText[row.no_edit_reason as NoEditReason] || noEditReasonText[NoEditReason.OTHER]
      )
    return ''
  }

  const numSelected = selectedInstallmentIds.length
  const rowCount = rows.filter(i => !i.disabled).length

  return (
    <Styled.Container>
      <Styled.TableTitle variation="headlineDesktopXsmall">Configurar parcelas</Styled.TableTitle>
      <Styled.WarningContainer container alignItems="center">
        <Grid item sm={8}>
          <InfoOutlinedIcon
            color="action"
            fontSize="small"
            style={{ verticalAlign: 'bottom', marginRight: '4px' }}
          />
          {headerNegotiatedNotPresentText}
        </Grid>
      </Styled.WarningContainer>
      <Typography variation="bodySmall" color="secondary">
        Selecione as parcelas para aplicar descontos, alterar valores ou data de vencimento.
      </Typography>
      <Styled.TableContainer>
        <Table style={{ isolation: 'isolate' }} ref={tableRef}>
          <Styled.TableHead>
            <TableRow>
              <Styled.TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={(_, checked) => handleSelectAll(checked)}
                />
              </Styled.TableCell>
              {columns.map(column => (
                <Styled.TableCell key={column.field} width={column.width}>
                  {column.headerName}
                </Styled.TableCell>
              ))}
            </TableRow>
          </Styled.TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <Styled.TableCell
                  colSpan={columns.length}
                  height={64}
                  style={{ textAlign: 'center' }}
                >
                  <CircularProgress size={32} />
                </Styled.TableCell>
              </TableRow>
            )}

            {!isLoading &&
              rows.map(row => (
                <Styled.BodyTableRow
                  key={row.backoffice_installment_id}
                  hover
                  onClick={() => handleSelectRow(row)}
                  selected={row.selected}
                  disabled={row.disabled}
                  title={defineNoEditReasonText(row)}
                >
                  <Styled.TableCell padding="checkbox">
                    <Checkbox checked={row.selected} disabled={row.disabled} />
                  </Styled.TableCell>
                  {columns.map(column => (
                    <Styled.TableCell key={column.field}>{column.content(row)}</Styled.TableCell>
                  ))}
                </Styled.BodyTableRow>
              ))}
          </TableBody>
        </Table>
      </Styled.TableContainer>
    </Styled.Container>
  )
}
