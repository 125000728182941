import useAddContractEnabled from '@/modules/enrollment/hooks/useAddContractEnabled'
import AddIcon from '@material-ui/icons/Add'
import { Button } from '@olaisaac/design-system'
import { useHistory } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { ReenrollmentStatus } from '@/shared/interfaces'

type EnrollButtonProps = {
  enrollmentStatus: ReenrollmentStatus
  enrollmentYear: number
  isReEnrollable: boolean
  startedEnrollment: boolean
  studentId: string
  studentName: string
}

export const EnrollButton = ({
  enrollmentYear,
  isReEnrollable,
  startedEnrollment,
  enrollmentStatus,
  studentId,
  studentName,
}: EnrollButtonProps) => {
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()
  const addContractEnabled = useAddContractEnabled()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const navigateToCreateContract = () => {
    history.push(`/${schoolSlug}/contratos/${enrollmentYear}/novo`, {
      studentID: studentId,
      studentName: studentName,
    })
  }

  if (!addContractEnabled) {
    return null
  }

  return (
    <Button
      variation="primary"
      startIcon={<AddIcon />}
      onClick={() => {
        navigateToCreateContract()
        isInitialized &&
          eventDispatcherClient.sendEvent({
            scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
            name: EnrollmentEventDispatcherEvents.CLICKED,
            action: 'click',
            customProperties: {
              $Button_name: 'ADICIONAR_NOVO_CONTRATO',
              $value: enrollmentStatus,
            },
          })
      }}
      disabled={!isReEnrollable}
    >
      {startedEnrollment ? 'Adicionar novo contrato' : 'Rematricular'}
    </Button>
  )
}
