import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.theme.gravity.spacing[14]};
  }
`
