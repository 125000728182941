import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 4rem;

  .title {
    width: 100%;
    z-index: 1;
    margin-top: ${props => props.theme.gravity.spacing[12]};
  }

  .border {
    box-shadow: 0 1px 0 0 ${props => props.theme.gravity.colors['colors-background-neutral-3']};
  }

  .subtitle {
    display: block;
    margin-top: ${props => props.theme.gravity.spacing[4]};
  }
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  padding-bottom: ${props => props.theme.gravity.spacing[10]};
  position: relative;
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${props => props.theme.gravity.spacing[5]} 0 0;
  margin-bottom: ${props => props.theme.gravity.spacing[12]};
`
