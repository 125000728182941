import { IconButton } from '@gravity/icon-button'
import { Heading } from '@gravity/heading'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'
import { useHistory } from 'react-router-dom'

import * as Styled from './styles'

export const PageHeader = () => {
  const history = useHistory()
  const handleBackBtnClick = history.goBack

  return (
    <Styled.Wrapper>
      <div />

      <Heading variant="heading-h4-regular">Troca de titularidade de contrato</Heading>

      <Styled.CloseBtnWrapper>
        <IconButton variant="solid" size={2} aria-label="Voltar" onClick={handleBackBtnClick}>
          <Icon color="#000" path={mdiClose} />
        </IconButton>
      </Styled.CloseBtnWrapper>
    </Styled.Wrapper>
  )
}
