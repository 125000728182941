import { z } from 'zod'

export const filterContractsItemSchema = z.object({
  label: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

export const filterContractsSchema = z.object({
  reference_years: z.array(filterContractsItemSchema).optional(),
})

export type filterContractsForm = z.infer<typeof filterContractsSchema>
