import { Switch, useRouteMatch } from 'react-router-dom'
import ContractListRouteYearCheckout from '@/escolas/router/[schoolSlug]/contratos/ContractsYear/checkout'
import ContractDetailsRoute from '@/escolas/router/[schoolSlug]/contratos/[contractId]'
import NewContractPageRoute from '@/escolas/router/[schoolSlug]/contratos/novo'
import PreContractEditRoute from '@/escolas/router/[schoolSlug]/contratos/ContractsYear/PreContractEdit/router'
import { Breadcrumb } from '@/shared/contexts/BreadcrumbsContext'
import { useOnboardingRedirect } from 'src/escolas/hooks/useOnboardingRedirect'
import { LoadingBox } from 'src/shared/components/LoadingBox'
import { CircularProgress } from '@material-ui/core'
import envConfig from '@/config'

export default () => {
  const { path, url } = useRouteMatch()

  const { SELF_ONBOARDING_REDIRECT_URL } = envConfig
  const { data: onboardingRedirectData, isFetching, isFetched } = useOnboardingRedirect()

  const { onboarding_redirect, self_onboarding_school_id } = onboardingRedirectData || {}

  const breadcrumbs: Breadcrumb[] = [{ path: url, name: 'Consultar contratos' }]

  const shouldRedirect = Boolean(onboarding_redirect && self_onboarding_school_id)

  if (isFetching) {
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    )
  }

  if (isFetched && shouldRedirect) {
    window.location.replace(`${SELF_ONBOARDING_REDIRECT_URL}/escolas/${self_onboarding_school_id}`)
    return <></>
  }

  return (
    <Switch>
      {/* This is not the same as <ContractDetailsRoute path={path} /> dont try to change it 😅
       More information: https://github.com/OlaIsaac/backoffice/pull/71 */}
      {NewContractPageRoute({ path, breadcrumbs })}
      {ContractListRouteYearCheckout({ path, breadcrumbs })}
      {ContractDetailsRoute({ path, breadcrumbs })}
      {PreContractEditRoute({ path, breadcrumbs })}
    </Switch>
  )
}
