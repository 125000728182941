import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  .grid-container {
    padding: ${({ theme }) => theme.gravity.spacing[16]} 0;
    gap: ${({ theme }) => theme.gravity.spacing[16]};
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.gravity.spacing[10]};

  .title {
    margin-bottom: ${({ theme }) => theme.gravity.spacing[4]};
  }
`
