import ConfirmationDialog from '@/shared/components/ConfirmationDialog'
import { ConfirmationDialogCtrlProps } from './types'

const ConfirmationDialogCtrl = ({
  selectedContentKey,
  isVisible,
  onClose,
  dialogContents,
}: ConfirmationDialogCtrlProps) => {
  if (!selectedContentKey) return <></>
  const selectedContent = dialogContents[selectedContentKey]
  return (
    <ConfirmationDialog
      isVisible={isVisible}
      onClose={onClose}
      submitHandler={selectedContent.submitHandler}
      backButtonLabel={selectedContent.backButtonLabel}
      buttonLabel={selectedContent.buttonLabel}
      title={selectedContent.title}
    >
      {selectedContent.content}
    </ConfirmationDialog>
  )
}

export default ConfirmationDialogCtrl
