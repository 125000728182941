import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@gravity/button'
import { mdiChevronLeft } from '@mdi/js'
import Icon from '@mdi/react'
import { Heading } from '@gravity/heading'

import { PageStatusWrapper } from '@/shared/components/PageStatusWrapper'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

import { useGetEnrollmentReport } from '../../hooks/queries/enrollmentReport'
import * as Styled from './styles'

export const EnrollmentReport = () => {
  const { goBack } = useHistory()
  const reportQuery = useGetEnrollmentReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  return (
    <PageStatusWrapper status={reportQuery.status}>
      <Styled.Header>
        <Button variant="ghost" iconStart={<Icon path={mdiChevronLeft} />} onClick={goBack}>
          Voltar
        </Button>
        <Heading variant="heading-h3-medium" className="title">
          Relatório de matrículas
        </Heading>
      </Styled.Header>

      {iframeStatus === 'loading' ? <CircularLoadingPlaceholder /> : null}

      <Styled.Iframe
        src={reportQuery.data?.url}
        style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
        onLoad={() => setIframeStatus('success')}
      />
    </PageStatusWrapper>
  )
}
