import { useQuery } from '@tanstack/react-query'

import { useApiClient, useJWT } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { MANAGEMENT_QUERY_KEY } from '../constants'
import { managementService } from '@monorepo/management/services/managementService'

export const useSchoolManagementQuery = () => {
  const { school } = useSelectedSchool()
  const { hasPayoutReportAccess } = useJWT()

  const { apiClient } = useApiClient()

  const service = managementService(apiClient.privateApi)

  return useQuery({
    queryKey: ['school', MANAGEMENT_QUERY_KEY, school?.id ?? ''],
    queryFn: async () => {
      const { data } = await service.fetchSchoolManagement({
        schoolId: school?.id ?? '',
      })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },

    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: !!school?.id && hasPayoutReportAccess,
  })
}
