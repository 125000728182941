import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { useFetchStudentHasCampaign } from '@/modules/enrollment/hooks/queries/useCampaignQueries'
import { OldProduct as Product } from '@/shared/interfaces'

import { FormProps } from '../types'

type Props = {
  form: UseFormReturn<FormProps>
  productYear: string
  schoolId: string
}

export const STUDENT_IN_CAMPAIGN_ERROR_MSG =
  'O aluno já está em campanha para o produto selecionado. Escolha outro produto ou remova-o da campanha.'

export const useProductValidation = ({ form, productYear, schoolId }: Props) => {
  const watchProduct = form.watch('product')
  const formStudent = form.getValues().student
  const studentHasCampaignQuery = useFetchStudentHasCampaign(
    {
      schoolId: schoolId,
      studentId: formStudent.id,
      referenceYear: productYear,
      productId: watchProduct?.id,
    },
    { enabled: !!(formStudent.id && watchProduct?.id && productYear && schoolId) }
  )

  const validateProductRequired = async (product: Product | string) => {
    if (!product) return 'Selecione um produto cadastrado'
    return true
  }

  const validateStudentIsInCampaign = async () => {
    if (studentHasCampaignQuery.isFetched && studentHasCampaignQuery.data.has_campaign_enrollment) {
      return STUDENT_IN_CAMPAIGN_ERROR_MSG
    }
    return true
  }

  useEffect(() => {
    if (!formStudent.id) return

    if (studentHasCampaignQuery.data?.has_campaign_enrollment) {
      form.setError('product_id', {
        type: 'custom',
        message: STUDENT_IN_CAMPAIGN_ERROR_MSG,
      })
    } else {
      form.clearErrors('product_id')
    }
  }, [studentHasCampaignQuery.data, formStudent])

  return {
    validateStudentIsInCampaign,
    validateProductRequired,
  }
}
