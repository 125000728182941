import styled from 'styled-components'

export const AmountContainerPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing[1]};
  padding: ${({ theme }) => theme.gravity.spacing[4]} 0;
`

export const FinanceValuesContainerPlaceholder = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gravity.spacing[8]};
`
