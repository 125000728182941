import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { onboardingAPI } from '../services/onboardingApi'
import { useApiClient } from '@/shared/hooks'
import { GetChargesResponse } from '../services/types/onboardingCharge'
import { GetOnboardingBatchRequest } from '../services/types/commonTypes'

type UseFetchChargesQueryParams = GetOnboardingBatchRequest

type UseFetchChargesQueryReturn = GetChargesResponse

export const FETCH_CHARGES_QUERY_KEY = 'fetchCharges'

export const useFetchChargesQuery = (
  params: UseFetchChargesQueryParams,
  options?: UseQueryOptions<UseFetchChargesQueryReturn>
) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)
  return useQuery<UseFetchChargesQueryReturn>(
    [FETCH_CHARGES_QUERY_KEY, params],
    async () => {
      const response = await api.getCharges(params)

      return response
    },
    options
  )
}
