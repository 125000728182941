import React, { ReactNode } from 'react'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { Dialog } from '@gravity/dialog'
import erroPi from 'src/escolas/assets/erro-pi.svg'
import { useApi } from '@/utils/hooks/useApi'
import * as Styled from './FeedbackCarneDialog.styles'

const DEFAULT_MESSAGE =
  'Estamos com uma instabilidade momentânea no sistema e já estamos trabalhando para ajustar. Enquanto isso, sugerimos que a família use o aplicativo do meu isaac para ter acesso às faturas ou ainda, você pode imprimir as faturas individualmente. '

const errorMsg = (err?: string) => {
  if (err) {
    if (err.includes('all open receivable are overdue')) {
      return 'Todas as parcelas desse contrato estão vencidas. É necessário gerar segunda via das parcelas para criar o carnê.'
    } else if (err.includes('any receivables open')) {
      return 'Não existem parcelas disponíveis para geração de carnê. Todas as parcelas do contrato já foram pagas ou canceladas.'
    } else if (err.includes('can only be paid with credit card')) {
      return 'Parcelas disponíveis desse contrato só podem ser pagas via cartão de crédito. Renegocie para disponibilizar outras formas de pagamento e gerar o carnê.'
    }
  }
  return DEFAULT_MESSAGE
}

const TextAndImageContent = ({
  imageSrc,
  message,
  secondaryMessage,
}: {
  imageSrc: string
  message: string
  secondaryMessage?: React.ReactNode
}) => (
  <Styled.TextAndImageContainer>
    <Styled.StyledImage src={imageSrc} alt="" />
    <Text variant="body-1-regular">{message}</Text>
    {secondaryMessage ? <Text variant="body-1-regular">{message}</Text> : null}
  </Styled.TextAndImageContainer>
)

export const FailureFeedbackContent = ({ err }: { err?: string }) => {
  let message = errorMsg(err)
  let secondaryMessage: React.ReactNode = null
  const { correlationId } = useApi()

  if (message === DEFAULT_MESSAGE && correlationId) {
    secondaryMessage = (
      <Text variant="body-1-regular">
        <strong>#{correlationId.split('-')[0]}</strong>
      </Text>
    )
    message = message + ' Código de erro: '
  }
  return (
    <TextAndImageContent message={message} imageSrc={erroPi} secondaryMessage={secondaryMessage} />
  )
}

export type FeedbackCarneDialogProps = {
  backButtonLabel?: string
  buttonLabel?: string
  children: ReactNode
  isConfirmDisabled?: () => boolean
  isVisible: boolean
  onClose: () => void
  submitHandler: () => void
  title?: string
}

const FeedbackCarneDialog = ({
  buttonLabel = 'Entendi',
  backButtonLabel,
  children,
  isVisible,
  onClose,
  submitHandler,
  title = '',
  isConfirmDisabled,
}: FeedbackCarneDialogProps) => {
  const handleDialogOpenChange = (open: boolean) => {
    if (!open && isVisible) onClose()
  }

  return (
    <Dialog
      data-testid="feedback-dialog"
      open={isVisible}
      size={2}
      backdrop
      title={title}
      onOpenChange={handleDialogOpenChange}
      content={<div>{children}</div>}
      cancelButton={
        backButtonLabel && (
          <Button variant="ghost" onClick={onClose}>
            {backButtonLabel}
          </Button>
        )
      }
      actionButton={
        <Button onClick={submitHandler} disabled={isConfirmDisabled?.()}>
          {buttonLabel}
        </Button>
      }
    />
  )
}

export default FeedbackCarneDialog

export const failureFeedbackTitle = 'A geração do carnê está indisponível no momento'

export const FailureFeedbackDialog = ({
  title = failureFeedbackTitle,
  ...rest
}: FeedbackCarneDialogProps) => (
  <FeedbackCarneDialog title={title} {...rest}>
    {rest.children ?? <FailureFeedbackContent />}
  </FeedbackCarneDialog>
)
