import { ContractResponse, DebtStatus } from '@/shared/interfaces'
import { BadgeProps } from '@gravity/badge'
import { Columns } from './types'

export const CONTRACTS_STATUS: PartialRecord<ContractResponse['status'], string> = {
  OPEN: 'Ativo',
  CANCELED: 'Inativo',
  PENDING_GUARDIAN_APPROVAL: 'Pendente',
}

export const DEBT_STATUS_VARIANT: {
  [k in DebtStatus]: { color: BadgeProps['color']; text: string }
} = {
  WARNING: { color: 'warning', text: 'Atenção' } as const,
  UP_TO_DATE: { color: 'success', text: 'Em dia' } as const,
  PENDING: { color: 'accent', text: 'Aguardando' } as const,
  OVERDUE: { color: 'error', text: 'Com pendências' } as const,
}

export const COLUMN_HEADERS: Array<{
  label: string
  name: Columns
  sortable: boolean
}> = [
  { name: 'product.name', label: 'Produto', sortable: true },
  { name: 'reference_year', label: 'Ano letivo', sortable: true },
  { name: 'guardian.name', label: 'Responsável', sortable: true },
  { name: 'status', label: 'Status', sortable: true },
  { name: 'debt_status', label: 'Situação financeira', sortable: true },
]

export enum installmentDueDateRule {
  FIXED_DAY = 'FIXED_DAY',
  WORKING_DAY = 'WORKING_DAY',
}

export enum discountRule {
  DUE_DATE_DISCOUNT = 'DUE_DATE_DISCOUNT',
  EARLY_PAYMENT_DISCOUNT = 'EARLY_PAYMENT_DISCOUNT',
  PERMANENT_DISCOUNT = 'PERMANENT_DISCOUNT',
}

export enum SchoolChargeOperation {
  default = '',
  isaacPay = 'isaac-pay',
}
