import type {
  APIResponse,
  CommonMetadata,
  DebtStatus,
  Discount,
  Guardian,
  InstallmentType,
  PaginationRequest,
  OldProduct as Product,
  ReEnrollmentSubStatus,
  ReenrollmentStatus,
} from 'src/shared/interfaces'

export type EnrollmentCycleRequest = {
  enrollmentStatus?: Omit<ReenrollmentStatus, ReenrollmentStatus.GATE_ERROR>[]
  enrollmentSubStatus?: ReEnrollmentSubStatus[]
  name?: string
  pagination: PaginationRequest
  referenceYears?: string[]
  schoolId: uuid
  studentId?: uuid
  studentIds?: uuid[]
}

export type EnrollmentCycleResponse = APIResponse<EnrollmentCycleItem[]>
export type GetEnrollmentCycleSituationResponse = APIResponse<Situation>

export type Situation = {
  is_reenrollable: boolean
  status: ReenrollmentStatus
  sub_status: ReEnrollmentSubStatus[]
}

export type EnrollmentCycleItem = {
  debt_status: DebtStatus
  is_reenrollable: boolean
  main_contract_id: uuid
  main_guardian: Guardian
  main_product: Product
  other_guardians: Guardian[]
  other_products: Product[]
  started_enrollment: boolean
  student_id: uuid
  student_name: string
  student_referral: string
  student_status: ReenrollmentStatus
  student_status_details: ReEnrollmentSubStatus[]
}

export type GetGuardiansRequest = {
  schoolId: uuid
  studentId?: uuid
  taxID?: string
}

export type GetGuardiansResponse = APIResponse<Guardian[]>

export type StatusSummaryRequest = {
  referenceYear: string
  schoolId: uuid
}

export type StatusSummaryResponse = APIResponse<{
  status: Partial<Record<ReenrollmentStatus, number>>
  total: number
}>

export enum Status {
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
}

export type Installment = CommonMetadata & {
  amount: number
  discounts: Discount[]
  due_date: datestring
  status: Status
  type: InstallmentType
  use_working_dates: boolean
}

export type Enrollment = CommonMetadata & {
  guardian_id: uuid
  installments: Installment[]
  product_id: uuid
  reference_year: string
  status: Status
  student_id: uuid
}

export type CreateCampaignRequest = {
  end_date: datestring
  name: string
  notification_channels: {
    channel: 'EMAIL' | 'CLASSAPP'
    enabled: boolean
    text: string
  }[]
  payment_plans: {
    amount: number
    due_date: datestring
    duration_months: number
    type: 'ENROLLMENT'
  }[]
  product_id: uuid
  reference_year: number
  school_enrollment_cycle_id: uuid
  source_products_ids: uuid[]
  start_date: datestring
  student_exclusions: uuid[]
}

export type EnrollmentPaymentPlan = {
  amount: number
  due_date: string
  duration_months: number
  id: uuid
  type: PaymentPlanType
}

export enum PaymentPlanType {
  ENROLLMENT = 'ENROLLMENT',
  TUITION = 'TUITION',
}

export type Campaign = {
  closed_type: CampaignClosedType | ''
  created_date: datestring
  end_date: datestring
  engaged_students_count: number
  has_payment_plan: boolean
  id: uuid
  name: string
  paid_enrollments_count: number
  payment_plans: EnrollmentPaymentPlan[] | null
  product_id: uuid
  product_name: string
  status: CampaignStatus
  students_count: number
  total_of_campaign_emails_read: number
}

export enum CampaignClosedType {
  AUTOMATICALLY = 'AUTOMATICALLY',
  MANUALLY = 'MANUALLY',
}

export enum CampaignStatus {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export const campaignStatusToTextMapper = {
  [CampaignStatus.CANCELED]: 'Cancelada',
  [CampaignStatus.CLOSED]: 'Encerrada',
  [CampaignStatus.OPEN]: 'Iniciada',
}

export type GetCampaignsRequest = {
  campaignId?: uuid
  pagination?: PaginationRequest
  schoolEnrollmentCycleId: string
  schoolId?: string
  status?: string[]
}

type CampaignsData = {
  campaigns: Campaign[]
}

export type GetCampaignsResponse = APIResponse<CampaignsData>

export type AcceptPaymentPlanResponse = APIResponse<{
  installment_id: uuid
}>

export type CloseCampaignRequest = {
  closed_type: 'MANUALLY'
}

export type HasCampaignEnrollmentRequest = {
  productId: uuid
  referenceYear: string
  schoolId: uuid
  studentId: uuid
}

export type HasCampaignEnrollmentResponse = {
  data: {
    has_campaign_enrollment: boolean
  }
}
export enum CampaignStudentExcludesRequest {
  enrollmentCanceled = 'ENROLLMENT_CANCELED',
  enrollmentWithTuition = 'ENROLLMENT_WITH_TUITION',
}
export type GetCampaignStudentRequest = {
  campaignId: uuid
  excludes?: CampaignStudentExcludesRequest[]
  pagination: PaginationRequest
}
export type CampaignStudent = {
  enrollment_id: uuid
  enrollment_status: ReenrollmentStatus
  guardian_email: string
  guardian_name: string
  has_open_email: boolean
  has_tuition: boolean
  is_campaign_proposal_canceled: boolean
  is_engaged: boolean
  origin_product_name: string
  paid_enrollment: boolean
  student_id: uuid
  student_name: string
}
export type GetCampaignStudentResponse = APIResponse<{ students: CampaignStudent[] }>

export type AddTuitionToCampaignResponse = APIResponse<AffectedEnrollmentsData>

type AffectedEnrollmentsData = {
  total_affected_enrollments: number
}

export type AddTuitionBodyRequest = {
  custom_config: AddBulkInstallmentData[]
  default_config: AddBulkInstallmentData
  not_included_students: uuid[]
}

export type AddBulkInstallmentData = {
  custom_monthly_amount?: number
  discounts: DiscountBulkInstallment[]
  due_day: number
  duration_months: number
  enrollment_id: string
  school_charge_operation: SchoolChargeOperation
  start_month: null | Date
  use_working_dates_tuition: boolean
}

export enum SchoolChargeOperation {
  default = '',
  isaacPay = 'isaac-pay',
}

export type DiscountBulkInstallment = {
  amount: number | null
  days_before_due_date: number
  description: string
}

export type SignatureDocumentType = 'SCHOOL_DOCUMENT' | 'SIGNATURE' | 'COMPLETED_DOCUMENT'
export type UploadSignatureDocumentRequest = {
  file: File
  type: SignatureDocumentType
}
export type GetSignatureDocumentRequest = {
  download_link: boolean
  schoolId: uuid
  type: SignatureDocumentType
}
export type GetSignatureDocumentResponse = {
  data: {
    Content: {
      Attrs: {
        CacheControl: string
        ContentEncoding: string
        ContentType: string
        CRC32C: number
        Decompressed: boolean
        Generation: number
        LastModified: string
        Metageneration: number
        Size: number
        StartOffset: number
      }
    }
    ContentType: string
    file_link: string | null
    Filename: string
    HasFile: boolean
  }
}
