import { Heading } from '@gravity/heading'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { Text } from '@gravity/text'
import { Grid, GridItem } from '@gravity/grid'

type LayoutTemplateProps = {
  children?: ReactNode
  description?: ReactNode
  footer?: ReactNode
  title?: ReactNode
}

const LayoutTemplateFooter = styled.footer`
  display: flex;
  padding: 28px 0px;
  justify-content: end;
  gap: 16px;
`

const LayoutTemplateContent = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 42px;
`

const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  padding-top: 124px;
`

const StyledGrid = styled(Grid)`
  height: 100%;
`

const StyledGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
`

export const LayoutTemplate = ({ children, description, footer, title }: LayoutTemplateProps) => {
  return (
    <PageWrapper>
      <StyledGrid>
        <StyledGridItem>
          <Heading variant="heading-h3-medium" style={{ marginBottom: '8px' }}>
            {title}
          </Heading>
          <Text variant="body-1-regular">{description}</Text>
          <LayoutTemplateContent>{children}</LayoutTemplateContent>
          <LayoutTemplateFooter>{footer}</LayoutTemplateFooter>
        </StyledGridItem>
      </StyledGrid>
    </PageWrapper>
  )
}
