import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { mdiInformation, mdiReload } from '@mdi/js'
import Icon from '@mdi/react'

import { Container } from './styles'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'

type SectionErrorStateProps = {
  onRetry: () => void
}

export const SectionErrorState = ({ onRetry }: SectionErrorStateProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleRetrySection = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.STUDENT_PAGE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.STUDENT_PAGE,
        identifierName: EventIdentifierName.RETRY_SECTION,
      })
    }

    onRetry()
  }
  return (
    <Container data-testid="error-state">
      <div>
        <Icon path={mdiInformation} size="20px" />
        <Text variant="subtitle-medium">Erro ao carregar as informações desta seção</Text>
      </div>

      <Button className="action" iconStart={<Icon path={mdiReload} />} onClick={handleRetrySection}>
        Recarregar seção
      </Button>
    </Container>
  )
}
