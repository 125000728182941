import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'

import { schoolsService } from '@/modules/app/services/schools'

import type {
  FetchSchoolsRequest,
  FetchSchoolsResponse,
  FetchSchoolRequest,
  FetchSchoolResponse,
} from '@/modules/app/services/schools/types'

export const useSchools = (
  { nameFilter, pagination }: FetchSchoolsRequest,
  options?: UseQueryOptions<FetchSchoolsResponse>
) => {
  const { apiClient } = useApiClient()

  const service = schoolsService(apiClient.getClients().privateApi)

  return useQuery<FetchSchoolsResponse>(
    ['schools', JSON.stringify({ nameFilter, ...pagination })],
    async () => {
      const data = await service.fetchSchools({
        nameFilter,
        pagination,
      })

      return data
    },
    options
  )
}

export const useSchool = (
  { schoolId }: FetchSchoolRequest,
  options?: UseQueryOptions<FetchSchoolResponse>
) => {
  const { apiClient } = useApiClient()

  const service = schoolsService(apiClient.getClients().privateApi)

  return useQuery<FetchSchoolResponse>(
    ['schools', schoolId],
    async () => {
      const data = await service.fetchSchool({
        schoolId,
      })

      return data
    },
    options
  )
}
