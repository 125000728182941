import React, { SyntheticEvent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link, Breadcrumbs } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import { useBreadcrumbs } from '@/shared/hooks/useBreadcrumbs'

const LinkWithRef = React.forwardRef(({ navigate, ...props }: any, ref) => {
  const handleClick = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate(e.currentTarget.getAttribute('href'))
  }
  return (
    <Link ref={ref} {...props} onClick={handleClick}>
      {props.children}
    </Link>
  )
})

const RouterBreadcrumbs = () => {
  const { breadcrumbs } = useBreadcrumbs()

  if (breadcrumbs.length < 2) {
    return null
  }

  return (
    <Box display="flex" alignItems="center">
      <Breadcrumbs>
        {breadcrumbs.map(({ path, name }, index) => {
          const isLastItem = index === breadcrumbs.length - 1

          return isLastItem ? (
            <Link key={path}>{name}</Link>
          ) : (
            <RouterLink key={path} to={path} component={LinkWithRef}>
              {name}
            </RouterLink>
          )
        })}
      </Breadcrumbs>
    </Box>
  )
}

export default RouterBreadcrumbs
