import { useApiClient } from '@/shared/hooks/useApiClient'
import { enrollmentService } from '@/modules/enrollment/services/enrollmentService'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  EnrollmentCycleRequest,
  EnrollmentCycleResponse,
} from '@/modules/enrollment/services/enrollmentService/types'

import { ENROLLMENT_CYCLE_QUERY_KEY } from '../../../constants/enrollment'

export const useEnrollmentCycle = (
  props: EnrollmentCycleRequest,
  options?: UseQueryOptions<EnrollmentCycleResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  return useQuery<EnrollmentCycleResponse, XMLHttpRequest>(
    [ENROLLMENT_CYCLE_QUERY_KEY, props],
    () => {
      return service.fetchEnrollmentCycle(props)
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}
