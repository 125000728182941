import { useHistory } from 'react-router-dom'

import Icon from '@mdi/react'
import { Button } from '@gravity/button'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { mdiArrowLeft } from '@mdi/js'

import { Container, ActionsWrapper, PageInfoWrapper } from './styles'
import { PaymentDisputeHelp } from '@/modules/payment-dispute/pages/PaymentDispute/components/PaymentDisputeHelp'

export const PaymentDisputeHeader = () => {
  const history = useHistory()

  return (
    <Container>
      <ActionsWrapper>
        <Button
          variant="ghost"
          iconStart={<Icon path={mdiArrowLeft} />}
          onClick={() => history.goBack()}
        >
          Voltar
        </Button>

        <PaymentDisputeHelp />
      </ActionsWrapper>

      <PageInfoWrapper>
        <Heading variant="heading-h3-medium">Cobranças indevidas</Heading>
        <Text variant="subtitle-regular">
          Visualize abaixo as <strong>faturas declaradas como cobrança indevida</strong> pelos
          responsáveis ao time de suporte do isaac.&nbsp;
          <strong>As contestações tem prazo de 30 dias para serem concluídas</strong> e, caso a
          escola não tome nenhuma ação dentro deste prazo, ocorrerá uma&nbsp;
          <strong>baixa automática dos valores devidos.</strong>
        </Text>
      </PageInfoWrapper>
    </Container>
  )
}
