import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { PageWrapper } from '@/shared/components/PageWrapper'

import { PageFooter } from './components/PageFooter/'
import { PageHeader } from './components/PageHeader'
import * as Styled from './styles'

export const ChangeContractOwnership = () => {
  useSetPageTitle('Troca de titularidade de contrato')

  return (
    <>
      <PageHeader />
      <PageWrapper>
        <Styled.FooterSpaceClearance>
          <div style={{ display: 'flex' }} />
          <PageFooter />
        </Styled.FooterSpaceClearance>
      </PageWrapper>
    </>
  )
}
