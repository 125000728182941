import { formatCentsToReal } from '@/shared/utils/numberFormatters'
import { TextField, TextFieldProps } from '@gravity/text-field'
import NumberFormat from 'react-number-format'

type CurrencyInputProps = {
  error?: boolean
  errorMessage?: string
  label: string
  name: string
  onChange?: (value: string) => void
  value?: string
}

const CustomInput = (props: Omit<TextFieldProps, 'ref'>) => (
  <TextField fullWidth size={3} {...props} />
)

export const CurrencyInput = ({
  error,
  errorMessage,
  label,
  name,
  value,
  onChange,
}: CurrencyInputProps) => {
  return (
    <NumberFormat
      valueIsNumericString
      onValueChange={({ value }) => {
        onChange?.(value ?? '')
      }}
      customInput={CustomInput}
      variant="outlined"
      label={label}
      format={formatCentsToReal}
      value={value}
      name={name}
      error={error}
      errorMessage={errorMessage}
    />
  )
}
