import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks'
import { onboardingAPI } from '../services/onboardingApi'

export type NotifyBatchParams = {
  batchID: uuid
}

export const useNotifyBatchMutation = (
  options?: UseMutationOptions<any, unknown, NotifyBatchParams, unknown>
) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useMutation(async (params: NotifyBatchParams) => {
    await api.notifyBatch(params)
  }, options)
}
