import { Table } from '@gravity/table'

import { usePagination } from '@/shared/hooks/usePagination'

import { useParams } from 'react-router-dom'
import { useStudentList } from '@monorepo/onboarding/hooks/useStudentList'
import { LoadingTable } from '@monorepo/onboarding/pages/components/LoadingTable'
import { NoInvalidData } from '../NoInvalidData'
import { Student } from '@monorepo/onboarding/services/types/student'
import { GenericError } from '../GenericError'
import { isFieldInvalid } from '@monorepo/onboarding/utils/isFieldInvalid'
import { PaginationFooter } from '../PaginationFooter'
import { TableErrorBadge } from '../TableErrorBadge'
import { TableAction } from '../TableAction'
import { useEffect, useState } from 'react'
import { EditStudentDialog } from './EditStudentDialog'
import { OptionalFieldBadge } from '../OptionalFieldBadge'
import { formatCPF } from '@/shared/utils'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'

const DEFAULT_ITEMS_PER_PAGE = 10

export const StudentTable = () => {
  const { pagination, updatePaginationValue } = usePagination(1, DEFAULT_ITEMS_PER_PAGE)
  const { batchID } = useParams<{ batchID: string }>()

  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null)

  const events = useEvents()

  useEffect(() => {
    events?.studentsTabViewed()
  }, [])

  const params = {
    batchID,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  }

  const { data, isLoading, isError } = useStudentList(params)

  const tableData = {
    columns: [
      {
        id: 'name',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Student) => <Table.TextCell>{item.name}</Table.TextCell>,
      },
      {
        id: 'birth_date',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Student) => <Table.TextCell>{item.birth_date}</Table.TextCell>,
      },
      {
        id: 'tax_id',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Student) =>
          item.tax_id !== '' ? (
            <Table.TextCell>{formatCPF(item.tax_id)}</Table.TextCell>
          ) : (
            <OptionalFieldBadge />
          ),
      },
    ],
  }

  if (isError) return <GenericError />

  if (isLoading || data === undefined)
    return <LoadingTable rowsLength={pagination.itemsPerPage} columnsLength={3} />

  if (data.data.length === 0) return <NoInvalidData />

  return (
    <>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell name="student" minWidth={80}>
            Aluno
          </Table.HeaderCell>
          <Table.HeaderCell name="birthDate" minWidth={80}>
            Data de nascimento
          </Table.HeaderCell>
          <Table.HeaderCell name="taxID">CPF</Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {data.data.map(student => (
            <Table.Row key={student.id}>
              {tableData.columns.map(column => {
                return isFieldInvalid('student', column.id, student.validations)
                  ? column.renderCellError
                  : column.renderCell(student)
              })}
              <TableAction
                onClick={() => {
                  setSelectedStudent(student)
                }}
              />
            </Table.Row>
          ))}
        </Table.Body>
        {data?.pagination?.total && (
          <PaginationFooter
            pagination={pagination}
            total={data?.pagination.total}
            updatePaginationValue={updatePaginationValue}
          />
        )}
      </Table.Root>

      {selectedStudent && (
        <EditStudentDialog
          params={params}
          setSelectedStudent={setSelectedStudent}
          selectedStudent={selectedStudent}
        />
      )}
    </>
  )
}
