import { useState } from 'react'

import { IconButton } from '@gravity/icon-button'
import { Icon } from '@mdi/react'
import { mdiCodeTags } from '@mdi/js'

import { DSHighlighterDialog } from './DSHighlighterDialog'

import config from '@/config'

export const DSHighlighter = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const isDevEnv = config.ENV === 'development'

  const isHighlighterEnabled = localStorage.getItem('_DS_HIGHLIGHTER_ENABLED') === 'true'
  const isDialogStepDisabled = localStorage.getItem('_DS_HIGHLIGHTER_DIALOG_DISABLED') === 'true'

  const handleClick = () => {
    if (isHighlighterEnabled || isDialogStepDisabled) {
      localStorage.setItem('_DS_HIGHLIGHTER_ENABLED', String(!isHighlighterEnabled))
      location.reload()
    } else {
      setIsDialogOpen(true)
    }
  }

  const handleConfirm = () => {
    localStorage.setItem('_DS_HIGHLIGHTER_ENABLED', String(!isHighlighterEnabled))

    location.reload()
  }

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  return (
    <>
      {isDevEnv && (
        <>
          <IconButton variant={isHighlighterEnabled ? 'solid' : 'ghost'} onClick={handleClick}>
            <Icon path={mdiCodeTags} />
          </IconButton>

          <DSHighlighterDialog
            open={isDialogOpen}
            onConfirm={handleConfirm}
            onClose={handleClose}
          />
        </>
      )}
    </>
  )
}
