import { Callout } from '@gravity/callout'

import { formatCentsToReal } from '@/shared/utils'

import { SectionErrorState } from '../../../SectionErrorState'
import { FinancesLoading } from '../FinancesLoading'
import { AmountCard } from '../AmountCard'

import { Container, FinanceValuesContainer } from './styles'

type FinancesContentProps = {
  hasError: boolean
  hasPendingsWithGuardians: boolean
  isLoading: boolean
  onCalloutLinkClick: () => void
  onRetry?: () => void
  openAmount: number
  overdueAmount: number
}

export const FinancesContent = ({
  hasError,
  hasPendingsWithGuardians,
  isLoading,
  onRetry,
  openAmount,
  overdueAmount,
  onCalloutLinkClick,
}: FinancesContentProps) => {
  if (hasError && !!onRetry) {
    return <SectionErrorState onRetry={onRetry} />
  }

  if (isLoading) {
    return (
      <Container>
        <FinancesLoading />
      </Container>
    )
  }

  return (
    <Container>
      <FinanceValuesContainer>
        <AmountCard title="Vencido" amount={formatCentsToReal(overdueAmount)} />
        <AmountCard title="A vencer" amount={formatCentsToReal(openAmount)} />
      </FinanceValuesContainer>

      {hasPendingsWithGuardians && (
        <Callout
          text="Responsáveis financeiros possuem pendências em outros alunos."
          linkLabel="Ver pendências"
          onLinkClick={() => onCalloutLinkClick()}
        />
      )}
    </Container>
  )
}
