import styled from 'styled-components'

export const SelectProductContainer = styled.div`
  margin-bottom: 42px;
  width: 273px;
`

export const ContentContainer = styled.div`
  padding: 1px 4px;
`

export const IrregularityTableContainer = styled.div`
  margin-bottom: 48px;
`

export const CancelButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row-reverse;
`
