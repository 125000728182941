import { ReenrollmentStatus } from '@/shared/interfaces'
import theme from '@/shared/theme'

type StatusColors = '#FEF1F0' | '#FAFAFE' | '#FFFAF5' | '#F9F9F9' | '#F6F6F6'

export const useStatusData = (status: ReenrollmentStatus) => {
  const statusColor: Record<ReenrollmentStatus, { color: StatusColors }> = {
    [ReenrollmentStatus.NOT_REENROLLABLE]: { color: theme.primitiveTokens.colors.coral[10] },
    [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: { color: '#FAFAFE' },
    [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: { color: theme.primitiveTokens.colors.beige[10] },
    [ReenrollmentStatus.ENROLLMENT_AT_RISK]: { color: theme.primitiveTokens.colors.coral[10] },
    [ReenrollmentStatus.ENROLLED]: { color: theme.primitiveTokens.colors.gray[2] },
    [ReenrollmentStatus.GATE_ERROR]: { color: theme.primitiveTokens.colors.gray[2] },
    [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: { color: theme.primitiveTokens.colors.gray[3] },
  }

  const statusContent: Record<ReenrollmentStatus, { text: string }> = {
    [ReenrollmentStatus.NOT_REENROLLABLE]: { text: '' },
    [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: { text: '' },
    [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: { text: '' },
    [ReenrollmentStatus.ENROLLMENT_AT_RISK]: { text: '' },
    [ReenrollmentStatus.ENROLLED]: { text: '' },
    [ReenrollmentStatus.GATE_ERROR]: {
      text:
        'Desculpe o inconveniente. Estamos trabalhando para resolver o problema e exibir a situação de matrícula do aluno. Por favor, tente novamente mais tarde.',
    },
    [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: {
      text:
        'Desculpe o inconveniente. Estamos trabalhando para resolver o problema e exibir a situação de matrícula do aluno. Por favor, tente novamente mais tarde.',
    },
  }

  return {
    statusColor: statusColor[status]?.color ?? statusColor.UNAVAILABLE_AT_MOMENT.color,
    statusText: statusContent[status]?.text ?? '',
  }
}
