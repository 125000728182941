import { FormHelperText, InputLabel, Select } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import { DatePicker } from '@olaisaac/design-system'
import { omit } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { Discount, OldProduct as Product } from 'src/shared/interfaces'
import {
  DATE_FORMAT,
  formatCentsToReal,
  isCurrentMonthOrAfter,
  isDayTodayOrAfter,
  makeIsCurrentCycleOrAfter,
  renderNumericSelectOptions,
} from 'src/shared/utils'
import styled from 'styled-components'

import DiscountForm from '../../DiscountForm'
import SelectDueDay from '../ContractEditDrawer/components/SelectNewDueDay/SelectNewDueDay'
import { AddDiscountFormType } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/AddDiscountContent'
import EnrollmentForm from './EnrollmentForm'
import { EnrollmentFormFields, PaymentPlanFormProps } from './types'
import { getDefaultEnrollmentFormFields } from './utils'
import { CURRENT_YEAR } from '../constants'
import dayjs from 'dayjs'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useMaxInstallmentValidation } from './hooks/useMaxInstallmentValidation'
import { Callout } from '@gravity/callout'

export const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`
export const DueDayBox = styled(Box)`
  margin-bottom: 4px;
  margin-top: 24px;
`
export const StartMonthBox = styled(Box)`
  margin: 34px 0;
`
export const SessionTitleTypography = styled(StyledTypography)`
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  color: #414141;
`
export const FieldTitleTypography = styled(StyledTypography)`
  line-height: 24px;
  margin-bottom: 4px;
  color: #414141;
`
export const FieldTitle2Typography = styled(FieldTitleTypography)`
  font-size: 18px;
`
export const FieldLegendTypography = styled(StyledTypography)`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  color: #6d6d6d;
`
export const ArithmeticOperator = styled(StyledTypography)`
  display: flex;
  align-items: center;
  margin: 0 12px;
  font-weight: 500;
  font-size: 18px;
  color: #414141;
`
const StyledButton = styled(Button)`
  text-transform: none;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 70%;
`

export const PaymentPlanForm = ({
  form,
  enrollmentFormFields,
  setEnrollmentFormFields,
  discountsForm,
  enrollmentForm,
  isEnrollmentEnabled,
  setEnrollmentEnabled,
  product,
}: PaymentPlanFormProps) => {
  const [canEnableEnrollment, setCanEnableEnrollment] = useState<boolean>(false)
  const isTuitionDisabled = useUnleashFlag(UnleashFlags.MAT_957_PRODUCT_WITHOUT_TUITION)

  const { formState, control, getValues, setValue, watch, trigger } = form
  const formValues = getValues()
  watch([
    'enrollment_duration_months',
    'enrollment_due_day',
    'use_working_dates_enrollment',
    'duration_months',
    'due_day',
    'product_id',
    'custom_enrollment_monthly_amount',
    'custom_monthly_amount',
    'start_month',
  ])

  const { enrollment_duration_months, duration_months, start_month } = formValues

  const {
    maxTuitionInstallments,
    minTuitionInstallments,
    isSelectedTuitionDurationMonthsValid,
    tuitionDurationMonthsValidationText,
    tuitionDurationMonthsValidation,
  } = useMaxInstallmentValidation({ form, isTuitionDisabled })

  useEffect(() => {
    if (enrollment_duration_months === '1') {
      setValue('use_working_dates_enrollment', 'false')
    }
  }, [enrollment_duration_months])

  useEffect(() => {
    if (duration_months === '1') {
      setValue('use_working_dates_tuition', 'false')
    }
  }, [duration_months])

  const { control: discountsFormControl, getValues: discountsFormGetValues } = discountsForm

  const discountsFormValues = discountsFormGetValues()
  const discounts = discountsFormValues?.discounts

  const { fields: discountFields, append, remove } = useFieldArray({
    control: discountsFormControl,
    name: 'discounts',
  })

  const removeDiscount = (index: number) => remove(index)

  const appendDiscount = (discount: Discount & Partial<AddDiscountFormType>) => {
    append({
      amount: discount?.amount,
      days_before_due_date: discount?.days_before_due_date,
      description: discount?.description,
      isFinished: false,
      label_days_before_due_date: discount?.label_days_before_due_date,
    })
  }

  const appendNewDiscount = () => {
    appendDiscount({
      amount: null,
      days_before_due_date: null,
      description: '',
    })
  }

  const invalidStartDate = Boolean(formState.errors.start_month)

  const enrollmentFormFieldsChangeHandler = (newFormField: Partial<EnrollmentFormFields>) => {
    setEnrollmentFormFields(prevState => {
      return { ...prevState, ...newFormField }
    })
  }

  const shouldShowDiscountAddCondition = discounts?.length < 3
  const durationMonthsString = getValues('duration_months')
  const durationMonths = durationMonthsString ? +durationMonthsString : 0
  const hasMultipleInstallments = durationMonths > 1
  const installmentTotalAmount = formValues.custom_monthly_amount * durationMonths
  const helperTextCycle =
    Number(formValues.reference_year) >= CURRENT_YEAR
      ? `Selecione um mês e ano entre ${dayjs().month() + 1}/${CURRENT_YEAR} e 12/${
          formValues.reference_year
        }`
      : `Selecione um mês e ano entre ${dayjs().month() + 1}/${CURRENT_YEAR} e 12/${CURRENT_YEAR}`

  const isCurrentCycleOrAfter = makeIsCurrentCycleOrAfter(formValues.reference_year)

  useEffect(() => {
    setValue('start_month', isTuitionDisabled ? dayjs() : null)
    setValue('due_day', isTuitionDisabled ? dayjs().date() : null)
  }, [hasMultipleInstallments, isTuitionDisabled])

  const updateProductPaymentFields = (product: Product) => {
    const { setValue } = form

    setValue('custom_monthly_amount', product.monthly_amount)
    setValue('custom_enrollment_monthly_amount', product.enrollment_amount)

    setCanEnableEnrollment(product.enrollment_amount > 0)
    setEnrollmentEnabled(product.enrollment_amount > 0)
    setEnrollmentFormFields(getDefaultEnrollmentFormFields(product.enrollment_amount))

    if (product.installments_quantity > 0) {
      const productTuitionInstallmentsQuantity = Math.min(
        product.installments_quantity,
        maxTuitionInstallments
      )

      setValue(
        'duration_months',
        isTuitionDisabled ? '0' : productTuitionInstallmentsQuantity.toString(),
        {
          shouldValidate: true,
        }
      )
    }

    if (product.enrollment_installments_quantity > 0) {
      setValue('enrollment_duration_months', product.enrollment_installments_quantity.toString(), {
        shouldValidate: true,
      })
    }
  }
  useEffect(() => {
    if (!product) return

    if (!product.id) {
      setCanEnableEnrollment(false)
      setEnrollmentEnabled(false)
      setEnrollmentFormFields(getDefaultEnrollmentFormFields(0))
      return
    }

    updateProductPaymentFields(product)
  }, [product, isTuitionDisabled])

  useEffect(() => {
    if (duration_months && start_month) {
      trigger('duration_months')
    }
  }, [duration_months, start_month])

  return (
    <Box>
      {canEnableEnrollment && (
        <EnrollmentForm
          form={form}
          enrollmentForm={enrollmentForm}
          enrollmentFormFields={enrollmentFormFields}
          isEnrollmentEnabled={isEnrollmentEnabled}
          setEnrollmentEnabled={setEnrollmentEnabled}
          handleEnrollmentFormFieldsChange={enrollmentFormFieldsChangeHandler}
        />
      )}

      {!isTuitionDisabled && (
        <div>
          <Box mt="60px">
            <SessionTitleTypography>Mensalidade</SessionTitleTypography>
            <FieldLegendTypography>
              O valor da mensalidade é definido a partir das configurações do produto. Caso queira,
              você pode alterar o valor da parcela abaixo.
            </FieldLegendTypography>
          </Box>

          <Callout text="Para vencimento informado no passado, atualizamos o vencimento da primeira parcela em 4 dias. Funciona assim: se você está no dia 10/01 e informa o vencimento para 05/01, a primeira parcela vai ter vencimento para 14/01." />

          <Box display="flex" justifyContent="space-between" alignItems="bottom" mb={3} mt={4}>
            <FormControl fullWidth variant="outlined" error={tuitionDurationMonthsValidation}>
              <InputLabel id="duration_months-label">Parcelas</InputLabel>
              <Controller
                name="duration_months"
                control={control}
                rules={{
                  required: !isEnrollmentEnabled,
                  validate: value => isSelectedTuitionDurationMonthsValid(value),
                }}
                render={({ field: { ...rest } }) => (
                  <Select
                    {...rest}
                    data-testid="duration_months-select"
                    labelId="duration_months-label"
                    id="duration_months-select"
                    label="Parcelas"
                    error={Boolean(formState.errors.duration_months)}
                  >
                    {renderNumericSelectOptions({
                      start: minTuitionInstallments,
                      end: maxTuitionInstallments,
                      name: 'duration_months-option',
                    })}
                  </Select>
                )}
              />
              {tuitionDurationMonthsValidation && (
                <FormHelperText>{tuitionDurationMonthsValidationText}</FormHelperText>
              )}
            </FormControl>
            <ArithmeticOperator>x</ArithmeticOperator>
            <FormControl variant="outlined" fullWidth>
              <Controller
                rules={{ required: true, validate: v => v >= 100 }}
                control={control}
                name="custom_monthly_amount"
                render={({ field: { onChange, value, ref } }) => (
                  <NumberFormat
                    id="custom_monthly_amount-input"
                    inputRef={ref}
                    onValueChange={({ floatValue }) => {
                      onChange(floatValue)
                    }}
                    customInput={TextField}
                    variant="outlined"
                    label="Valor da parcela"
                    format={formatCentsToReal}
                    InputProps={{
                      inputProps: { min: 100 },
                    }}
                    value={value}
                    error={Boolean(formState.errors.custom_monthly_amount)}
                    helperText={
                      formState.errors.custom_monthly_amount ? 'Insira um valor válido' : ''
                    }
                  />
                )}
              />
            </FormControl>
            <ArithmeticOperator>=</ArithmeticOperator>
            <FormControl fullWidth disabled variant="outlined">
              <NumberFormat
                disabled
                value={installmentTotalAmount}
                placeholder="R$ 0,00"
                customInput={TextField}
                variant="outlined"
                label="Valor total"
                format={formatCentsToReal}
                id="installment-total-amount"
              />
            </FormControl>
          </Box>
          <StartMonthBox>
            <FieldTitleTypography>
              {hasMultipleInstallments ? 'Início das mensalidades' : 'Data de vencimento'}
            </FieldTitleTypography>
            <FieldLegendTypography>
              {hasMultipleInstallments
                ? 'Selecione o mês de início das parcelas'
                : 'Selecione o dia de vencimento'}
            </FieldLegendTypography>
            <FormControl variant="outlined">
              {hasMultipleInstallments ? (
                <Controller
                  rules={{
                    required: true,
                    validate: v => isCurrentCycleOrAfter(v) && isCurrentMonthOrAfter(v),
                  }}
                  name="start_month"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      views={['year', 'month']}
                      id="start_month"
                      label="Mês"
                      placeholder="MM/YYYY"
                      format="MM/YYYY"
                      disablePast
                      maxDate={
                        Number(formValues.reference_year) >= CURRENT_YEAR
                          ? new Date(Number(formValues.reference_year), 11, 31)
                          : new Date(Number(CURRENT_YEAR), 11, 31)
                      }
                      minDateMessage="A data não pode ser anterior a hoje"
                      invalidDateMessage="Formato de data inválido"
                      error={invalidStartDate}
                      helperText={invalidStartDate ? helperTextCycle : ''}
                      {...omit(['ref'], field)}
                    />
                  )}
                />
              ) : (
                <Controller
                  rules={{
                    required: true,
                    validate: v => isCurrentCycleOrAfter(v) && isDayTodayOrAfter(v),
                  }}
                  name="start_month"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      id="start_month"
                      label="Dia"
                      placeholder={DATE_FORMAT}
                      format={DATE_FORMAT}
                      disablePast
                      maxDate={
                        Number(formValues.reference_year) >= CURRENT_YEAR
                          ? new Date(Number(formValues.reference_year), 11, 31)
                          : new Date(Number(CURRENT_YEAR), 11, 31)
                      }
                      minDateMessage="A data não pode ser anterior a hoje"
                      invalidDateMessage="Formato de data inválido"
                      error={invalidStartDate}
                      helperText={invalidStartDate ? 'Selecione uma data de vencimento válida' : ''}
                      {...omit(['ref'], field)}
                    />
                  )}
                />
              )}
            </FormControl>
          </StartMonthBox>
          {hasMultipleInstallments && (
            <Box mb={3} mt={4}>
              <DueDayBox>
                <FieldTitleTypography>Data de vencimento</FieldTitleTypography>
                <FieldLegendTypography>
                  Selecione a regra de dia de vencimento das mensalidades
                </FieldLegendTypography>
              </DueDayBox>
              <SelectDueDay form={form} inputName="due_day" radioName="use_working_dates_tuition" />
            </Box>
          )}
          {discounts?.length ? (
            <>
              <Box mb={1} mt={2}>
                <Typography>Descontos</Typography>
              </Box>
              <Box mb={2}>
                <Typography>Cadastre os descontos que entrarão em todo o contrato</Typography>
              </Box>
            </>
          ) : null}
          {discountFields?.map((data, index) => (
            <Box key={data.id} width="70%">
              <DiscountForm
                data={data}
                index={index}
                form={discountsForm}
                idPrefix="tuition"
                totalAmount={formValues.custom_monthly_amount}
                removeDiscount={removeDiscount}
              />
            </Box>
          ))}
          {shouldShowDiscountAddCondition && (
            <Box mb={4} mt={4}>
              <StyledButton
                variant="outlined"
                color="primary"
                aria-label="adicionar desconto na matrícula"
                size="large"
                fullWidth
                endIcon={<AddIcon />}
                onClick={() => appendNewDiscount()}
              >
                Adicionar desconto às mensalidades
              </StyledButton>
            </Box>
          )}
        </div>
      )}
    </Box>
  )
}

export default PaymentPlanForm
