import dayjs, { Dayjs } from 'dayjs'
import { InstallmentV2 } from '../../services/types'
import { getStartOfToday, makeIsDayBeforeDate } from '@/shared/utils'

type ValidationForPaidDateParams = {
  installment: InstallmentV2
  isValidatePaidDateBefore7Days?: boolean
  paidDate: Dayjs
}

export const getValidationForPaidDate = ({
  paidDate,
  installment,
  isValidatePaidDateBefore7Days,
}: ValidationForPaidDateParams) => {
  // Helper function to validate if date is before the minimum date
  const validateDate = (minDate: Dayjs, message: string) => {
    if (makeIsDayBeforeDate(minDate)(paidDate)) {
      return { isBeforeMinDate: true, message }
    }
    return null
  }

  // Case 1: Created At Contract
  const createdAtContract = dayjs(installment.contracts?.[0]?.created_at).utc()
  const validationResult = validateDate(
    createdAtContract,
    'Data não pode ser menor que a data de criação do contrato'
  )
  if (validationResult) return validationResult

  // Case 2: Down Payment
  if (installment.type === 'DOWN_PAYMENT') {
    const negotiationDate = dayjs(installment.origin_negotiation?.negotiation_date).utc()
    const validationResult = validateDate(
      negotiationDate,
      'Data não pode ser menor que a data de negociação'
    )
    if (validationResult) return validationResult
  }

  // Case 3: Paid Date before 7 days
  if (isValidatePaidDateBefore7Days) {
    const minDateBefore7Days =
      installment.status === 'PAID'
        ? dayjs(installment.paid_date).subtract(7, 'day').utc()
        : getStartOfToday().subtract(7, 'day').utc()

    const validationResult = validateDate(
      minDateBefore7Days,
      installment.status === 'PAID'
        ? 'Data não pode ser menor que 7 dias antes da data de pagamento'
        : 'Data não pode ser menor que 7 dias antes da data de hoje'
    )
    if (validationResult) return validationResult
  }

  return { isBeforeMinDate: false, message: '' }
}
