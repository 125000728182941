import { Button } from '@gravity/button'
import { Text } from '@gravity/text'

import IsaacLogo from '@/shared/assets/isaac-logo.svg'

import { Container } from './styles'

type MobileDisclaimerProps = {
  onContinue: VoidFunction
}

export const MobileDisclaimer = ({ onContinue }: MobileDisclaimerProps) => {
  return (
    <Container>
      <img src={IsaacLogo} alt="isaac" />

      <Text as="p" className="disclaimer" variant="body-2-regular">
        Acesse o portal por computador ou tablet para obter a melhor experiência e ter acesso a
        todos os serviços isaac.
      </Text>

      <Button fullWidth onClick={onContinue}>
        Continuar
      </Button>
    </Container>
  )
}
