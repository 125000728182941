import { useEffect } from 'react'

import { Maintenance } from './Maintenance'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'
import { GenericError } from '@/shared/components/GenericError'

import { useJWT } from '@/shared/hooks/useJWT'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { useCheckUnleash } from '@/shared/hooks/useCheckUnleash'

export function AppWrapper({ children }: { children: React.ReactNode }) {
  const { userId, name, email } = useJWT()
  const { flagsReady, isUnleashError } = useCheckUnleash()

  const isMaintenancePageEnabled = useUnleashFlag(UnleashFlags.ENABLE_MAINTENANCE_PAGE)

  useEffect(() => {
    if (userId && name && email) {
      window.userGuiding.identify(userId, { userId, name, email })
    }
  }, [userId, name, email])

  if (isUnleashError) {
    return (
      <GenericError
        onRetry={() => {
          window.location.reload()
        }}
      />
    )
  }

  if (!flagsReady) {
    return <CircularLoadingPlaceholder />
  }

  if (isMaintenancePageEnabled) {
    return <Maintenance />
  }

  return <>{children}</>
}
