import { Table } from '@gravity/table'

import { usePagination } from '@/shared/hooks/usePagination'

import { useParams } from 'react-router-dom'

import {
  CONTRACTS_BY_PRODUCT_QUERY_KEY,
  useContractsByProduct,
} from '@monorepo/onboarding/hooks/useContractsByProduct'
import { LoadingTable } from '@monorepo/onboarding/pages/components/LoadingTable'
import {
  Contract,
  GetContractsResponse,
} from '@monorepo/onboarding/services/types/onboardingContract'
import { isFieldInvalid } from '@monorepo/onboarding/utils/isFieldInvalid'
import { GenericError } from '../GenericError'
import { NoInvalidData } from '../NoInvalidData'
import { PaginationFooter } from '../PaginationFooter'

import { useUpdateProductMutation } from '@monorepo/onboarding/hooks/useUpdateProductMutation'
import { useToast } from '@gravity/toast'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { TableAction } from '../TableAction'
import { EditDialog } from './EditDialog'
import { TableErrorBadge } from '../TableErrorBadge'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { GET_ONBOARDING_BATCH_QUERY_KEY } from '@monorepo/onboarding/hooks/useFetchOnboardingBatches'

const DEFAULT_ITEMS_PER_PAGE = 10

export const ContractTableByProduct = () => {
  const { pagination, updatePaginationValue } = usePagination(1, DEFAULT_ITEMS_PER_PAGE)
  const { batchID } = useParams<{ batchID: string }>()
  const mutation = useUpdateProductMutation()
  const { toast } = useToast()
  const events = useEvents()

  useEffect(() => {
    events?.contractByProductTabViewed()
  }, [])

  const [selectedContract, setSelectedContract] = useState<Contract | null>(null)
  const queryClient = useQueryClient()

  const params = {
    batchID,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  }

  const { data, isError, isFetching } = useContractsByProduct(params, { retry: 2 })

  const onSave = () => {
    if (selectedContract === null) {
      return
    }

    mutation.mutate(
      { batchID, product: selectedContract.product },
      {
        onSuccess: data => {
          if (data.is_valid) {
            setSelectedContract(null)
            queryClient.invalidateQueries([CONTRACTS_BY_PRODUCT_QUERY_KEY])
            // TODO: consider only invalidating if there's no other invalid contracts
            queryClient.invalidateQueries([GET_ONBOARDING_BATCH_QUERY_KEY, batchID])

            toast({
              type: 'success',
              title: 'Correção realizada com sucesso',
              description: 'Este curso saiu da tabela de pendências e está na fila de migração',
            })
            return
          }

          setSelectedContract({ ...selectedContract, product: data })

          queryClient.setQueryData(
            [CONTRACTS_BY_PRODUCT_QUERY_KEY, params],
            (old: GetContractsResponse | undefined) => {
              if (old === undefined) {
                return undefined
              }

              const updatedContracts = old.data.map(contract => {
                if (contract.id === selectedContract.id) {
                  return { ...selectedContract, product: data }
                }

                return contract
              })

              const updatedData: GetContractsResponse = {
                ...old,
                data: updatedContracts,
              }

              return updatedData
            }
          )
        },
        onError: error => {
          setSelectedContract(null)
          toast({
            type: 'error',
            title: 'Erro ao salvar correção.',
            description: 'Tente corrigir novamente',
          })
          console.log(error)
        },
      }
    )
  }

  const tableData = {
    columns: [
      {
        id: 'course',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Contract) => <Table.TextCell>{item.product.course}</Table.TextCell>,
      },
      {
        id: 'name',
        renderCell: (item: Contract) => <Table.TextCell>{item.student.name}</Table.TextCell>,
      },
      {
        id: 'reference_year',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Contract) => (
          <Table.TextCell>{item.product.reference_year}</Table.TextCell>
        ),
      },
      {
        id: 'grade',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Contract) => <Table.TextCell>{item.product.grade}</Table.TextCell>,
      },
    ],
  }

  if (isError) return <GenericError />

  if (isFetching || data === undefined)
    return <LoadingTable rowsLength={pagination.itemsPerPage} columnsLength={4} />

  if (data.data.length === 0) return <NoInvalidData />

  return (
    <>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell name="course" minWidth={80}>
            Curso
          </Table.HeaderCell>
          <Table.HeaderCell name="name" minWidth={80}>
            Aluno
          </Table.HeaderCell>
          <Table.HeaderCell name="reference_year">Ano letivo</Table.HeaderCell>
          <Table.HeaderCell name="grade">Série</Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {data.data.map(contract => (
            <Table.Row key={contract.id}>
              {tableData.columns.map(column => {
                return isFieldInvalid('product', column.id, contract.product.validations)
                  ? column.renderCellError
                  : column.renderCell(contract)
              })}
              <TableAction
                onClick={() => {
                  return setSelectedContract(contract)
                }}
              />
            </Table.Row>
          ))}
        </Table.Body>
        {data?.pagination?.total && (
          <PaginationFooter
            pagination={pagination}
            total={data?.pagination.total}
            updatePaginationValue={updatePaginationValue}
          />
        )}
      </Table.Root>

      {selectedContract !== null && (
        <EditDialog
          setCurrentContract={setSelectedContract}
          contract={selectedContract}
          onSave={onSave}
          isLoading={mutation.isLoading}
        />
      )}
    </>
  )
}
