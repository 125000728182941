import { useApiClient } from '@/shared/hooks'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { onboardingAPI } from '@monorepo/onboarding/services/onboardingApi'
import { GetContractsResponse } from '@monorepo/onboarding/services/types/onboardingContract'
import { GetOnboardingBatchRequest } from '../services/types/commonTypes'

export const CONTRACTS_BY_PRODUCT_QUERY_KEY = 'getContractsByProduct'

export const useContractsByProduct = (
  params: GetOnboardingBatchRequest,
  options?: UseQueryOptions<GetContractsResponse>
) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useQuery<GetContractsResponse>(
    [CONTRACTS_BY_PRODUCT_QUERY_KEY, params],
    () => api.getContractsByProduct(params),
    options
  )
}
