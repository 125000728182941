import { Dispatch, SetStateAction } from 'react'
import { Text } from '@gravity/text'
import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'
import { Select } from '@gravity/select'

import { Icon } from '@mdi/react'
import { mdiInformationOutline } from '@mdi/js'

import { StudentStatusBadge } from '../StudentStatusBadge'
import { RefreshSituationButton } from './components/RefreshSituationButton'
import { StatusEnrollmentError } from './components/StatusEnrollmentError'
import { StatusDetailCards } from './components/StatusDetailCards'
import { EnabledEnrollmentCycle } from './hooks/useSelectCycle'
import { useStatusData } from './hooks/useStatusData'
import * as Styled from './styles'

type StatusEnrollmentSectionProps = {
  data: EnabledEnrollmentCycle
  enabledCycles: {
    label: string
    value: string
  }[]
  hasEnrollmentCycleEnabled: boolean
  mainGuardianId: string
  onRetry?: () => void
  selectValue: number
  setSelectedCycleYear: Dispatch<SetStateAction<number>>
  shouldShowSelectCycleYearDropdown?: boolean
  studentId: string
}

export const StatusEnrollmentSection = ({
  data,
  hasEnrollmentCycleEnabled,
  onRetry,
  enabledCycles,
  selectValue,
  setSelectedCycleYear,
  shouldShowSelectCycleYearDropdown,
  studentId,
  mainGuardianId,
}: StatusEnrollmentSectionProps) => {
  const { statusText, statusColor } = useStatusData(data.student_status)

  if (!data || !hasEnrollmentCycleEnabled) {
    return <StatusEnrollmentError />
  }

  return (
    <Styled.Container style={{ backgroundColor: statusColor }}>
      <Styled.BannerHeader>
        <Text variant="subtitle-regular">{`Situação de matrícula ${data.reference_year}`}</Text>
        <Styled.BadgeContainer>
          <StudentStatusBadge status={data.student_status} />
          {statusText ? (
            <Tooltip position="bottom" text={statusText}>
              <IconButton variant="ghost" className="tooltip-button">
                <Icon path={mdiInformationOutline} />
              </IconButton>
            </Tooltip>
          ) : null}
        </Styled.BadgeContainer>
        <RefreshSituationButton status={data?.student_status} refreshSituation={onRetry} />
        {shouldShowSelectCycleYearDropdown && !!enabledCycles?.length && (
          <Styled.RefreshButtonContainer>
            <Select
              onValueChange={value => setSelectedCycleYear(Number(value))}
              variant="soft"
              value={String(selectValue)}
              size={2}
              options={enabledCycles}
            />
          </Styled.RefreshButtonContainer>
        )}
      </Styled.BannerHeader>
      <StatusDetailCards
        enrollmentYear={data.reference_year}
        status={data.student_status}
        details={data.student_status_details}
        studentId={studentId}
        mainGuardianId={mainGuardianId}
      />
    </Styled.Container>
  )
}
