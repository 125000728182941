import { useHistory } from 'react-router-dom'
import { useSelectedSchool } from '../useSelectedSchool'

/**
 * Hook to redirect to a specific path.
 */
export const useRedirectTo = () => {
  const { replace, push } = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const basePath = schoolSlug ? `/${schoolSlug}` : ''

  /**
   * Redirect to a specific path.
   * @param {string} path - Path to redirect.
   * @param {boolean} [replaceInHistory] - If `true`, replace the current history entry.
   */
  const redirectTo = (path: string, replaceInHistory?: boolean) => {
    if (!path) return

    const formattedPath = `${basePath}${path}`

    if (replaceInHistory) replace(formattedPath)
    else push(formattedPath)
  }

  return { redirectTo }
}
