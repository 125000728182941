import FailureFeedbackDialog, {
  FailureFeedbackContent,
} from '@/shared/components/ConfirmationDialog'

export type ContractDetailsFailureDialogProps = {
  isVisible: boolean
  setIsFailureDialogOpen: (b: boolean) => void
}

const ContractDetailsFailureDialog = ({
  isVisible,
  setIsFailureDialogOpen,
}: ContractDetailsFailureDialogProps) => {
  const handleCloseFormDialog = () => {
    setIsFailureDialogOpen(false)
  }

  return (
    <FailureFeedbackDialog
      isVisible={isVisible}
      onClose={handleCloseFormDialog}
      submitHandler={handleCloseFormDialog}
    >
      <FailureFeedbackContent />
    </FailureFeedbackDialog>
  )
}

export default ContractDetailsFailureDialog
