import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'
import { contractService } from '@/shared/services/contracts'

import type { FetchContractsByStudentResponseDTO } from '@/shared/services/contracts/types'
import type {
  ContractStatus,
  DebtStatus,
  PaginationRequest,
  SignPaymentStatus,
} from '@/shared/interfaces'

import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'

type StudentsContractsFilterType = {
  contractStatus: ContractStatus[]
  debtStatus: DebtStatus[]
  isReenrollable?: ('true' | 'false')[]
  nextYearSignPaymentStatus?: SignPaymentStatus[]
  productId: string[]
  signPaymentStatus?: SignPaymentStatus[]
}

type ContractsByStudentsQueryProps = {
  filter: Partial<StudentsContractsFilterType & { name: string; studentId: string }>
  pagination: PaginationRequest
  referenceYear?: string
  schoolId: string
}

export const useContractsByStudents = (
  { schoolId, referenceYear, filter, pagination }: ContractsByStudentsQueryProps,
  options?: UseQueryOptions<FetchContractsByStudentResponseDTO>
) => {
  const { apiClient } = useApiClient()

  const service = contractService(apiClient.getClients().privateApi)

  const queryKey = [
    'contracts',
    'students',
    JSON.stringify({ schoolId, referenceYear, ...filter, ...pagination }),
  ]

  return useQuery<FetchContractsByStudentResponseDTO>(
    queryKey,
    async () => {
      return await service.fetchContractsByStudent({
        schoolId,
        referenceYear,
        filter,
        pagination,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}
