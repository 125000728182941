import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { assoc, propEq, assocPath } from 'ramda'
import { api, GetContractParams } from '@/utils/api'
import { ListProductsFilters } from '@/shared/interfaces/product'
import {
  APIResponse,
  ContractNeverPaid,
  ContractNeverPaidDetail,
  Guardian,
  Installment,
  LiquidationPaymentMethod,
  Pagination,
  PreContract,
  OldProduct as Product,
  StartCheckoutRequestBody,
} from '@/shared/interfaces'

import {
  AgreementSimulationParams,
  MakeAgreementBody,
  DuplicateAgreementRequest,
} from '@/modules/guardians/models/agreement'

import {
  studentList,
  student,
  invoice1,
  school1,
  schoolsList,
  installment1,
  installmentsList,
  openContract,
  contractsList,
  product1,
  productsList,
  productClassifications,
  guardiansList,
  guardian1,
  guardianStudents,
  receivables,
  renegotiationResp,
  receivablesPaid,
  LiquidationResponse,
  presigned,
  processingResponse,
  guardiansStudentSchoolMatchesList,
  contractsOverviewsList,
  contractsList2021,
  returnWorkingDate,
  listGroupGuardian,
  checkDuplicationResponse,
  invoice2,
  mockUnifiedInvoice,
} from '@/shared/mocks'

import { mockCheckout, mockPos, mockPos2 } from '@/modules/guardians/mocks/checkout'
import { mockAgreementSimulations, mockEffectedResponse } from '@/modules/guardians/mocks/agreement'

import {
  acceptPaymentPlanMock,
  createCampaignMock,
  closeCampaignMock,
  fetchCampaignsMock,
  fetchEnrollmentCycleMock,
  fetchStatusSummaryMock,
  fetchStudentGuardiansMock,
  getMatriculasApiLegacyEnrollmentsMock,
  putEnrollmentMock,
  updateEnrollmentSituationMock,
  hasCampaignEnrollmentMock,
  fetchCampaignStudentsMock,
  addTuitionToCampaignMock,
  uploadSignatureDocumentMock,
  getSignatureDocumentMock,
} from '@/modules/enrollment/mocks/enrollment'
import { payoutSummary } from '@/modules/report/mocks/payoutSummary'
import { unguaranteedList } from '@/modules/report/mocks/report'

import { contractListToContractResponse } from '@/shared/utils'
import { getGuardiansMock } from '@/escolas/pages/responsaveis/ListGuardians/__mocks__/getGuardiansMock'
import { getGuardianContractsMock } from '@/escolas/pages/responsaveis/GuardianDetailsContractsTab/__mocks__/getGuardianContractsMock'
import { getGuardianMock } from '@/escolas/pages/responsaveis/GuardianDetails/__mocks__/getGuardianMock'
import { getInstallmentsMock } from '@/escolas/pages/responsaveis/GuardianDetails/__mocks__/getInstallmentsMock'
import { getGuardianFiltersOptionsMock } from '@/escolas/pages/responsaveis/GuardianDetails/__mocks__/getGuardianFiltersOptionsMock'
import type { FetchPayoutDetailsResponseDTO } from '@/escolas/services/report/types'
import type { PayoutReportType } from '@/modules/report/models/PayoutReportType'
import dayjs from 'dayjs'
import { CheckoutInstallmentsSimulationParams } from '@/modules/guardians/GuardianDetails/features/GuardianDetailsFooter/types'
import {
  AnticipationSimulationResponse,
  CreditReasonsResponse,
  CreditSimulationResponse,
  GetLockResponse,
  ResponseGetStatus,
} from '@/shared/services/credit/types'

export const payoutDetailsMock = {} as FetchPayoutDetailsResponseDTO<'tuition'>

export const mockApiMethods: ReturnType<typeof api> = {
  agreement: {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    createSimulation: async (
      params: AgreementSimulationParams | CheckoutInstallmentsSimulationParams,
      useMockServer?: boolean
    ) => mockAgreementSimulations,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    makeAgreement: async (body: MakeAgreementBody[], useMockServer?: boolean) =>
      mockEffectedResponse,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    duplicateAgreement: async (params: DuplicateAgreementRequest, useMockServer?: boolean) =>
      mockEffectedResponse[0],
  },
  auth: {
    logout: (async () => true) as any,
  },
  checkout: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCheckout: async (id: uuid) => mockCheckout,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startCheckout: async (body: StartCheckoutRequestBody) => mockCheckout,
    cancelCheckout: async (id: uuid) => {
      return {} as AxiosResponse<any>
    },
    listPos: async () => [mockPos, mockPos2],
  },
  unifiedInvoice: {
    createInvoice: async () => Promise.resolve(mockUnifiedInvoice),
  },
  preContracts: {
    get: async () => Promise.resolve({} as PreContract),
    save: async () => Promise.resolve({} as AxiosResponse<APIResponse<any, string>>),
  },
  contracts: {
    bulkEditDiscountsCreate: async () => contractsList[0],
    bulkEditDiscountsInfo: async () => ({
      original_installments: [installment1],
      new_installments: [installment1],
      is_contract_in_flexible_period: false,
    }),
    changeDueDayCreate: async () => contractsList[0],
    cancelContractInfo: async () => [installment1],
    changeDueDayInfo: async () => ({
      original_installments: [installment1],
      new_installments: [installment1],
      flexible_maximum_due_date: '',
      is_contract_in_flexible_period: false,
    }),
    changeOwnership: async () => ({
      new_contract_id: openContract.id,
      new_guardian_id: guardian1.id,
    }),
    create: async () => openContract,
    get: async (_, getParams?: GetContractParams) => {
      let contract = openContract
      if (getParams?.include_guardian) {
        contract = assoc('guardian', guardian1, contract)
      }
      if (getParams?.include_student) {
        contract = assoc('student', student, contract)
      }
      if (getParams?.include_installments) {
        contract = assoc('installments', installmentsList, contract)
        if (getParams?.include_receivables) {
          contract = assoc(
            'installments',
            installmentsList.map((inst, idx) =>
              assoc('receivables', idx % 2 ? receivables : receivablesPaid, inst)
            ),
            contract
          )
        }
      }

      return contract
    },
    fetchByGuardian: async () => {
      return {
        data: listGroupGuardian,
        pagination: { total: listGroupGuardian.length },
      }
    },
    fetchByStudent: async params => {
      const filteredContracts = contractsList.filter(({ status: currentStatus }) => {
        if (params?.filter?.contractStatus?.length && params.filter.contractStatus.length > 0) {
          return params.filter.contractStatus[0] === currentStatus
        }

        return true
      })

      return {
        data: contractListToContractResponse(filteredContracts),
        pagination: { total: filteredContracts.length },
      }
    },
    getList2021: async params => {
      if (!params)
        return {
          data: contractListToContractResponse([]),
          pagination: { total: 0 },
        }

      const { status } = params
      const filteredContracts = contractsList2021.filter(
        ({ status: currentStatus }) => !status || status === currentStatus
      )
      return {
        data: contractListToContractResponse(filteredContracts),
        pagination: { total: filteredContracts.length },
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOverviewsBySchoolStudent: async (schoolId, studentId) => contractsOverviewsList,
    getReenrollmentStats: async () => ({
      data: {
        in_progress_count: 1,
        reenrolled_count: 2,
        total_open_contracts: 10,
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    manualSign: async () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    recreateInvoices: async () => {
      return openContract
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    revoke: async () => {},
    checkDuplication: async () => checkDuplicationResponse,
    createCampaign: async () => {
      return {} as AxiosResponse<APIResponse<void, string>>
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCampaignStatus: async (schoolId: uuid) => ({
      data: {
        campaign_allow: true,
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    effect: async (ids: uuid[]) => {
      return {} as AxiosResponse<APIResponse<void, string>>
    },
    fetchNeverPaid: async (schoolId: uuid, referenceYear: string, installmentType, pagination) => {
      return {} as APIResponse<ContractNeverPaid, string>
    },
    fetchNeverPaidDetail: async (schoolId: string, referenceYear: string, uniqueKey: string) => {
      return {} as APIResponse<ContractNeverPaidDetail, string>
    },
    confirmNeverPaid: async (schoolId: string, uniqueKeys: string[]) => [],
    confirmNeverPaidBulk: async () => [],
    downloadCarne: async () => {
      return {} as AxiosResponse<Blob>
    },
  },
  date: {
    getWorkingDueDates: async () => returnWorkingDate,
    getWorkingDateFromNthDay: async () => {
      return {
        data: dayjs().toISOString(),
      }
    },
  },
  guardians: {
    create: async () => guardian1,
    // mover para guardianv2
    getListV2: async () => getGuardiansMock(),
    get: async (id: uuid) => guardiansList.find(propEq('id', id)) as Guardian,
    getAddressFromZip: async () => guardian1.address,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getContracts: async (id: uuid) => contractsList,
    getContractsList: async () => contractsList,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getMatriculasApiLegacyEnrollments: getMatriculasApiLegacyEnrollmentsMock,
    getList: async () => ({ data: guardiansList, pagination: { total: guardiansList.length } }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getStudentSchoolMatches: async (id: uuid) => guardiansStudentSchoolMatchesList,
    update: async () => guardian1,
  },
  guardianStudents: {
    update: async () => guardianStudents,
  },
  installments: {
    getList: async () => ({
      data: installmentsList,
      pagination: { total: installmentsList.length },
    }),
    get: async () => installment1,
    changeDueDayEnrollment: async () => ({} as Installment),
  },
  invoices: {
    get: async () => invoice1,
    getByIds: async () => [
      {
        additional_informations: {
          payment_method: '',
          provider_response: {
            Reason: 'other',
            Status: 'error',
            Content:
              'invalid status code. actual=422. err={"errors":{"payer.address.zip_code":["não é válido"], "payer.address.zip_code":["não é válido"], "payer.address.tax_id":["não é válido"]}}',
          },
        },
        checkout_url: invoice2.checkout_url,
        id: invoice2.id,
        referral: invoice2.referral,
      },
    ],
  },
  presigned: {
    uploadFile: async (url: string, data?: any, _config?: AxiosRequestConfig | undefined) => {
      return {} as AxiosResponse
    },
  },
  products: {
    get: async () => product1,
    getList: async (params?: Pagination & ListProductsFilters) => {
      const { page = 1, ids = '', ...p } = params || {}
      const productsIds = ids ? ids.split(',') : []

      const filterByProductsIds = (products: Product[], productIds: string[] = []) => {
        if (productIds.length === 0) return products
        return products.filter(item => productIds.includes(item.id))
      }

      if (!p.name) {
        return new Promise(function (resolve) {
          setTimeout(
            () =>
              resolve({
                data: filterByProductsIds(productsList, productsIds).slice(
                  page === 1 ? 0 : (page - 1) * 4,
                  4 * page
                ),
                pagination: { total: productsList.length },
              }),
            200
          )
        })
      }

      const filteredList = productsList.filter(item =>
        item.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            (p?.name || '')
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          )
      )

      return new Promise(function (resolve) {
        setTimeout(
          () =>
            resolve({
              data: filterByProductsIds(filteredList, productsIds).slice(
                page === 1 ? 0 : (page - 1) * 4,
                4 * page
              ),
              pagination: { total: filteredList.length },
            }),
          2000
        )
      })
    },
    getClassifications: async () => ({ data: productClassifications }),
  },
  receivables: {
    addDiscounts: async () => receivables,
    canAddDiscounts: async () => ({
      is_contract_in_flexible_period: false,
    }),
    liquidationInfo: async () => ({
      final_amount: 9000,
      final_base_amount: 10000,
      final_discount: 1000,
      final_fine_and_interest: 100,
      fine_and_interest: 100,
      lost_due_payment_discount: 1000,
      lost_early_payment_discount: 1000,
      previous_due_payment_discount: 0,
      previous_early_payment_discount: 0,
      previous_fines_and_interest: 0,
      payment_method: LiquidationPaymentMethod.PIX,
    }),
    manualLiquidation: async () => LiquidationResponse,
    patchManualLiquidation: async () => LiquidationResponse,
    printReceipt: async () => ({ data: '' } as AxiosResponse),
    renegotiate: async renegotiation =>
      assocPath(['renegotiated', 'id'], renegotiation.from, renegotiationResp),
    // eslint-disable-next-line sort-keys
    agglutinate: async () => openContract,
    fetchExternalPaymentsReportList: async () => ({
      data: receivables,
      pagination: { total: receivables.length },
    }),
    changePaymentMethod: async () => receivables[0],
  },
  schools: {
    downloadFile: async () => ({} as AxiosResponse<Blob>),
    getBySlug: async () => school1,
    getList: async () => ({ data: schoolsList, pagination: { total: schoolsList.length } }),
    getPresignedUrl: async () => presigned,
    processUploadedFile: async () => ({ data: processingResponse }),
  },
  students: {
    getList: async () => ({ data: studentList, pagination: { total: studentList.length } }),
    get: async () => student,
    createOrUpdate: async () => student,
    update: async () => student,
    updateV2: async () => student,
  },
  enrollment: {
    getSignatureDocument: getSignatureDocumentMock,
    fetchStudentGuardians: fetchStudentGuardiansMock,
    fetchEnrollmentCycle: fetchEnrollmentCycleMock,
    updateEnrollmentSituation: updateEnrollmentSituationMock,
    fetchStatusSummary: fetchStatusSummaryMock,
    putEnrollment: putEnrollmentMock,
    createCampaign: createCampaignMock,
    closeCampaign: closeCampaignMock,
    fetchCampaigns: fetchCampaignsMock,
    fetchCampaignStudents: fetchCampaignStudentsMock,
    acceptPaymentPlan: acceptPaymentPlanMock,
    hasCampaignEnrollment: hasCampaignEnrollmentMock,
    addTuitionToCampaign: addTuitionToCampaignMock,
    getMatriculasApiLegacyEnrollments: getMatriculasApiLegacyEnrollmentsMock,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    cancel: async () => {},
    uploadSignatureDocument: uploadSignatureDocumentMock,
  },
  report: {
    fetchUnguaranteedReportFile: async () => ({} as Blob),
    fetchReportList: async () => unguaranteedList,
    fetchPayoutReportFile: async () => ({} as Blob),
    fetchPayoutDetails: async <T extends PayoutReportType = 'tuition'>() =>
      payoutDetailsMock as FetchPayoutDetailsResponseDTO<T>,
    fetchPayoutMonthAggregation: async <T extends PayoutReportType = 'tuition'>() =>
      payoutDetailsMock as FetchPayoutDetailsResponseDTO<T>,
    fetchPayoutsSummary: async () => payoutSummary,
    fetchAggregatedProvidersReport: async () => ({
      data: {
        total: 425000,
        payout_total_base_amount: 425000,
        external_total_base_amount: 0,
        total_fee: 63750,
        external_total_fee: 0,
        payout_total: 361250,
        external_total: 0,
        payout_total_fee: 63750,
      },
    }),
  },
  credit: {
    getSchoolEligibility: async () => ({
      data: {
        eligibility: {
          eligible: { AR: false, CP: false, CPCR: true },
          pa: { AR: 1000, CP: 500, CPCR: 750 },
        },
        eligibleForGracePeriod: false,
        gracePeriod: {
          CP: [],
          CPCR: [],
          AR: [],
        },
      },
    }),
    createCreditSimulation: async () => ({} as CreditSimulationResponse),
    createAnticipationSimulation: async () => ({} as AnticipationSimulationResponse),
    getCreditReasons: async () => ({} as CreditReasonsResponse),
    chooseOffer: async () => ({} as APIResponse<any, string>),
    getOrderStatus: async <T = ResponseGetStatus>(): Promise<T> => {
      return {} as T
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    orderDropout: async () => {},
    getLock: async () => ({} as GetLockResponse),
  },
  guardianV2: {
    fetchGuardian: getGuardianMock,
    fetchGuardianContracts: async () => getGuardianContractsMock(),
    fetchInstallments: getInstallmentsMock,
    fetchGuardianFiltersOptions: getGuardianFiltersOptionsMock,
  },
  management: {
    fetchSchoolGroupManagement: async () => ({
      data: {
        url: 'https://olaisaac.metabaseapp.com/embed/dashboard/schoolGroup',
      },
    }),
    fetchSchoolManagement: async () => ({
      data: {
        url: 'https://olaisaac.metabaseapp.com/embed/dashboard/school',
      },
    }),
    fetchSchoolGroupEnrollmentManagement: async () => ({
      data: {
        url: 'https://olaisaac.metabaseapp.com/embed/dashboard/enrollmentSchoolGroup',
      },
    }),
  },
  selfOnboarding: {
    onboardingRedirect: async () =>
      new Promise(resolve => {
        setTimeout(
          () =>
            resolve({
              onboarding_redirect: false,
              self_onboarding_school_id: school1.id,
              is_school_active: true,
            }),
          2000
        )
      }),
  },
}

export default () => mockApiMethods
