import { useRouteMatch } from 'react-router-dom'

import { routes } from './routes'

import type { ModuleDefinition } from '@/shared/core/module'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

export const useRegisterEnrollmentModule: ModuleDefinition = module => {
  const { path: basePath } = useRouteMatch()

  const isEnabledNewStudentPage = useUnleashFlag(UnleashFlags.PE_423_ENABLE_NEW_STUDENT_PAGE)

  const filteredRoutes = routes.filter(route => {
    if (isEnabledNewStudentPage && route.key === 'STUDENT_ENROLLMENT') {
      route.redirect = '/alunos/:studentId'
    }

    return route
  })

  module.registerRoutes(filteredRoutes, { basePath })
}
