import { useApiClient } from '@/shared/hooks/useApiClient'
import { enrollmentService } from '@/modules/enrollment/services/enrollmentService'
import { useMutation } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'
import type { AddTuitionBodyRequest } from '@/modules/enrollment/services/enrollmentService/types'

type MutationProps = {
  body: AddTuitionBodyRequest
  params: {
    campaignID: uuid
  }
}
export const useAddTuitionToCampaignQuery = () => {
  const { apiClient } = useApiClient()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  return useMutation({
    mutationFn: async ({ params, body }: MutationProps) => {
      return await service.addTuitionToCampaign(params.campaignID, body)
    },
    onError: error => {
      Sentry.captureException(error)
    },
  })
}
