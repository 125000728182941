import type { AxiosInstance } from 'axios'

import type { GetEmbedDashboardReportRequest, GetEmbedReportResponse } from './types'

export const reportService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetching the metabase embed dashboard
     *
     * @param schoolId School ID
     * @param dashboardName Dashboard name
     *
     * @returns Embed report URL
     */
    fetchEmbedDashboardReport: async ({
      schoolId,
      dashboardName,
    }: GetEmbedDashboardReportRequest) => {
      const { data } = await fetcher.get<GetEmbedReportResponse>(
        `/report/dashboard/${dashboardName}/${schoolId}`
      )
      return data
    },
  }
}
