import { Skeleton } from '@gravity/skeleton'
import { CancelModalVariants, installmentHasInterestOrFine } from '.'

import { Text } from '@gravity/text'
import { ContractCancellationReason } from '@/shared/interfaces'
import { Installment } from '@/modules/contract/services/types'
import { RadioGroup, RadioGroupItem } from '@gravity/radio'
import { TextField } from '@gravity/text-field'
import { SetStateAction } from 'react'
import styled from 'styled-components'
import { formatCentsToReal } from '@/shared/utils'
import { Callout } from '@gravity/callout'

type CancelContractModalContentProps = {
  hasOverdueInstallments: boolean
  installments?: Installment[]
  is_installments_left_without_canceling: boolean
  otherReasonDescription: string
  radioOption: string
  setOtherReasonDescription: (reason: string) => void
  setRadioOption: (option: SetStateAction<string>) => void
  variant: CancelModalVariants
}

type ContractCancellationReasons = {
  [key: string]: string
}

const ContractCancellationReasonMapper: ContractCancellationReasons = {
  [ContractCancellationReason.WRONG_DATE]: 'Alteração da data',
  [ContractCancellationReason.DUPLICATED_CONTRACT]: 'Contrato duplicado',
  [ContractCancellationReason.WRONG_GUARDIAN_INFO]: 'Alteração dos dados do responsável',
  [ContractCancellationReason.STUDENT_LEFT]: 'Aluno saiu da escola',
  [ContractCancellationReason.WRONG_AMOUNT]: 'Alteração de valor',
  [ContractCancellationReason.OTHER]: 'Outro (digite o motivo abaixo)',
}

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  line-height: 24px;
  gap: 24px;
  width: 100%;
`

const ReasonContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  line-height: 24px;
  gap: 16px;
  width: 100%;
`

const SkeletonContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  height: 100%;
`

const SkeletonContentDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`

export const CancelContractModalContent = ({
  hasOverdueInstallments,
  installments,
  is_installments_left_without_canceling,
  radioOption,
  otherReasonDescription,
  setRadioOption,
  setOtherReasonDescription,
  variant,
}: CancelContractModalContentProps) => {
  const handleRadioChange = (value: SetStateAction<string>) => {
    setRadioOption(value)
    setOtherReasonDescription('')
  }

  if (variant === 'loading') {
    return (
      <SkeletonContentWrapper>
        <Skeleton width={340} height={24} />

        <SkeletonContentDetailsWrapper>
          <Skeleton width={186} height={24} />
          <Skeleton width={186} height={24} />
          <Skeleton width={186} height={24} />
        </SkeletonContentDetailsWrapper>

        <Skeleton fullWidth height={32} />
      </SkeletonContentWrapper>
    )
  }

  if (variant === 'non-cancellable') {
    return (
      <Callout
        text="Isso pode acontecer quando o contrato tem todas as parcelas pagas, todas as parcelas negociadas ou quando já está inativo, entre outros motivos."
        linkLabel="Saiba mais"
        linkTarget="_blank"
        href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/quando-um-contrato-nao-pode-ser-cancelado"
      />
    )
  }

  const overdueTuitionInstallments = installments?.filter(installmentHasInterestOrFine) ?? []

  const tuitionTotal = overdueTuitionInstallments.reduce(
    (total, installment) => total + installment.amount,
    0
  )

  const fineAndInterestTotal = overdueTuitionInstallments.reduce(
    (total, installment) => total + installment.current_fine + installment.current_interest,
    0
  )

  if (variant === 'overdue-act' || variant === 'overdue-acknowledge') {
    return (
      <ContentWrapper>
        {is_installments_left_without_canceling && (
          <div>
            {hasOverdueInstallments && (
              <Text variant="body-1-medium" as="div">
                Parcelas pagas e negociadas
              </Text>
            )}

            <Text variant="body-1-regular" as="div">
              Estas parcelas não serão canceladas. Se existir parcela negociada em aberto, a
              cobrança permanece ativa mesmo com o contrato se tornando inativo. Ou seja, os
              responsáveis vão continuar sendo cobrados.
            </Text>
          </div>
        )}

        {hasOverdueInstallments && (
          <div>
            {is_installments_left_without_canceling && (
              <Text variant="body-1-medium" as="div">
                Parcelas vencidas
              </Text>
            )}

            <Text variant="body-1-regular" as="div">
              {overdueTuitionInstallments.length === 1 && !is_installments_left_without_canceling
                ? 'Esta parcela será cancelada. Será descontado no próximo repasse:'
                : 'Estas parcelas serão canceladas. Será descontado no próximo repasse:'}
            </Text>
          </div>
        )}

        {hasOverdueInstallments && (
          <>
            <div>
              <div>
                <Text variant="body-1-medium" as="span">
                  Mensalidades:{' '}
                </Text>
                <Text variant="body-1-regular" as="span">
                  {formatCentsToReal(tuitionTotal)}
                </Text>
              </div>

              <Text>
                <Text variant="body-1-medium" as="span">
                  Multas e juros em atraso:{' '}
                </Text>
                <Text variant="body-1-regular" as="span">
                  {formatCentsToReal(fineAndInterestTotal)}
                </Text>
              </Text>
            </div>

            {variant === 'overdue-act' && (
              <Text>
                Se preferir, você pode ver os detalhes destas parcelas antes de cancelar o contrato.
              </Text>
            )}
          </>
        )}
      </ContentWrapper>
    )
  }

  const otherReasonDisabled = radioOption !== ContractCancellationReason.OTHER

  if (variant === 'reason') {
    return (
      <ReasonContentWrapper>
        <Text variant="subtitle-medium">Qual é o motivo do cancelamento?</Text>

        <RadioGroup
          aria-label="Cancellation reasons options"
          name="radio-buttons-group"
          value={radioOption}
          onValueChange={handleRadioChange}
        >
          {Object.entries(ContractCancellationReasonMapper).map(([reasonType, reason]) => (
            <RadioGroupItem
              size={1}
              value={reasonType}
              label={reason}
              id={reasonType}
              key={reasonType}
            />
          ))}
        </RadioGroup>
        <TextField
          name="Motivo"
          label="Motivo"
          value={otherReasonDescription}
          disabled={otherReasonDisabled}
          onChange={e => setOtherReasonDescription(e.target.value)}
        />
      </ReasonContentWrapper>
    )
  }
}
