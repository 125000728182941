import { ReactNode } from 'react'
import { Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { TooltipButton, Typography } from '@olaisaac/design-system'

import { EnabledEnrollmentCycle } from '@/modules/enrollment/services/types'
import { StatusBadge } from '@/modules/enrollment/components/StatusBadge'
import { StatusDetailCards } from '@/modules/enrollment/pages/StudentEnrollment/components/StatusDetailCards'
import theme from '@/shared/theme'

import { useStatusData } from './hooks/useStatusData'
import { RefreshSituationButton } from '../RefreshSituationButton'
import * as Styled from './styles'

type StatusBannerProps = {
  data: EnabledEnrollmentCycle
  hasEnrollmentCycleEnabled: boolean
  mainGuardianId: string
  onClickRefresh: () => Promise<void>
  renderCycleSelector: ReactNode
  studentId: string
}

export const StatusBanner = ({
  data,
  hasEnrollmentCycleEnabled,
  mainGuardianId,
  studentId,
  renderCycleSelector,
  onClickRefresh,
}: StatusBannerProps) => {
  const { statusText, statusColor } = useStatusData(data.student_status)

  if (!data || !hasEnrollmentCycleEnabled) {
    return (
      <Styled.Container style={{ backgroundColor: theme.primitiveTokens.colors.gray[3] }}>
        <Styled.BannerHeader>
          <Typography variation="bodyLarge">
            Não há dados de matrícula para serem exibidos no ano letivo atual
          </Typography>
        </Styled.BannerHeader>
      </Styled.Container>
    )
  }

  return (
    <Styled.Container style={{ backgroundColor: statusColor }}>
      <Styled.BannerHeader>
        <Typography variation="subtitleDesktopLarge">
          {`Situação de matrícula ${data.reference_year}`}
        </Typography>

        <Styled.BadgeContainer>
          <StatusBadge status={data.student_status} />
          {statusText ? (
            <Tooltip title={statusText} style={{ marginLeft: 4 }}>
              <TooltipButton>
                <InfoOutlinedIcon color="action" fontSize="small" />
              </TooltipButton>
            </Tooltip>
          ) : null}
        </Styled.BadgeContainer>

        <RefreshSituationButton status={data?.student_status} refreshSituation={onClickRefresh} />

        <Styled.RefreshButtonContainer>{renderCycleSelector}</Styled.RefreshButtonContainer>
      </Styled.BannerHeader>

      <StatusDetailCards
        enrollmentYear={data.reference_year}
        status={data.student_status}
        details={data.student_status_details}
        studentId={studentId}
        mainGuardianId={mainGuardianId}
      />
    </Styled.Container>
  )
}
