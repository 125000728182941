import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes as scopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents as events } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { ReactNode, createContext, useContext } from 'react'

type Events = {
  batchPageDashboardViewed: () => void
  batchPageDoneViewed: () => void
  cancelEditCharge: () => void
  cancelEditContractByProduct: () => void
  cancelEditGuardian: () => void
  cancelEditStudent: () => void
  cancelOverrideBatch: () => void
  closeEditChargeModal: () => void
  closeEditContractByProductModal: () => void
  closeEditGuardianModal: () => void
  closeEditStudentModal: () => void
  closeOverrideBatchModal: () => void
  confirmEditCharge: () => void
  confirmEditContractByProduct: () => void
  confirmEditGuardian: () => void
  confirmEditStudent: () => void
  confirmOverrideBatch: () => void
  continueBatchButtonClicked: () => void
  contractByProductTabViewed: () => void
  contractChargesTabViewed: () => void
  editButtonClicked: () => void
  editDiscountType: () => void
  exportContractsButtonClicked: () => void
  guardianTabViewed: () => void
  moreInfoAboutEditContractByProduct: () => void
  moreInfoAboutEditGuardian: () => void
  moreInfoAboutEditStudent: () => void
  overrideSpreadsheetButtonClicked: (pageName: string) => void
  redirectToHomeButtonCLicked: () => void
  sendToOpsAgentButtonClicked: () => void
  startUploadButtonClicked: () => void
  studentsTabViewed: () => void
  submitBatchButtonClicked: () => void
  uploadPageViewed: () => void
  validatePageViewed: () => void
}

type EventContext = Events | null

export enum pageNames {
  BATCH_PAGE = 'Visão final de migração',
  BATCH_RESULT_PAGE = 'Resultado de migração',
  UPLOAD_PAGE = 'Importar planilha',
  VALIDATE_PAGE = 'Corrigir dados da escola',
}

const EventContext = createContext<EventContext>(null)

export const EventProvider = ({ children }: { children: ReactNode }) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  type Event = Parameters<typeof eventDispatcherClient.sendEvent>[0]

  const send = (event: Event) => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent(event)
  }

  const sendEvents: Events = {
    overrideSpreadsheetButtonClicked: (pageName: string) => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageName,
          $button_name: 'Substituir planilha',
        },
      })
    },
    batchPageDashboardViewed: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $page_name: pageNames.BATCH_PAGE,
        },
      })
    },
    continueBatchButtonClicked: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageNames.VALIDATE_PAGE,
          $button_name: 'Continuar',
        },
      })
    },
    validatePageViewed: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $page_name: pageNames.VALIDATE_PAGE,
        },
      })
    },
    editButtonClicked: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageNames.VALIDATE_PAGE,
          $button_name: 'Corrigir dados',
        },
      })
    },
    contractByProductTabViewed: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.PAGE_VIEWED,
        action: 'click',
        customProperties: {
          $type: 'Dados acadêmicos',
        },
      })
    },
    studentsTabViewed: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.PAGE_VIEWED,
        action: 'click',
        customProperties: {
          $type: 'Dados de alunos',
        },
      })
    },
    guardianTabViewed: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.PAGE_VIEWED,
        action: 'click',
        customProperties: {
          $type: 'Dados de responsável',
        },
      })
    },
    contractChargesTabViewed: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.PAGE_VIEWED,
        action: 'click',
        customProperties: {
          $type: 'Dados de pagamentos',
        },
      })
    },
    batchPageDoneViewed: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $page_name: pageNames.BATCH_RESULT_PAGE,
        },
      })
    },
    exportContractsButtonClicked: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageNames.BATCH_RESULT_PAGE,
          $button_name: 'Exportar contratos',
        },
      })
    },
    sendToOpsAgentButtonClicked: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageNames.BATCH_RESULT_PAGE,
          $button_name: 'Sinalizar analista',
        },
      })
    },
    redirectToHomeButtonCLicked: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageNames.BATCH_RESULT_PAGE,
          $button_name: 'Conhecer Plataforma isaac',
        },
      })
    },
    submitBatchButtonClicked: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageNames.BATCH_PAGE,
          $button_name: 'Migrar contratos',
        },
      })
    },
    startUploadButtonClicked: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageNames.UPLOAD_PAGE,
          $button_name: 'Começar',
        },
      })
    },
    uploadPageViewed: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $page_name: pageNames.UPLOAD_PAGE,
        },
      })
    },
    cancelOverrideBatch: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Substituir planilha',
          $type: 'Cancelar',
        },
      })
    },
    confirmOverrideBatch: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Substituir planilha',
          $type: 'Substituir',
        },
      })
    },
    closeOverrideBatchModal: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Substituir planilha',
          $type: 'Fechar',
        },
      })
    },
    closeEditContractByProductModal: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados acadêmicos',
          $type: 'Fechar',
        },
      })
    },
    cancelEditContractByProduct: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados acadêmicos',
          $type: 'Cancelar',
        },
      })
    },
    confirmEditContractByProduct: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados acadêmicos',
          $type: 'Salvar',
        },
      })
    },
    moreInfoAboutEditContractByProduct: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados acadêmicos',
          $type: 'Saiba mais',
        },
      })
    },
    closeEditStudentModal: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de alunos',
          $type: 'Fechar',
        },
      })
    },
    cancelEditStudent: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de alunos',
          $type: 'Cancelar',
        },
      })
    },
    confirmEditStudent: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de alunos',
          $type: 'Salvar',
        },
      })
    },
    moreInfoAboutEditStudent: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de alunos',
          $type: 'Saiba mais',
        },
      })
    },
    closeEditGuardianModal: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de responsável',
          $type: 'Fechar',
        },
      })
    },
    cancelEditGuardian: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de responsável',
          $type: 'Cancelar',
        },
      })
    },
    confirmEditGuardian: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de responsável',
          $type: 'Salvar',
        },
      })
    },
    moreInfoAboutEditGuardian: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de responsável',
          $type: 'Saiba mais',
        },
      })
    },
    cancelEditCharge: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de pagamentos',
          $type: 'Cancelar',
        },
      })
    },
    closeEditChargeModal: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de pagamentos',
          $type: 'Fechar',
        },
      })
    },
    confirmEditCharge: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de pagamentos',
          $type: 'Salvar',
        },
      })
    },
    editDiscountType: () => {
      send({
        scope: scopes.ONBOARDING,
        name: events.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $modal_name: 'Dados de pagamentos',
          $type: 'Desconto permanente',
        },
      })
    },
  }

  return <EventContext.Provider value={sendEvents}>{children}</EventContext.Provider>
}

export const useEvents = () => useContext(EventContext)
