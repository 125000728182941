import { Contract } from '@monorepo/onboarding/services/types/onboardingContract'
import { Product } from '@monorepo/onboarding/services/types/onboardingProduct'

import { Button } from '@gravity/button'
import { Callout } from '@gravity/callout'
import { Dialog } from '@gravity/dialog'
import { Select, Option } from '@gravity/select'
import { Text } from '@gravity/text'
import { TextField } from '@gravity/text-field'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'

// TODO: available options for reference_year
const currentYear = new Date().getFullYear()
const REFERENCE_YEAR_OPTIONS = [currentYear, currentYear + 1].map(i => ({
  value: String(i),
  label: String(i),
}))

enum ProductErrorCode {
  COURSE_REQUIRED = 'product.course:required',
  GRADE_REQUIRED = 'product.grade:required',
  REFERENCE_YEAR_INVALID_FORMAT = 'product.reference_year:invalid_format',
  REFERENCE_YEAR_REQUIRED = 'product.reference_year:required',
}

const ERROR_MESSAGES: Record<ProductErrorCode, string> = {
  [ProductErrorCode.COURSE_REQUIRED]: 'Informe um curso válido',
  [ProductErrorCode.GRADE_REQUIRED]: 'Informe uma série válida',
  [ProductErrorCode.REFERENCE_YEAR_REQUIRED]: 'Informe um ano letivo válido',
  [ProductErrorCode.REFERENCE_YEAR_INVALID_FORMAT]: 'Informe um ano letivo válido',
}

const ReferenceYearAndGradeContainer = styled.div`
  display: flex;
  gap: 24px;
  margin: 32px 0 16px;
`

const StudentNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`

type FieldName = keyof Omit<Product, 'validations' | 'is_valid'>

const isError = (
  name: FieldName,
  product: Product,
  originalProduct: Product
): [boolean, string] => {
  const field = `product.${name}`

  const error =
    product.validations.some(i => i.field === field) && product[name] === originalProduct[name]

  const errorMessage =
    ERROR_MESSAGES[
      product.validations.filter(i => i.field === field)[0]?.error_code as ProductErrorCode
    ]

  return [error, errorMessage]
}

const CustomTextField = ({
  name,
  label,
  product,
  onChange,
  originalProduct,
}: {
  label: string
  name: FieldName
  onChange: (name: string, value: string) => void
  originalProduct: Product
  product: Product
}) => {
  const [error, errorMessage] = isError(name, product, originalProduct)

  return (
    <TextField
      fullWidth
      size={3}
      label={label}
      name={name}
      value={product[name]}
      onChange={e => onChange(e.target.name, e.target.value)}
      errorMessage={errorMessage}
      error={error}
    />
  )
}

const CustomSelect = ({
  name,
  label,
  product,
  onChange,
  originalProduct,
  options,
}: {
  label: string
  name: FieldName
  onChange: (name: string, value: string) => void
  options: Option[]
  originalProduct: Product
  product: Product
}) => {
  const [error, errorMessage] = isError(name, product, originalProduct)

  return (
    <Select
      fullWidth
      size={3}
      name={name}
      variant="surface"
      label={label}
      placeholder={originalProduct[name]}
      options={options}
      onValueChange={v => onChange(name, v)}
      enableHelperTextSlot
      hasError={error}
      helperText={error ? errorMessage : ''}
    />
  )
}

export const EditDialog = ({
  contract,
  setCurrentContract,
  onSave,
  isLoading,
}: {
  contract: Contract
  isLoading: boolean
  onSave: () => void
  setCurrentContract: (c: Contract | null) => void
}) => {
  // Used to determine if any fields have been modified by the user
  const originalProduct = useRef<Product>(contract.product)
  const events = useEvents()

  useEffect(() => {
    originalProduct.current = contract.product
  }, [contract.product.validations])

  const onChange = (name: string, value: string) => {
    setCurrentContract({
      ...contract,
      product: {
        ...contract.product,
        [name]: value,
      },
    })
  }

  const onClose = () => {
    events?.closeEditContractByProductModal()
    setCurrentContract(null)
  }

  const product = contract.product

  return (
    <Dialog
      size={3}
      backdrop
      modal
      open
      onOpenChange={onClose}
      title="Corrigir dados acadêmicos"
      content={
        <div>
          <StudentNameContainer>
            <TextField
              size={3}
              label="Aluno"
              name="student"
              value={contract.student.name}
              disabled
            />
            <Text variant="caption-regular">
              Só é possível editar nome na seção "Dados de alunos", e se este aluno estiver com
              algum dado pendente. Caso contrário, só é possível editar após a migração de
              contratos.
            </Text>
          </StudentNameContainer>
          <CustomTextField
            name="course"
            label="Curso"
            onChange={onChange}
            product={product}
            originalProduct={originalProduct.current}
          />
          <ReferenceYearAndGradeContainer>
            <CustomSelect
              name="reference_year"
              label="Ano letivo"
              product={product}
              originalProduct={originalProduct.current}
              options={REFERENCE_YEAR_OPTIONS}
              onChange={onChange}
            />
            <CustomTextField
              name="grade"
              label="Série"
              onChange={onChange}
              product={product}
              originalProduct={originalProduct.current}
            />
          </ReferenceYearAndGradeContainer>
          <Callout
            text="Ano letivo e série são dados acadêmicos obrigatórios para a migração dos contratos para a Plataforma isaac."
            href="https://centraldeajuda.olaisaac.io" // TODO: update link
            color="gray"
            linkTarget="_blank"
            onLinkClick={events?.moreInfoAboutEditContractByProduct}
          />
        </div>
      }
      actionButton={
        <Button
          onClick={() => {
            events?.confirmEditContractByProduct()
            return onSave()
          }}
          loading={isLoading}
        >
          Salvar
        </Button>
      }
      cancelButton={
        <Button onClick={events?.cancelEditContractByProduct} disabled={isLoading} variant="ghost">
          Cancelar
        </Button>
      }
    />
  )
}
