import { AxiosError } from 'axios'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks'

import { studentsService } from '@monorepo/students/services/students'
import {
  UpdateStudentRequest,
  UpdateStudentResponse,
} from '@monorepo/students/services/students/types'

export const useMutateOnUpdateStudent = (
  options?: UseMutationOptions<UpdateStudentResponse, AxiosError, UpdateStudentRequest, unknown>
) => {
  const { apiClient } = useApiClient()

  const service = studentsService(apiClient.getClients().privateApiV2)

  return useMutation(service.updateStudent, options)
}
